
import DateFormat from '../common/dateFormat/DateFormat';

const INITIAL_STATE = {
	registro: {},
	modoTela: 'lista',
	lista: [],
	listaSolicitacoes: [],
	listaPrevisoes: [],
	listaEventos: [],
	listaPrevisoesEventos: [],
	listaOcorrencias: [],
	listaFronteiras: [],
	listaNegociadores: [],
	listaRumos: [{ id: 'RN', valor: 'Rumo Norte' }, { id: 'RS', valor: 'Rumo Sul' }, { id: 'NAC', valor: 'Nacional' }],
	listaFarolAtraso: [{ id: 'NENHUM', valor: 'Informação Insuficiente' }, { id: 'ADIANTADO', valor: 'Adiantado' }, { id: 'ATRASADO', valor: 'Atrasado' }],
	listaOperacoes: [],
	aguardando: false,
	filtro: {
		data_inicial: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAnterior(30)),
		data_final: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAtual())
	}
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case 'REPORTS_CONFORMIDADE_MODO_TELA':
            return { ...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};

		case 'REPORTS_CONFORMIDADE_SET_AGUARDANDO':
            return { ...state,
				aguardando: action.payload
			};

		case 'REPORTS_CONFORMIDADE_FILTRO':
            return { ...state,
				filtro: action.payload.filtro
			};

        case 'REPORTS_CONFORMIDADE_LISTADO':
            return {
				...state,
				lista: action.payload.data
			};
		
		case 'REPORTS_CONFORMIDADE_SOLICITACAO_LISTADO':
			return {
				...state,
				listaSolicitacoes: action.payload.data[0] ? action.payload.data[0].solicitacoes || [] : [],
				listaPrevisoes: action.payload.data[0] ? action.payload.data[0].previsoes || [] : [],
				listaEventos: action.payload.data[0] ? action.payload.data[0].eventos || [] : [],
				listaPrevisoesEventos: action.payload.data[0] ? action.payload.data[0].lista_evento_previsao || [] : []
			};	
	
		case 'REPORTS_CONFORMIDADE_OCORRENCIA_LISTADO':
			return {
				...state,
				listaOcorrencias: action.payload.data || []
			};

		case 'REPORTS_CONFORMIDADE_FRONTEIRA_LISTADO':
			return {
				...state,
				listaFronteiras: action.payload.data || []
			};

		case 'REPORTS_CONFORMIDADE_NEGOCIADOR_LISTADO':
			return {
				...state,
				listaNegociadores: action.payload.data || []
			};

		case 'REPORTS_CONFORMIDADE_OPERACAO_LISTADO':
			return {
				...state,
				listaOperacoes: (action.payload.data || []).filter(item => item.id != 575) //EXCECAO_GERAL
			};
		
        default:
            return state;
    }
}
