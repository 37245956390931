import DateFormat from '../common/dateFormat/DateFormat';

const INITIAL_STATE = {
	aguardando: false,
	registro: {},
	modoTela: 'lista',
	filtro: {
		data_inicial: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAnterior(30)),
		data_final: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAtual())
	},
	lista: [],
	listaTipo: [],
	listaEvento: [],
	listaUnidade: [],
	listaVeiculo: [],
	listaMotorista: []
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case 'ACESSORIO_TRANSFERENCIA_MODO_TELA':
            return { ...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};

		case 'ACESSORIO_TRANSFERENCIA_SET_AGUARDANDO':
            return { ...state,
				aguardando: action.payload
			};

        case 'ACESSORIO_TRANSFERENCIA_FILTRO':
            return {
				...state,
				filtro: action.payload.filtro
			};

        case 'ACESSORIO_TRANSFERENCIA_LISTADO':
            return {
				...state,
				lista: action.payload.data
			};

        case 'ACESSORIO_TRANSFERENCIA_TIPO_SELECT_LISTADO':
			return {
				...state,
				listaTipo: action.payload.data
			};

        case 'ACESSORIO_TRANSFERENCIA_EVENTO_SELECT_LISTADO':
			return {
				...state,
				listaEvento: action.payload.data
			};
			
        case 'ACESSORIO_TRANSFERENCIA_UNIDADE_SELECT_LISTADO':
			return {
				...state,
				listaUnidade: action.payload.data
			};

        case 'ACESSORIO_TRANSFERENCIA_VEICULO_SELECT_LISTADO':
			return {
				...state,
				listaVeiculo: action.payload.data
			};

        case 'ACESSORIO_TRANSFERENCIA_MOTORISTA_SELECT_LISTADO':
			return {
				...state,
				listaMotorista: action.payload.data
			};

        default:
            return state;
    }
}
