import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setError, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import DateFormat from '../common/dateFormat/DateFormat';
import FormatUtils from '../common/formatUtils/FormatUtils';

export function setModoTela(modo, registro = {}) {
    return {
        type: 'COMPROVANTE_ENTREGA_MODO_TELA',
        payload: {
			modo: modo,
			registro: registro
		}
    };
}
export function initForm(registro = {}) {
    return [
        initialize('comprovanteEntregaForm', registro)
    ];
}

export function getListaLog(dataInicial, dataFinal) {
	return (dispatch, getState) => {
		axios.get(`${consts.API_URL}/comprovanteEntregaLog?
			data_inicial=${DateFormat.formatarDataTelaParaSql(dataInicial) == 'Invalid DateTime' ? null : DateFormat.formatarDataTelaParaSql(dataInicial)}
			&data_final=${DateFormat.formatarDataTelaParaSql(dataFinal) == 'Invalid DateTime' ? null : DateFormat.formatarDataTelaParaSql(dataFinal)}`)
		.then(resp => {
			console.log(resp);
			dispatch({
		        type: 'COMPROVANTE_ENTREGA_LOG_LISTADO',
		        payload: resp
		    });
		}).catch(e => {
			setErroAPI(e);
		});
	}
}

export function getLista(crt) {
	return (dispatch, getState) => {
		axios.get(`${consts.API_URL}/reports/buscarCRT?crt=${crt}`)
		.then(resp => {
			
			if (resp.data.length == 0 && crt != '00.000.000.000') {
				setError('Nenhum registro encontrado');	
			}

			dispatch({
		        type: 'COMPROVANTE_ENTREGA_LISTADO',
		        payload: resp
		    });
		}).catch(e => {
			setErroAPI(e);
		});
	}
}

export function gerarPdf(registro, dataInicial, dataFinal) {

	return (dispatch, getState) => {

		axios.post(`${consts.API_URL}/comprovanteEntregaLog`, {
            ...registro,
			id_usuario_inclusao: getState().auth.usuario.id,
			id_usuario_alteracao: getState().auth.usuario.id
        }).then(resp => {
			window.open(`${consts.URL}/${resp.data}`, '_blank');

			dispatch(getListaLog(dataInicial, dataFinal));
		}).catch(ex => {
			console.log(ex);
		});

	}
}