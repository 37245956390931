import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ContentCardFooter from '../common/template/contentCardFooter';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import LabelAndInputDateTime from '../common/form/labelAndInputDateTime';
import Select from '../common/form/select';
import SelectAlteracao from '../common/form/selectAlteracao';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import { setError } from '../common/toast/toast';
import ColorSelector from '../common/form/colorSeletor';
import {
	setModoTela, initForm
} from './operacaoPerfilActions';

class OperacaoPerfilForm extends Component {

	state = {

	}

	componentDidMount() {

	}

    componentWillMount() {

    }

    render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';

        return (
			<div>
				<ContentCard>
					<Form event={this.props.handleSubmit}>
						<ContentCardBody>
							<Row>
								<Field
									name='nome'
									component={LabelAndInput}
									label='Nome' placeholder='Informe o nome'
									cols='12 12 12 12'
									readOnly={readOnly} />
							</Row>			
							<Row>
								<Field
									name='cor'
									component={ColorSelector}
									label='Cor'
									cols='12 10 6 4'
									readOnly={readOnly}
									color={this.props.formularioValues.cor || '#fff'}
									complete={(color) => {
										this.props.initForm({ ...this.props.formularioValues, cor: color.hex });
									}} />
							</Row>			
						</ContentCardBody>
						<ContentCardFooter>
							<Row alignCenter>
								<Grid cols='6 6 4 3'>
									{this.props.excluir ? (
										<Button
											text='Excluir'
											submit type={'danger'}
											icon={'fa fa-trash'} />
									) : (
									<Button
										text='Salvar'
										submit
										type={'success'}
										icon={'fa fa-check'} />
									)}
								</Grid>
								<Grid cols='6 6 4 3'>
									<Button
										text='Voltar'
										type={'warning'}
										icon={'fa fa-chevron-left'}
										event={() => this.props.setModoTela('lista')} />
								</Grid>
							</Row>
						</ContentCardFooter>
					</Form>
				</ContentCard>
			</div>
        )
    }

}

OperacaoPerfilForm = reduxForm({form: 'operacaoPerfilForm', destroyOnUnmount: false})(OperacaoPerfilForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	formularioValues: getFormValues('operacaoPerfilForm')(state),
	registro: state.operacaoPerfil.registro
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, initForm
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(OperacaoPerfilForm);
