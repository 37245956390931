import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import Form from './comissaoTipoForm';
import Aguardando from '../common/template/aguardando';
import FormatUtils from '../common/formatUtils/FormatUtils';
import ReactExport from 'react-export-excel-fixed-xlsx';

import { 
	setModoTela, initForm, salvar, excluir, getLista
} from './comissaoTipoActions';

class ComissaoTipo extends Component {

	state = {
		linhaSelecionada: null
	}

    componentWillMount() {
        this.props.getLista();
    }

    render() {
        return (
            <div>
                <Content>

					<Aguardando aguardando={this.props.aguardando} />

					{this.props.modoTela == 'cadastro' ? (
						<Form onSubmit={this.props.salvar} />
					) : null}
					{this.props.modoTela == 'exclusao' ? (
						<Form excluir onSubmit={this.props.excluir} />
					) : null}
					{this.props.modoTela == 'lista' ? this.lista() : null}
                </Content>
            </div>
        )
    }

	lista() {

		const ExcelFile = ReactExport.ExcelFile;
		const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
		const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

		let lista  = this.props.lista.filter(item => {

			if (this.state.pesquisar
				&& !(`${item.id} ${item.nome || ''}`).toUpperCase().includes(this.state.pesquisar.toUpperCase())) {
				return false;
			}
			return true;
		});

		return (
			<ContentCard>
				<ContentCardHeader>
					<Row>
						<Grid cols='6 6 4 2'>
							<Button
								text='Adicionar'
								type={'success'}
								icon={'fa fa-plus'}
								event={() => {
									this.props.setModoTela('cadastro', {});
									this.props.initForm({});
								}} />
						</Grid>
						<Grid cols='6 6 4 2'>
							<ExcelFile
								filename={'Comissão - Tipo'}
								element={
									<Button
										text='Exportar'
										type={'primary'}
										icon={'fa fa-plus'} />
								}>
								<ExcelSheet data={lista.map(item => ({
									...item,
									conquista_integral: parseFloat(item.conquista_integral),
									conquista_30_60: parseFloat(item.conquista_30_60),
									conquista_mais_60: parseFloat(item.conquista_mais_60),
									manutencao_integral: parseFloat(item.manutencao_integral),
									manutencao_30_60: parseFloat(item.manutencao_30_60),
									manutencao_mais_60: parseFloat(item.manutencao_mais_60)
								}))} name='Comissão - Tipo'>
									<ExcelColumn label='Cód.' value='id' />
									<ExcelColumn label='Nome' value='nome' />
									<ExcelColumn label='Conquista (Integral)' value='conquista_integral'  />
									<ExcelColumn label='Conquista (30 até 60)' value='conquista_30_60'  />
									<ExcelColumn label='Conquista (Mais de 60)' value='conquista_mais_60'  />
									<ExcelColumn label='Manutenção (Integral)' value='manutencao_integral'  />
									<ExcelColumn label='Manutenção (30 até 60)' value='manutencao_30_60'  />
									<ExcelColumn label='Manutenção (Mais de 60)' value='manutencao_mais_60'  />
								</ExcelSheet>
							</ExcelFile>
						</Grid>
					</Row>
				</ContentCardHeader>
				<ContentCardBody>
					
					<input className='form-control'
						 placeholder='Pesquisar'
						 type='text'
						 value={this.state.pesquisar}
						 onChange={e => {
							 this.setState({ ...this.state, pesquisar: e.target.value });
						 }} />

					<Table responsive>
						<THead>
							<Tr>
								<Th rowspan={2}>Cód.</Th>
								<Th rowspan={2}>Nome</Th>
								<Th colspan={3} alignCenter>Conquista</Th>
								<Th colspan={3} alignCenter>Manutenção</Th>
								<Th rowspan={2}></Th>
							</Tr>
							<Tr>
								<Th alignCenter>Integral</Th>
								<Th alignCenter>30 até 60</Th>
								<Th alignCenter>Mais de 60</Th>
								<Th alignCenter>Integral</Th>
								<Th alignCenter>30 até 60</Th>
								<Th alignCenter>Mais de 60</Th>
							</Tr>
						</THead>
						<TBody>
							{lista.map(item => (
								<Tr key={item.id} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
									onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
									onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
									<Td>{item.id}</Td>
									<Td>{item.nome}</Td>
									<Td alignCenter>{FormatUtils.formatarValorTela(item.conquista_integral, 3)}</Td>
									<Td alignCenter>{FormatUtils.formatarValorTela(item.conquista_30_60, 3)}</Td>
									<Td alignCenter>{FormatUtils.formatarValorTela(item.conquista_mais_60, 3)}</Td>
									<Td alignCenter>{FormatUtils.formatarValorTela(item.manutencao_integral, 3)}</Td>
									<Td alignCenter>{FormatUtils.formatarValorTela(item.manutencao_30_60, 3)}</Td>
									<Td alignCenter>{FormatUtils.formatarValorTela(item.manutencao_mais_60, 3)}</Td>
									<Td alignRight minWidth={100}>
										<ButtonTable
											type={'warning'}
											icon={'fas fa-pencil-alt'}
											visible={this.state.linhaSelecionada == item.id}
											event={() => {
												this.props.setModoTela('cadastro', {
													...item,
													conquista_integral: FormatUtils.formatarValorTela(item.conquista_integral, 3),
													conquista_30_60: FormatUtils.formatarValorTela(item.conquista_30_60, 3),
													conquista_mais_60: FormatUtils.formatarValorTela(item.conquista_mais_60, 3),
													manutencao_integral: FormatUtils.formatarValorTela(item.manutencao_integral, 3),
													manutencao_30_60: FormatUtils.formatarValorTela(item.manutencao_30_60, 3),
													manutencao_mais_60: FormatUtils.formatarValorTela(item.manutencao_mais_60, 3)
												});
												this.props.initForm({
													...item,
													conquista_integral: FormatUtils.formatarValorTela(item.conquista_integral, 3),
													conquista_30_60: FormatUtils.formatarValorTela(item.conquista_30_60, 3),
													conquista_mais_60: FormatUtils.formatarValorTela(item.conquista_mais_60, 3),
													manutencao_integral: FormatUtils.formatarValorTela(item.manutencao_integral, 3),
													manutencao_30_60: FormatUtils.formatarValorTela(item.manutencao_30_60, 3),
													manutencao_mais_60: FormatUtils.formatarValorTela(item.manutencao_mais_60, 3)
												});
											}} />

										<ButtonTable
											type={'danger'}
											icon={'fas fa-trash-alt'}
											visible={this.state.linhaSelecionada == item.id}
											event={() => {
												this.props.setModoTela('exclusao', {
													...item,
													conquista_integral: FormatUtils.formatarValorTela(item.conquista_integral, 3),
													conquista_30_60: FormatUtils.formatarValorTela(item.conquista_30_60, 3),
													conquista_mais_60: FormatUtils.formatarValorTela(item.conquista_mais_60, 3),
													manutencao_integral: FormatUtils.formatarValorTela(item.manutencao_integral, 3),
													manutencao_30_60: FormatUtils.formatarValorTela(item.manutencao_30_60, 3),
													manutencao_mais_60: FormatUtils.formatarValorTela(item.manutencao_mais_60, 3)
												});
												this.props.initForm({
													...item,
													conquista_integral: FormatUtils.formatarValorTela(item.conquista_integral, 3),
													conquista_30_60: FormatUtils.formatarValorTela(item.conquista_30_60, 3),
													conquista_mais_60: FormatUtils.formatarValorTela(item.conquista_mais_60, 3),
													manutencao_integral: FormatUtils.formatarValorTela(item.manutencao_integral, 3),
													manutencao_30_60: FormatUtils.formatarValorTela(item.manutencao_30_60, 3),
													manutencao_mais_60: FormatUtils.formatarValorTela(item.manutencao_mais_60, 3)
												});
											}} />
									</Td>
								</Tr>
							))}
						</TBody>
					</Table>
				</ContentCardBody>
			</ContentCard>
		);
	}


}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	modoTela: state.comissaoTipo.modoTela,
	aguardando: state.comissaoTipo.aguardando,
	lista: state.comissaoTipo.lista
});
const mapDispatchToProps = dispatch => bindActionCreators({ 
	setModoTela, initForm, salvar, excluir, getLista
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ComissaoTipo);
