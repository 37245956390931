import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ContentCardFooter from '../common/template/contentCardFooter';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import LabelAndInputDateTime from '../common/form/labelAndInputDateTime';
import Select from '../common/form/select';
import SelectAlteracao from '../common/form/selectAlteracao';
import LabelAndCheckbox from '../common/form/labelAndCheckbox';

import {
	setModoTelaItem, initFormItem
} from './operacaoActions';

class OperacaoDocumentoForm extends Component {

	state = {

	}

    componentWillMount() {

    }

    render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';

        return (
			<ContentCard>
				<Form event={this.props.handleSubmit}>
					<ContentCardBody>

						<Row>
							<Field
								name='id_documento'
								component={Select}
								options={this.props.listaDocumento}
								label='Documento'
								cols='12 12 12 12'
								placeholder='Selecione o documento'
								readOnly={readOnly} />
						</Row>
						<Row>
							<Field
								name='id_cliente_tipo'
								component={Select}
								options={this.props.listaTipo}
								label='Cliente'
								cols='12 12 12 12'
								placeholder='Selecione'
								readOnly={readOnly} />
						</Row>
						<Row>
							<Field
								name='id_letsara_tipo'
								component={Select}
								options={this.props.listaTipo}
								label='Letsara'
								cols='12 12 12 12'
								placeholder='Selecione'
								readOnly={readOnly} />
						</Row>
						<Row>
							<Field
								name='digital'
								component={LabelAndCheckbox}
								label='Digital'
								cols='12 12 12 12'
								placeholder='Selecione se o documento digital é obrigatório'
								readOnly={readOnly} />
						</Row>

					</ContentCardBody>
					<ContentCardFooter>
						<Row alignCenter>
							<Grid cols='6 6 4 3'>
								{this.props.excluir ? (
									<Button
										text='Excluir'
										submit type={'danger'}
										icon={'fa fa-trash'} />
								) : (
								<Button
									text='Salvar'
									submit
									type={'success'}
									icon={'fa fa-check'} />
								)}
							</Grid>
							<Grid cols='6 6 4 3'>
								<Button
									text='Voltar'
									type={'warning'}
									icon={'fa fa-chevron-left'}
									event={() => this.props.setModoTelaItem('lista')} />
							</Grid>
						</Row>
					</ContentCardFooter>
				</Form>
			</ContentCard>
        )
    }

}

OperacaoDocumentoForm = reduxForm({form: 'operacaoItemForm', destroyOnUnmount: false})(OperacaoDocumentoForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	formularioValues: getFormValues('operacaoItemForm')(state),
	listaDocumento: state.operacao.listaDocumento,
	listaTipo: state.operacao.listaTipo
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTelaItem, initFormItem
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(OperacaoDocumentoForm);
