import DateFormat from '../common/dateFormat/DateFormat';

const INITIAL_STATE = {
	aguardando: false,
	registro: {},
	modoTela: 'lista',
	filtro: {
	},
	listaTipo: [],
	listaSaldoUnidade: [],
	listaSaldoVeiculo: []
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case 'ACESSORIO_SALDO_MODO_TELA':
            return { ...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};

		case 'ACESSORIO_SALDO_SET_AGUARDANDO':
            return { ...state,
				aguardando: action.payload
			};

        case 'ACESSORIO_SALDO_FILTRO':
            return {
				...state,
				filtro: action.payload.filtro
			};
		
		case 'ACESSORIO_SALDO_TIPO_SELECT_LISTADO':
            return {
				...state,
				listaTipo: action.payload.data
			};

        case 'PRINCIPAL_SALDO_UNIDADE_LISTADO':
            return {
				...state,
				listaSaldoUnidade: action.payload.data
			};

        case 'PRINCIPAL_SALDO_VEICULO_LISTADO':
			return {
				...state,
				listaSaldoVeiculo: action.payload.data
			};

        default:
            return state;
    }
}
