import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ContentCardFooter from '../common/template/contentCardFooter';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import LabelAndInputDateTime from '../common/form/labelAndInputDateTime';
import Select from '../common/form/select';
import SelectDefault from '../common/form/selectDefault';
import SelectAlteracao from '../common/form/selectAlteracao';

import {
	setModoTela, initForm, buscarViagemPeloMotorista
} from './despesaActions';
import DateFormat from '../common/dateFormat/DateFormat';
import FormatUtils from '../common/formatUtils/FormatUtils';
import ButtonTable from '../common/table/buttonTable';
import selectDefault from '../common/form/selectDefault';

class DespesaForm extends Component {

	state = {

	}

    componentWillMount() {

    }

    render() {

		let readOnly = this.props.excluir || this.props.formularioValues.datahora_aprovacao || this.props.formularioValues.datahora_reprovacao ? 'readOnly' : '';

        return (
			<ContentCard>
				<Form event={this.props.handleSubmit}>
					<ContentCardBody>
						<Row>

							<Select
								name='id_motorista'
								options={this.props.listaMotorista}
								label='Motorista' placeholder='Informe o motorista'
								cols='12 12 12 12'
								readOnly={this.props.registro.id ? 'readOnly' : readOnly}
								value={this.props.formularioValues.id_motorista}
								onChange={value => {
									this.props.initForm({
										...this.props.formularioValues,
										id_motorista: value
									});
									let motorista = this.props.listaMotorista.filter(item => item.id == value)[0];
									if (motorista) {
										this.props.buscarViagemPeloMotorista({
											...this.props.formularioValues,
											id_motorista: value
										}, motorista.valor);
									}
								}} />
								
							<Field
								name='id_tipo'
								component={Select}
								options={this.props.listaTipo.filter(item => item.vigente)}
								label='Tipo' placeholder='Informe o tipo'
								cols='12 12 12 12'
								readOnly={readOnly} />
								
							{!this.props.registro.id ? (
								<Select
									options={(this.props.formularioValues.listaDocumentos || []).map(item => ({ 
										id: item.documento, 
										valor: `${item.tipo_documento}: ${item.numero_documento} | ${item.cavalo} | Cavalo: ${item.cavalo} | Remet: ${item.nome_remetente} | Dest: ${item.nome_destinatario}`
									}))}
									label='Documento' placeholder='Selecione o Documento'
									cols='12 12 12 12'
									readOnly={readOnly}
									value={this.props.formularioValues.documento}
									onChange={value => {

										let documento = (this.props.formularioValues.listaDocumentos || []).filter(item => item.id == value)[0];

										this.props.initForm({
											...this.props.formularioValues,
											id_shipping_code: documento ? documento.id_shipping_code : null,
											placa: documento ? documento.cavalo : null,
											documento_tipo: documento ? documento.tipo_documento : null,
											documento: documento ? documento.numero_documento : null,
											operacao: documento ? documento.operacao : null,
											cnpj_remetente: documento ? documento.cnpj_remetente : null,
											nome_remetente: documento ? documento.nome_remetente : null,
											cnpj_destinatario: documento ? documento.cnpj_destinatario : null, 
											nome_destinatario: documento ? documento.nome_destinatario : null
										});
									}} />
							) : (
							<>
								<Field
									name='operacao'
									component={LabelAndInput}
									label='Operação' placeholder='Informe a operação'
									cols='12 6 3 3'
									readOnly={'readOnly'} />
									
								<Field
									name='placa'
									component={LabelAndInput}
									label='Placa' placeholder='Informe a placa'
									cols='12 6 3 3'
									readOnly={'readOnly'} />
									
								<Field
									name='documento_tipo'
									component={SelectDefault}
									options={this.props.listaDocumentoTipo}
									label='Tipo do Documento' placeholder='Informe o tipo'
									cols='12 6 3 3'
									readOnly={'readOnly'} />
									
								<Field
									name='documento'
									component={LabelAndInput}
									label='Número do Documento' placeholder='Informe o número'
									cols='12 6 3 3'
									readOnly={'readOnly'} />


								<Field
									name='cnpj_remetente'
									component={LabelAndInput}
									label='CNPJ Remetente' placeholder='Informe o remetente'
									cols='12 6 3 3'
									readOnly={'readOnly'} />

								<Field
									name='nome_remetente'
									component={LabelAndInput}
									label='Nome Remetente' placeholder='Informe o remetente'
									cols='12 6 9 9'
									readOnly={'readOnly'} />

								<Field
									name='cnpj_destinatario'
									component={LabelAndInput}
									label='CNPJ Destinatário' placeholder='Informe o destinatário'
									cols='12 6 3 3'
									readOnly={'readOnly'} />

								<Field
									name='nome_destinatario'
									component={LabelAndInput}
									label='Nome Destinatário' placeholder='Informe o destinatário'
									cols='12 6 9 9'
									readOnly={'readOnly'} />
							</>
							)}
								
							<Field
								name='valor'
								component={LabelAndInputNumber}
								label='Valor' placeholder='Informe o valor'
								cols='12 6 4 3'
								casas={2}
								readOnly={readOnly} />
								
							<Field
								name='id_moeda'
								component={Select}
								options={this.props.listaMoeda}
								label='Moeda' placeholder='Informe a moeda'
								cols='12 6 8 9'
								readOnly={readOnly} />
							
							<Field
								name='local_despesa'
								component={LabelAndInput}
								label='Local da Despesa' placeholder='Informe o local'
								cols='12 12 12 12'
								readOnly={readOnly} />

							<Field
								name='observacao'
								component={LabelAndInput}
								label='Observação' placeholder='Informe a observação'
								cols='12 12 12 12'
								readOnly={readOnly} />

						</Row>

						{this.props.formularioValues.datahora_aprovacao &&
						<div style={{ display: 'flex', justifyContent: 'center' }}>
							<Grid cols='12 8 5 4'>
								<Row style={{ padding: 20, backgroundColor: '#1bf2aa' }}>
									<Grid cols='12 12 12 12' style={{ textAlign: 'center', fontSize: 30 }}>
										{this.props.formularioValues.token ? 'Token' : ''}
									</Grid>
									<Grid cols='12 12 12 12' style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 40, letterSpacing: 2 }}>
										{this.props.formularioValues.token ? this.props.formularioValues.token : ''}
									</Grid>
									{this.props.formularioValues.token &&
									<Grid cols='12 12 12 12' style={{ textAlign: 'center' }}>
										<ButtonTable
											type={'primary'}
											icon={'fab fa-whatsapp'}
											visible={true}
											event={() => {
												let mensagem = ''
												+ `Despesa: ${this.props.formularioValues.nome_tipo}`
												+ `\nMotorista: ${this.props.formularioValues.nome_motorista}`
												+ `\nSolicitação: ${DateFormat.formatarDataHoraSqlParaTela(this.props.formularioValues.datahora_solicitacao)}`
												+ `\nValor: ${this.props.formularioValues.simbolo_moeda} ${FormatUtils.formatarValorTela(this.props.formularioValues.valor, 2)}`
												+ `\nToken: ${this.props.formularioValues.token}`
												window.open(`https://api.whatsapp.com/send?phone=+55${this.props.formularioValues.celular_motorista || '55991735243'}&text=${encodeURI(mensagem)}`, '_blank');
											}} />
									</Grid>}
								</Row>
							</Grid>
						</div>}

						{this.props.formularioValues.datahora_solicitacao &&
						<div style={{ backgroundColor: '#f5f5f5' }}>
							{this.props.formularioValues.datahora_solicitacao &&
							<Row style={{ marginTop: 20 }}>
								<Grid cols='4 4 4 4' style={{ textAlign: 'center', fontWeight: 'bold' }}>
									Operação
								</Grid>
								<Grid cols='4 4 4 4' style={{ textAlign: 'center', fontWeight: 'bold' }}>
									Data/Hora
								</Grid>
								<Grid cols='4 4 4 4' style={{ textAlign: 'center', fontWeight: 'bold' }}>
									Usuário
								</Grid>
							</Row>}

							{this.props.formularioValues.datahora_solicitacao &&
							<Row>
								<Grid cols='4 4 4 4' style={{ textAlign: 'center' }}>
									Solicitação
								</Grid>
								<Grid cols='4 4 4 4' style={{ textAlign: 'center' }}>
									{DateFormat.formatarDataHoraSqlParaTela(this.props.formularioValues.datahora_solicitacao)}
								</Grid>
								<Grid cols='4 4 4 4' style={{ textAlign: 'center' }}>
									{this.props.formularioValues.nome_usuario_inclusao}
								</Grid>
							</Row>}

							{this.props.formularioValues.datahora_aprovacao &&
							<Row>
								<Grid cols='4 4 4 4' style={{ textAlign: 'center' }}>
									Aprovação
								</Grid>
								<Grid cols='4 4 4 4' style={{ textAlign: 'center' }}>
									{DateFormat.formatarDataHoraSqlParaTela(this.props.formularioValues.datahora_aprovacao)}
								</Grid>
								<Grid cols='4 4 4 4' style={{ textAlign: 'center' }}>
									{this.props.formularioValues.nome_usuario_aprovacao}
								</Grid>
							</Row>}

							{this.props.formularioValues.datahora_reprovacao &&
							<Row>
								<Grid cols='4 4 4 4' style={{ textAlign: 'center' }}>
									Reprovação
								</Grid>
								<Grid cols='4 4 4 4' style={{ textAlign: 'center' }}>
									{DateFormat.formatarDataHoraSqlParaTela(this.props.formularioValues.datahora_reprovacao)}
								</Grid>
								<Grid cols='4 4 4 4' style={{ textAlign: 'center' }}>
									{this.props.formularioValues.nome_usuario_reprovacao}
								</Grid>
							</Row>}

							{this.props.formularioValues.datahora_acerto &&
							<Row>
								<Grid cols='4 4 4 4' style={{ textAlign: 'center' }}>
									Acerto
								</Grid>
								<Grid cols='4 4 4 4' style={{ textAlign: 'center' }}>
									{DateFormat.formatarDataHoraSqlParaTela(this.props.formularioValues.datahora_acerto)}
								</Grid>
								<Grid cols='4 4 4 4' style={{ textAlign: 'center' }}>
									{this.props.formularioValues.nome_usuario_acerto}
								</Grid>
							</Row>}
						</div>}

					</ContentCardBody>
					<ContentCardFooter>
						<Row alignCenter>
							<Grid cols='6 6 4 3'>
								{this.props.excluir ? (
									<Button
										text='Excluir'
										submit type={'danger'}
										icon={'fa fa-trash'} />
								) : (
								<Button
									text='Salvar'
									submit
									type={'success'}
									icon={'fa fa-check'} />
								)}
							</Grid>
							<Grid cols='6 6 4 3'>
								<Button
									text='Voltar'
									type={'warning'}
									icon={'fa fa-chevron-left'}
									event={() => this.props.setModoTela('lista')} />
							</Grid>
						</Row>
					</ContentCardFooter>
				</Form>
			</ContentCard>
        )
    }


}

DespesaForm = reduxForm({form: 'despesaForm', destroyOnUnmount: false})(DespesaForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	formularioValues: getFormValues('despesaForm')(state),
	registro: state.despesa.registro,
	listaMotorista: state.despesa.listaMotorista,
	listaTipo: state.despesa.listaTipo,
	listaDocumentoTipo: state.despesa.listaDocumentoTipo,
	listaMoeda: state.despesa.listaMoeda
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, initForm, buscarViagemPeloMotorista
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(DespesaForm);
