import DateFormat from '../common/dateFormat/DateFormat';

const INITIAL_STATE = {
	aguardando: false,
	registro: {},
	modoTela: 'lista',
	filtro: {
		data_inicial: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAnterior(30)),
		data_final: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAtual()),
		saldo_data_base: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAtual()),
		saldo_periodo_data_inicial: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAnterior(30)),
		saldo_periodo_data_final: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAtual()),
		motivo_periodo_data_inicial: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAnterior(30)),
		motivo_periodo_data_final: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAtual()),
		motivo_periodo_id_motivo: null
	},
	lista: [],
	listaValePedagio: [],
	listaMotivo: [],
	listaMotorista: [],
	listaSaldo: [],
	listaPeriodo: [],
	listaSaldoGeral: [],
	listaSaldoGeralPeriodo: [],
	listaMotivoPeriodo: [],
	listaTomador: [],
	modoTelaOcorrencia: 'lista',
	listaOcorrencia: [],
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case 'VALE_PEDAGIO_MOTORISTA_MODO_TELA':
            return { ...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};

		case 'VALE_PEDAGIO_MOTORISTA_SET_AGUARDANDO':
            return { ...state,
				aguardando: action.payload
			};

        case 'VALE_PEDAGIO_MOTORISTA_FILTRO':
            return {
				...state,
				filtro: action.payload.filtro
			};
		
		case 'VALE_PEDAGIO_MOTORISTA_LISTADO':
            return {
				...state,
				lista: action.payload.data
			};

        case 'VALE_PEDAGIO_MOTORISTA_SALDO_LISTADO':
            return {
				...state,
				listaSaldo: action.payload.data
			};

        case 'VALE_PEDAGIO_MOTORISTA_MOTORISTA_SELECT_LISTADO':
			return {
				...state,
				listaMotorista: action.payload.data
			};

		case 'VALE_PEDAGIO_MOTORISTA_TIPO_SELECT_LISTADO':
			return {
				...state,
				listaValePedagio: action.payload.data
			};

		case 'VALE_PEDAGIO_MOTORISTA_MOTIVO_SELECT_LISTADO':
			return {
				...state,
				listaMotivo: action.payload.data
			};

		case 'VALE_PEDAGIO_MOTORISTA_PERIODO_SELECT_LISTADO':
			return {
				...state,
				listaPeriodo: action.payload.data
			};

		case 'VALE_PEDAGIO_MOTORISTA_TOMADOR_SELECT_LISTADO':
			return {
				...state,
				listaTomador: action.payload.data
			};

		case 'VALE_PEDAGIO_MOTORISTA_SALDO_GERAL_LISTADO':
			return {
				...state,
				listaSaldoGeral: action.payload.data
			};

		case 'VALE_PEDAGIO_MOTORISTA_SALDO_GERAL_PERIODO_LISTADO':
			return {
				...state,
				listaSaldoGeralPeriodo: action.payload.data
			};


		case 'VALE_PEDAGIO_MOTORISTA_MOTIVO_PERIODO_LISTADO':
			return {
				...state,
				listaMotivoPeriodo: action.payload.data
			};

		case 'VALE_PEDAGIO_MOTORISTA_OCORRENCIA_MODO_TELA':
			return { ...state,
				modoTelaOcorrencia: action.payload.modo
			};

        case 'VALE_PEDAGIO_MOTORISTA_OCORRENCIA_LISTADO':
			return {
				...state,
				listaOcorrencia: action.payload.data
			};


        default:
            return state;
    }
}
