import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import Form from './usuarioPerfilForm';
import DateFormat from '../common/dateFormat/DateFormat';
import FormatUtils from '../common/formatUtils/FormatUtils';

import { setModoTela, initForm, salvar, excluir, getLista } from './usuarioPerfilActions';

class UsuarioPerfil extends Component {

	state = {
		linhaSelecionada: null
	}

    componentWillMount() {
        this.props.getLista();
    }

    render() {
        return (
            <div>
                <Content>
					{this.props.modoTela == 'cadastro' ? (
						<Form onSubmit={this.props.salvar} />
					) : null}
					{this.props.modoTela == 'exclusao' ? (
						<Form excluir onSubmit={this.props.excluir} />
					) : null}
					{this.props.modoTela == 'lista' ? this.lista() : null}
                </Content>
            </div>
        )
    }

	lista() {
		return (
			<ContentCard>
				<ContentCardHeader>
					<Row>
						<Grid cols='6 6 4 2'>
							<Button
								text='Adicionar'
								type={'success'}
								icon={'fa fa-plus'}
								event={() => {
									this.props.setModoTela('cadastro', {});
									this.props.initForm({});
								}} />
						</Grid>
					</Row>
				</ContentCardHeader>
				<ContentCardBody>
					<Table responsive>
						<THead>
							<Tr>
								<Th>Nome</Th>
								<Th alignCenter>Usuários</Th>
								<Th alignCenter>Cadastros</Th>
								<Th alignCenter>Todas Ocorrências</Th>
								<Th alignCenter>Premiação</Th>
								<Th alignCenter>CNH</Th>
								<Th alignCenter>Documento</Th>
								<Th alignCenter>Operação</Th>
								<Th alignCenter>Comp. Aberta</Th>
								<Th alignCenter>Comp. Encerrada</Th>
								<Th alignCenter>Comp. Financeiro</Th>
								<Th alignCenter>Comp. Finalizada</Th>
								<Th alignCenter>Acessórios</Th>
								<Th alignCenter>Acessórios - Operação</Th>
								<Th alignCenter>Vale Pedágio</Th>
								<Th></Th>
							</Tr>
						</THead>
						<TBody>
							{(this.props.lista).map(item => (
								<Tr key={item.id} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
									onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
									onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
									<Td small>{item.nome}</Td>
									<Td alignCenter small>{item.acesso_usuario ? 'LIBERADO' : 'BLOQUEADO'}</Td>
									<Td alignCenter small>{item.acesso_cadastro ? 'LIBERADO' : 'BLOQUEADO'}</Td>
									<Td alignCenter small>{item.acesso_ocorrencia ? 'LIBERADO' : 'BLOQUEADO'}</Td>
									<Td alignCenter small>{item.acesso_premiacao ? 'LIBERADO' : 'BLOQUEADO'}</Td>
									<Td alignCenter small>{item.acesso_cnh ? 'LIBERADO' : 'BLOQUEADO'}</Td>
									<Td alignCenter small>{item.acesso_documento ? 'LIBERADO' : 'BLOQUEADO'}</Td>
									<Td alignCenter small>{item.acesso_operacao ? 'LIBERADO' : 'BLOQUEADO'}</Td>
									<Td alignCenter small>{[item.acesso_comprovante_aberta_visualizar ? 'VISUALIZAR' : null, item.acesso_comprovante_aberta_alterar ? 'ALTERAR' : null].filter(permissao => permissao).join('|') || 'BLOQUEADO'}</Td>
									<Td alignCenter small>{[item.acesso_comprovante_encerrada_visualizar ? 'VISUALIZAR' : null, item.acesso_comprovante_encerrada_alterar ? 'ALTERAR' : null, item.acesso_comprovante_encerrada_desfazer ? 'DESFAZER' : null].filter(permissao => permissao).join('|') || 'BLOQUEADO'}</Td>
									<Td alignCenter small>{[item.acesso_comprovante_financeiro_visualizar ? 'VISUALIZAR' : null, item.acesso_comprovante_financeiro_alterar ? 'ALTERAR' : null].filter(permissao => permissao).join('|') || 'BLOQUEADO'}</Td>
									<Td alignCenter small>{[item.acesso_comprovante_finalizada_visualizar ? 'VISUALIZAR' : null, item.acesso_comprovante_finalizada_alterar ? 'ALTERAR' : null, item.acesso_comprovante_finalizada_desfazer ? 'DESFAZER' : null].filter(permissao => permissao).join('|') || 'BLOQUEADO'}</Td>
									<Td alignCenter small>{item.acesso_acessorios ? 'LIBERADO' : 'BLOQUEADO'}</Td>
									<Td alignCenter small>{item.acesso_acessorios_operacao ? 'LIBERADO' : 'BLOQUEADO'}</Td>
									<Td alignCenter small>{item.acesso_vale_pedagio ? 'LIBERADO' : 'BLOQUEADO'}</Td>
									<Td alignRight minWidth={100}>
										<ButtonTable
											type={'warning'}
											icon={'fas fa-pencil-alt'}
											visible={this.state.linhaSelecionada == item.id}
											event={() => {
												this.props.setModoTela('cadastro', {
													...item
												});
												this.props.initForm({
													...item
												});
											}} />

										<ButtonTable
											type={'danger'}
											icon={'fas fa-trash-alt'}
											visible={this.state.linhaSelecionada == item.id}
											event={() => {
												this.props.setModoTela('exclusao', {
													...item
												});
												this.props.initForm({
													...item
												});
											}} />
									</Td>
								</Tr>
							))}
						</TBody>
					</Table>
				</ContentCardBody>
			</ContentCard>
		);
	}


}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	modoTela: state.usuarioPerfil.modoTela,
	lista: state.usuarioPerfil.lista
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, initForm, salvar, excluir, getLista }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(UsuarioPerfil);
