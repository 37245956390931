import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import Form from './motoristaForm';
import DateFormat from '../common/dateFormat/DateFormat';
import FormatUtils from '../common/formatUtils/FormatUtils';
import ReactExport from 'react-export-excel-fixed-xlsx';
import Select from '../common/form/select';
import Aguardando from '../common/template/aguardando';

import { setModoTela, initForm, salvar, excluir, getListaCnh, getListaGrupo, consultarCNH, limparConsultaCNH } from './motoristaActions';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import LabelAndInput from '../common/form/labelAndInput';

class MotoristaCnh extends Component {

	state = {
		linhaSelecionada: null,
		pesquisar: '',
		pontuacao: null,
		situacao: ''
	}

    componentWillMount() {
        this.props.getListaCnh();
    }

    render() {

		const ExcelFile = ReactExport.ExcelFile;
		const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
		const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

		let lista = this.props.listaCnh.filter(item => {
			if (this.state.pesquisar
				&& !(`${item.nome} ${FormatUtils.formatarCPF(item.cpf)} ${item.rg_cnh || item.rg} ${DateFormat.formatarDataSqlParaTela(item.data_nascimento)}
					${item.nome_grupo} ${item.cnh_numero} ${item.cnh_codigo_seguranca} ${item.cnh_categoria} ${item.cnh_uf} ${DateFormat.formatarDataSqlParaTela(item.cnh_validade)}
					${item.cnh_validade ? (item.dias_vencimento > 0 ? `${item.dias_vencimento} ${item.dias_vencimento == 1 ? 'dia' : 'dias'}` : 'VENCIDA') : '-'}
					${item.pontos_confirmados} ${item.pontos_aguardando} ${item.pontuacao_processando} ${item.cnh_situacao || item.erro_infosimples} ${DateFormat.formatarDataSqlParaTela(item.datahora_infosimples)}`).toUpperCase().includes(this.state.pesquisar.toUpperCase())) {
				return false;
			}
			if (this.state.pontuacao
				&& !((this.state.pontuacao == 1 && parseInt(item.pontos_confirmados || 0) + parseInt(item.pontos_aguardando || 0) + parseInt(item.pontuacao_processando || 0) > 0)
					|| (this.state.pontuacao == 2 && parseInt(item.pontos_confirmados || 0) + parseInt(item.pontos_aguardando || 0) + parseInt(item.pontuacao_processando || 0) == 0))) {
				return false;
			}
			if (this.state.situacao
				&& !(`${item.cnh_situacao || item.erro_infosimples}`).toUpperCase().includes(this.state.situacao.toUpperCase())) {
				return false;
			}
			return true;
		});

		let listaSituacao = [];
		this.props.listaCnh.forEach(item => {
			if (!listaSituacao.filter(situacao => situacao.id == (item.cnh_situacao || item.erro_infosimples)).length) {
				listaSituacao.push({ id: item.cnh_situacao || item.erro_infosimples, valor: item.cnh_situacao || item.erro_infosimples })
			}
		});

		return (
			<Content>

				<Aguardando aguardando={this.props.aguardando} />
			
				<ContentCard>

					{this.modalConsulta()}

					{this.state.id_motorista ? this.modalInfracao() : null}
					<ContentCardHeader>
						<Row>
							<Grid cols='6 6 3 2'>
								<ExcelFile
									filename={'Situação CNH'}
									element={
										<Button
											text='Exportar'
											type={'primary'}
											icon={'fa fa-plus'} />
									}>
									<ExcelSheet data={lista.map(item => ({
										...item,
										cpf: FormatUtils.formatarCPF(item.cpf),
										data_nascimento: DateFormat.formatarDataSqlParaTela(item.data_nascimento),
										cnh_validade: DateFormat.formatarDataSqlParaTela(item.cnh_validade),
										status: item.cnh_validade ? (item.dias_vencimento > 0 ? `${item.dias_vencimento} ${item.dias_vencimento == 1 ? 'dia' : 'dias'}` : 'VENCIDA') : '-',
										cnh_situacao: item.cnh_uf != 'RS' ? '' : (item.cnh_situacao || item.erro_infosimples),
										datahora_infosimples: item.cnh_uf != 'RS' ? '' : DateFormat.formatarDataSqlParaTela(item.datahora_infosimples)
									}))} name='cnh'>
										<ExcelColumn label='Nome' value='nome' />
										<ExcelColumn label='CPF' value='cpf' />
										<ExcelColumn label='RG' value='rg_cnh' />
										<ExcelColumn label='Data Nasc.' value='data_nascimento' />
										<ExcelColumn label='Grupo' value='nome_grupo' />
										<ExcelColumn label='Número' value='cnh_numero' />
										<ExcelColumn label='Código Segurança' value='cnh_codigo_seguranca' />
										<ExcelColumn label='Categoria' value='cnh_categoria' />
										<ExcelColumn label='UF' value='cnh_uf' />
										<ExcelColumn label='Validade' value='cnh_validade' />
										<ExcelColumn label='Vence em' value='status' />
										<ExcelColumn label='Pts Confirmado' value='pontos_confirmados' />
										<ExcelColumn label='Pts Aguardando' value='pontos_aguardando' />
										<ExcelColumn label='Pts Processando' value='pontuacao_processando' />
										<ExcelColumn label='Situação' value='cnh_situacao' />
										<ExcelColumn label='Ult Cons.' value='datahora_infosimples' />

									</ExcelSheet>
								</ExcelFile>
							</Grid>

							<Grid cols='6 6 3 2'>
								<Button
									text='Nova Consulta'
									type={'success'}
									icon={'fa fa-chevron-left'}
									event={() => {
										this.props.limparConsultaCNH();
										this.setState({
											...this.state,
											consultaAberta: true,
											cpf_consulta: null,
											data_nascimento_consulta: null
										});
									}} />
							</Grid>
						</Row>
					</ContentCardHeader>
					<ContentCardBody>
						<Row>
							<Select
								options={[{ id: 1, valor: 'Possui' }, { id: 2, valor: 'Não Possui' }]}
								label='Pontuação'
								cols='12 6 6 4'
								placeholder='Selecione a pontuação'
								value={this.state.pontuacao}
								onChange={data => {
									this.setState({ ...this.props.filtro, pontuacao: data });
								}} />

							<Select
								options={listaSituacao}
								label='Situação'
								cols='12 6 6 4'
								placeholder='Selecione a situação'
								value={this.state.situacao}
								onChange={data => {
									this.setState({ ...this.props.filtro, situacao: data });
								}} />
						</Row>

						<input className='form-control'
							placeholder='Pesquisar'
							type='text'
							value={this.state.pesquisar}
							onChange={e => {
								this.setState({ ...this.state, pesquisar: e.target.value });
							}} />
						<Table responsive>
							<THead>
								<Tr>
									<Th>Nome</Th>
									<Th alignCenter>CPF</Th>
									<Th alignCenter>RG</Th>
									<Th alignCenter>Data Nasc.</Th>
									<Th alignCenter>Grupo</Th>
									<Th alignCenter>Número</Th>
									<Th alignCenter>Categoria</Th>
									<Th alignCenter>UF</Th>
									<Th alignCenter>Validade</Th>
									<Th alignCenter>Vence em</Th>
									<Th alignCenter>Pts Confirmado</Th>
									<Th alignCenter>Pts Aguardando</Th>
									<Th alignCenter>Pts Processando</Th>
									<Th alignCenter>Situação</Th>
									<Th alignCenter>Ult Cons.</Th>
									<Th alignCenter width={40}></Th>
								</Tr>
							</THead>
							<TBody>
								{lista.map(item => (
									<Tr key={item.id}>
										<Td small>{item.nome}</Td>
										<Td alignCenter small>{FormatUtils.formatarCPF(item.cpf)}</Td>
										<Td alignCenter small>{item.rg_cnh || item.rg}</Td>
										<Td alignCenter small>{DateFormat.formatarDataSqlParaTela(item.data_nascimento)}</Td>
										<Td alignCenter small>{item.nome_grupo}</Td>
										<Td alignCenter small>{item.cnh_numero}</Td>
										<Td alignCenter small>{item.cnh_categoria}</Td>
										<Td alignCenter small backgroundColor={item.cnh_uf == 'RS' ? null : '#757373'} color={item.cnh_uf == 'RS' ? null : '#fff'}>{item.cnh_uf}</Td>
										<Td alignCenter small>{DateFormat.formatarDataSqlParaTela(item.cnh_validade)}</Td>
										<Td alignCenter small
											backgroundColor={!item.cnh_validade ? null : (item.dias_vencimento <= 0 ? '#f00' : (item.dias_vencimento < 30 ? '#ff0' : null))}
											color={!item.cnh_validade ? null : (item.dias_vencimento <= 0 ? '#fff' : (item.dias_vencimento < 30 ? '#000' : null))}>
											{item.cnh_validade ? (item.dias_vencimento > 0 ? `${item.dias_vencimento} ${item.dias_vencimento == 1 ? 'dia' : 'dias'}` : 'VENCIDA') : '-'}
										</Td>
										<Td alignCenter small>{item.pontos_confirmados}</Td>
										<Td alignCenter small>{item.pontos_aguardando}</Td>
										<Td alignCenter small>{item.pontuacao_processando}</Td>
										{item.cnh_uf == 'RS' ? (
											<Td alignCenter small noWrap
												backgroundColor={item.cnh_situacao == 'NORMAL' ? '#81c784' : (!item.cnh_situacao ? null : '#f00')}
												color={item.cnh_situacao == 'NORMAL' ? '#fff' : (!item.cnh_situacao ? null : '#fff')}>{item.cnh_situacao || item.erro_infosimples}</Td>
										) : <Td />}
										{item.cnh_uf == 'RS' ? (
											<Td alignCenter small>{DateFormat.formatarDataSqlParaTela(item.datahora_infosimples)}</Td>
										) : <Td />}
										<ButtonTable
											type={'primary'}
											icon={'fas fa-eye'}
											disabled={false}
											visible={item.cnh_link && item.cnh_uf == 'RS'}
											title='Visualizar'
											small
											event={() => {
												// window.open(item.cnh_link, '_blank');
												this.setState({ ...this.state, id_motorista: item.id })
											}} />
									</Tr>
								))}
							</TBody>
						</Table>
					</ContentCardBody>
				</ContentCard>
			</Content>
		);
	}

	modalInfracao() {

		let motorista = this.props.listaCnh.filter(item => item.id == this.state.id_motorista)[0];

		if (!motorista) {
			return <div />
		}		

        return (
			<div class='modal fade show' id='modal-xl' style={{ display: 'block' }} aria-modal='true'>
				<div class='modal-dialog modal-xl'>
					<div class='modal-content'>
						<div class='modal-header'>
							<h6 class='modal-title'>{motorista.nome}</h6>
							<button type='button' class='close' data-dismiss='modal' aria-label='Close' onClick={() => this.setState({ ...this.state, id_motorista: null })}>
								<span aria-hidden='true'>×</span>
							</button>
						</div>
						<div class='modal-body'>
							<Table responsive>
								<THead>
									<Tr>
										<Th small>Infração</Th>
										<Th alignCenter small>Data/Hora</Th>
										<Th alignCenter small>Local</Th>
										<Th alignCenter small>Veículo</Th>
										<Th alignCenter small>Situação</Th>
										<Th alignCenter small>Pontuação</Th>
										<Th alignCenter small>Suapensão CNH</Th>
									</Tr>
								</THead>
								<TBody>
									{(motorista.infracoes || []).map(item => (
										<Tr key={item.data_hora}>
											<Td small>{item.infracao}</Td>
											<Td alignCenter small>{item.data_hora}</Td>
											<Td alignCenter small noWrap>{item.local}</Td>
											<Td alignCenter small>{item.veiculo}</Td>
											<Td alignCenter small>{item.situacao_ait}</Td>
											<Td alignCenter small>{item.pontuacao}</Td>
											<Td alignCenter small>{item.suspensao_cnh}</Td>
										</Tr>
									))}
								</TBody>
							</Table>
						</div>
						<div class='modal-footer justify-content-between'>
							<button type='button' class='btn btn-default' data-dismiss='modal' style={{ visibility: 'hidden' }}>Close</button>
							<button type='button' class='btn btn-primary'
								onClick={() => this.setState({ ...this.state, id_motorista: null })}>OK</button>
						</div>
					</div>
				</div>
			</div>
        );
    }

	modalConsulta() {

		if (!this.state.consultaAberta) {
			return null
		}

        return (
			<div class='modal fade show' id='modal-xl' style={{ display: 'block' }} aria-modal='true'>
				<div class='modal-dialog modal-xl'>
					<div class='modal-content'>
						<div class='modal-header' style={{ backgroundColor: '#1F3066', color: '#fff' }}>
							<h4 class='modal-title'>Consulta da CNH</h4>
							<button type='button' class='close' data-dismiss='modal' aria-label='Close' onClick={() => this.setState({ ...this.state, consultaAberta: null })}>
								<span aria-hidden='true' style={{ color: '#fff', fontSize: 32 }}>×</span>
							</button>
						</div>
						<div class='modal-body' style={{  }}>

							<Row alignCenter>

								<LabelAndInputMask
									label='CPF' 
									placeholder='Informe o CPF'
									cols='12 12 4 4'
									mask='999.999.999-99'
									value={this.state.cpf_consulta}
									onChange={data => {
										this.setState({
											...this.state,
											cpf_consulta: data.target.value
										});
									}} />

								<LabelAndInput
									label='CNH' 
									placeholder='Informe a CNH'
									cols='12 12 4 4'
									value={this.state.cnh_consulta}
									onChange={data => {
										this.setState({
											...this.state,
											cnh_consulta: data.target.value
										});
									}} />

								<LabelAndInput
									label='RG' 
									placeholder='Informe o RG'
									cols='12 12 4 4'
									value={this.state.rg_consulta}
									onChange={data => {
										this.setState({
											...this.state,
											rg_consulta: data.target.value
										});
									}} />

								<LabelAndInput
									label='Nome Completo' 
									placeholder='Informe o nome'
									cols='12 12 12 8'
									value={this.state.nome_consulta}
									onChange={data => {
										this.setState({
											...this.state,
											nome_consulta: (data.target.value || '').toUpperCase()
										});
									}} />
									
								<LabelAndInputMask
									label='Data de Nascimento' 
									placeholder='Informe a data'
									cols='12 12 6 4'
									mask='99/99/9999'
									value={this.state.data_nascimento_consulta}
									onChange={data => {
										this.setState({
											...this.state,
											data_nascimento_consulta: data.target.value
										});
									}} />
							</Row>

							<div style={{ minHeight: 120 }}>

								{this.props.consultaCNH &&
								<Row alignCenter>
									{!this.props.consultaCNH.situacao ? (
										<div class='alert alert-warning alert-dismissible'>
											<h5><i class='icon fas fa-exclamation-circle'></i> {this.props.consultaCNH.situacao || this.props.consultaCNH.erro}</h5>
										</div>
									) : (this.props.consultaCNH.situacao == 'NORMAL' ? (
										<div class='alert alert-success alert-dismissible'>
											<h5><i class='icon fas fa-check'></i> {this.props.consultaCNH.situacao || this.props.consultaCNH.erro}</h5>
											<Row alignCenter style={{ marginTop: 18 }}>
												<a href={this.props.consultaCNH.link} target='blank' class='btn btn-primary btn-sm'>Abrir Site do DETRAN</a>
											</Row>
										</div>
									) : (
										<div class='alert alert-danger alert-dismissible'>
											<h5><i class='icon fas fa-ban'></i> {this.props.consultaCNH.situacao || this.props.consultaCNH.erro}</h5>
											<Row alignCenter style={{ marginTop: 18 }}>
												<a href={this.props.consultaCNH.link} target='blank' class='btn btn-primary btn-sm'>Abrir Site do DETRAN</a>
											</Row>
										</div>
									))}
								</Row>}

								{(this.props.consultaCNH && this.props.consultaCNH.situacao) &&
								<>
									<Row alignCenter>
										<div class='col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6'>
											<p style={{ textAlign: 'right' }}><b>Categoria:</b></p>
										</div>
										<div class='col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6'>
											<p style={{ textAlign: 'left' }}>{this.props.consultaCNH.categoria}</p>
										</div>
									</Row>
									<Row alignCenter>
										<div class='col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6'>
											<p style={{ textAlign: 'right' }}><b>Validade:</b></p>
										</div>
										<div class='col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6'>
											<p style={{ textAlign: 'left' }}>{this.props.consultaCNH.validade}</p>
										</div>
									</Row>
									<Row alignCenter>
										<div class='col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6'>
											<p style={{ textAlign: 'right' }}><b>Pontos Confirmados:</b></p>
										</div>
										<div class='col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6'>
											<p style={{ textAlign: 'left' }}>{this.props.consultaCNH.pontos_confirmados}</p>
										</div>
									</Row>
									<Row alignCenter>
										<div class='col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6'>
											<p style={{ textAlign: 'right' }}><b>Pontos Aguardando:</b></p>
										</div>
										<div class='col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6'>
											<p style={{ textAlign: 'left' }}>{this.props.consultaCNH.pontos_aguardando}</p>
										</div>
									</Row>
									<Row alignCenter>
										<div class='col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6'>
											<p style={{ textAlign: 'right' }}><b>Pontos Processando:</b></p>
										</div>
										<div class='col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6'>
											<p style={{ textAlign: 'left' }}>{this.props.consultaCNH.pontuacao_processando}</p>
										</div>
									</Row>
								</>}
								
								{this.props.aguardando &&
								<div class='text-center' style={{ paddingTop: 28, paddingBottom: 32 }}>
									<div class='spinner-border' role='status'>
										<span class='sr-only'>Consultando...</span>
									</div>
								</div>}

							</div>

							<Row alignCenter>
								<Grid cols='12 12 12 8'>
									<Button
										text='Realizar Consulta'
										type={'success'}
										icon={'fa fa-chevron-left'}
										disabled={!this.state.cpf_consulta || !this.state.cnh_consulta || !this.state.rg_consulta || !this.state.nome_consulta || !this.state.data_nascimento_consulta}
										event={() => {
											this.props.limparConsultaCNH();
											this.props.consultarCNH(this.state.cpf_consulta, this.state.cnh_consulta, this.state.rg_consulta, this.state.nome_consulta, this.state.data_nascimento_consulta);
										}} />
								</Grid>
							</Row>

						</div>
					</div>
				</div>
			</div>
        );
    }

}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	modoTela: state.motorista.modoTela,
	aguardando: state.motorista.aguardando,
	listaCnh: state.motorista.listaCnh,
	consultaCNH: state.motorista.consultaCNH
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, initForm, salvar, excluir, getListaCnh, getListaGrupo, consultarCNH, limparConsultaCNH }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(MotoristaCnh);
