import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';

import { setModoTela, initForm, consultar, consultarLog } from './braskemActions';
import FormatUtils from '../common/formatUtils/FormatUtils';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import DateFormat from '../common/dateFormat/DateFormat';
import InputPesquisa from '../common/form/inputPesquisa';
import LabelAndInput from '../common/form/labelAndInput';
import Select from '../common/form/select';

class Braskem extends Component {

	state = {
		linhaSelecionada: null,
		pesquisar: '',
		dataInicial: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAnterior(30)),
		dataFinal: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAtual()),
		mostrarLogs: false
	}

    componentWillMount() {
		this.props.consultarLog(this.state.dataInicial, this.state.dataFinal);
    }

    render() {
        return (
            <div>
                <Content>					
					{this.lista()}
					{this.listaLog()}
                </Content>
            </div>
        )
    }

	lista() {

		return (
			<ContentCard>
				<ContentCardHeader>
					<Row>
						<Select
							label='CNPJ' placeholder='Informe'
							cols='6 6 4 3'
							options={this.props.listaCnpj}
							value={this.state.cnpj}
							onChange={value => {
								this.setState({
									...this.state,
									cnpj: value
								})
							}} />

						<LabelAndInput
							label='Documento' placeholder='Informe'
							cols='6 6 4 3'
							value={this.state.numeroDocumento}
							onChange={data => {
								this.setState({
									...this.state,
									numeroDocumento: data.target.value
								})
							}} />

						<Grid cols='6 6 4 2' style={{ marginTop: 28 }}>
							<Button
								text='Consultar'
								type={'success'}
								icon={'fa fa-plus'}
								event={() => {
									this.props.consultar(this.state.cnpj, this.state.numeroDocumento);
								}} />
						</Grid>
					</Row>
				</ContentCardHeader>
				<ContentCardBody>
					<Table responsive>
						<THead>
							<Tr>
								<Th alignCenter small>Cnpj. Transp.</Th>
								<Th alignCenter small>Comprovante</Th>
								<Th alignCenter small>Data. Criação</Th>
								<Th alignCenter small>Denom. Itinerário</Th>
								<Th alignCenter small>Descrição Status</Th>
								<Th alignCenter small>Horas Compra Ped.</Th>
								<Th alignCenter small>Id Rota</Th>
								<Th alignCenter small>Itinerário</Th>
								<Th alignCenter small>Meio Pagamento</Th>
								<Th alignCenter small>Nome Transp.</Th>
								<Th alignCenter small>NF Data</Th>
								<Th alignCenter small>NF Número</Th>
								<Th alignCenter small>NF Série</Th>
							</Tr>
						</THead>
						<TBody>		
							{this.props.registro &&							
							<Tr>
								<Td alignCenter small>{this.props.registro.objeto.CnpjTransp}</Td>
								<Td alignCenter small>{this.props.registro.objeto.Comprovante}</Td>
								<Td alignCenter small>{DateFormat.formatarDataSqlParaTela(this.props.registro.objeto.DataCriacao)}</Td>
								<Td alignCenter small>{this.props.registro.objeto.DenomItinerario}</Td>
								<Td alignCenter small>{this.props.registro.objeto.DescricaoStatus}</Td>
								<Td alignCenter small>{this.props.registro.objeto.HorasCompraPedagio}</Td>
								<Td alignCenter small>{this.props.registro.objeto.IdRota}</Td>
								<Td alignCenter small>{this.props.registro.objeto.Itinerario}</Td>
								<Td alignCenter small>{this.props.registro.objeto.MeioPagamento}</Td>
								<Td alignCenter small>{this.props.registro.objeto.NomeTransp}</Td>
								<Td alignCenter small>{this.props.registro.objeto.NotaFiscal && this.props.registro.objeto.NotaFiscal.item ? this.props.registro.objeto.NotaFiscal.item.DataDocumento : ''}</Td>
								<Td alignCenter small>{this.props.registro.objeto.NotaFiscal && this.props.registro.objeto.NotaFiscal.item ? this.props.registro.objeto.NotaFiscal.item.NumeroNotaFiscal : ''}</Td>
								<Td alignCenter small>{this.props.registro.objeto.NotaFiscal && this.props.registro.objeto.NotaFiscal.item ? this.props.registro.objeto.NotaFiscal.item.Serie : ''}</Td>
							</Tr>}
						</TBody>
					</Table>

					<Table responsive>
						<THead>
							<Tr>
								<Th alignCenter small>Número Eixos</Th>
								<Th alignCenter small>Número Transporte</Th>
								<Th alignCenter small>Número Vale Ped.</Th>
								<Th alignCenter small>Pedido Vale Ped.</Th>
								<Th alignCenter small>Cavalo</Th>
								<Th alignCenter small>Qtd. Cupons</Th>
								<Th alignCenter small>Rota</Th>
								<Th alignCenter small>Status Pedido</Th>
								<Th alignCenter small>Transportador</Th>
								<Th alignCenter small>Valor Total Ped.</Th>
							</Tr>
						</THead>
						<TBody>		
							{this.props.registro &&
							<Tr>
								<Td alignCenter small>{this.props.registro.objeto.NumeroEixos}</Td>
								<Td alignCenter small>{this.props.registro.objeto.NumeroTransporte}</Td>
								<Td alignCenter small>{this.props.registro.objeto.NumeroValePed}</Td>
								<Td alignCenter small>{this.props.registro.objeto.PedidoValePed}</Td>
								<Td alignCenter small>{this.props.registro.objeto.PlacaCavalo}</Td>
								<Td alignCenter small>{this.props.registro.objeto.QtdeCupons}</Td>
								<Td alignCenter small>{this.props.registro.objeto.Rota}</Td>
								<Td alignCenter small>{this.props.registro.objeto.StatusPedido}</Td>
								<Td alignCenter small>{this.props.registro.objeto.Transportador}</Td>
								<Td alignCenter small>{this.props.registro.objeto.ValorTotalPed}</Td>
							</Tr>}
						</TBody>
					</Table>
				</ContentCardBody>
			</ContentCard>
		);
	}

	listaLog() {
		return (
			<ContentCard style={{ marginTop: 20 }}>
				<div class='card-header ui-sortable-handle'>
					<h3 class='card-title' 
						style={{ cursor: 'pointer' }}
						onClick={() => this.setState({ ...this.state, mostrarLogs: !this.state.mostrarLogs })}>
						<i class={`fas fa-chevron-${this.state.mostrarLogs ? 'up' : 'down'}`}></i> Logs de Emissão
					</h3>
				</div>

				{this.state.mostrarLogs &&				
				<ContentCardBody>
					<Row style={{ paddingLeft: 8 }}>
						<LabelAndInputMask
							label='Data Inicial' placeholder='Informe o período inicial'
							cols='12 6 3 2'
							mask='99/99/9999'
							value={this.state.dataInicial}
							onChange={(data) => {
								this.setState({ ...this.state, dataInicial: data.target.value });
							}} />

						<LabelAndInputMask
							label='Data Final' placeholder='Informe o período final'
							cols='12 6 3 2'
							mask='99/99/9999'
							value={this.state.dataFinal}
							onChange={(data) => {
								this.setState({ ...this.state, dataFinal: data.target.value });
							}} />

						<Grid cols='6 6 4 2' style={{ marginTop: 28 }}>
							<Button
								text='Atualizar'
								type={'primary'}
								icon={'fa fa-plus'}
								event={() => {
									this.props.consultarLog(this.state.dataInicial, this.state.dataFinal);
								}} />
						</Grid>
					</Row>
								
					<InputPesquisa
						cols='12 12 12 12'
						label='Pesquisar'
						onChange={value => this.setState({ ...this.state, pesquisar: value })} />

					{this.state.resgistroSelecionado ? (
						<Row>
							<Table responsive>
								<THead>
									<Tr>
										<Th alignCenter small width={10}></Th>
										<Th alignCenter small>Cnpj. Transp.</Th>
										<Th alignCenter small>Comprovante</Th>
										<Th alignCenter small>Data. Criação</Th>
										<Th alignCenter small>Denom. Itinerário</Th>
										<Th alignCenter small>Descrição Status</Th>
										<Th alignCenter small>Horas Compra Ped.</Th>
										<Th alignCenter small>Id Rota</Th>
										<Th alignCenter small>Itinerário</Th>
										<Th alignCenter small>Meio Pagamento</Th>
										<Th alignCenter small>Nome Transp.</Th>
										<Th alignCenter small>NF Data</Th>
										<Th alignCenter small>NF Número</Th>
										<Th alignCenter small>NF Série</Th>
									</Tr>
								</THead>
								<TBody>		
									<Tr>
										<Td alignCenter minWidth={10}>
											<ButtonTable
												type={'primary'}
												icon={'fas fa-arrow-left'}
												visible={true}
												title='Voltar'
												small
												event={() => {
													this.setState({ ...this.state, resgistroSelecionado: null });
												}} />
										</Td>
										<Td alignCenter small>{this.state.resgistroSelecionado.objeto.CnpjTransp}</Td>
										<Td alignCenter small>{this.state.resgistroSelecionado.objeto.Comprovante}</Td>
										<Td alignCenter small>{DateFormat.formatarDataSqlParaTela(this.state.resgistroSelecionado.objeto.DataCriacao)}</Td>
										<Td alignCenter small>{this.state.resgistroSelecionado.objeto.DenomItinerario}</Td>
										<Td alignCenter small>{this.state.resgistroSelecionado.objeto.DescricaoStatus}</Td>
										<Td alignCenter small>{this.state.resgistroSelecionado.objeto.HorasCompraPedagio}</Td>
										<Td alignCenter small>{this.state.resgistroSelecionado.objeto.IdRota}</Td>
										<Td alignCenter small>{this.state.resgistroSelecionado.objeto.Itinerario}</Td>
										<Td alignCenter small>{this.state.resgistroSelecionado.objeto.MeioPagamento}</Td>
										<Td alignCenter small>{this.state.resgistroSelecionado.objeto.NomeTransp}</Td>
										<Td alignCenter small>{this.state.resgistroSelecionado.objeto.NotaFiscal && this.state.resgistroSelecionado.objeto.NotaFiscal.item ? this.state.resgistroSelecionado.objeto.NotaFiscal.item.DataDocumento : ''}</Td>
										<Td alignCenter small>{this.state.resgistroSelecionado.objeto.NotaFiscal && this.state.resgistroSelecionado.objeto.NotaFiscal.item ? this.state.resgistroSelecionado.objeto.NotaFiscal.item.NumeroNotaFiscal : ''}</Td>
										<Td alignCenter small>{this.state.resgistroSelecionado.objeto.NotaFiscal && this.state.resgistroSelecionado.objeto.NotaFiscal.item ? this.state.resgistroSelecionado.objeto.NotaFiscal.item.Serie : ''}</Td>
									</Tr>
								</TBody>
							</Table>
				
							<Table responsive>
								<THead>
									<Tr>
										<Th alignCenter small>Número Eixos</Th>
										<Th alignCenter small>Número Transporte</Th>
										<Th alignCenter small>Número Vale Ped.</Th>
										<Th alignCenter small>Pedido Vale Ped.</Th>
										<Th alignCenter small>Cavalo</Th>
										<Th alignCenter small>Qtd. Cupons</Th>
										<Th alignCenter small>Rota</Th>
										<Th alignCenter small>Status Pedido</Th>
										<Th alignCenter small>Transportador</Th>
										<Th alignCenter small>Valor Total Ped.</Th>
									</Tr>
								</THead>
								<TBody>		
									<Tr>
										<Td alignCenter small>{this.state.resgistroSelecionado.objeto.NumeroEixos}</Td>
										<Td alignCenter small>{this.state.resgistroSelecionado.objeto.NumeroTransporte}</Td>
										<Td alignCenter small>{this.state.resgistroSelecionado.objeto.NumeroValePed}</Td>
										<Td alignCenter small>{this.state.resgistroSelecionado.objeto.PedidoValePed}</Td>
										<Td alignCenter small>{this.state.resgistroSelecionado.objeto.PlacaCavalo}</Td>
										<Td alignCenter small>{this.state.resgistroSelecionado.objeto.QtdeCupons}</Td>
										<Td alignCenter small>{this.state.resgistroSelecionado.objeto.Rota}</Td>
										<Td alignCenter small>{this.state.resgistroSelecionado.objeto.StatusPedido}</Td>
										<Td alignCenter small>{this.state.resgistroSelecionado.objeto.Transportador}</Td>
										<Td alignCenter small>{this.state.resgistroSelecionado.objeto.ValorTotalPed}</Td>
									</Tr>
								</TBody>
							</Table>
						</Row>
					) : (
						<Table responsive>
							<THead>
								<Tr>
									<Th alignCenter small width={10}></Th>
									<Th alignCenter small>Consulta</Th>
									<Th alignCenter small>Cnpj. Transp.</Th>
									<Th alignCenter small>Comprovante</Th>
									<Th alignCenter small>Data. Criação</Th>
									<Th alignCenter small>Descrição Status</Th>
									<Th alignCenter small>Nome Transp.</Th>
									<Th alignCenter small>NF Data</Th>
									<Th alignCenter small>NF Número</Th>
									<Th alignCenter small>NF Série</Th>
									<Th alignCenter small>Usuário</Th>
								</Tr>
							</THead>
							<TBody>
								{this.props.listaLog.filter(item => {

									return !(this.state.pesquisar || '').trim()
										|| (this.state.pesquisar.toUpperCase() || '').split('+').filter(pesquisar => (pesquisar || '').trim()).filter(pesquisar => {
				
										if (pesquisar
											&& !(`
											${DateFormat.formatarDataHoraSqlParaTela(item.datahora_inclusao)}
											${item.objeto.CnpjTransp}
											${item.objeto.Comprovante}
											${DateFormat.formatarDataSqlParaTela(item.objeto.DataCriacao)}
											${item.objeto.DescricaoStatus}
											${item.objeto.NomeTransp}
											${item.objeto.NotaFiscal && item.objeto.NotaFiscal.item ? item.objeto.NotaFiscal.item.DataDocumento : ''}
											${item.objeto.NotaFiscal && item.objeto.NotaFiscal.item ? item.objeto.NotaFiscal.item.NumeroNotaFiscal : ''}
											${item.objeto.NotaFiscal && item.objeto.NotaFiscal.item ? item.objeto.NotaFiscal.item.Serie : ''}
											${item.nome_usuario}
										`).toUpperCase().includes(pesquisar)) {
											return false;
										}

										return true;
									}).length > 0;
								}).map(item => (
									<Tr key={item.id}>
										<Td alignCenter minWidth={10}>
											<ButtonTable
												type={'primary'}
												icon={'fas fa-eye'}
												visible={true}
												title='Visualizar'
												small
												event={() => {
													this.setState({ ...this.state, resgistroSelecionado: item });
												}} />
										</Td>
										<Td alignCenter small>{DateFormat.formatarDataHoraSqlParaTela(item.datahora_inclusao)}</Td>
										<Td alignCenter small>{item.objeto.CnpjTransp}</Td>
										<Td alignCenter small>{item.objeto.Comprovante}</Td>
										<Td alignCenter small>{DateFormat.formatarDataSqlParaTela(item.objeto.DataCriacao)}</Td>
										<Td alignCenter small>{item.objeto.DescricaoStatus}</Td>
										<Td alignCenter small>{item.objeto.NomeTransp}</Td>
										<Td alignCenter small>{item.objeto.NotaFiscal && item.objeto.NotaFiscal.item ? item.objeto.NotaFiscal.item.DataDocumento : ''}</Td>
										<Td alignCenter small>{item.objeto.NotaFiscal && item.objeto.NotaFiscal.item ? item.objeto.NotaFiscal.item.NumeroNotaFiscal : ''}</Td>
										<Td alignCenter small>{item.objeto.NotaFiscal && item.objeto.NotaFiscal.item ? item.objeto.NotaFiscal.item.Serie : ''}</Td>
										<Td alignCenter small>{item.nome_usuario}</Td>
									</Tr>
								))}
							</TBody>
						</Table>
					)}
				</ContentCardBody>}
			</ContentCard>
		);
	}


}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	modoTela: state.braskem.modoTela,
	listaCnpj: state.braskem.listaCnpj,
	registro: state.braskem.registro,
	listaLog: state.braskem.listaLog
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, initForm, consultar, consultarLog }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Braskem);
