import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import Form from './despesaTipoForm';

import { setModoTela, initForm, salvar, excluir, getLista, getListaUsuario } from './despesaTipoActions';
import FormatUtils from '../common/formatUtils/FormatUtils';
import DateFormat from '../common/dateFormat/DateFormat';
import Select from '../common/form/select';

class DespesaTipo extends Component {

	state = {
		linhaSelecionada: null,
		id_vigencia: 1
	}

    componentWillMount() {
        this.props.getLista();
		this.props.getListaUsuario();
    }

    render() {
        return (
            <div>
                <Content>
					{this.props.modoTela == 'cadastro' ? (
						<Form onSubmit={this.props.salvar} />
					) : null}
					{this.props.modoTela == 'exclusao' ? (
						<Form excluir onSubmit={this.props.excluir} />
					) : null}
					{this.props.modoTela == 'lista' ? this.lista() : null}
                </Content>
            </div>
        )
    }

	lista() {
		return (
			<ContentCard>
				<ContentCardHeader>
					<Row>
						<Grid cols='6 6 4 2'>
							<Button
								text='Adicionar'
								type={'success'}
								icon={'fa fa-plus'}
								event={() => {
									this.props.setModoTela('cadastro', {});
									this.props.initForm({});
								}} />
						</Grid>
					</Row>
				</ContentCardHeader>
				<ContentCardBody>
					<Row>
						<Select
							options={[{ id: 1, valor: 'Vigente' }, { id: 2, valor: 'Não Vigente' }, { id: 3, valor: 'Ambos' }]}
							label='Vigência'
							cols='12 3 3 3 '
							placeholder='Selecione'
							value={this.state.id_vigencia}
							onChange={data => {
								this.setState({ ...this.state, id_vigencia: data || 3 });
							}} />
					</Row>
					<Table responsive>
						<THead>
							<Tr>
								<Th>Nome</Th>
								<Th alignCenter>Aprovadores</Th>
								<Th alignRight>Limite (R$)</Th>
								<Th alignCenter>Início Vigência</Th>
								<Th alignCenter>Fim Vigência</Th>
								<Th></Th>
							</Tr>
						</THead>
						<TBody>
							{(this.props.lista.filter(item => {
								if ((this.state.id_vigencia == 1 && !item.vigente) || (this.state.id_vigencia == 2 && item.vigente)) {
									return false;
								}

								return true;
							})).map(item => (
								<Tr key={item.id} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
									backgroundColor={item.vigente ? '#dce6d1' : null} >
									<Td>{item.nome}</Td>
									<Td alignCenter>
										{(item.aprovadores || []).map((usuario, i) => 
										(this.props.listaUsuario || []).filter(item => item.id == usuario).map(item => item.valor).join('')
										).map(usuario => (
											<p style={{ padding: 0, margin: 0 }}>
												{usuario}
											</p>
										))}
									</Td>
									<Td alignRight>{FormatUtils.formatarValorTela(item.limite, 2)}</Td>
									<Td alignCenter>{DateFormat.formatarDataSqlParaTela(item.data_inicio) || '-'}</Td>
									<Td alignCenter>{DateFormat.formatarDataSqlParaTela(item.data_fim) || '-'}</Td>
									<Td alignRight minWidth={100}>
										<ButtonTable
											type={'warning'}
											icon={'fas fa-pencil-alt'}
											visible={true}
											event={() => {
												this.props.setModoTela('cadastro', {
													...item,
													limite: FormatUtils.formatarValorTela(item.limite, 2),
													data_inicio: DateFormat.formatarDataSqlParaTela(item.data_inicio),
													data_fim: DateFormat.formatarDataSqlParaTela(item.data_fim),
												});
												this.props.initForm({
													...item,
													limite: FormatUtils.formatarValorTela(item.limite, 2),
													data_inicio: DateFormat.formatarDataSqlParaTela(item.data_inicio),
													data_fim: DateFormat.formatarDataSqlParaTela(item.data_fim),
												});
											}} />

										<ButtonTable
											type={'danger'}
											icon={'fas fa-trash-alt'}
											visible={true}
											event={() => {
												this.props.setModoTela('exclusao', {
													...item,
													limite: FormatUtils.formatarValorTela(item.limite, 2),
													data_inicio: DateFormat.formatarDataSqlParaTela(item.data_inicio),
													data_fim: DateFormat.formatarDataSqlParaTela(item.data_fim),
												});
												this.props.initForm({
													...item,
													limite: FormatUtils.formatarValorTela(item.limite, 2),
													data_inicio: DateFormat.formatarDataSqlParaTela(item.data_inicio),
													data_fim: DateFormat.formatarDataSqlParaTela(item.data_fim),
												});
											}} />
									</Td>
								</Tr>
							))}
						</TBody>
					</Table>
				</ContentCardBody>
			</ContentCard>
		);
	}


}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	modoTela: state.despesaTipo.modoTela,
	lista: state.despesaTipo.lista,
	listaUsuario: state.despesaTipo.listaUsuario
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, initForm, salvar, excluir, getLista, getListaUsuario }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(DespesaTipo);
