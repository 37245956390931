import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import DateFormat from '../common/dateFormat/DateFormat';

export function setModoTela(modo, registro = {}) {
    return {
        type: 'REPORTS_INTEGRACAO_GM_MODO_TELA',
        payload: {
			modo: modo,
			registro: registro
		}
    };
}

export function setAguardando(aguardando) {
    return {
        type: 'REPORTS_INTEGRACAO_GM_SET_AGUARDANDO',
        payload: aguardando
    };
}

export function getLista(data_inicial, data_final) {
	return async (dispatch, getState) => {
		dispatch(setAguardando(true));
    	await axios.get(`${consts.API_URL}/reports/apiGm?
			data_inicial=${DateFormat.formatarDataTelaParaSql(data_inicial) == 'Invalid DateTime' ? null : DateFormat.formatarDataTelaParaSql(data_inicial)}
			&data_final=${DateFormat.formatarDataTelaParaSql(data_final) == 'Invalid DateTime' ? null : DateFormat.formatarDataTelaParaSql(data_final)}`)
		.then(resp => {
			dispatch({
		        type: 'REPORTS_INTEGRACAO_GM_LISTADO',
		        payload: resp
		    });
			dispatch(setAguardando(false));
		}).catch(e => {
			setErroAPI(e);
			dispatch(setAguardando(false));
		});
	}
}

export function getListaLog(shipping) {
	return async (dispatch, getState) => {
		dispatch(setAguardando(true));
		dispatch({
			type: 'REPORTS_INTEGRACAO_GM_LOG_LISTADO',
			payload: { data: [] }
		});
    	await axios.get(`${consts.API_URL}/reports/apiGmLog?shipping=${shipping}`)
		.then(resp => {
			dispatch({
		        type: 'REPORTS_INTEGRACAO_GM_LOG_LISTADO',
		        payload: resp
		    });
			dispatch(setAguardando(false));
		}).catch(e => {
			setErroAPI(e);
			dispatch(setAguardando(false));
		});
	}
}