import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import DateFormat from '../common/dateFormat/DateFormat';

export function setModoTela(modo, registro = {}) {
    return {
        type: 'OPERACAO_MODO_TELA',
        payload: {
			modo: modo,
			registro: registro
		}
    };
}

export function setAguardando(aguardando) {
    return {
        type: 'OPERACAO_SET_AGUARDANDO',
        payload: aguardando
    };
}

export function initForm(registro = {}) {
    return [
        initialize('operacaoForm', registro)
    ];
}

export function getListaPerfil() {
    const request = axios.get(`${consts.API_URL}/operacaoPerfil/listarSelect`);
    return {
        type: 'OPERACAO_PERFIL_SELECT_LISTADO',
        payload: request
    };
}

export function getLista() {
	return (dispatch, getState) => {
		dispatch(setAguardando(true));
    	axios.get(`${consts.API_URL}/operacao`)
		.then(resp => {
			dispatch({
		        type: 'OPERACAO_LISTADO',
		        payload: resp
		    });
			dispatch(setAguardando(false));
		}).catch(e => {
			setErroAPI(e);
			dispatch(setAguardando(false));
		});
	}
}

export function salvar(registro) {
    return (dispatch, getState) => {
		if (!registro.id) {
	        axios.post(`${consts.API_URL}/operacao`, {
				...registro,
				emails: [registro.email1, registro.email2, registro.email3].filter(email => email),
				id_usuario_inclusao: getState().auth.usuario.id,
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		} else {
			axios.put(`${consts.API_URL}/operacao`, {
				...registro,
				emails: [registro.email1, registro.email2, registro.email3].filter(email => email),
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		}
    };
}

export function excluir(registro) {
    return dispatch => {
        axios.delete(`${consts.API_URL}/operacao?id=${registro.id}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista());
			dispatch(setModoTela('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

//OPERAÇÃO DOCUMENTO
export function setModoTelaItem(modo, registro = {}) {
    return {
        type: 'OPERACAO_ITEM_MODO_TELA',
        payload: {
			modo: modo,
			registro: registro
		}
    };
}

export function initFormItem(registro = {}) {
    return [
        initialize('operacaoItemForm', registro)
    ];
}

export function getListaItem(id_operacao) {
	return (dispatch, getState) => {
    	axios.get(`${consts.API_URL}/operacaoDocumento?id_operacao=${id_operacao}`)
		.then(resp => {
			dispatch({
		        type: 'OPERACAO_ITEM_LISTADO',
		        payload: resp
		    });
		}).catch(e => {
			setErroAPI(e);
		});
	}
}

export function salvarItem(registro) {
    return (dispatch, getState) => {
		if (!registro.id) {
	        axios.post(`${consts.API_URL}/operacaoDocumento`, {
				...registro,
				id_usuario_inclusao: getState().auth.usuario.id,
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getListaItem(registro.id_operacao));
				dispatch(setModoTelaItem('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		} else {
			axios.put(`${consts.API_URL}/operacaoDocumento`, {
				...registro,
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getListaItem(registro.id_operacao));
				dispatch(setModoTelaItem('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		}
    };
}

export function excluirItem(registro) {
    return (dispatch, getState) => {
        axios.delete(`${consts.API_URL}/operacaoDocumento?id=${registro.id}&id_usuario_alteracao=${getState().auth.usuario.id}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getListaItem(registro.id_operacao));
			dispatch(setModoTelaItem('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function getListaDocumento() {
    const request = axios.get(`${consts.API_URL}/documento/listarSelect`);
    return {
        type: 'OPERACAO_DOCUMENTO_SELECT_LISTADO',
        payload: request
    };
}

//OPERAÇÃO EXCEÇÃO
export function setModoTelaExcecao(modo, registro = {}) {
    return {
        type: 'OPERACAO_EXCECAO_MODO_TELA',
        payload: {
			modo: modo,
			registro: registro
		}
    };
}

export function initFormExcecao(registro = {}) {
	
    return [
        initialize('operacaoExcecaoForm', registro)
    ];
}

export function getListaExcecao(id_operacao) {
	return (dispatch, getState) => {
    	axios.get(`${consts.API_URL}/operacaoExcecao?id_operacao=${id_operacao}`)
		.then(resp => {
			dispatch({
		        type: 'OPERACAO_EXCECAO_LISTADO',
		        payload: resp
		    });
		}).catch(e => {
			setErroAPI(e);
		});
	}
}

export function salvarExcecao(registro) {
	
    return (dispatch, getState) => {
		if (!registro.id) {
	        axios.post(`${consts.API_URL}/operacaoExcecao`, {
				...registro,
				id_usuario_inclusao: getState().auth.usuario.id,
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getListaExcecao(registro.id_operacao));
				dispatch(setModoTelaExcecao('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		} else {
			axios.put(`${consts.API_URL}/operacaoExcecao`, {
				...registro,
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getListaExcecao(registro.id_operacao));
				dispatch(setModoTelaExcecao('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		}
    };
}

export function excluirExcecao(registro) {
    return (dispatch, getState) => {
        axios.delete(`${consts.API_URL}/operacaoExcecao?id=${registro.id}&id_usuario_alteracao=${getState().auth.usuario.id}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getListaExcecao(registro.id_operacao));
			dispatch(setModoTelaExcecao('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function importarExcecao(tabela, idOperacao) {
    return async (dispatch, getState) => {

		dispatch(setAguardando(true));
		
		for (var i = 0; i < tabela.length; i++) {
			let item = tabela[i];

			console.log(item);

			let registro = {
				id_operacao: idOperacao,
				cnpj_destinatario: String(item[1] || '').trim(),
				excecao: String(item[2] || '').trim(),
				id_usuario_inclusao: getState().auth.usuario.id,
				id_usuario_alteracao: getState().auth.usuario.id
			};

			await axios.post(`${consts.API_URL}/operacaoExcecao`, {
				...registro
			}).then(resp => {
			}).catch(e => {
				setErroAPI(e);
			});	
			
		}

		setSuccess('Importação Realizada com sucesso.');
		dispatch(getListaExcecao(idOperacao));
		dispatch(setAguardando(false));
    };
}