import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';

import { setModoTela, initForm, getLista, gerarPdf, getListaLog } from './comprovanteEntregaActions';
import FormatUtils from '../common/formatUtils/FormatUtils';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import DateFormat from '../common/dateFormat/DateFormat';
import InputPesquisa from '../common/form/inputPesquisa';

class ComprovanteEntrega extends Component {

	state = {
		linhaSelecionada: null,
		pesquisar: '',
		dataInicial: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAnterior(30)),
		dataFinal: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAtual()),
		mostrarLogs: false
	}

    componentWillMount() {
		this.props.getLista('00.000.000.000');
		this.props.getListaLog(this.state.dataInicial, this.state.dataFinal);
    }

    render() {
        return (
            <div>
                <Content>					
					{this.lista()}
					{this.listaLog()}
                </Content>
            </div>
        )
    }

	lista() {
		return (
			<ContentCard>
				<ContentCardHeader>
					<Row>
						<LabelAndInputMask
							label='Número do CRT' placeholder='Informe'
							cols='6 6 4 3'
							mask='aa.999.999.999'
							value={this.state.numeroDocumento}
							onChange={data => {
								this.setState({
									...this.state,
									numeroDocumento: data.target.value
								})
							}} />

						<Grid cols='6 6 4 2' style={{ marginTop: 28 }}>
							<Button
								text='Consultar'
								type={'success'}
								icon={'fa fa-plus'}
								event={() => {
									this.props.getLista(this.state.numeroDocumento);
								}} />
						</Grid>
					</Row>
				</ContentCardHeader>
				<ContentCardBody>
					<Table responsive>
						<THead>
							<Tr>
								<Th alignCenter>Sol.</Th>
								<Th alignCenter>MIC</Th>
								<Th alignCenter>Carreta</Th>
								<Th alignCenter>Cavalo</Th>
								<Th alignCenter>Motorista</Th>
								<Th alignCenter>Remetente</Th>
								<Th alignCenter>Destinatário</Th>
								<Th alignCenter>Espécie</Th>
								<Th alignCenter>Volumes</Th>
								<Th></Th>
							</Tr>
						</THead>
						<TBody>
							{(this.props.lista).map(item => (
								<Tr key={`${item.shipping_code} - ${item.solicitacao} - ${item.mic}`} 
									className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
									onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
									onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
									<Td alignCenter small>{item.solicitacao}</Td>
									<Td alignCenter small>{item.mic}</Td>
									<Td alignCenter small>{item.carreta}</Td>
									<Td alignCenter small>{item.cavalo}</Td>
									<Td alignCenter small>{item.motorista}</Td>
									<Td alignCenter small>{item.remetente}</Td>
									<Td alignCenter small>{item.destinatario}</Td>
									<Td alignCenter small>{item.especie}</Td>
									<Td alignCenter small>{item.volumes}</Td>
									<Td alignRight minWidth={100}>
										<ButtonTable
											type={'primary'}
											icon={'fas fa-file-pdf'}
											visible={this.state.linhaSelecionada == item.id}
											event={() => {
												this.props.gerarPdf(item, this.state.dataInicial, this.state.dataFinal);
											}} />
									</Td>
								</Tr>
							))}
						</TBody>
					</Table>
				</ContentCardBody>
			</ContentCard>
		);
	}

	listaLog() {
		return (
			<ContentCard style={{ marginTop: 20 }}>
				<div class='card-header ui-sortable-handle'>
					<h3 class='card-title' 
						style={{ cursor: 'pointer' }}
						onClick={() => this.setState({ ...this.state, mostrarLogs: !this.state.mostrarLogs })}>
						<i class={`fas fa-chevron-${this.state.mostrarLogs ? 'up' : 'down'}`}></i> Logs de Emissão
					</h3>
				</div>

				{this.state.mostrarLogs &&				
				<ContentCardBody>
					<Row style={{ paddingLeft: 8 }}>
						<LabelAndInputMask
							label='Data Inicial' placeholder='Informe o período inicial'
							cols='12 6 3 2'
							mask='99/99/9999'
							value={this.state.dataInicial}
							onChange={(data) => {
								this.setState({ ...this.state, dataInicial: data.target.value });
							}} />

						<LabelAndInputMask
							label='Data Final' placeholder='Informe o período final'
							cols='12 6 3 2'
							mask='99/99/9999'
							value={this.state.dataFinal}
							onChange={(data) => {
								this.setState({ ...this.state, dataFinal: data.target.value });
							}} />

						<Grid cols='6 6 4 2' style={{ marginTop: 28 }}>
							<Button
								text='Atualizar'
								type={'primary'}
								icon={'fa fa-plus'}
								event={() => {
									this.props.getListaLog(this.state.dataInicial, this.state.dataFinal);
								}} />
						</Grid>
					</Row>
								
					<InputPesquisa
						cols='12 12 12 12'
						label='Pesquisar'
						onChange={value => this.setState({ ...this.state, pesquisar: value })} />

					<Table responsive>
						<THead>
							<Tr>
								<Th alignCenter>Emissão</Th>
								<Th alignCenter>Usuário</Th>
								<Th alignCenter>Sol.</Th>
								<Th alignCenter>MIC</Th>
								<Th alignCenter>Carreta</Th>
								<Th alignCenter>Cavalo</Th>
								<Th alignCenter>Motorista</Th>
								<Th alignCenter>Remetente</Th>
								<Th alignCenter>Destinatário</Th>
								<Th alignCenter>Espécie</Th>
								<Th alignCenter>Volumes</Th>
							</Tr>
						</THead>
						<TBody>
							{this.props.listaLog.filter(item => {
								
								return !(this.state.pesquisar || '').trim()
									|| (this.state.pesquisar.toUpperCase() || '').split('+').filter(pesquisar => (pesquisar || '').trim()).filter(pesquisar => {
		
									if (pesquisar
										&& !(`
										${DateFormat.formatarDataHoraSqlParaTela(item.datahora_inclusao)} 
										${item.nome_usuario}
										${item.solicitacao}
										${item.mic}
										${item.carreta}
										${item.cavalo}
										${item.motorista}
										${item.remetente}
										${item.destinatario}
										${item.especie}
										${item.volumes}											
									`).toUpperCase().includes(pesquisar)) {
										return false;
									}
	
									return true;
								}).length > 0;
							}).map(item => (
								<Tr key={`${item.shipping_code} - ${item.solicitacao} - ${item.mic}`} 
									className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
									onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
									onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
									<Td alignCenter small>{DateFormat.formatarDataHoraSqlParaTela(item.datahora_inclusao)}</Td>
									<Td alignCenter small>{item.nome_usuario}</Td>
									<Td alignCenter small>{item.solicitacao}</Td>
									<Td alignCenter small>{item.mic}</Td>
									<Td alignCenter small>{item.carreta}</Td>
									<Td alignCenter small>{item.cavalo}</Td>
									<Td alignCenter small>{item.motorista}</Td>
									<Td alignCenter small>{item.remetente}</Td>
									<Td alignCenter small>{item.destinatario}</Td>
									<Td alignCenter small>{item.especie}</Td>
									<Td alignCenter small>{item.volumes}</Td>
								</Tr>
							))}
						</TBody>
					</Table>
				</ContentCardBody>}
			</ContentCard>
		);
	}


}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	modoTela: state.comprovanteEntrega.modoTela,
	lista: state.comprovanteEntrega.lista,
	listaLog: state.comprovanteEntrega.listaLog
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, initForm, getLista, gerarPdf, getListaLog }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ComprovanteEntrega);
