import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import Form from './comissaoApuracaoForm';
import Aguardando from '../common/template/aguardando';
import FormatUtils from '../common/formatUtils/FormatUtils';
import ReactExport from 'react-export-excel-fixed-xlsx';

import { 
	setModoTela, initForm, salvar, excluir, getLista,
	getListaApuracao, getListaComissaoTermo, getListaComissaoVinculo, 
	getListaUsuario, getListaComissaoTipo
} from './comissaoApuracaoActions';
import DateFormat from '../common/dateFormat/DateFormat';
import Select from '../common/form/select';

class ComissaoApuracao extends Component {

	state = {
		linhaSelecionada: null,
		id_status: 1
	}

    componentWillMount() {
        this.props.getLista();
		this.props.getListaApuracao();
		this.props.getListaComissaoTermo();
		this.props.getListaComissaoVinculo();
		this.props.getListaUsuario();
		this.props.getListaComissaoTipo();
    }

    render() {
        return (
            <div>
                <Content>

					<Aguardando aguardando={this.props.aguardando} />

					{this.props.modoTela == 'cadastro' ? (
						<Form onSubmit={this.props.salvar} />
					) : null}
					{this.props.modoTela == 'exclusao' ? (
						<Form excluir onSubmit={this.props.excluir} />
					) : null}
					{this.props.modoTela == 'lista' ? this.lista() : null}
                </Content>
            </div>
        )
    }

	lista() {

		const ExcelFile = ReactExport.ExcelFile;
		const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
		const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

		let lista = [];
		
		//Sem vínculo
		if (this.state.id_status == 2) {

			let listaAux = this.props.lista.map(item => item).filter(item => !this.state.id_vinculo || item.id_vinculo == this.state.id_vinculo);
			let vinculo = this.props.listaComissaoVinculo.filter(item => item.id == this.state.id_vinculo)[0];

			lista = this.props.listaApuracao.filter(item => {
				return listaAux.filter(itemAux => itemAux.id_operacao == item.id).length <= 0;
			}).map(item => ({
				id_operacao: item.id,
				nome_operacao: item.valor,
				id_vinculo: vinculo ? vinculo.id : null,
				nome_vinculo: vinculo ? vinculo.valor : ''
			}));

		} else {
			lista = this.props.lista.filter(item => {

				if (this.state.id_vinculo && item.id_vinculo != this.state.id_vinculo) {
					return false;
				}
				return true;
			});
		}

		lista = lista.filter(item => {

			if (this.state.pesquisar
				&& !(`		
					${item.nome_operacao}
					${DateFormat.formatarDataSqlParaTela(item.data_inicio)} ${DateFormat.formatarDataSqlParaTela(item.data_fim)}
					${item.nome_termo} ${item.nome_vinculo} ${item.nome_colaborador} ${item.nome_tipo}
				`).toUpperCase().includes(this.state.pesquisar.toUpperCase())) {
				return false;
			}
			return true;
		});

		return (
			<ContentCard>
				<ContentCardHeader>
					<Row>
						<Grid cols='6 6 4 2'>
							<Button
								text='Adicionar'
								type={'success'}
								icon={'fa fa-plus'}
								event={() => {
									this.props.setModoTela('cadastro', {});
									this.props.initForm({});
								}} />
						</Grid>
						<Grid cols='6 6 4 2'>
							<ExcelFile
								filename={'Comissão - Operação'}
								element={
									<Button
										text='Exportar'
										type={'primary'}
										icon={'fa fa-plus'} />
								}>
								<ExcelSheet data={lista.map(item => ({
									...item,
									data_base: DateFormat.formatarDataSqlParaTela(item.data_base),
									data_inicio: DateFormat.formatarDataSqlParaTela(item.data_inicio),
									data_fim: DateFormat.formatarDataSqlParaTela(item.data_fim),
									conquista_integral: parseFloat(item.conquista_integral),
									conquista_30_60: parseFloat(item.conquista_30_60),
									conquista_mais_60: parseFloat(item.conquista_mais_60),
									manutencao_integral: parseFloat(item.manutencao_integral),
									manutencao_30_60: parseFloat(item.manutencao_30_60),
									manutencao_mais_60: parseFloat(item.manutencao_mais_60)
								}))} name='Comissão - Operação'>
									<ExcelColumn label='Operação' value='nome_operacao' />
									<ExcelColumn label='Data Base' value='data_base' />
									<ExcelColumn label='Início Vigência' value='data_inicio' />
									<ExcelColumn label='Fim Vigência' value='data_fim' />
									<ExcelColumn label='Termo' value='nome_termo' />
									<ExcelColumn label='Vínculo' value='nome_vinculo' />
									<ExcelColumn label='Colaborador' value='nome_colaborador' />
									<ExcelColumn label='Tipo' value='nome_tipo' />
									<ExcelColumn label='Conquista (Integral)' value='conquista_integral' type='number' />
									<ExcelColumn label='Conquista (30 até 60)' value='conquista_30_60' type='number' />
									<ExcelColumn label='Conquista (Mais de 60)' value='conquista_mais_60' type='number' />
									<ExcelColumn label='Manutenção (Integral)' value='manutencao_integral' type='number' />
									<ExcelColumn label='Manutenção (30 até 60)' value='manutencao_30_60' type='number' />
									<ExcelColumn label='Manutenção (Mais de 60)' value='manutencao_mais_60' type='number' />
								</ExcelSheet>
							</ExcelFile>
						</Grid>
					</Row>
				</ContentCardHeader>
				<ContentCardBody>

					<Row>
						<Select
							options={this.props.listaComissaoVinculo}
							label='Vínculo'
							cols='12 6 4 3'
							placeholder='Selecione o vínculo'
							value={this.state.id_vinculo}
							onChange={value => {
								this.setState({
									...this.state,
									id_vinculo: value
								});
							}} />

						<Select
							options={[{ id: 1, valor: 'Operações com vínculo' }, { id: 2, valor: 'Operações sem vínculo' }]}
							label='Status'
							cols='12 6 4 3'
							placeholder='Selecione o status'
							value={this.state.id_status}
							onChange={value => {
								this.setState({
									...this.state,
									id_status: value
								});
							}} />
					</Row>
					
					<input className='form-control'
						 placeholder='Pesquisar'
						 type='text'
						 value={this.state.pesquisar}
						 onChange={e => {
							 this.setState({ ...this.state, pesquisar: e.target.value });
						 }} />

					<Table responsive border>
						<THead>
							<Tr>
								<Th alignCenter small>Função</Th>
								<Th alignCenter small>Representante</Th>
								<Th alignCenter small>Emissão</Th>
								<Th alignCenter small>Tipo</Th>
								<Th alignCenter small>Documento</Th>
								<Th alignCenter small>Valor Doc. (R$)</Th>
								<Th alignCenter small>Valor Recebido (R$)</Th>
								<Th alignCenter small>Tipo Emissão</Th>
								<Th alignCenter small>Operação</Th>
								<Th alignCenter small>Fatura</Th>
								<Th alignCenter small>Cliente Fatura</Th>
								<Th alignCenter small>Vencimento</Th>
								<Th alignCenter small>Pagamento</Th>
								<Th alignCenter small>Liquidação</Th>
								<Th alignCenter small>Dias Atraso</Th>
								<Th alignCenter small>Serviço</Th>
								<Th alignCenter small>Tipo Comissão</Th>
								<Th alignCenter small>Classificação</Th>
								<Th alignCenter small>%</Th>
								<Th alignCenter small>R$</Th>
							</Tr>
						</THead>
						<TBody>
							{lista.map(item => (
								<Tr key={item.id || item.id_operacao} className={`${this.state.linhaSelecionada == (item.id || item.id_operacao) ? 'hover' : ''}`}
									onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id || item.id_operacao })}
									onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id || item.id_operacao })} >
									<Td alignCenter small>{item.nome_funcao || 'Gestor'}</Td>
									<Td alignCenter small>{item.nome_representante || 'Teste Colaborador'}</Td>
									<Td alignCenter small>{DateFormat.formatarDataSqlParaTela(item.data_emissao)}</Td>
									<Td alignCenter small>{item.tipo_documento}</Td>
									<Td alignCenter small>{item.numero_documento}</Td>
									<Td alignCenter small>{FormatUtils.formatarValorTelaDecimal(item.valor_documento, 2)}</Td>
									<Td alignCenter small>{FormatUtils.formatarValorTelaDecimal(item.valor_recebido, 2)}</Td>
									<Td alignCenter small>{item.tipo_emissao}</Td>
									<Td alignCenter small>{item.operacao}</Td>
									<Td alignCenter small>{item.fatura}</Td>
									<Td alignCenter small>{item.cnpj_cliente} - {item.nome_cliente}</Td>
									<Td alignCenter small>{DateFormat.formatarDataSqlParaTela(item.data_vencimento)}</Td>
									<Td alignCenter small>{DateFormat.formatarDataSqlParaTela(item.data_pagamento)}</Td>
									<Td alignCenter small>{DateFormat.formatarDataSqlParaTela(item.data_liquidacao)}</Td>
									<Td alignCenter small>{item.dias_atraso}</Td>
									<Td alignCenter small>{item.servico}</Td>
									<Td alignCenter small>{'Clientes Conquistados e/ou migrados'}</Td>
									<Td alignCenter small>{'Conquista'}</Td>
									<Td alignCenter small>{'0,096'}</Td>
									<Td alignCenter small>{FormatUtils.formatarValorTelaDecimal(parseFloat(item.valor_documento || 0) * 0.096, 2)}</Td>
								</Tr>
							))}
						</TBody>
					</Table>
				</ContentCardBody>
			</ContentCard>
		);
	}


}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	modoTela: state.comissaoApuracao.modoTela,
	aguardando: state.comissaoApuracao.aguardando,
	lista: state.comissaoApuracao.lista,
	listaApuracao: state.comissaoApuracao.listaApuracao,
	listaComissaoVinculo: state.comissaoApuracao.listaComissaoVinculo
});
const mapDispatchToProps = dispatch => bindActionCreators({ 
	setModoTela, initForm, salvar, excluir, getLista,
	getListaApuracao, getListaComissaoTermo, getListaComissaoVinculo, 
	getListaUsuario, getListaComissaoTipo
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ComissaoApuracao);
