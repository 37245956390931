import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import Form from './ocorrenciaForm';
import DateFormat from '../common/dateFormat/DateFormat';
import FormatUtils from '../common/formatUtils/FormatUtils';
import ReactExport from 'react-export-excel-fixed-xlsx';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import InputPesquisa from '../common/form/inputPesquisa';

import { 
	setModoTela, setFiltro, initForm, salvar, excluir, getLista, 
	getListaTipo, getListaMotorista, getListaGravidade, getListaLocal, 
	getListaResponsabilidade, getListaTipoCusto, salvarAbona, 
	getListaStatus, getListaAcao, getListaUsuario, getListaCategoria
} from './ocorrenciaActions';

class Ocorrencia extends Component {

	state = {
		linhaSelecionada: null,
		pesquisar: ''
	}

    componentWillMount() {
        this.props.getLista();
		this.props.getListaTipo();
		this.props.getListaMotorista();
		this.props.getListaGravidade();
		this.props.getListaLocal();
		this.props.getListaResponsabilidade();
		this.props.getListaTipoCusto();
		this.props.getListaStatus();
		this.props.getListaAcao();
		this.props.getListaUsuario();
		this.props.getListaCategoria();
    }

    render() {
        return (
            <div>
                <Content>
					{this.props.modoTela == 'cadastro' ? (
						<Form onSubmit={(registro) => this.props.salvar(registro)} />
					) : null}
					{this.props.modoTela == 'exclusao' ? (
						<Form excluir onSubmit={(registro) => this.props.excluir(registro)} />
					) : null}
					{this.props.modoTela == 'lista' ? this.lista() : null}
                </Content>
            </div>
        )
    }

	lista() {

		const ExcelFile = ReactExport.ExcelFile;
		const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
		const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

		let lista = this.props.lista.filter(item => {

			return !(this.state.pesquisar || '').trim()
				|| (this.state.pesquisar.toUpperCase() || '').split('+').filter(pesquisar => (pesquisar || '').trim()).filter(pesquisar => {
				if (pesquisar
					&& !(`
					${DateFormat.formatarDataHoraSqlParaTela(item.datahora_ocorrencia)} 
					${item.nome_motorista} 
					${item.nome_tipo} 
					${item.observacao} 
					${item.nome_usuario_inclusao}
				`).toUpperCase().includes(pesquisar)) {
					return false;
				}

				return true;

			}).length > 0;
			
		}).filter(item => {				

			if (!this.props.usuarioCarregado) {
				return false;
			} else if (this.props.usuarioCarregado.acesso_ocorrencia) {
				return true;
			} else if (
				item.id_usuario_inclusao == this.props.usuarioCarregado.id
				|| item.id_usuario_responsavel == this.props.usuarioCarregado.id
				|| item.id_usuario_aprovador == this.props.usuarioCarregado.id
			) {
				return true;
			}
			return false;
		});

		return (
			<ContentCard>
				<ContentCardHeader>
					<Row>
						<Grid cols='6 6 4 2'>
							<Button
								text='Adicionar'
								type={'success'}
								icon={'fa fa-plus'}
								event={() => {
									this.props.setModoTela('cadastro', { datahora_ocorrencia: DateFormat.formatarDataHoraSqlParaTela(DateFormat.getDataHoraAtual()), quantidade: 1 });
									this.props.initForm({ datahora_ocorrencia:  DateFormat.formatarDataHoraSqlParaTela(DateFormat.getDataHoraAtual()), quantidade: 1 });
								}} />
						</Grid>
						<Grid cols='6 6 4 2'>
							<Button
								text='Adicionar para Múltiplos'
								type={'success'}
								icon={'fa fa-plus'}
								event={() => {
									this.props.setModoTela('cadastro', { multiplos: true, datahora_ocorrencia: DateFormat.formatarDataHoraSqlParaTela(DateFormat.getDataHoraAtual()), quantidade: 1 });
									this.props.initForm({ multiplos: true, datahora_ocorrencia:  DateFormat.formatarDataHoraSqlParaTela(DateFormat.getDataHoraAtual()), quantidade: 1 });
								}} />
						</Grid>
						<Grid cols='6 6 3 2'>
							<ExcelFile
								filename={'Ocorrências'}
								element={
									<Button
										text='Exportar'
										type={'primary'}
										icon={'fa fa-plus'} />
								}>
								<ExcelSheet data={lista.map(item => ({
									...item,
									datahora_ocorrencia: DateFormat.formatarDataHoraSqlParaTela(item.datahora_ocorrencia),
									percentual_desconto: FormatUtils.formatarValorTela(item.percentual_desconto, 2),
									datahora_finalizacao: DateFormat.formatarDataHoraSqlParaTela(item.datahora_finalizacao),
									datahora_pagamento: DateFormat.formatarDataHoraSqlParaTela(item.datahora_pagamento),
									custo: FormatUtils.formatarValorTela(item.custo, 2),
									valor: FormatUtils.formatarValorTela(item.valor, 2),
									valor_autorizado: FormatUtils.formatarValorTela(item.valor_autorizado, 2)
								}))} name='Premiação'>
									<ExcelColumn label='Data/Hora' value='datahora_ocorrencia' />
									<ExcelColumn label='Motorista' value='nome_motorista' />
									<ExcelColumn label='Ocorrência' value='nome_tipo' />
									<ExcelColumn label='Perc. Desconto' value='percentual_desconto' />
									<ExcelColumn label='Observação' value='observacao' />
									<ExcelColumn label='Usuário Inclusão' value='nome_usuario_inclusao' />
									<ExcelColumn label='Abona' value='abona' />
									<ExcelColumn label='Usuário Abono' value='nome_usuario_abono' />
								</ExcelSheet>
							</ExcelFile>
						</Grid>
						<Grid cols='6 6 4 2'>
							<Button
								text='Atualizar'
								type={'primary'}
								icon={'fa fa-plus'}
								event={() => {
									this.props.getLista();
									this.props.getListaTipo();
									this.props.getListaMotorista();
									this.props.getListaGravidade();
									this.props.getListaLocal();
									this.props.getListaResponsabilidade();
									this.props.getListaTipoCusto();
								}} />
						</Grid>
					</Row>
				</ContentCardHeader>
				<ContentCardBody>
					<Row>
						<LabelAndInputMask
							label='Data/Hora' placeholder='Informe o período inicial'
							cols='12 6 3 2'
							mask='99/99/9999'
							value={this.props.filtro.data_inicial}
							onChange={(data) => {
								this.props.setFiltro({ ...this.props.filtro, data_inicial: data.target.value });
							}} />

						<LabelAndInputMask
							label='Data/Hora' placeholder='Informe o período final'
							cols='12 6 3 2'
							mask='99/99/9999'
							value={this.props.filtro.data_final}
							onChange={(data) => {
								this.props.setFiltro({ ...this.props.filtro, data_final: data.target.value });
							}} />
					</Row>

					<InputPesquisa
						cols='12 12 12 12'
						label='Pesquisar'
						onChange={value => this.setState({ ...this.state, pesquisar: value })} />

					<Table responsive>
						<THead>
							<Tr>
								<Th>Data/Hora</Th>
								<Th>Motorista</Th>
								<Th>Ocorrência</Th>
								<Th alignCenter>Perc. Desconto</Th>
								<Th>Observação</Th>
								<Th>Usuário Inclusão</Th>
								<Th alignCenter>Abona</Th>
								<Th></Th>
							</Tr>
						</THead>
						<TBody>
							{lista.map(item => (
								<Tr key={item.id} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
									onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
									onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
									<Td>{DateFormat.formatarDataHoraSqlParaTela(item.datahora_ocorrencia)}</Td>
									<Td>{item.nome_motorista}</Td>
									<Td>{item.nome_tipo}</Td>
									<Td alignCenter>{FormatUtils.formatarValorTela(item.percentual_desconto, 2)}</Td>
									<Td>{item.observacao}</Td>
									<Td>{item.nome_usuario_inclusao}</Td>
									{this.props.usuarioCarregado && this.props.usuarioCarregado.abona ? (
										<Td>
											<Button
												text={item.abona ? `SIM - ${item.nome_usuario_abono}` : 'NÃO'}
												type={item.abona ? 'danger' : 'success'}
												icon={'fa fa-plus'}
												event={() => {
													this.props.salvarAbona(item);
												}} />											
										</Td>
									) : (
										<Td>{item.abona ? `SIM - ${item.nome_usuario_abono}` : 'NÃO'}</Td>
									)}
									<Td alignRight minWidth={100}>
										<ButtonTable
											type={'warning'}
											icon={'fas fa-pencil-alt'}
											visible={this.state.linhaSelecionada == item.id}
											event={() => {
												this.props.setModoTela('cadastro', {
													...item,
													percentual_desconto: FormatUtils.formatarValorTela(item.percentual_desconto, 2),
													datahora_ocorrencia: DateFormat.formatarDataHoraSqlParaTela(item.datahora_ocorrencia),
													datahora_finalizacao: DateFormat.formatarDataHoraSqlParaTela(item.datahora_finalizacao),
													datahora_pagamento: DateFormat.formatarDataHoraSqlParaTela(item.datahora_pagamento),
													custo: FormatUtils.formatarValorTela(item.custo, 2),
													valor: FormatUtils.formatarValorTela(item.valor, 2),
													valor_autorizado: FormatUtils.formatarValorTela(item.valor_autorizado, 2)
												});
												this.props.initForm({
													...item,
													percentual_desconto: FormatUtils.formatarValorTela(item.percentual_desconto, 2),
													datahora_ocorrencia: DateFormat.formatarDataHoraSqlParaTela(item.datahora_ocorrencia),
													datahora_finalizacao: DateFormat.formatarDataHoraSqlParaTela(item.datahora_finalizacao),
													datahora_pagamento: DateFormat.formatarDataHoraSqlParaTela(item.datahora_pagamento),
													custo: FormatUtils.formatarValorTela(item.custo, 2),
													valor: FormatUtils.formatarValorTela(item.valor, 2),
													valor_autorizado: FormatUtils.formatarValorTela(item.valor_autorizado, 2)
												});
											}} />

										<ButtonTable
											type={'danger'}
											icon={'fas fa-trash-alt'}
											visible={this.state.linhaSelecionada == item.id}
											event={() => {
												this.props.setModoTela('exclusao', {
													...item,
													percentual_desconto: FormatUtils.formatarValorTela(item.percentual_desconto, 2),
													datahora_ocorrencia: DateFormat.formatarDataHoraSqlParaTela(item.datahora_ocorrencia),
													datahora_finalizacao: DateFormat.formatarDataHoraSqlParaTela(item.datahora_finalizacao),
													datahora_pagamento: DateFormat.formatarDataHoraSqlParaTela(item.datahora_pagamento),
													custo: FormatUtils.formatarValorTela(item.custo, 2),
													valor: FormatUtils.formatarValorTela(item.valor, 2),
													valor_autorizado: FormatUtils.formatarValorTela(item.valor_autorizado, 2)
												});
												this.props.initForm({
													...item,
													percentual_desconto: FormatUtils.formatarValorTela(item.percentual_desconto, 2),
													datahora_ocorrencia: DateFormat.formatarDataHoraSqlParaTela(item.datahora_ocorrencia),
													datahora_finalizacao: DateFormat.formatarDataHoraSqlParaTela(item.datahora_finalizacao),
													datahora_pagamento: DateFormat.formatarDataHoraSqlParaTela(item.datahora_pagamento),
													custo: FormatUtils.formatarValorTela(item.custo, 2),
													valor: FormatUtils.formatarValorTela(item.valor, 2),
													valor_autorizado: FormatUtils.formatarValorTela(item.valor_autorizado, 2)
												});
											}} />
									</Td>
								</Tr>
							))}
						</TBody>
					</Table>
				</ContentCardBody>
			</ContentCard>
		);
	}


}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	usuarioCarregado: state.auth.usuarioCarregado,
	modoTela: state.ocorrencia.modoTela,
	lista: state.ocorrencia.lista,
	listaCategoria: state.ocorrencia.listaCategoria,
	filtro: state.ocorrencia.filtro
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, setFiltro, initForm, salvar, excluir, getLista, 
	getListaTipo, getListaMotorista, getListaGravidade, getListaLocal, 
	getListaResponsabilidade, getListaTipoCusto, salvarAbona, 
	getListaStatus, getListaAcao, getListaUsuario, getListaCategoria
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Ocorrencia);
