
export default class ColorUtils {

	static getLuminosidade(cor) {
		if (cor) {
			var r, g, b, lum;

			cor = cor.replace('#', '');

			r = parseInt(cor.substr(0, 2), 16);
			g = parseInt(cor.substr(2, 2), 16);
			b = parseInt(cor.substr(4, 2), 16);

			return ((r * 299) + (g * 587) + (b * 114)) / 1000;
		} else {
			return 255;
		}
	}

	static getCorFonte(corFundo) {
		return this.getLuminosidade(corFundo) > 128 ? '#000' : '#fff';
	}

}
