import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ContentCardFooter from '../common/template/contentCardFooter';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import LabelAndInputDateTime from '../common/form/labelAndInputDateTime';
import Select from '../common/form/select';
import SelectAlteracao from '../common/form/selectAlteracao';
import LabelAndCheckbox from '../common/form/labelAndCheckbox';

import {
	setModoTelaExcecao, initFormExcecao
} from './operacaoActions';

class OperacaoExcecaoForm extends Component {

	state = {

	}

    componentWillMount() {

    }

    render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';

        return (
			<ContentCard>
				<Form event={this.props.handleSubmit}>
					<ContentCardBody>

						<Row>
							<Field
								name='cnpj_destinatario'
								component={LabelAndInput}
								label='CNPJ do Destinatário'
								cols='12 6 4 3'
								placeholder='Informe o CNPJ'
								readOnly={readOnly} />
						</Row>
						<Row>
							<Field
								name='excecao'
								component={LabelAndInput}
								label='Exceção'
								cols='12 12 12 12'
								placeholder='Informe a exceção'
								readOnly={readOnly} />
						</Row>
					</ContentCardBody>
					<ContentCardFooter>
						<Row alignCenter>
							<Grid cols='6 6 4 3'>
								{this.props.excluir ? (
									<Button
										text='Excluir'
										submit type={'danger'}
										icon={'fa fa-trash'} />
								) : (
								<Button
									text='Salvar'
									submit
									type={'success'}
									icon={'fa fa-check'} />
								)}
							</Grid>
							<Grid cols='6 6 4 3'>
								<Button
									text='Voltar'
									type={'warning'}
									icon={'fa fa-chevron-left'}
									event={() => this.props.setModoTelaExcecao('lista')} />
							</Grid>
						</Row>
					</ContentCardFooter>
				</Form>
			</ContentCard>
        )
    }

}

OperacaoExcecaoForm = reduxForm({form: 'operacaoExcecaoForm', destroyOnUnmount: false})(OperacaoExcecaoForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	formularioValues: getFormValues('operacaoExcecaoForm')(state),
	listaExcecao: state.operacao.listaExcecao,
	listaTipo: state.operacao.listaTipo
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTelaExcecao, initFormExcecao
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(OperacaoExcecaoForm);
