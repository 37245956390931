import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import FormEntrada from './valePedagioEntradaForm';
import FormTransferencia from './valePedagioTransferenciaForm';
import FormSaida from './valePedagioSaidaForm';
import DateFormat from '../common/dateFormat/DateFormat';
import FormatUtils from '../common/formatUtils/FormatUtils';
import Aguardando from '../common/template/aguardando';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import Select from '../common/form/select';

import { 
	setModoTela, setFiltro, getLista, getListaSaldo,
	initFormEntrada, salvarEntrada, excluirEntrada,
	initFormTransferencia, salvarTransferencia, excluirTransferencia,
	initFormSaida, salvarSaida, excluirSaida,
	getListaMotorista, getListaValePedagio, getListaMotivo, getListaPeriodo, getListaSaldoGeral
} from './valePedagioMotoristaActions';

class ValePedagioSaldo extends Component {

	state = {
		pesquisar: '',
		linhaSelecionada: null
	}

    componentWillMount() {
		this.props.getListaSaldoGeral();
		this.props.getListaValePedagio();
		this.props.getListaMotorista();
		this.props.getListaMotivo();
		this.props.getListaPeriodo();
    }

    render() {

		let saldoTotal = 0;
		
		return (
			<ContentCard>
				<ContentCardHeader>
					<Row>
						<LabelAndInputMask
							label='Data Base' placeholder='Informe a data'
							cols='12 6 3 2'
							mask='99/99/9999'
							value={this.props.filtro.saldo_data_base}
							onChange={data => {
							this.props.setFiltro({ 
								...this.props.filtro, 
								saldo_data_base: data.target.value 
							});
								this.props.getListaSaldoGeral();
							}} />

						<Select
							options={this.props.listaValePedagio}
							label='Tipo'
							cols='12 6 3 4'
							placeholder='Selecione o tipo'
							value={this.props.filtro.id_saldo_vale_pedagio}
							onChange={data => {
								this.props.setFiltro({
									...this.props.filtro,
									id_saldo_vale_pedagio: data
								});
								this.props.getListaSaldoGeral();
							}} />

						<Select
							options={this.props.listaMotorista}
							label='Motorista'
							cols='12 6 6 6'
							placeholder='Selecione o motorista'
							value={this.props.filtro.id_saldo_motorista}
							onChange={data => {
								this.props.setFiltro({
									...this.props.filtro,
									id_saldo_motorista: data
								});
								this.props.getListaSaldoGeral();
							}} />
					</Row>
				</ContentCardHeader>
				<ContentCardBody>

					<Table responsive>
						<THead>
							<Tr>
								<Th>Motorista</Th>
								<Th alignCenter>Tipo</Th>
								<Th alignRight>Saldo</Th>
								<Th></Th>
							</Tr>
						</THead>
						<TBody>
							{(this.props.listaSaldoGeral || []).map(item => {
								
								saldoTotal += parseFloat(item.saldo || 0);														
								
								return (
									<Tr key={item.id} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
										onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
										onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
										<Td>{item.nome_motorista}</Td>
										<Td alignCenter>{item.nome_vale_pedagio}</Td>
										<Td alignRight>{FormatUtils.formatarValorTela(item.saldo, 2)}</Td>	
									</Tr>
								);
							})}
							<Tr>
								<Th colspan={2}>Total</Th>
								<Th alignRight>{FormatUtils.formatarValorTela(saldoTotal, 2)}</Th>	
							</Tr>
						</TBody>
					</Table>
				</ContentCardBody>
			</ContentCard>
		);
	}


}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	modoTela: state.valePedagioMotorista.modoTela,
	filtro: state.valePedagioMotorista.filtro,
	aguardando: state.valePedagioMotorista.aguardando,
	listaSaldoGeral: state.valePedagioMotorista.listaSaldoGeral,
	listaMotorista: state.valePedagioMotorista.listaMotorista,
	listaPeriodo: state.valePedagioMotorista.listaPeriodo,
	listaValePedagio: state.valePedagioMotorista.listaValePedagio
});
const mapDispatchToProps = dispatch => bindActionCreators({ 
	setModoTela, setFiltro, getLista, getListaSaldo,
	initFormEntrada, salvarEntrada, excluirEntrada, 
	initFormTransferencia, salvarTransferencia, excluirTransferencia,
	initFormSaida, salvarSaida, excluirSaida,
	getListaMotorista, getListaValePedagio, getListaMotivo, getListaPeriodo, getListaSaldoGeral
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ValePedagioSaldo);
