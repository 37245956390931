import React from 'react'

export default props => (
	<button type={'button'} className={`btn btn-flat ${props.small ? 'btn-xs' : ''} btn-${props.type}`}
		title={props.title}
		onClick={props.event} disabled={props.disabled}
		style={{ visibility: props.visible ? 'visible' : 'collapse', width: props.width, height: props.height, marginLeft: 1, marginRight: 1 }}>
		<i className={props.icon} style={{ color: props.type == 'light' ? props.color || '#000' : null }}></i>
	</button>
)

/*
primary
secondary
success
danger
warning
info
light
dark
link
*/
