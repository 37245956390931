import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ContentCardFooter from '../common/template/contentCardFooter';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import LabelAndCheckbox from '../common/form/labelAndCheckbox';
import LabelAndInputDateTime from '../common/form/labelAndInputDateTime';
import Select from '../common/form/select';
import SelectAlteracao from '../common/form/selectAlteracao';
import FormatUtils from '../common/formatUtils/FormatUtils';
import pdfAdd from '../assets/images/icone_add_pdf.png';
import pdfAdicionado from '../assets/images/icone_pdf_adicionado.png';
import { setError } from '../common/toast/toast';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';

import {
	setModoTela, initForm, gerarBoletim, getListaHistorico, salvarComentarioVisto
} from './ocorrenciaActions';
import SelectDefault from '../common/form/selectDefault';
import DateFormat from '../common/dateFormat/DateFormat';
import ColorUtils from '../common/colorUtils/ColorUtils';

class OcorrenciaForm extends Component {

	state = {

	}

	componentDidMount() {

		const fileSelectorArquivo = document.createElement('input');
		fileSelectorArquivo.setAttribute('type', 'file');
		fileSelectorArquivo.addEventListener("change", (data) => {

			let reader = new FileReader();
			let file = data && data.path && data.path[0] && data.path[0].files && data.path[0].files[0] ? data.path[0].files[0] : null;
			if (!file) {
				file = data && data.target && data.target.files && data.target.files[0] ? data.target.files[0] : null;
			}

			if (file) {

				if (!file.type.includes('application/pdf')
					&& !file.type.includes('image/png') && !file.type.includes('image/jpg') && !file.type.includes('image/jpeg')) {
					setError('Selecione apenas (.PDF, .PNG, .JPG e .JPEG)');
					return;
				}

				reader.onloadend = () => {

					let lista = (this.props.formularioValues.arquivo || []).map(item => item);
					lista.push({
						arquivo_base64: reader.result
					});

					this.props.initForm({
						...this.props.formularioValues,
						arquivo: lista
					});

				}

				reader.readAsDataURL(file);

			}
		});

		this.fileSelectorArquivo = fileSelectorArquivo;
	}

    componentWillMount() {

		this.props.getListaHistorico(this.props.formularioValues.id);

    }

    render() {

		let isDocumentoVPR = this.props.formularioValues.id_documento_viagem 
			|| this.props.formularioValues.id_vale_pedagio_entrada
			|| this.props.formularioValues.id_vale_pedagio_saida
			|| this.props.formularioValues.id_vale_pedagio_transferencia
			|| this.props.formularioValues.avulsa;
			
		let readOnly = this.props.excluir || (
			this.props.formularioValues 
			&& this.props.formularioValues.id 
			&& isDocumentoVPR
			&& this.props.formularioValues.id_usuario_inclusao != this.props.sessao.id //Usuário que incluiu pode ter acesso total

			&& this.props.sessao.id != 1 // INFINITUM

			&& this.props.sessao.id != 13 // Mauricio Junior Sfalcin
			&& this.props.sessao.id != 30 // Clarissa Brauner

			&& this.props.sessao.id != 48 // Bruna Schneider Padilha
			&& this.props.sessao.id != 31 // Alana Costa
			&& this.props.sessao.id != 58 // Vanessa Brizzi da Silva
			&& this.props.sessao.id != 67 // Agatha Nila Correa Maciel
			&& this.props.sessao.id != 120 // Bruna Grazieli Barbosa 

			&& this.props.sessao.id != 37 //Rodrigo Soares
		) ? 'readOnly' : '';

		let dentroMes = true;
		try {
			if (!this.props.formularioValues.alterandoData) {
				dentroMes = new Date(DateFormat.formatarDataHoraTelaParaSql(this.props.formularioValues.datahora_ocorrencia)).getFullYear() == new Date().getFullYear()
					&& (
						new Date(DateFormat.formatarDataHoraTelaParaSql(this.props.formularioValues.datahora_ocorrencia)).getMonth() == new Date().getMonth()
						|| new Date(DateFormat.formatarDataHoraTelaParaSql(this.props.formularioValues.datahora_ocorrencia)).getMonth() == new Date().getMonth() - 1
					)
			}
		} catch (ex) {
			
		}

		/*
			ADMIN
			1-INFINITUM
			
			SUPERVISOR
			13-Mauricio Junior Sfalcin
			30-Clarissa Brauner
			23-Alesandra Fatima da Silva Scheuer
			
			OPERADOR
			48-Bruna Schneider Padilha
			31-Alana Costa
			58-Vanessa Brizzi da Silva

			USUÁRIO
			23-Alesandra Fatima da Silva Scheuer
			67 - Agatha Nila Correa Maciel
			120 - Bruna Grazieli Barbosa
			68- Arielli Jaqueline Gross Boeno
			76-Viviane Rodrigues Moreira
			70-Elisangela Lira
			71-Ricardo Lazzaretti Neto
			72-Isadora Konarzewsky Patzer
			73-Flaviane Rodrigues Oliveira
			74-Thiago Ribeiro Lopes
			75-Daniela Ribeiro Autori
			20-Andrea Andrade Alves
			9 -Fabiana Ribeiro de Almeida-
			60-EDMILSON DA SILVA SERPA
			10-Kelvin Lima de Lemos
			77-Lucas dos Santos Serpa
			78-Carlos Eduardo Pereira
			79-Jefferson da Cruz Cardoso
			6 -Diego Metztorf Mallmann 
		*/

		let editaMes = true;
		if (isDocumentoVPR
			&& this.props.formularioValues.id
			&& !dentroMes
		) {
			editaMes = false;
		}

		let acessoResponsavel = true;
		if (!editaMes) {
			acessoResponsavel = false;
			if (readOnly && !this.props.excluir && this.props.formularioValues && this.props.formularioValues.id && isDocumentoVPR) {
				//Usuário responsável e aprovador pode ter acessoa parcial
				if (this.props.formularioValues.id_usuario_responsavel == this.props.sessao.id) {
					acessoResponsavel = true;
				}
			}
		}

		let acessoAprovador = true;
		if (!editaMes) {
			acessoAprovador = false;
			if (readOnly && !this.props.excluir && this.props.formularioValues && this.props.formularioValues.id && isDocumentoVPR) {
				//Usuário responsável e aprovador pode ter acessoa parcial
				if (this.props.formularioValues.id_usuario_aprovador == this.props.sessao.id) {
					acessoAprovador = true;
				}
			}
		}

		let podeExcluir = true;
		if (isDocumentoVPR
			&& this.props.formularioValues.id
			&& this.props.sessao.id != 1 // INFINITUM
			&& this.props.sessao.id != 13 // Mauricio Junior Sfalcin
			&& this.props.sessao.id != 30 // Clarissa Brauner
		) {
			podeExcluir = false;
		}

        return (
			<>
				<ContentCard>
					<ContentCardHeader>
						<Row>
							{/*APENAS CAMPOS PARA COMPROVANTES*/}
							{/*isDocumentoVPR ? (
								<Grid cols='6 6 4 2'>
									<Button
										text='Imprimir BO'
										type={'primary'}
										icon={'fa fa-plus'}
										event={() => {
											this.props.gerarBoletim(this.props.formularioValues);
										}} />
								</Grid>
							) : null*/}

							{this.props.resumoDocumento && 
							<Table responsive tableBordered>
								<THead>
									<Tr>
										<Th alignCenter width={10}>Sol.</Th>
										<Th alignCenter width={10}>Rom.</Th>
										<Th alignCenter width={100}>Emissão</Th>
										<Th alignCenter width={100}>Descarga</Th>
										<Th alignCenter width={10}>Tipo Doc</Th>
										<Th alignCenter width={10}>Documento</Th>
										<Th alignCenter width={10}>MIC</Th>
										<Th alignCenter width={10}>Shipping</Th>
										<Th alignCenter width={10}>Veículo</Th>
										<Th alignCenter width={200}>Motorista Entrega</Th>
										<Th alignCenter width={10}>Tipo</Th>
									</Tr>
								</THead>
								<TBody>
									<Tr>
										<Td alignCenter minWidth={10} small>{this.props.formularioValues.solicitacao}</Td>
										<Td alignCenter minWidth={10}  small>{this.props.formularioValues.romaneio}</Td>
										<Td alignCenter minWidth={100} small>{this.props.formularioValues.datahora_viagem}</Td>
										<Td alignCenter minWidth={100} small>{this.props.formularioValues.datahora_descarga}</Td>
										<Td alignCenter minWidth={10} small>{this.props.formularioValues.tipo_documento}</Td>
										<Td alignCenter minWidth={10} small>{this.props.formularioValues.numero_documento}</Td>
										<Td alignCenter minWidth={10} small>{this.props.formularioValues.mic_documento}</Td>
										<Td alignCenter minWidth={10} small>{this.props.formularioValues.shipping}</Td>
										<Td alignCenter minWidth={10} small>{this.props.formularioValues.cavalo}</Td>
										<Td alignCenter minWidth={200} noWrap small>{this.props.formularioValues.nome_motorista_documento}</Td>
										<Td alignCenter minWidth={10} small>{this.props.formularioValues.tipo_veiculo}</Td>
									</Tr>
								</TBody>
							</Table>}
							{this.props.resumoDocumento && 
							<Table responsive tableBordered>
								<THead>
									<Tr>
										<Th alignCenter width={200}>Cliente</Th>
										<Th alignCenter width={200}>Recebedor</Th>
										<Th alignCenter width={200}>Local Coleta</Th>
										<Th alignCenter width={200}>Local Entrega</Th>
										<Th alignCenter width={200}>Operação</Th>
									</Tr>
								</THead>
								<TBody>
									<Tr>
										<Td alignCenter minWidth={200} noWrap small>{this.props.formularioValues.nome_cliente}</Td>
										<Td alignCenter minWidth={200} noWrap small>{this.props.formularioValues.nome_destinatario}</Td>
										<Td alignCenter minWidth={200} noWrap small>{this.props.formularioValues.cidade_coleta} - {this.props.formularioValues.estado_coleta} - {this.props.formularioValues.pais_coleta}</Td>
										<Td alignCenter minWidth={200} noWrap small>{this.props.formularioValues.cidade_entrega} - {this.props.formularioValues.estado_entrega} - {this.props.formularioValues.pais_entrega}</Td>
										<Td alignCenter minWidth={200} noWrap small backgroundColor={this.props.formularioValues.cor_perfil} color={ColorUtils.getCorFonte(this.props.formularioValues.cor_perfil)}>{`${this.props.formularioValues.nome_operacao || ''}${this.props.formularioValues.nome_perfil ? ' - ' : ''}${this.props.formularioValues.nome_perfil || ''}`}</Td>
									</Tr>
								</TBody>
							</Table>}
						</Row>
					</ContentCardHeader>
					<Form event={this.props.handleSubmit}>
						<ContentCardBody>
							<Row>
								<Field
									name='datahora_ocorrencia'
									component={LabelAndInputMask}
									label='Data e Hora' placeholder='Informe a data e hora'
									cols='12 12 6 4'
									mask='99/99/9999 99:99'
									readOnly={!editaMes ? 'readOnly' : readOnly}
									value={this.props.formularioValues.datahora_ocorrencia}
									onChange={value => {
										this.props.initForm({
											...this.props.formularioValues,
											datahora_ocorrencia: value,
											alterandoData: true
										});
									}} />

								{this.props.formularioValues.multiplos ? (
									<Select
										options={this.props.listaMotorista}
										label='Motoristas'
										cols='12 12 12 12'
										placeholder='Selecione os motoristas'
										readOnly={''}
										multiple
										value={(this.props.formularioValues.motoristas || [])}
										onChange={value => {
											this.props.initForm({
												...this.props.formularioValues,
												motoristas: (value || []).map(item => parseInt(item.value))
											});
										}} />
								) : (
									<Field
										name='id_motorista'
										component={Select}
										options={this.props.listaMotorista}
										label='Motorista'
										cols='12 12 12 12'
										placeholder='Selecione o motorista'
										readOnly={!editaMes ? 'readOnly' : readOnly} />
								)}

								<Select
									name='id_tipo'
									options={this.props.listaTipo.filter(item => {
										
										if (item.data_inicio) {
											const dataVigencia = DateFormat.formatarDataTelaParaSql(DateFormat.formatarDataSqlParaTela(item.data_inicio));
											const dataOcorrencia = DateFormat.formatarDataTelaParaSql((this.props.formularioValues.datahora_ocorrencia || '').split(' ')[0]);
											// console.log(`${dataOcorrencia} < ${dataVigencia} = ${dataOcorrencia < dataVigencia}`);
											if (dataOcorrencia < dataVigencia) {
												return false;
											}
										} 
										if (item.data_fim) {
											const dataVigencia = DateFormat.formatarDataTelaParaSql(DateFormat.formatarDataSqlParaTela(item.data_fim));
											const dataOcorrencia = DateFormat.formatarDataTelaParaSql((this.props.formularioValues.datahora_ocorrencia || '').split(' ')[0]);
											// console.log(`${dataOcorrencia} > ${dataVigencia} = ${dataOcorrencia > dataVigencia}`);
											if (dataOcorrencia > dataVigencia) {
												return false;
											}
										}		
										
										if (isDocumentoVPR && !item.documentacao) {
											return false;
										}
										
										if (!this.props.usuarioCarregado) {
											return false;
										} else if (this.props.usuarioCarregado.acesso_ocorrencia) {
											return true;
										} else if (item.id_perfil == this.props.usuarioCarregado.id_perfil 
												|| (item.perfis || []).filter(perfil => perfil == this.props.usuarioCarregado.id_perfil).length) {
											return true;
										}

										return false;
									}).map(item => ({ ...item, valor: `${item.valor} (${item.id})`}))}
									label='Tipo'
									cols='12 12 6 6'
									placeholder='Selecione o tipo'
									readOnly={!editaMes ? 'readOnly' : readOnly}
									value={this.props.formularioValues.id_tipo}
									onChange={index => {
										let quantidade = parseInt(this.props.formularioValues.quantidade || 1);
										let tipo = this.props.listaTipo.filter(tipo => tipo.id == index)[0];
										let percentual = this.props.listaTipo.filter(tipo => tipo.id == index)[0] 
											? parseFloat(this.props.listaTipo.filter(tipo => tipo.id == index)[0].percentual_desconto || 0) 
											: 0;

										this.props.initForm({
											...this.props.formularioValues,
											id_tipo: index,
											quantidade: null,
											percentual_desconto: tipo && tipo.multiplica_percentual ? FormatUtils.formatarValorTela(0, 2) : FormatUtils.formatarValorTela(percentual, 2)
										});
									}} />
								
								{this.props.listaTipo.filter(tipo => tipo.id == this.props.formularioValues.id_tipo)[0] 
								&& this.props.listaTipo.filter(tipo => tipo.id == this.props.formularioValues.id_tipo)[0].multiplica_percentual &&
								<SelectDefault
									options={this.props.formularioValues.id_tipo == 31 || this.props.formularioValues.id_tipo == 41 //Acidente
										? [{ id: 30, valor: '30%' }, { id: 100, valor: '100%' }] 
										: [{ id: 5, valor: '5%' }, { id: 25, valor: '25%' }, { id: 50, valor: '50%' }, { id: 100, valor: '100%' }]}
									label='Quantidade'
									cols='12 12 2 2'
									placeholder='Selecione a quantidade'
									readOnly={readOnly}
									value={this.props.formularioValues.quantidade}
									onChange={data => {
										let quantidade = parseInt(data.target.value || 0);
										let tipo = this.props.listaTipo.filter(tipo => tipo.id == this.props.formularioValues.id_tipo)[0];
										let percentual = tipo ? parseFloat(tipo.percentual_desconto || 0) : 0;

										this.props.initForm({
											...this.props.formularioValues,
											quantidade: quantidade,
											percentual_desconto: tipo && tipo.multiplica_percentual ? FormatUtils.formatarValorTela(quantidade, 2) : FormatUtils.formatarValorTela(percentual, 2)
										});
									}} />}

								<Field
									name='percentual_desconto'
									component={LabelAndInputNumber}
									label='Percentual de Desconto' placeholder='Informe o percentual'
									cols='12 12 4 4'
									casas={2}
									readOnly={'readOnly'} />

								<Field
									name='observacao'
									component={LabelAndInput}
									label='Observação' placeholder='Informe a observação'
									cols='12 12 12 12'
									readOnly={readOnly} />

							</Row>
							
							{/*APENAS CAMPOS PARA COMPROVANTES*/}
							{isDocumentoVPR ? (
								<Row>
									<Grid cols='12 12 12 12'>
										<label>Dados Comprovantes</label>
									</Grid>

									{this.props.formularioValues.avulsa && 
									<Field
										name='solicitacao'
										component={LabelAndInput}
										label='Solicitação' placeholder='Informe a solicitacao'
										cols='12 6 3 3'
										readOnly={readOnly} />}

									<Field
										name='id_status'
										component={Select}
										options={this.props.listaStatus}
										label='Status'
										cols='12 12 3 3'
										placeholder='Selecione o status'
										readOnly={readOnly} />

									<Field
										name='datahora_ocorrencia'
										component={LabelAndInputMask}
										label='Data da Constatação' placeholder='Informe a data e hora'
										cols='12 6 3 3'
										mask='99/99/9999 99:99'
										readOnly={'readOnly'} />
									
									<Field
										name='datahora_finalizacao'
										component={LabelAndInputMask}
										label='Data da Finalização' placeholder='Informe a data e hora'
										cols='12 6 3 3'
										mask='99/99/9999 99:99'
										readOnly={'readOnly'} />
										
									<Field
										name='id_acao'
										component={Select}
										options={this.props.listaAcao}
										label='Ação Requerida'
										cols='12 6 3 3'
										placeholder='Selecione a ação'
										readOnly={readOnly} />

									<Field
										name='id_area'
										component={Select}
										options={this.props.listaResponsabilidade}
										label='Área Responsável'
										cols='12 6 6 6'
										placeholder='Selecione o responsável'
										readOnly={readOnly} />
										
									<Field
										name='id_usuario_responsavel'
										component={Select}
										options={this.props.listaUsuario}
										label='Usuário Responsável'
										cols='12 6 6 6'
										placeholder='Selecione o responsável'
										readOnly={readOnly} />
										
									<Field
										name='id_usuario_aprovador'
										component={Select}
										options={this.props.listaUsuario}
										label='Usuário Aprovador'
										cols='12 6 6 6'
										placeholder='Selecione o aprovador'
										readOnly={readOnly } />
									
									<Field
										name='id_tipo_custo'
										component={Select}
										options={this.props.listaTipoCusto}
										label='Tipo de Custo'
										cols='12 6 6 6'
										placeholder='Selecione o tipo de custo'
										readOnly={readOnly && !acessoAprovador} />
										
									<Select
										name='parecer'
										options={[{ id: 0, valor: 'Aguardando' }, { id: 1, valor: 'Aprovado' }, { id: 2, valor: 'Rejeitado' }]}
										label='Parecer'
										cols='12 4 4 4'
										placeholder='Selecione o parecer'
										readOnly={readOnly && !acessoAprovador}
										value={JSON.parse(this.props.formularioValues.parecer || null) == true ? 1 : (JSON.parse(this.props.formularioValues.parecer || null) == false ? 2 : 0)}
										onChange={(value) => {
											this.props.initForm({
												...this.props.formularioValues,
												parecer: value == 1 ? true : (value == 2 ? false : null)
											})
										}} />
										
									<Field
										name='valor'
										component={LabelAndInputNumber}
										label='Valor (R$)' placeholder='Informe o valor'
										cols='12 4 4 4'
										casas={2}
										readOnly={readOnly} />
										
									<Field
										name='valor_autorizado'
										component={LabelAndInputNumber}
										label='Valor Autorizado (R$)' placeholder='Informe o valor'
										cols='12 4 4 4'
										casas={2}
										readOnly={readOnly && !acessoAprovador} />	

									<Field
										name='email_copia'
										component={LabelAndInput}
										label='E-mails para notificar (separar por vírgula)' placeholder='Informe os e-mail'
										cols='12 12 12 12'
										readOnly={readOnly} />						
										
									{this.props.formularioValues.id ? (
									<Field
										name='comentario'
										component={LabelAndInput}
										label='Comentário' placeholder='Informe o comentário'
										cols='12 12 12 12'
										readOnly={readOnly && !acessoResponsavel && !acessoAprovador} />) : null}

									<Grid cols='12 12 12 12'>
										<Table responsive>
											<TBody>
												{this.props.listaHistorico.filter(item => item.coluna == 'comentarios' && item.atual).map(item => (
													<Tr key={item.id}>
														<Td minWidth={100}>{DateFormat.formatarDataHoraSqlParaTela(item.datahora_historico)}</Td>
														<Td minWidth={100} alignCenter>{item.nome_usuario || ''}</Td>
														<Td minWidth={700} alignCenter>
															<input className='form-control'
																readOnly={'readOnly'}
																value={item.atual || ''} />
														</Td>
													</Tr>
												))}
											</TBody>
										</Table>
									</Grid>

									{this.props.formularioValues.datahora_interacao 
										&& this.props.formularioValues.id_usuario_responsavel != this.props.sessao.id ? (
										this.props.formularioValues.datahora_interacao > this.props.formularioValues.datahora_comentario_visto 
										|| !this.props.formularioValues.datahora_comentario_visto ? (
											<Grid cols='12 12 12 12' style={{ paddingBottom: 20 }}>
												<Button
													text='Li todos os comentários ainda não visualizados'
													type={'primary'}
													icon={'fa fa-plus'}
													event={() => {
														this.props.salvarComentarioVisto({ ...this.props.formularioValues });
														this.props.initForm({ ...this.props.formularioValues, datahora_comentario_visto:  DateFormat.getDataHoraAtual() });
													}} />
											</Grid>
										) : null
									) : null}

								</Row>
							) : null}

							<Row>
								<Grid cols='12 12 12 12'>
									<label>Arquivo</label>
								</Grid>
								{(this.props.formularioValues.arquivo || []).map(arquivo => (
									<div style={{ display: 'flex', flexDirection: 'column' }}>
										<span class="btn btn-outline-primary fileinput-button dz-clickable">
											<img src={arquivo.arquivo_base64 ? pdfAdicionado : (arquivo.arquivo ? pdfAdicionado : pdfAdd)} style={{ height: 30 }} />
										</span>
										<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingRight: 2, marginTop: 4 }}>
											<button
												type='button'
												class='btn btn-flat btn-outline-primary'
												style={{ width: '48%' }}
												disabled={arquivo.arquivo_base64 ?true : false}
												onClick={() => {
													window.open(`https://api-letsarapremiacao.infinitum-ti.com/${arquivo.arquivo}`, '_blank');
												}}>
												<i class='fas fa-download'></i>
											</button>

											<button
												type='button'
												class='btn btn-flat btn-outline-danger'
												style={{ width: '48%' }}
												disabled={readOnly && !acessoResponsavel && !acessoAprovador}
												onClick={() => {
													this.props.initForm({
														...this.props.formularioValues,
														arquivo: this.props.formularioValues.arquivo.filter(item => item != arquivo)
													});
												}}>
												<i class='fas fa-trash-alt'></i>
											</button>
										</div>
									</div>
								))}
								{!readOnly || acessoResponsavel  || acessoAprovador ? (
									<span class="btn btn-outline-primary fileinput-button dz-clickable" onClick={() => this.fileSelectorArquivo.click()}>
										<img src={pdfAdd} style={{ height: 30 }} />
									</span>
								) : null}
							</Row>

						</ContentCardBody>
						<ContentCardFooter>
							<Row alignCenter>
								<Grid cols='6 6 4 3'>
									{this.props.excluir ? (
										<Button
											text='Excluir'
											submit type={'danger'}
											icon={'fa fa-trash'}
											disabled={!podeExcluir}
											title={podeExcluir ? null : 'Solicite ao Supervisor'} />
									) : (
									<Button
										text='Salvar'
										submit
										type={'success'}
										icon={'fa fa-check'} />
									)}
								</Grid>
								<Grid cols='6 6 4 3'>
									<Button
										text='Voltar'
										type={'warning'}
										icon={'fa fa-chevron-left'}
										event={() => this.props.voltarComprovante ? this.props.voltarComprovante() : this.props.setModoTela('lista')} />
								</Grid>
							</Row>
						</ContentCardFooter>
					</Form>
				</ContentCard>

				{/*APENAS CAMPOS PARA COMPROVANTES*/}
				{isDocumentoVPR ? (
					this.renderHistorico()
				) : null}
			</>
        )
    }

	renderHistorico(readOnly) {
		
		return (
			<>
				<ContentCard>
					<ContentCardHeader>
						<label>Histórico</label>
					</ContentCardHeader>
					<ContentCardBody>
						<Table responsive>
							<THead>
								<Tr>
									<Th>Data/Hora</Th>
									<Th alignCenter>Operação</Th>
									<Th alignCenter>Registro</Th>
									<Th alignCenter>Anterior</Th>
									<Th alignCenter>Atual</Th>
									<Th alignCenter>Descrição</Th>
									<Th alignCenter>Usuário</Th>
								</Tr>
							</THead>
							<TBody>
								{this.props.listaHistorico.map(item => (
									<Tr key={item.id} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`} >
										<Td small >{DateFormat.formatarDataHoraSqlParaTela(item.datahora_historico)}</Td>
										<Td small alignCenter>{item.tipo}</Td>
										<Td small alignCenter>{item.nome}</Td>
										<Td small alignCenter>{(item.coluna == 'valor' || item.coluna == 'valor_autorizado' || item.coluna == 'percentual_desconto')
											? FormatUtils.formatarValorTela(item.anterior, 2) : (item.anterior || '')}</Td>
										<Td small alignCenter>{(item.coluna == 'valor' || item.coluna == 'valor_autorizado' || item.coluna == 'percentual_desconto')
											? FormatUtils.formatarValorTela(item.atual, 2) : (item.atual || '')}</Td>
										<Td small alignCenter>{item.descricao || ''}</Td>
										<Td small alignCenter>{item.nome_usuario || ''}</Td>
									</Tr>
								))}
							</TBody>
						</Table>
					</ContentCardBody>
				</ContentCard>
			</>
		);
	}


}

OcorrenciaForm = reduxForm({form: 'ocorrenciaForm', destroyOnUnmount: false})(OcorrenciaForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	usuarioCarregado: state.auth.usuarioCarregado,
	formularioValues: getFormValues('ocorrenciaForm')(state),
	registro: state.ocorrencia.registro,
	listaTipo: state.ocorrencia.listaTipo,
	listaMotorista: state.ocorrencia.listaMotorista,
	listaGravidade: state.ocorrencia.listaGravidade,
	listaLocal: state.ocorrencia.listaLocal,
	listaResponsabilidade: state.ocorrencia.listaResponsabilidade,
	listaTipoCusto: state.ocorrencia.listaTipoCusto,
	listaStatus: state.ocorrencia.listaStatus,
	listaAcao: state.ocorrencia.listaAcao,
	listaUsuario: state.ocorrencia.listaUsuario,
	listaHistorico: state.ocorrencia.listaHistorico
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, initForm, gerarBoletim, getListaHistorico, salvarComentarioVisto
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(OcorrenciaForm);
