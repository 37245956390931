import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setError, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import DateFormat from '../common/dateFormat/DateFormat';
import FormatUtils from '../common/formatUtils/FormatUtils';

export function setModoTela(modo, registro = {}) {
    return {
        type: 'BRASKEM_MODO_TELA',
        payload: {
			modo: modo,
			registro: registro
		}
    };
}
export function initForm(registro = {}) {
    return [
        initialize('braskemForm', registro)
    ];
}

export function consultar(cnpj, documento) {
	return (dispatch, getState) => {
		
		axios.get(`${consts.API_URL}/braskem/consulta?cnpj=${cnpj}&documento=${documento}&id_usuario=${getState().auth.usuario.id}`)
		.then(resp => {
			
			if (!resp.data) {
				setError('Nenhum registro encontrado');	
			}

			dispatch({
		        type: 'BRASKEM_LISTADO',
		        payload: resp
		    });
		}).catch(e => {
			setErroAPI(e);
		});
	}
}

export function consultarLog(dataInicial, dataFinal) {
	return (dispatch, getState) => {
		axios.get(`${consts.API_URL}/braskem/log?
			data_inicial=${DateFormat.formatarDataTelaParaSql(dataInicial) == 'Invalid DateTime' ? null : DateFormat.formatarDataTelaParaSql(dataInicial)}
			&data_final=${DateFormat.formatarDataTelaParaSql(dataFinal) == 'Invalid DateTime' ? null : DateFormat.formatarDataTelaParaSql(dataFinal)}`)
		.then(resp => {
			dispatch({
		        type: 'BRASKEM_LOG_LISTADO',
		        payload: resp
		    });
		}).catch(e => {
			setErroAPI(e);
		});
	}
}