import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ContentCardFooter from '../common/template/contentCardFooter';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndCheckbox from '../common/form/labelAndCheckbox';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import LabelAndInputDateTime from '../common/form/labelAndInputDateTime';
import Select from '../common/form/select';
import SelectAlteracao from '../common/form/selectAlteracao';

import {
	setModoTela, initForm
} from './usuarioPerfilActions';

class UsuarioPerfilForm extends Component {

	state = {

	}

    componentWillMount() {

    }

    render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';

        return (
			<ContentCard>
				<Form event={this.props.handleSubmit}>
					<ContentCardBody>
						<Row>
							<Field
								name='nome'
								component={LabelAndInput}
								label='Nome' placeholder='Informe o nome'
								cols='12 12 12 12'
								readOnly={readOnly} />

							<Field
								name='acesso_usuario'
								component={LabelAndCheckbox}
								label='Cadastro de Usuários' placeholder='Selecione'
								cols='12 6 4 3'
								readOnly={readOnly} />

							<Field
								name='acesso_cadastro'
								component={LabelAndCheckbox}
								label='Cadastro' placeholder='Selecione'
								cols='12 6 4 3'
								readOnly={readOnly} />

							<Field
								name='acesso_ocorrencia'
								component={LabelAndCheckbox}
								label='Todas Ocorrências' placeholder='Selecione'
								cols='12 6 4 3'
								readOnly={readOnly} />

							<Field
								name='acesso_premiacao'
								component={LabelAndCheckbox}
								label='Premiação' placeholder='Selecione'
								cols='12 6 4 3'
								readOnly={readOnly} />

							<Field
								name='acesso_cnh'
								component={LabelAndCheckbox}
								label='CNH' placeholder='Selecione'
								cols='12 6 4 3'
								readOnly={readOnly} />

							<Field
								name='acesso_documento'
								component={LabelAndCheckbox}
								label='Documento' placeholder='Selecione'
								cols='12 6 4 3'
								readOnly={readOnly} />

							<Field
								name='acesso_operacao'
								component={LabelAndCheckbox}
								label='Operação' placeholder='Selecione'
								cols='12 6 4 3'
								readOnly={readOnly} />

							<Field
								name='acesso_acessorios'
								component={LabelAndCheckbox}
								label='Acessórios' placeholder='Selecione'
								cols='12 6 4 3'
								readOnly={readOnly} />
							
							<Field
								name='acesso_acessorios_operacao'
								component={LabelAndCheckbox}
								label='Acessórios - Operação' placeholder='Selecione'
								cols='12 6 4 3'
								readOnly={readOnly} />

							<Field
								name='acesso_vale_pedagio'
								component={LabelAndCheckbox}
								label='Vale Pedágio' placeholder='Selecione'
								cols='12 6 4 3'
								readOnly={readOnly} />

						</Row>

						<Row>
							<label>Comprovantes - Viagem - Aberta</label>
						</Row>
						<Row>
							<Field
								name='acesso_comprovante_aberta_visualizar'
								component={LabelAndCheckbox}
								label='Visualizar' placeholder='Selecione'
								cols='4 4 3 3'
								readOnly={readOnly} />
							
							<Field
								name='acesso_comprovante_aberta_alterar'
								component={LabelAndCheckbox}
								label='Alterar' placeholder='Selecione'
								cols='4 4 3 3'
								readOnly={readOnly} />
						</Row>

						<Row>
							<label>Comprovantes - Viagem - Encerrada</label>
						</Row>
						<Row>						
							<Field
								name='acesso_comprovante_encerrada_visualizar'
								component={LabelAndCheckbox}
								label='Visualizar' placeholder='Selecione'
								cols='4 4 3 3'
								readOnly={readOnly} />
							
							<Field
								name='acesso_comprovante_encerrada_alterar'
								component={LabelAndCheckbox}
								label='Alterar' placeholder='Selecione'
								cols='4 4 3 3'
								readOnly={readOnly} />
							
							<Field
								name='acesso_comprovante_encerrada_desfazer'
								component={LabelAndCheckbox}
								label='Desfazer' placeholder='Selecione'
								cols='4 4 3 3'
								readOnly={readOnly} />
						</Row>
						
						<Row>
							<label>Comprovantes - Viagem - Financeiro</label>
						</Row>
						<Row>					
							<Field
								name='acesso_comprovante_financeiro_visualizar'
								component={LabelAndCheckbox}
								label='Visualizar' placeholder='Selecione'
								cols='4 4 3 3'
								readOnly={readOnly} />
							
							<Field
								name='acesso_comprovante_financeiro_alterar'
								component={LabelAndCheckbox}
								label='Alterar' placeholder='Selecione'
								cols='4 4 3 3'
								readOnly={readOnly} />
						</Row>
						
						<Row>
							<label>Comprovantes - Viagem - Finalizada</label>
						</Row>
						<Row>
							<Field
								name='acesso_comprovante_finalizada_visualizar'
								component={LabelAndCheckbox}
								label='Visualizar' placeholder='Selecione'
								cols='4 4 3 3'
								readOnly={readOnly} />

							<Field
								name='acesso_comprovante_finalizada_alterar'
								component={LabelAndCheckbox}
								label='Alterar' placeholder='Selecione'
								cols='4 4 3 3'
								readOnly={readOnly} />

							<Field
								name='acesso_comprovante_finalizada_desfazer'
								component={LabelAndCheckbox}
								label='Desfazer' placeholder='Selecione'
								cols='4 4 3 3'
								readOnly={readOnly} />
						</Row>

					</ContentCardBody>
					<ContentCardFooter>
						<Row alignCenter>
							<Grid cols='6 6 4 3'>
								{this.props.excluir ? (
									<Button
										text='Excluir'
										submit type={'danger'}
										icon={'fa fa-trash'} />
								) : (
								<Button
									text='Salvar'
									submit
									type={'success'}
									icon={'fa fa-check'} />
								)}
							</Grid>
							<Grid cols='6 6 4 3'>
								<Button
									text='Voltar'
									type={'warning'}
									icon={'fa fa-chevron-left'}
									event={() => this.props.setModoTela('lista')} />
							</Grid>
						</Row>
					</ContentCardFooter>
				</Form>
			</ContentCard>
        )
    }


}

UsuarioPerfilForm = reduxForm({form: 'usuarioPerfilForm', destroyOnUnmount: false})(UsuarioPerfilForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	formularioValues: getFormValues('usuarioPerfilForm')(state),
	registro: state.usuarioPerfil.registro
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, initForm
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(UsuarioPerfilForm);
