import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import DateFormat from '../common/dateFormat/DateFormat';
import FormatUtils from '../common/formatUtils/FormatUtils';

export function setModoTela(modo, registro = {}) {
    return {
        type: 'PREMIACAO_MODO_TELA',
        payload: {
			modo: modo,
			registro: registro
		}
    };
}

export function setAguardando(aguardando) {
    return {
        type: 'PREMIACAO_SET_AGUARDANDO',
        payload: aguardando
    };
}

export function initForm(registro = {}) {
    return [
        initialize('premiacaoForm', registro)
    ];
}


export function getListaGrupo() {
    const request = axios.get(`${consts.API_URL}/grupo/listarSelect`);
    return {
        type: 'PREMIACAO_GRUPO_SELECT_LISTADO',
        payload: request
    };
}


export function getLista() {
	return (dispatch, getState) => {
    	axios.get(`${consts.API_URL}/premiacao`)
		.then(resp => {
			dispatch({
		        type: 'PREMIACAO_LISTADO',
		        payload: resp
		    });
		}).catch(e => {
			setErroAPI(e);
		});
	}
}

export function atualizarLista(lista) {
	return (dispatch, getState) => {
		dispatch({
			type: 'PREMIACAO_LISTADO',
			payload: {
				data: lista
			}
		});
	}
}

export function salvar(registro) {
    return (dispatch, getState) => {
		if (!registro.id) {
	        axios.post(`${consts.API_URL}/premiacao`, {
				...registro,
				competencia_analise: (registro.competencia_analise || '').split('/')[0] && ((registro.competencia_analise || '').split('/')[0].match(/\d+/g) || []).join('').trim().length == 2
					&& (registro.competencia_analise || '').split('/')[1] && ((registro.competencia_analise || '').split('/')[1].match(/\d+/g) || []).join('').trim().length == 4
					? `${(registro.competencia_analise || '').split('/')[1]}${(registro.competencia_analise || '').split('/')[0]}` : null,
				competencia_pagamento: (registro.competencia_pagamento || '').split('/')[0] && ((registro.competencia_pagamento || '').split('/')[0].match(/\d+/g) || []).join('').trim().length == 2
					&& (registro.competencia_pagamento || '').split('/')[1] && ((registro.competencia_pagamento || '').split('/')[1].match(/\d+/g) || []).join('').trim().length == 4
					? `${(registro.competencia_pagamento || '').split('/')[1]}${(registro.competencia_pagamento || '').split('/')[0]}` : null,
				id_usuario_inclusao: getState().auth.usuario.id,
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		} else {
			axios.put(`${consts.API_URL}/premiacao`, {
				...registro,
				competencia_analise: (registro.competencia_analise || '').split('/')[0] && ((registro.competencia_analise || '').split('/')[0].match(/\d+/g) || []).join('').trim().length == 2
					&& (registro.competencia_analise || '').split('/')[1] && ((registro.competencia_analise || '').split('/')[1].match(/\d+/g) || []).join('').trim().length == 4
					? `${(registro.competencia_analise || '').split('/')[1]}${(registro.competencia_analise || '').split('/')[0]}` : null,
				competencia_pagamento: (registro.competencia_pagamento || '').split('/')[0] && ((registro.competencia_pagamento || '').split('/')[0].match(/\d+/g) || []).join('').trim().length == 2
					&& (registro.competencia_pagamento || '').split('/')[1] && ((registro.competencia_pagamento || '').split('/')[1].match(/\d+/g) || []).join('').trim().length == 4
					? `${(registro.competencia_pagamento || '').split('/')[1]}${(registro.competencia_pagamento || '').split('/')[0]}` : null,
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		}
    };
}

export function reprocessarMotoristas(registro) {
    return (dispatch, getState) => {

		dispatch(setAguardando(true));

		axios.put(`${consts.API_URL}/premiacao`, {
			...registro,
			competencia_analise: (registro.competencia_analise || '').split('/')[0] && ((registro.competencia_analise || '').split('/')[0].match(/\d+/g) || []).join('').trim().length == 2
				&& (registro.competencia_analise || '').split('/')[1] && ((registro.competencia_analise || '').split('/')[1].match(/\d+/g) || []).join('').trim().length == 4
				? `${(registro.competencia_analise || '').split('/')[1]}${(registro.competencia_analise || '').split('/')[0]}` : null,
			competencia_pagamento: (registro.competencia_pagamento || '').split('/')[0] && ((registro.competencia_pagamento || '').split('/')[0].match(/\d+/g) || []).join('').trim().length == 2
				&& (registro.competencia_pagamento || '').split('/')[1] && ((registro.competencia_pagamento || '').split('/')[1].match(/\d+/g) || []).join('').trim().length == 4
				? `${(registro.competencia_pagamento || '').split('/')[1]}${(registro.competencia_pagamento || '').split('/')[0]}` : null,
			id_usuario_alteracao: getState().auth.usuario.id
		}).then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista());
			dispatch(getListaItem(registro.id));
			dispatch(setAguardando(false));
		}).catch(e => {
			setErroAPI(e);
			dispatch(setAguardando(false));
		});
    };
}

export function excluir(registro) {
    return (dispatch, getState) => {
        axios.delete(`${consts.API_URL}/premiacao?id=${registro.id}&id_usuario_alteracao=${getState().auth.usuario.id}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista());
			dispatch(setModoTela('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function getListaTipo() {
    const request = axios.get(`${consts.API_URL}/premiacaoTipo/listarSelect`);
    return {
        type: 'PREMIACAO_TIPO_SELECT_LISTADO',
        payload: request
    };
}

export function getListaMotorista() {
    const request = axios.get(`${consts.API_URL}/motorista/listarSelect`);
    return {
        type: 'PREMIACAO_MOTORISTA_SELECT_LISTADO',
        payload: request
    };
}

//Métodos dos Itens
export function setModoTelaItem(modo, registro = {}) {
    return {
        type: 'PREMIACAO_MOTORISTA_MODO_TELA',
        payload: {
			modo: modo,
			registro: registro
		}
    };
}
export function initFormItem(registro = {}) {
    return [
        initialize('premiacaoMotoristaForm', registro)
    ];
}

export function getListaItem(id_premiacao) {
	return (dispatch, getState) => {
    	axios.get(`${consts.API_URL}/premiacaoMotorista?id_premiacao=${id_premiacao}`)
		.then(resp => {
			dispatch({
		        type: 'PREMIACAO_MOTORISTA_LISTADO',
		        payload: resp
		    });
		}).catch(e => {
			setErroAPI(e);
		});
	}
}

export function setListaItem(lista) {
	return {
		type: 'PREMIACAO_MOTORISTA_LISTADO',
		payload: { data: lista }
	};
}

export function salvarItem(registro) {
    return (dispatch, getState) => {
		if (!registro.id) {
	        axios.post(`${consts.API_URL}/premiacaoMotorista`, {
				...registro,
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getListaItem(registro.id_premiacao));
				dispatch(setModoTelaItem('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		} else {
			axios.put(`${consts.API_URL}/premiacaoMotorista`, {
				...registro,
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getListaItem(registro.id_premiacao));
				dispatch(setModoTelaItem('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		}
    };
}

export function excluirItem(registro) {
    return dispatch => {
        axios.delete(`${consts.API_URL}/premiacaoMotorista?id=${registro.id}&id_premiacao=${registro.id_premiacao}&id_usuario_alteracao=${registro.id_usuario_alteracao}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getListaItem(registro.id_premiacao));
			dispatch(setModoTelaItem('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}
/*
export function importarMedias(tabelaMeta, tabelaQuilometragem, id_premiacao) {
    return async (dispatch, getState) => {

		dispatch(setAguardando(true));
		let lista = [];

		for (var i = 0; i < getState().premiacao.listaItem.length; i++) {
			let motorista = getState().premiacao.listaItem[i];

			let linhaMeta = tabelaMeta.filter(km => (km[3] || '').trim().toUpperCase() == motorista.cpf_motorista.trim().toUpperCase())[0];
			let linhaKm = tabelaQuilometragem.filter(km => (km[0] || '').trim().toUpperCase() == motorista.nome_motorista.trim().toUpperCase())[0];

			lista.push({
				...motorista,
				atingiu_meta: linhaMeta ? linhaMeta[9] == 'Atingiu' : null,
				km_percorrido: linhaKm ? parseInt(linhaKm[5] || 0) : 0,
				id_usuario_alteracao: getState().auth.usuario.id
			});

		}

		if (lista.length > 0) {
			await axios.put(`${consts.API_URL}/premiacaoMotorista`, {
				lista: lista
			}).then(resp => {

			}).catch(e => {
				setErroAPI(e);
			});
		}

		setSuccess('Importação Realizada com sucesso.');
		dispatch(getListaItem(id_premiacao));
		dispatch(setModoTelaItem('lista'));
		dispatch(setAguardando(false));
    };
}
*/

export function reprocessar(registro) {
    return async (dispatch, getState) => {

		dispatch(setAguardando(true));

		let lista = [];

		for (var i = 0; i < getState().premiacao.listaItem.length; i++) {
			let motorista = getState().premiacao.listaItem[i];

			lista.push({
				...motorista,
				reprocessar: true,
				id_usuario_alteracao: getState().auth.usuario.id
			});

		}

		if (lista.length > 0) {
			await axios.put(`${consts.API_URL}/premiacaoMotorista`, {
				lista: lista
			}).then(resp => {

			}).catch(e => {
				setErroAPI(e);
			});
		}

		setSuccess('Importação Realizada com sucesso.');
		dispatch(getListaItem(registro.id));
		dispatch(setModoTelaItem('lista'));
		dispatch(setAguardando(false));
    };
}

export function importarGrupos(tabela, id_premiacao) {
    return async (dispatch, getState) => {

		dispatch(setAguardando(true));
		let lista = [];

		for (var i = 0; i < getState().premiacao.listaItem.length; i++) {
			let motorista = getState().premiacao.listaItem[i];

			let linha = tabela.filter(item => String(item[0] || '').trim().toUpperCase() == motorista.cpf_motorista.trim().toUpperCase())[0] || [];
			let grupo = getState().premiacao.listaGrupo.filter(item => item.valor.trim() == String(linha[2] || '').trim())[0];

			if (linha && grupo) {
				lista.push({
					...motorista,
					id_grupo: grupo ? grupo.id : motorista.id_grupo,
					id_usuario_alteracao: getState().auth.usuario.id
				});
			}

		}

		if (lista.length > 0) {
			await axios.put(`${consts.API_URL}/premiacaoMotorista`, {
				lista: lista
			}).then(resp => {

			}).catch(e => {
				setErroAPI(e);
			});
		}

		setSuccess('Importação Realizada com sucesso.');
		dispatch(getListaItem(id_premiacao));
		dispatch(setModoTelaItem('lista'));
		dispatch(setAguardando(false));
    };
}

export function importarMedias(tabela, id_premiacao) {
    return async (dispatch, getState) => {

		dispatch(setAguardando(true));
		let lista = [];

		for (var i = 0; i < getState().premiacao.listaItem.length; i++) {
			let motorista = getState().premiacao.listaItem[i];

			let linha = tabela.filter(item => String(item[0] || '').trim().toUpperCase() == motorista.cpf_motorista.trim().toUpperCase())[0];

			if (linha) {
				lista.push({
					...motorista,
					atingiu_meta: linha ? linha[4].toUpperCase() == 'SIM' : false,
					id_usuario_alteracao: getState().auth.usuario.id
				});
			}

		}

		if (lista.length > 0) {
			await axios.put(`${consts.API_URL}/premiacaoMotorista`, {
				lista: lista
			}).then(resp => {

			}).catch(e => {
				setErroAPI(e);
			});
		}

		setSuccess('Importação Realizada com sucesso.');
		dispatch(getListaItem(id_premiacao));
		dispatch(setModoTelaItem('lista'));
		dispatch(setAguardando(false));
    };
}

export function importarQuilometragem(tabela, id_premiacao) {
    return async (dispatch, getState) => {

		dispatch(setAguardando(true));
		let lista = [];

		for (var i = 0; i < getState().premiacao.listaItem.length; i++) {
			let motorista = getState().premiacao.listaItem[i];

			let linha = tabela.filter(item => String(item[0] || '').trim().toUpperCase() == motorista.cpf_motorista.trim().toUpperCase())[0];

			if (linha) {
				lista.push({
					...motorista,
					km_percorrido: linha ? parseInt(linha[3] || 0) : 0,
					id_usuario_alteracao: getState().auth.usuario.id
				});
			}

		}

		if (lista.length > 0) {
			await axios.put(`${consts.API_URL}/premiacaoMotorista`, {
				lista: lista
			}).then(resp => {

			}).catch(e => {
				setErroAPI(e);
			});
		}

		setSuccess('Importação Realizada com sucesso.');
		dispatch(getListaItem(id_premiacao));
		dispatch(setModoTelaItem('lista'));
		dispatch(setAguardando(false));
    };
}

/*
export function importarMedias(tabelaMeta, tabelaQuilometragem, id_premiacao) {
    return async (dispatch, getState) => {

		dispatch(setAguardando(true));
		let lista = [];

		for (var i = 0; i < getState().premiacao.listaItem.length; i++) {
			let motorista = getState().premiacao.listaItem[i];

			let linhaMeta = tabelaMeta.filter(km => (km[3] || '').trim().toUpperCase() == motorista.cpf_motorista.trim().toUpperCase())[0];
			let linhaKm = tabelaQuilometragem.filter(km => (km[0] || '').trim().toUpperCase() == motorista.nome_motorista.trim().toUpperCase())[0];

			lista.push({
				...motorista,
				atingiu_meta: linhaMeta ? linhaMeta[9] == 'Atingiu' : null,
				km_percorrido: linhaKm ? parseInt(linhaKm[5] || 0) : 0,
				id_usuario_alteracao: getState().auth.usuario.id
			});

		}

		if (lista.length > 0) {
			await axios.put(`${consts.API_URL}/premiacaoMotorista`, {
				lista: lista
			}).then(resp => {

			}).catch(e => {
				setErroAPI(e);
			});
		}

		setSuccess('Importação Realizada com sucesso.');
		dispatch(getListaItem(id_premiacao));
		dispatch(setModoTelaItem('lista'));
		dispatch(setAguardando(false));
    };
}
*/
//A importação está desativada, será feita pela mesma importação das médias
/*
export function importarQuilometragem(registro) {
    return (dispatch, getState) => {
		dispatch(setAguardando(true));

		axios.post(`${consts.API_URL}/premiacaoMotorista/importarQuilometragem`, {
			...registro,
			competencia_analise: (registro.competencia_analise || '').split('/')[0] && ((registro.competencia_analise || '').split('/')[0].match(/\d+/g) || []).join('').trim().length == 2
				&& (registro.competencia_analise || '').split('/')[1] && ((registro.competencia_analise || '').split('/')[1].match(/\d+/g) || []).join('').trim().length == 4
				? `${(registro.competencia_analise || '').split('/')[1]}${(registro.competencia_analise || '').split('/')[0]}` : null,
			itens: getState().premiacao.listaItem
		}).then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getListaItem(registro.id));
			dispatch(setModoTelaItem('lista'));
			dispatch(setAguardando(false));
		}).catch(e => {
			setErroAPI(e);
			dispatch(setAguardando(false));
		});
    };
}
*/

export function importarOcorrencia(registro) {
    return (dispatch, getState) => {
		dispatch(setAguardando(true));
		axios.post(`${consts.API_URL}/premiacaoMotorista/importarOcorrencia`, {
			...registro,
			competencia_analise: (registro.competencia_analise || '').split('/')[0] && ((registro.competencia_analise || '').split('/')[0].match(/\d+/g) || []).join('').trim().length == 2
				&& (registro.competencia_analise || '').split('/')[1] && ((registro.competencia_analise || '').split('/')[1].match(/\d+/g) || []).join('').trim().length == 4
				? `${(registro.competencia_analise || '').split('/')[1]}${(registro.competencia_analise || '').split('/')[0]}` : null,
			itens: getState().premiacao.listaItem
		}).then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getListaItem(registro.id));
			dispatch(setModoTelaItem('lista'));
			dispatch(setAguardando(false));
		}).catch(e => {
			setErroAPI(e);
			dispatch(setAguardando(false));
		});
    };
}

export function gerarPdf(registro) {
    return (dispatch, getState) => {
		dispatch(setAguardando(true));
		axios.post(`${consts.API_URL}/premiacao/gerarPdf`, {
			...registro
		}).then(resp => {
			window.open(`https://api-letsarapremiacao.infinitum-ti.com/${resp.data}`, '_blank');
			dispatch(setAguardando(false));
		}).catch(e => {
			setErroAPI(e);
			dispatch(setAguardando(false));
		});
    };
}

export function gerarPdfLista(registro) {
    return (dispatch, getState) => {
		dispatch(setAguardando(true));
		axios.post(`${consts.API_URL}/premiacao/gerarPdfLista`, {
			...registro,
			lista: getState().premiacao.listaItem.map(item => ({
				...registro,
				premio: {
					...item,
					valor_maximo_economica: FormatUtils.formatarValorTela(item.valor_maximo_economica, 2),
					premio_economica: FormatUtils.formatarValorTela(item.premio_economica, 2),
					valor_maximo_excelencia: FormatUtils.formatarValorTela(item.valor_maximo_excelencia, 2),
					percentual_perda_ocorrencia: FormatUtils.formatarValorTela(item.percentual_perda_ocorrencia, 0),
					premio_excelencia: FormatUtils.formatarValorTela(item.premio_excelencia, 2),
					valor_maximo_total: FormatUtils.formatarValorTela(parseFloat(item.valor_maximo_economica) + parseFloat(item.valor_maximo_excelencia), 2),
					premio_total: FormatUtils.formatarValorTela(parseFloat(item.premio_economica) + parseFloat(item.premio_excelencia), 2),
				}
			}))
		}).then(resp => {
			window.open(`https://api-letsarapremiacao.infinitum-ti.com/${resp.data}`, '_blank');
			dispatch(setAguardando(false));
		}).catch(e => {
			setErroAPI(e);
			dispatch(setAguardando(false));
		});
    };
}

export function gerarPdfListaGeral() {
    return async (dispatch, getState) => {

		dispatch(setAguardando(true));

		let lista = [];

		for (var i = 0; i < getState().premiacao.lista.filter(registro => registro.imprimir).length; i++) {
			let registro = getState().premiacao.lista.filter(registro => registro.imprimir)[i];

			await axios.get(`${consts.API_URL}/premiacaoMotorista?id_premiacao=${registro.id}`)
			.then(resp => {

				for (var j = 0; j < resp.data.length; j++) {
					let item = resp.data[j];
					lista.push({
						...registro,
						competencia_analise: `${String(registro.competencia_analise).substring(4, 6)}/${String(registro.competencia_analise).substring(0, 4)}`,
						competencia_pagamento: `${String(registro.competencia_pagamento).substring(4, 6)}/${String(registro.competencia_pagamento).substring(0, 4)}`,
						premio: {
							...item,
							valor_maximo_economica: FormatUtils.formatarValorTela(item.valor_maximo_economica, 2),
							premio_economica: FormatUtils.formatarValorTela(item.premio_economica, 2),
							valor_maximo_excelencia: FormatUtils.formatarValorTela(item.valor_maximo_excelencia, 2),
							percentual_perda_ocorrencia: FormatUtils.formatarValorTela(item.percentual_perda_ocorrencia, 0),
							premio_excelencia: FormatUtils.formatarValorTela(item.premio_excelencia, 2),
							valor_maximo_total: FormatUtils.formatarValorTela(parseFloat(item.valor_maximo_economica) + parseFloat(item.valor_maximo_excelencia), 2),
							premio_total: FormatUtils.formatarValorTela(parseFloat(item.premio_economica) + parseFloat(item.premio_excelencia), 2),
						}
					});
				}
			}).catch(e => {
				setErroAPI(e);
			});

		}

		lista.sort((a, b) => {
			return (a.premio.nome_motorista).localeCompare(b.premio.nome_motorista)
		});

		if (lista.length > 0) {
			let listaAux = [];
			let arquivo = 1;
			for (var i = 0; i < lista.length; i++) {
				let item = lista[i];

				if (listaAux.length > 0 && ((listaAux.length > 1000 && listaAux[listaAux.length-1].premio.nome_motorista != item.premio.nome_motorista) || lista.length == i+1)) {

					if (lista.length == i+1){
						listaAux.push(item);
					}
					await axios.post(`${consts.API_URL}/premiacao/gerarPdfLista`, {
						...listaAux[0],
						competencia_analise: `GERAL_${arquivo}_${listaAux.length}`,
						competencia_pagamento: `GERAL_${arquivo}_${listaAux.length}`,
						lista: listaAux
					}).then(resp => {
						window.open(`https://api-letsarapremiacao.infinitum-ti.com/${resp.data}`, '_blank');
					}).catch(e => {
						setErroAPI(e);
					});

					listaAux = [];
					arquivo++;
				} else {
					listaAux.push(item);
				}

			}
		}

		dispatch(setAguardando(false));
    };
}
