import React from 'react'
import { Link } from 'react-router-dom'

export default props => (
    <li className="nav-item">
        <Link to={props.path} className={`nav-link ${props.active ? 'active' : ''}`} 
            style={{
                paddingLeft: props.submenu ? 15 : null,
                backgroundColor: props.submenu && !props.active ? '#f5f2f2' : null
            }}>
            <i className={`nav-icon ${props.icon}`} style={{ fontSize: props.submenu ? 14 : null }}></i>
			<p style={{ fontSize: props.submenu ? 14 : null }}>
				{props.label}
				<span class={`badge badge-${props.spanType} right`}>{props.span}</span>
			</p>
        </Link>
    </li>
)
