import React from 'react'

export default props => (
	<button type={props.submit ? 'submit' : 'button'} className={`btn btn-block btn-lg btn-outline-${props.type}`} onClick={props.event} disabled={props.disabled} title={props.title} >
		{props.text}
	</button>
)

/*
primary
secondary
success
danger
warning
info
light
dark
link
*/
