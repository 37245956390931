import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ContentCardFooter from '../common/template/contentCardFooter';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import LabelAndInputDateTime from '../common/form/labelAndInputDateTime';
import Select from '../common/form/select';
import SelectAlteracao from '../common/form/selectAlteracao';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import { setError } from '../common/toast/toast';
import ColorSelector from '../common/form/colorSeletor';
import {
	setModoTela, initForm
} from './comissaoOperacaoActions';

class ComissaoOperacaoForm extends Component {

	state = {

	}

	componentDidMount() {

	}

    componentWillMount() {

    }

    render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';

        return (
			<div>
				<ContentCard>
					<Form event={this.props.handleSubmit}>
						<ContentCardBody>
							<Row>

								<Field
									name='id_operacao'
									component={Select}
									options={this.props.listaOperacao}
									label='Operação'
									cols='12 12 12 12'
									placeholder='Selecione a operação'
									readOnly={readOnly} />

								<Field
									name='data_base'
									component={LabelAndInputMask}
									label='Início Base' placeholder='Informe a data'
									cols='12 6 4 3'
									mask='99/99/9999'
									readOnly={readOnly} />

								<Field
									name='data_inicio'
									component={LabelAndInputMask}
									label='Início de Vigência' placeholder='Informe a data'
									cols='12 6 4 3'
									mask='99/99/9999'
									readOnly={readOnly} />

								<Field
									name='data_fim'
									component={LabelAndInputMask}
									label='Fim de Vigência' placeholder='Informe a data'
									cols='12 6 4 3'
									mask='99/99/9999'
									readOnly={readOnly} />
							
							</Row>

							<Row>
								<Field
									name='id_termo'
									component={Select}
									options={this.props.listaComissaoTermo}
									label='Termo'
									cols='12 12 12 12'
									placeholder='Selecione o termo'
									readOnly={readOnly} />

								<Field
									name='id_vinculo'
									component={Select}
									options={this.props.listaComissaoVinculo}
									label='Vínculo'
									cols='12 12 12 12'
									placeholder='Selecione o vínculo'
									readOnly={readOnly} />
									
								<Field
									name='id_colaborador'
									component={Select}
									options={this.props.listaUsuario}
									label='Colaborador'
									cols='12 12 12 12'
									placeholder='Selecione o colaborador'
									readOnly={readOnly} />
								
								<Field
									name='id_tipo'
									component={Select}
									options={this.props.listaComissaoTipo}
									label='Tipo'
									cols='12 12 12 12'
									placeholder='Selecione o tipo'
									readOnly={readOnly} />
									
							</Row>			
						</ContentCardBody>
						<ContentCardFooter>
							<Row alignCenter>
								<Grid cols='6 6 4 3'>
									{this.props.excluir ? (
										<Button
											text='Excluir'
											submit type={'danger'}
											icon={'fa fa-trash'} />
									) : (
									<Button
										text='Salvar'
										submit
										type={'success'}
										icon={'fa fa-check'} />
									)}
								</Grid>
								<Grid cols='6 6 4 3'>
									<Button
										text='Voltar'
										type={'warning'}
										icon={'fa fa-chevron-left'}
										event={() => this.props.setModoTela('lista')} />
								</Grid>
							</Row>
						</ContentCardFooter>
					</Form>
				</ContentCard>
			</div>
        )
    }

}

ComissaoOperacaoForm = reduxForm({form: 'comissaoOperacaoForm', destroyOnUnmount: false})(ComissaoOperacaoForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	formularioValues: getFormValues('comissaoOperacaoForm')(state),
	registro: state.comissaoOperacao.registro,
	listaOperacao: state.comissaoOperacao.listaOperacao,
	listaComissaoTermo: state.comissaoOperacao.listaComissaoTermo,
	listaComissaoVinculo: state.comissaoOperacao.listaComissaoVinculo,
	listaUsuario: state.comissaoOperacao.listaUsuario,
	listaComissaoTipo: state.comissaoOperacao.listaComissaoTipo
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, initForm
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ComissaoOperacaoForm);
