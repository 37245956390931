import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import DateFormat from '../common/dateFormat/DateFormat';
import Aguardando from '../common/template/aguardando';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import ReactExport from 'react-export-excel-fixed-xlsx';
import Select from '../common/form/select';

import { 
	getListaSaldoUnidade, getListaSaldoVeiculo, getListaTipo
} from './acessorioSaldoActions';
import FormatUtils from '../common/formatUtils/FormatUtils';
import InputPesquisa from '../common/form/inputPesquisa';

class AcessorioSaldo extends Component {

	state = {
		pesquisar: '',
		linhaSelecionada: null,
		filtro: {
			id_tipo: null
		},
		ordenacao: {
			placa_veiculo: 1,
			saldos: [],
			saldo_total: 0
		}
	}

    componentWillMount() {
		this.props.getListaTipo();
        this.props.getListaSaldoUnidade(this.state.filtro);
		this.props.getListaSaldoVeiculo(this.state.filtro);
    }

	getNovaOrdenacao(atual) {
		return atual == 2 ? 0 : (atual || 0) + 1;
	}

	getNovaOrdenacaoSaldo(colunas = [], posicao) {
		let atual = colunas.filter(coluna => coluna.posicao == posicao)[0] || {};
		let novaOrdenacao = atual.ordem == 2 ? 0 : (atual.ordem || 0) + 1;
		
		let lista = colunas.filter(coluna => coluna.posicao != posicao);
		lista.push({
			posicao: posicao,
			ordem: novaOrdenacao
		});

		return lista;
	}

	getOrdenacao(ordenacao) {
		return ordenacao == 1 
			? <i class="fas fa-sort-amount-down-alt" /> 
			: (
				ordenacao == 2 
					? <i class="fas fa-sort-amount-up" /> 
					: null
			);
	}

	getOrdenacaoSaldo(colunas = [], posicao) {
		
		let ordenacao = colunas.filter(coluna => coluna.posicao == posicao)[0] || {};

		return ordenacao.ordem == 1 
			? <i class="fas fa-sort-amount-down-alt" /> 
			: (
				ordenacao.ordem == 2 
					? <i class="fas fa-sort-amount-up" /> 
					: null
			);
	}

	filtrar() {
		
		let lista = this.props.listaSaldoVeiculo.filter(item => {
			return !(this.state.pesquisar || '').trim()
				|| (this.state.pesquisar.toUpperCase() || '').split('+').filter(pesquisar => (pesquisar || '').trim()).filter(pesquisar => {		
				if (pesquisar
					&& !(`
					${item.placa_veiculo}
					${parseInt(item.quantidade)}
				`).toUpperCase().includes(pesquisar)) {
					return false;
				}	
				return true;
			}).length > 0;
		});

		if (this.state.ordenacao.placa_veiculo) {
			lista.sort((a, b) => this.state.ordenacao.placa_veiculo == 1 
				? (a.placa_veiculo > b.placa_veiculo ? 1 : -1) 
				: (a.placa_veiculo > b.placa_veiculo ? -1 : 1));
		} else if (this.state.ordenacao.saldo_total) {
			lista.sort((a, b) => this.state.ordenacao.saldo_total == 1 
				? (parseInt(a.saldo_total || 0) > parseInt(b.saldo_total || 0) ? 1 : -1) 
				: (parseInt(a.saldo_total || 0) > parseInt(b.saldo_total || 0) ? -1 : 1));
		}

		(this.state.ordenacao.saldos || []).filter(coluna => coluna.ordem > 0).map(coluna => {
			lista.sort((a, b) => coluna.ordem == 1 
				? (parseInt(((a.saldos || [])[coluna.posicao] || {}).saldo || 0) > parseInt(((b.saldos || [])[coluna.posicao] || {}).saldo || 0) ? 1 : -1) 
				: (parseInt(((a.saldos || [])[coluna.posicao] || {}).saldo || 0) > parseInt(((b.saldos || [])[coluna.posicao] || {}).saldo || 0) ? -1 : 1));
		});

		return lista;

	}

	montarExcel(lista) {

		const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
		const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

		let colunas = [];
		colunas.push({ componente: <ExcelColumn key={1} label='Placa' value='placa_veiculo' /> });
		
		(this.filtrar()[0] ? this.filtrar()[0].saldos : []).map((coluna, i) => {
			colunas.push({ componente: <ExcelColumn key={colunas.length + 1} label={coluna.nome_tipo} value={`coluna_${i}`} type='number' /> });
		});
			
		colunas.push({ componente: <ExcelColumn key={colunas.length + 1} label='Total' value='saldo_total' type='number' /> });

		return (
			<ExcelSheet data={lista.map(item => ({
				...item,
				coluna_0: parseInt((item.saldos || [])[0] && (item.saldos || [])[0].saldo ? (item.saldos || [])[0].saldo :null),
				coluna_1: parseInt((item.saldos || [])[1] && (item.saldos || [])[1].saldo ? (item.saldos || [])[1].saldo :null),
				coluna_2: parseInt((item.saldos || [])[2] && (item.saldos || [])[2].saldo ? (item.saldos || [])[2].saldo :null),
				coluna_3: parseInt((item.saldos || [])[3] && (item.saldos || [])[3].saldo ? (item.saldos || [])[3].saldo :null),
				coluna_4: parseInt((item.saldos || [])[4] && (item.saldos || [])[4].saldo ? (item.saldos || [])[4].saldo :null),
				coluna_5: parseInt((item.saldos || [])[5] && (item.saldos || [])[5].saldo ? (item.saldos || [])[5].saldo :null),
				coluna_6: parseInt((item.saldos || [])[6] && (item.saldos || [])[6].saldo ? (item.saldos || [])[6].saldo :null),
				coluna_7: parseInt((item.saldos || [])[7] && (item.saldos || [])[7].saldo ? (item.saldos || [])[7].saldo :null),
				coluna_8: parseInt((item.saldos || [])[8] && (item.saldos || [])[8].saldo ? (item.saldos || [])[8].saldo :null),
				coluna_9: parseInt((item.saldos || [])[9] && (item.saldos || [])[9].saldo ? (item.saldos || [])[9].saldo :null),
				saldo_total: parseInt(item.saldo_total)
			}))} name='Acessórios - Saldo'>
				{colunas.filter(item => {
					return true;
				}).map(item => (
					item.componente
				))}
			</ExcelSheet>
		);
	}

    render() {

		const ExcelFile = ReactExport.ExcelFile;

		let totalVeiculos = 0;
		let totalUnidades = 0;
		this.props.listaSaldoVeiculo.map(item => {
			totalVeiculos += parseInt(item.saldo_total || 0);
		});

		this.props.listaSaldoUnidade.map(item => {
			totalUnidades += parseInt(item.saldo || 0);
		});

		let totalGeral = totalVeiculos + totalUnidades;

		let colunas = this.filtrar()[0] ? this.filtrar()[0].saldos : [];

		return (
			<ContentCard>

				<ContentCardBody>

					<div class='row'>
						<div class="col-xs-12 col-sm-12 col-md-12 col-lg-9">
							<div class='row'>
								{this.props.listaSaldoUnidade.map(item => {

									const backgroundColor = 'rgb(31, 48, 102, 0.9)';
									const color = '#fff';
											
									return (
										<div key={item.nome_unidade} class="col-xs-6 col-sm-6 col-md-4 col-lg-2" 
											style={{ cursor: 'pointer', minWidth: 160 }}>
											<div class="small-box" 
												style={{
													backgroundColor: backgroundColor
												}}>
												<div class="inner">
													<h5 style={{ textAlign: 'center', color: color, fontSize: 14, height: 26 }}>{item.nome_unidade}</h5>											
													<p style={{ padding: 0, margin: 0, textAlign: 'center', color: color, fontSize: 13 }}>
														{totalGeral != 0 ? FormatUtils.formatarValorTela((parseInt(item.saldo || 0) * 100) / totalGeral, 2) : 0}%
													</p>
													<p style={{ padding: 0, margin: 0, fontWeight: 'bold', textAlign: 'center', color: color, fontSize: 20 }}>
														{item.saldo || 0}
													</p>											
												</div>
											</div>
										</div>
									);
								})}
							</div>
						</div>

						<div class="col-xs-12 col-sm-12 col-md-12 col-lg-3">
							<div class='row'>
								<div class="col-xs-6 col-sm-12 col-md-12 col-lg-6" 
									style={{ cursor: 'pointer' }}>
									<div class="small-box" 
										style={{ backgroundColor: '#ffffff', borderStyle: 'solid', borderColor: '#1F3066' }}>
										<div class="inner" style={{ paddingLeft: 4, paddingRight: 4 }}>
											<p style={{ padding: 0, margin: 0, textAlign: 'center', color: '#1F3066', fontSize: 13 }}>
												{totalUnidades != 0 ? FormatUtils.formatarValorTela((parseInt(totalUnidades || 0) * 100) / totalGeral, 2) : 0}%
											</p>
											<p style={{ padding: 0, margin: 0, fontWeight: 'bold', textAlign: 'center', color: '#1F3066', fontSize: 20 }}>
												{totalUnidades}
											</p>
											<h5 style={{ textAlign: 'center', color: '#1F3066', fontSize: 14, height: 20, fontWeight: 'bold', marginBottom: 0, marginTop: '0.5rem' }}>Estoque</h5>
										</div>
									</div>
								</div>

								<div class="col-xs-6 col-sm-12 col-md-12 col-lg-6" 
									style={{ cursor: 'pointer' }}>
									<div class="small-box" 
										style={{ backgroundColor: '#ffffff', borderStyle: 'solid', borderColor: '#1F3066' }}>
										<div class="inner" style={{ paddingLeft: 4, paddingRight: 4 }}>
											<p style={{ padding: 0, margin: 0, textAlign: 'center', color: '#1F3066', fontSize: 13 }}>
												{totalGeral != 0 ? FormatUtils.formatarValorTela((parseInt(totalVeiculos || 0) * 100) / totalGeral, 2) : 0}%
											</p>
											<p style={{ padding: 0, margin: 0, fontWeight: 'bold', textAlign: 'center', color: '#1F3066', fontSize: 20 }}>
												{totalVeiculos}
											</p>
											<h5 style={{ textAlign: 'center', color: '#1F3066', fontSize: 14, height: 20, fontWeight: 'bold', marginBottom: 0, marginTop: '0.5rem' }}>Veículos</h5>
										</div>
									</div>
								</div>

								<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12" 
									style={{ cursor: 'pointer' }}>
									<div class="small-box" 
										style={{ backgroundColor: '#ffffff', borderStyle: 'solid', borderColor: '#1F3066' }}>
										<div class="inner" style={{ paddingLeft: 4, paddingRight: 4 }}>
											<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', paddingTop: 6, paddingBottom: 6 }}>
												<h5 style={{ textAlign: 'center', color: '#1F3066', fontSize: 20, height: 20, fontWeight: 'bold', marginBottom: 0, marginTop: 0 }}>Total</h5>

												<div style={{ display: 'flex', flexDirection: 'column', marginLeft: 24 }}>
													<p style={{ padding: 0, margin: 0, textAlign: 'center', color: '#1F3066', fontSize: 16 }}>
														100%
													</p>
													<p style={{ padding: 0, margin: 0, fontWeight: 'bold', textAlign: 'center', color: '#1F3066', fontSize: 28 }}>
														{totalGeral}
													</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
									
					<Row>
						<InputPesquisa
							cols='4 4 4 4'
							label='Pesquisar'
							minWidth={200}
							onChange={value => this.setState({ ...this.state, pesquisar: value })} />

						<Select
							label='Acessório'
							options={this.props.listaTipo}
							cols='4 4 4 4'
							placeholder='Selecione'
							minWidth={200}
							value={this.state.filtro.id_tipo}
							onChange={value => {
								this.setState({
									...this.state,
									filtro: {
										...this.state.filtro,
										id_tipo: value
									}
								});
							}} />
						.<div style={{ marginTop: 8 }}>
							<a class='btn btn-app bg-primary' style={{ marginLeft: 2, minWidth: 75 }}
								onClick={() => {
									this.props.getListaSaldoUnidade(this.state.filtro);
									this.props.getListaSaldoVeiculo(this.state.filtro);
								}}>
								<i class='fas fa-sync-alt'></i> Atualizar
							</a>
							<ExcelFile
								filename={'Acessórios - Saldo'}
								element={
									<a class='btn btn-app bg-success' style={{ marginLeft: 2, minWidth: 75 }}>
										<i class='fas fa-file-excel'></i> Exportar
									</a>
								}>
								{this.montarExcel(this.filtrar())}													
							</ExcelFile>
						</div>
					</Row>

					<Table responsive border alturaFixa striped 
						maxHeight={window.innerWidth < 576 ? '100%' : (
							window.innerWidth < 992? '100%' : (
								window.innerWidth < 1318 ? window.innerHeight - 540 : window.innerHeight - 420
							)
						)}>
						<THead>
							<Tr backgroundColor={'#fff'}>
								<Th alignLeft style={{paddingLeft: 8 }} sticky width={600} color={'#1F3066'} small
									onClick={() => this.setState({ ...this.state, ordenacao: { placa_veiculo: this.getNovaOrdenacao(this.state.ordenacao.placa_veiculo) }})}>
									Placa {this.getOrdenacao(this.state.ordenacao.placa_veiculo)}
								</Th>
								{colunas.map((coluna, posColuna) => (
									<Th key={coluna.id_tipo} alignCenter sticky width={100} color={'#1F3066'} small
										onClick={() => this.setState({ ...this.state, ordenacao: { saldos: this.getNovaOrdenacaoSaldo(this.state.ordenacao.saldos, posColuna) }})}>
										{coluna.nome_tipo} {this.getOrdenacaoSaldo(this.state.ordenacao.saldos, posColuna)}
									</Th>	
								))}
								<Th alignCenter sticky width={100} color={'#1F3066'} small
									onClick={() => this.setState({ ...this.state, ordenacao: { saldo_total: this.getNovaOrdenacao(this.state.ordenacao.saldo_total) }})}>
									Total {this.getOrdenacao(this.state.ordenacao.saldo_total)}
								</Th>
							</Tr>
						</THead>
						<TBody>
							{this.filtrar().map(item => (
								<Tr key={item.id} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
									onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
									onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
									<Td alignLeft small style={{paddingLeft: 8 }}>{item.placa_veiculo}</Td>
									{item.saldos.map(coluna => (
										<Td key={coluna.id_tipo} alignCenter small>{coluna.saldo || '-'}</Td>
									))}
									<Td alignCenter small bold>{item.saldo_total || '-'}</Td>
								</Tr>
							))}
						</TBody>
					</Table>
				</ContentCardBody>
			</ContentCard>
		);
	}


}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	modoTela: state.acessorioSaldo.modoTela,
	filtro: state.acessorioSaldo.filtro,
	aguardando: state.acessorioSaldo.aguardando,
	listaTipo: state.acessorioSaldo.listaTipo.filter(item => 
		state.auth.usuarioCarregado && (state.auth.usuarioCarregado.acessorio_tipos || []).includes(item.id)
	),
	listaSaldoUnidade: state.acessorioSaldo.listaSaldoUnidade,
	listaSaldoVeiculo: state.acessorioSaldo.listaSaldoVeiculo
});
const mapDispatchToProps = dispatch => bindActionCreators({ 
	getListaSaldoUnidade, getListaSaldoVeiculo, getListaTipo
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(AcessorioSaldo);
