import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import Form from './motoristaForm';
import DateFormat from '../common/dateFormat/DateFormat';
import Aguardando from '../common/template/aguardando';
import LabelAndCheckbox from '../common/form/labelAndCheckbox';
import ReactExport from 'react-export-excel-fixed-xlsx';

import { setModoTela, initForm, salvar, excluir, getLista, getListaGrupo, atualizarDataLake } from './motoristaActions';

class Motorista extends Component {

	state = {
		linhaSelecionada: null,
		ativos: true,
		inativos: false
	}

    componentWillMount() {
        this.props.getLista();
		this.props.getListaGrupo();
    }

    render() {
        return (
            <div>
				
				<Aguardando aguardando={this.props.aguardando} />

                <Content>
					{this.props.modoTela == 'cadastro' ? (
						<Form onSubmit={this.props.salvar} />
					) : null}
					{this.props.modoTela == 'exclusao' ? (
						<Form excluir onSubmit={this.props.excluir} />
					) : null}
					{this.props.modoTela == 'lista' ? this.lista() : null}
                </Content>
            </div>
        )
    }

	lista() {

		let lista = [];
		this.props.lista.filter(item => {
			if (this.state.pesquisar
				&& !(`${item.nome} ${item.nome_grupo} ${item.rg_cnh || item.rg}`).toUpperCase().includes(this.state.pesquisar.toUpperCase())) {
				return false;
			}

			return true;
		}).forEach(item => {
			let contratos = (item.contratos || []).filter(contrato => {
				if (!(this.state.ativos && this.state.inativos)) {
					if (this.state.ativos && contrato.data_desligamento) {
						return false;
					}
					if (this.state.inativos && !contrato.data_desligamento) {
						return false;
					}
				};
				return true;
			});

			contratos.forEach(contrato => {
				lista.push({
					...item,
					data_admissao: contrato.data_admissao,
					data_desligamento: contrato.data_desligamento,
					tempo_contrato: contrato.tempo_contrato
				})
			});
				
		});

		const ExcelFile = ReactExport.ExcelFile;
		const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
		const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;		

		return (
			<ContentCard>
				<ContentCardHeader>
					<Row>
						<Grid cols='6 6 4 2'>
							<Button
								text='Atualizar DataLake'
								type={'primary'}
								icon={'fa fa-plus'}
								event={() => {
									this.props.atualizarDataLake();
								}} />
						</Grid>

						<Grid cols='6 6 3 2'>
							<ExcelFile
								filename={'Motoristas'}
								element={
									<Button
										text='Exportar'
										type={'primary'}
										icon={'fa fa-plus'} />
								}>
								<ExcelSheet data={lista.map(item => ({
									...item,
									data_admissao: DateFormat.formatarDataSqlParaTela(item.data_admissao),
									data_desligamento: DateFormat.formatarDataSqlParaTela(item.data_desligamento),
									status: item.data_desligamento ? 'Inativo' : 'Ativo',
									cnh_validade: DateFormat.formatarDataSqlParaTela(item.cnh_validade),
								}))} name='Motoristas'>
									<ExcelColumn label='Nome' value='nome' />
									<ExcelColumn label='Grupo' value='nome_grupo' />
									<ExcelColumn label='Admissão' value='data_admissao' />
									<ExcelColumn label='Desligamento' value='data_desligamento' />
									<ExcelColumn label='Tempo Contrato' value='tempo_contrato' />
									<ExcelColumn label='Status' value='status' />
									<ExcelColumn label='Validade CNH' value='cnh_validade' />
									<ExcelColumn label='RG' value='rg' />
									<ExcelColumn label='RG CNH' value='rg_cnh' />
								</ExcelSheet>
							</ExcelFile>
						</Grid>
					</Row>
				</ContentCardHeader>
				<ContentCardBody>
					<Row style={{ marginTop: 20 }}>
						<LabelAndCheckbox
							label='Ativos'
							cols='6 3 2 1'
							value={this.state.ativos}
							onChange={(data) => {
								this.setState({
									...this.state,
									ativos: !this.state.ativos
								});
							}} />

						<LabelAndCheckbox
							label='Inativos'
							cols='6 3 2 1'
							value={this.state.inativos}
							onChange={(data) => {
								this.setState({
									...this.state,
									inativos: !this.state.inativos
								});
							}} />
					</Row>

					<input className='form-control'
							placeholder='Pesquisar'
							type='text'
							value={this.state.pesquisar}
							onChange={e => {
								this.setState({ ...this.state, pesquisar: e.target.value });
							}} />
					<Table responsive>
						<THead>
							<Tr>
								<Th>Nome</Th>
								<Th alignCenter>Grupo</Th>
								<Th alignCenter>Admissão</Th>
								<Th alignCenter>Desligamento</Th>
								<Th alignCenter>Tempo Contrato</Th>
								<Th alignCenter>Status</Th>
								<Th alignCenter>Validade CNH</Th>
								<Th alignCenter>RG</Th>
								<Th alignCenter>RG CNH</Th>
								<Th></Th>
							</Tr>
						</THead>
						<TBody>
							{lista.map(item => (
								<Tr key={`${item.id}-${item.data_admissao}`}
									backgroundColor={item.data_desligamento? '#f2c9c9' : null}
									onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
									onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
									<Td>{item.nome}</Td>
									<Td alignCenter>{item.nome_grupo}</Td>
									<Td alignCenter>{DateFormat.formatarDataSqlParaTela(item.data_admissao)}</Td>
									<Td alignCenter>{DateFormat.formatarDataSqlParaTela(item.data_desligamento)}</Td>
									<Td alignCenter>{item.tempo_contrato}</Td>
									<Td alignCenter>{item.data_desligamento ? 'Inativo' : 'Ativo'}</Td>
									<Td alignCenter>{DateFormat.formatarDataSqlParaTela(item.cnh_validade)}</Td>
									<Td alignCenter>{item.rg}</Td>
									<Td alignCenter>{item.rg_cnh}</Td>
									{item.data_desligamento ? <Td /> : (
									<Td alignRight minWidth={50}>
										<ButtonTable
											type={'warning'}
											icon={'fas fa-pencil-alt'}
											visible={this.state.linhaSelecionada == item.id}
											event={() => {
												this.props.setModoTela('cadastro', {
													...item,
													data_admissao: DateFormat.formatarDataSqlParaTela(item.data_admissao),
													data_desligamento: DateFormat.formatarDataSqlParaTela(item.data_desligamento),
													cnh_validade: DateFormat.formatarDataSqlParaTela(item.cnh_validade)
												});
												this.props.initForm({
													...item,
													data_admissao: DateFormat.formatarDataSqlParaTela(item.data_admissao),
													data_desligamento: DateFormat.formatarDataSqlParaTela(item.data_desligamento),
													cnh_validade: DateFormat.formatarDataSqlParaTela(item.cnh_validade)
												});
											}} />

										{/*<ButtonTable
											type={'danger'}
											icon={'fas fa-trash-alt'}
											visible={this.state.linhaSelecionada == item.id}
											event={() => {
												this.props.setModoTela('exclusao', {
													...item,
													data_admissao: DateFormat.formatarDataSqlParaTela(item.data_admissao),
													data_desligamento: DateFormat.formatarDataSqlParaTela(item.data_desligamento),
													cnh_validade: DateFormat.formatarDataSqlParaTela(item.cnh_validade)
												});
												this.props.initForm({
													...item,
													data_admissao: DateFormat.formatarDataSqlParaTela(item.data_admissao),
													data_desligamento: DateFormat.formatarDataSqlParaTela(item.data_desligamento),
													cnh_validade: DateFormat.formatarDataSqlParaTela(item.cnh_validade)
												});
											}} />*/}
									</Td>)}
								</Tr>
							))}
						</TBody>
					</Table>
				</ContentCardBody>
			</ContentCard>
		);
	}


}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	modoTela: state.motorista.modoTela,
	aguardando: state.motorista.aguardando,
	lista: state.motorista.lista
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, initForm, salvar, excluir, getLista, getListaGrupo, atualizarDataLake }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Motorista);
