import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import DateFormat from '../common/dateFormat/DateFormat';

export function setModoTela(modo, registro = { id_estoque_local: 2 }) {
    return {
        type: 'VALE_PEDAGIO_MOTORISTA_MODO_TELA',
        payload: {
			modo: modo,
			registro: registro
		}
    };
}

export function setAguardando(aguardando) {
    return {
        type: 'VALE_PEDAGIO_MOTORISTA_SET_AGUARDANDO',
        payload: aguardando
    };
}

export function setFiltro(filtro) {
    return {
        type: 'VALE_PEDAGIO_MOTORISTA_FILTRO',
        payload: {
			filtro: filtro
		}
    };
}

export function getLista() {
	return (dispatch, getState) => {
		dispatch(setAguardando(true));
		axios.get(`${consts.API_URL}/valePedagioMovimentoConsolidado?
			id_motorista=${getState().valePedagioMotorista.filtro.id_motorista || null}
			&data_inicial=${DateFormat.formatarDataTelaParaSql(getState().valePedagioMotorista.filtro.data_inicial || null)}
			&data_final=${DateFormat.formatarDataTelaParaSql(getState().valePedagioMotorista.filtro.data_final || null)}
		`).then(resp => {
			dispatch({
		        type: 'VALE_PEDAGIO_MOTORISTA_LISTADO',
		        payload: resp
		    });
			dispatch(setAguardando(false));
		}).catch(e => {
			console.log(e);
			setErroAPI(e);
			dispatch(setAguardando(false));
		});
	}
}

export function getListaSaldo() {
	return (dispatch, getState) => {
		dispatch(setAguardando(true));
		axios.get(`${consts.API_URL}/valePedagioMovimento/listarSaldoMotorista?
			id_motorista=${getState().valePedagioMotorista.filtro.id_motorista || 0}
			&data=${DateFormat.formatarDataTelaParaSql(getState().valePedagioMotorista.filtro.data_final || null)}
		`).then(resp => {
			dispatch({
		        type: 'VALE_PEDAGIO_MOTORISTA_SALDO_LISTADO',
		        payload: resp
		    });
			dispatch(setAguardando(false));
		}).catch(e => {
			console.log(e);
			
			setErroAPI(e);
			dispatch(setAguardando(false));
		});
	}
}

//Métodos das Ocorrências
export function setModoTelaOcorrencia(modo, registro = {}) {
    return {
        type: 'VALE_PEDAGIO_MOTORISTA_OCORRENCIA_MODO_TELA',
        payload: {
			modo: modo,
			registro: registro
		}
    };
}

export function getListaOcorrencia(id_vale_pedagio, tipo) {

	return (dispatch, getState) => {
    	axios.get(`${consts.API_URL}/ocorrencia?
			${tipo == 'ENTRADA' ? `id_vale_pedagio_entrada=${id_vale_pedagio}` 
			: (tipo == 'SAIDA' ? `id_vale_pedagio_saida=${id_vale_pedagio}` 
				: `id_vale_pedagio_transferencia=${id_vale_pedagio}`)}`)
		.then(resp => {
			dispatch({
		        type: 'VALE_PEDAGIO_MOTORISTA_OCORRENCIA_LISTADO',
		        payload: resp
		    });
		}).catch(e => {
			setErroAPI(e);
		});
	}
}

//Entrada

export function initFormEntrada(registro = { id_estoque_local: 2 }) {
    return [
        initialize('valePedagioEntradaForm', registro)
    ];
}

export function salvarEntrada(registro) {
		
    return (dispatch, getState) => {

		if (!registro.id) {
	        axios.post(`${consts.API_URL}/valePedagioEntrada`, {
				...registro,
				id_usuario_inclusao: getState().auth.usuario.id,
				id_usuario_alteracao: getState().auth.usuario.id,
				datahora_entrada: DateFormat.formatarDataHoraTelaParaSql(registro.datahora_entrada),
				valor: String(registro.valor || '').replace(',', '.')
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(getListaSaldo());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		} else {
			axios.put(`${consts.API_URL}/valePedagioEntrada`, {
				...registro,
				id_usuario_alteracao: getState().auth.usuario.id,
				datahora_entrada: DateFormat.formatarDataHoraTelaParaSql(registro.datahora_entrada),
				valor: String(registro.valor || '').replace(',', '.')
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(getListaSaldo());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		}
    };
}

export function excluirEntrada(registro) {
    return (dispatch, getState) => {
		axios.delete(`${consts.API_URL}/valePedagioEntrada?
			id=${registro.id}
			&datahora_entrada=${DateFormat.formatarDataHoraTelaParaSql(registro.datahora_entrada)}
			&id_usuario_alteracao=${getState().auth.usuario.id}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista());
			dispatch(getListaSaldo());
			dispatch(setModoTela('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

//Saída

export function initFormSaida(registro = { id_estoque_local: 2 }) {
    return [
        initialize('valePedagioSaidaForm', registro)
    ];
}

export function salvarSaida(registro) {
		
    return (dispatch, getState) => {

		if (!registro.id) {
	        axios.post(`${consts.API_URL}/valePedagioSaida`, {
				...registro,
				id_usuario_inclusao: getState().auth.usuario.id,
				id_usuario_alteracao: getState().auth.usuario.id,
				datahora_saida: DateFormat.formatarDataHoraTelaParaSql(registro.datahora_saida),
				valor: String(registro.valor || '').replace(',', '.')
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(getListaSaldo());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		} else {
			axios.put(`${consts.API_URL}/valePedagioSaida`, {
				...registro,
				id_usuario_alteracao: getState().auth.usuario.id,
				datahora_saida: DateFormat.formatarDataHoraTelaParaSql(registro.datahora_saida),
				valor: String(registro.valor || '').replace(',', '.')
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(getListaSaldo());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		}
    };
}

export function excluirSaida(registro) {
    return (dispatch, getState) => {
		axios.delete(`${consts.API_URL}/valePedagioSaida?
			id=${registro.id}
			&datahora_saida=${DateFormat.formatarDataHoraTelaParaSql(registro.datahora_saida)}
			&id_usuario_alteracao=${getState().auth.usuario.id}
		`).then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista());
			dispatch(getListaSaldo());
			dispatch(setModoTela('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}


//Transferência

export function initFormTransferencia(registro = { id_estoque_local: 2 }) {
    return [
        initialize('valePedagioTransferenciaForm', registro)
    ];
}

export function salvarTransferencia(registro) {

    return (dispatch, getState) => {

		if (!registro.id) {
	        axios.post(`${consts.API_URL}/valePedagioTransferencia`, {
				...registro,
				id_usuario_inclusao: getState().auth.usuario.id,
				id_usuario_alteracao: getState().auth.usuario.id,
				datahora_transferencia: DateFormat.formatarDataHoraTelaParaSql(registro.datahora_transferencia),
				valor: String(registro.valor || '').replace(',', '.')
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(getListaSaldo());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		} else {
			axios.put(`${consts.API_URL}/valePedagioTransferencia`, {
				...registro,
				id_usuario_alteracao: getState().auth.usuario.id,
				datahora_transferencia: DateFormat.formatarDataHoraTelaParaSql(registro.datahora_transferencia),
				valor: String(registro.valor || '').replace(',', '.')
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(getListaSaldo());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		}
    };
}

export function excluirTransferencia(registro) {
    return (dispatch, getState) => {
		axios.delete(`${consts.API_URL}/valePedagioTransferencia?
			id=${registro.id}
			&datahora_transferencia=${DateFormat.formatarDataHoraTelaParaSql(registro.datahora_transferencia)}
			&id_usuario_alteracao=${getState().auth.usuario.id}
		`).then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista());
			dispatch(getListaSaldo());
			dispatch(setModoTela('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function getListaMotorista() {
	return (dispatch, getState) => {
    	axios.get(`${consts.API_URL}/motorista/listarSelect`)
		.then(resp => {
			dispatch({
				type: 'VALE_PEDAGIO_MOTORISTA_MOTORISTA_SELECT_LISTADO',
		        payload: resp
		    });
		}).catch(e => {
			setErroAPI(e);
		});
	}
}

export function getListaValePedagio() {
	return (dispatch, getState) => {
    	axios.get(`${consts.API_URL}/valePedagio/listarSelect`)
		.then(resp => {
			dispatch({
				type: 'VALE_PEDAGIO_MOTORISTA_TIPO_SELECT_LISTADO',
		        payload: resp
		    });
		}).catch(e => {
			setErroAPI(e);
		});
	}
}

export function getListaMotivo() {
	return (dispatch, getState) => {
    	axios.get(`${consts.API_URL}/valePedagioMotivo/listarSelect`)
		.then(resp => {
			dispatch({
				type: 'VALE_PEDAGIO_MOTORISTA_MOTIVO_SELECT_LISTADO',
		        payload: resp
		    });
		}).catch(e => {
			setErroAPI(e);
		});
	}
}

export function getListaTomador() {
	return (dispatch, getState) => {
    	axios.get(`${consts.API_URL}/valePedagioTomador/listarSelect`)
		.then(resp => {
			dispatch({
				type: 'VALE_PEDAGIO_MOTORISTA_TOMADOR_SELECT_LISTADO',
		        payload: resp
		    });
		}).catch(e => {
			setErroAPI(e);
		});
	}
}

export function getListaPeriodo() {
	return (dispatch, getState) => {
    	axios.get(`${consts.API_URL}/valePedagioPeriodo/listarSelect`)
		.then(resp => {
			dispatch({
				type: 'VALE_PEDAGIO_MOTORISTA_PERIODO_SELECT_LISTADO',
		        payload: resp
		    });
		}).catch(e => {
			setErroAPI(e);
		});
	}
}

export function getListaSaldoGeral() {
	return (dispatch, getState) => {
	
		axios.get(`${consts.API_URL}/valePedagioMovimento/listarSaldoGeral?
			data=${DateFormat.formatarDataTelaParaSql(getState().valePedagioMotorista.filtro.saldo_data_base || null)}
			&id_vale_pedagio=${getState().valePedagioMotorista.filtro.id_saldo_vale_pedagio || null}
			&id_motorista=${getState().valePedagioMotorista.filtro.id_saldo_motorista || null}
		`).then(resp => {
			dispatch({
				type: 'VALE_PEDAGIO_MOTORISTA_SALDO_GERAL_LISTADO',
		        payload: resp
		    });
		}).catch(e => {
			setErroAPI(e);
		});
	}
}

export function getListaSaldoGeralPeriodo() {
	return (dispatch, getState) => {
	
		axios.get(`${consts.API_URL}/valePedagioMovimento/listarSaldoGeralPeriodo?
			data_inicial=${DateFormat.formatarDataTelaParaSql(getState().valePedagioMotorista.filtro.saldo_periodo_data_inicial || null)}
			&data_final=${DateFormat.formatarDataTelaParaSql(getState().valePedagioMotorista.filtro.saldo_periodo_data_final || null)}
			&id_vale_pedagio=${getState().valePedagioMotorista.filtro.id_saldo_periodo_vale_pedagio || null}
			&id_motorista=${getState().valePedagioMotorista.filtro.id_saldo_periodo_motorista || null}
		`).then(resp => {
			dispatch({
				type: 'VALE_PEDAGIO_MOTORISTA_SALDO_GERAL_PERIODO_LISTADO',
		        payload: resp
		    });
		}).catch(e => {
			setErroAPI(e);
		});
	}
}

export function getListaMotivoPeriodo() {
	return (dispatch, getState) => {
	
		axios.get(`${consts.API_URL}/valePedagioMovimento/listarMotivoPeriodo?
			data_inicial=${DateFormat.formatarDataTelaParaSql(getState().valePedagioMotorista.filtro.motivo_periodo_data_inicial || null)}
			&data_final=${DateFormat.formatarDataTelaParaSql(getState().valePedagioMotorista.filtro.motivo_periodo_data_final || null)}
			${getState().valePedagioMotorista.filtro.motivo_periodo_id_motivo 
				? `&id_motivo=${getState().valePedagioMotorista.filtro.motivo_periodo_id_motivo}` 
				: ''
			}
		`).then(resp => {
			dispatch({
				type: 'VALE_PEDAGIO_MOTORISTA_MOTIVO_PERIODO_LISTADO',
		        payload: resp
		    });
		}).catch(e => {
			setErroAPI(e);
		});
	}
}

export function gerarPdf(registro) {
    return (dispatch, getState) => {
		dispatch(setAguardando(true));
		axios.post(`${consts.API_URL}/valePedagio/gerarPdf`, {
			...registro
		}).then(resp => {
			window.open(`https://api-letsarapremiacao.infinitum-ti.com/${resp.data}`, '_blank');
			dispatch(setAguardando(false));
		}).catch(e => {
			setErroAPI(e);
			dispatch(setAguardando(false));
		});
    };
}