import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';

export function setAguardando(aguardando) {
    return {
        type: 'DASHBOARD_SET_AGUARDANDO',
        payload: aguardando
    };
}

export function getListaPremiacaoEconomica() {
	return (dispatch, getState) => {
		if (getState().auth.usuario) {
			dispatch(setAguardando(true));
	    	axios.get(`${consts.API_URL}/premiacao/listarPremiacaoEconomica`).then(resp => {
				dispatch({
			        type: 'DASHBOARD_PREMIACAO_ECONOMICA_LISTADO',
			        payload: resp
			    });
				dispatch(setAguardando(false));
			}).catch(e => {
				setErroAPI(e);
				dispatch(setAguardando(false));
			});
		}
	}
}

export function getListaPremiacaoExelencia() {
	return (dispatch, getState) => {
		if (getState().auth.usuario) {
			dispatch(setAguardando(true));
	    	axios.get(`${consts.API_URL}/premiacao/listarPremiacaoExcelencia`).then(resp => {
				dispatch({
			        type: 'DASHBOARD_PREMIACAO_EXCELENCIA_LISTADO',
			        payload: resp
			    });
				dispatch(setAguardando(false));
			}).catch(e => {
				setErroAPI(e);
				dispatch(setAguardando(false));
			});
		}
	}
}

export function getListaPremiacaoPenalidades() {
	return (dispatch, getState) => {
		if (getState().auth.usuario) {
			dispatch(setAguardando(true));
	    	axios.get(`${consts.API_URL}/premiacao/listarPremiacaoPenalidades`).then(resp => {
				dispatch({
			        type: 'DASHBOARD_PREMIACAO_PENALIDADES_LISTADO',
			        payload: resp
			    });
				dispatch(setAguardando(false));
			}).catch(e => {
				setErroAPI(e);
				dispatch(setAguardando(false));
			});
		}
	}
}

export function getListaPremiacaoQuilometragem() {
	return (dispatch, getState) => {
		if (getState().auth.usuario) {
			dispatch(setAguardando(true));
	    	axios.get(`${consts.API_URL}/premiacao/listarPremiacaoQuilometragem`).then(resp => {
				dispatch({
			        type: 'DASHBOARD_PREMIACAO_QUILOMETRAGEM_LISTADO',
			        payload: resp
			    });
				dispatch(setAguardando(false));
			}).catch(e => {
				setErroAPI(e);
				dispatch(setAguardando(false));
			});
		}
	}
}
