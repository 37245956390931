import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Aguardando from '../common/template/aguardando';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import Form from './premiacaoForm';
import DateFormat from '../common/dateFormat/DateFormat';
import FormatUtils from '../common/formatUtils/FormatUtils';

import { setModoTela, initForm, salvar, excluir, getLista, getListaTipo, getListaMotorista, getListaGrupo, atualizarLista, gerarPdfListaGeral } from './premiacaoActions';

class Premiacao extends Component {

	state = {
		linhaSelecionada: null
	}

    componentWillMount() {
        this.props.getLista();
		this.props.getListaTipo();
		this.props.getListaMotorista();
		this.props.getListaGrupo();
    }

    render() {
        return (
            <div>
                <Content>

					<Aguardando aguardando={this.props.aguardando} />

					{this.props.modoTela == 'cadastro' ? (
						<Form onSubmit={this.props.salvar} />
					) : null}
					{this.props.modoTela == 'exclusao' ? (
						<Form excluir onSubmit={this.props.excluir} />
					) : null}
					{this.props.modoTela == 'lista' ? this.lista() : null}
                </Content>
            </div>
        )
    }

	lista() {
		return (
			<ContentCard>
				<ContentCardHeader>
					<Row>
						<Grid cols='6 6 4 2'>
							<Button
								text='Adicionar'
								type={'success'}
								icon={'fa fa-plus'}
								event={() => {
									this.props.setModoTela('cadastro', { datahora_premiacao: DateFormat.formatarDataHoraSqlParaTela(DateFormat.getDataHoraAtual()) });
									this.props.initForm({ datahora_premiacao:  DateFormat.formatarDataHoraSqlParaTela(DateFormat.getDataHoraAtual()) });
								}} />
						</Grid>
						<Grid cols='6 6 3 2'>
							<Button
								text={'Imprimir Prêmios Selecionados'}
								type={'primary'}
								icon={'fa fa-chevron-left'}
								event={() => this.props.gerarPdfListaGeral()} />
						</Grid>
					</Row>
				</ContentCardHeader>
				<ContentCardBody>
					<Table responsive>
						<THead>
							<Tr>
								<Th alignCenter>Análise</Th>
								<Th alignCenter>Pagamento</Th>
								<Th alignCenter>Finalizada</Th>
								<Th alignCenter>Imprimir</Th>
								<Th></Th>
							</Tr>
						</THead>
						<TBody>
							{(this.props.lista).map(item => (
								<Tr key={item.id} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
									onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
									onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
									<Td alignCenter>{`${String(item.competencia_analise).substring(4, 6)}/${String(item.competencia_analise).substring(0, 4)}`}</Td>
									<Td alignCenter>{`${String(item.competencia_pagamento).substring(4, 6)}/${String(item.competencia_pagamento).substring(0, 4)}`}</Td>
									<Td alignCenter>{item.finalizada ? 'Sim' : 'Não'}</Td>
									<Td alignCenter minWidth={100}>
										<ButtonTable
											type={item.imprimir ? 'success' : 'warning'}
											icon={item.imprimir ? 'far fa-check-square' : 'far fa-square'}
											visible={true}
											event={() => {
												this.props.atualizarLista(this.props.lista.map(itemAux => {
													if (itemAux.id == item.id) {
														return {
															...itemAux,
															imprimir: itemAux.imprimir ? false : true
														};
													} else {
														return itemAux;
													}
												}))
											}} />
									</Td>
									<Td alignRight minWidth={100}>
										<ButtonTable
											type={'warning'}
											icon={'fas fa-pencil-alt'}
											visible={this.state.linhaSelecionada == item.id}
											event={() => {
												this.props.setModoTela('cadastro', {
													...item,
													competencia_analise: `${String(item.competencia_analise).substring(4, 6)}/${String(item.competencia_analise).substring(0, 4)}`,
													competencia_pagamento: `${String(item.competencia_pagamento).substring(4, 6)}/${String(item.competencia_pagamento).substring(0, 4)}`
												});
												this.props.initForm({
													...item,
													competencia_analise: `${String(item.competencia_analise).substring(4, 6)}/${String(item.competencia_analise).substring(0, 4)}`,
													competencia_pagamento: `${String(item.competencia_pagamento).substring(4, 6)}/${String(item.competencia_pagamento).substring(0, 4)}`
												});
											}} />

										<ButtonTable
											type={'danger'}
											icon={'fas fa-trash-alt'}
											visible={this.state.linhaSelecionada == item.id}
											disabled={item.finalizada}
											event={() => {
												this.props.setModoTela('exclusao', {
													...item,
													competencia_analise: `${String(item.competencia_analise).substring(4, 6)}/${String(item.competencia_analise).substring(0, 4)}`,
													competencia_pagamento: `${String(item.competencia_pagamento).substring(4, 6)}/${String(item.competencia_pagamento).substring(0, 4)}`
												});
												this.props.initForm({
													...item,
													competencia_analise: `${String(item.competencia_analise).substring(4, 6)}/${String(item.competencia_analise).substring(0, 4)}`,
													competencia_pagamento: `${String(item.competencia_pagamento).substring(4, 6)}/${String(item.competencia_pagamento).substring(0, 4)}`
												});
											}} />
									</Td>
								</Tr>
							))}
						</TBody>
					</Table>
				</ContentCardBody>
			</ContentCard>
		);
	}


}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	modoTela: state.premiacao.modoTela,
	lista: state.premiacao.lista,
	aguardando: state.premiacao.aguardando
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, initForm, salvar, excluir, getLista, getListaTipo, getListaMotorista, getListaGrupo, atualizarLista, gerarPdfListaGeral }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Premiacao);
