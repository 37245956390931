import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import Form from './acessorioInventarioForm';
import DateFormat from '../common/dateFormat/DateFormat';
import Aguardando from '../common/template/aguardando';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import InputPesquisa from '../common/form/inputPesquisa';
import ReactExport from 'react-export-excel-fixed-xlsx';
import Select from '../common/form/select';

import { 
	setModoTela, initForm, setFiltro, salvar, excluir, getLista, 
	getListaUnidade
} from './acessorioInventarioActions';

class AcessorioInventario extends Component {

	state = {
		pesquisar: '',
		linhaSelecionada: null,
		filtro: {
			id_unidade: null,
			status: 'ambos'
		},
		ordenacao: {
			datahora_inventario: 1,
			nome_unidade: 0,
			nome_usuario_alteracao: 0,
			datahora_alteracao: 0,
			divergencias: 0
		}
	}

    componentWillMount() {
        this.props.getLista();
		this.props.getListaUnidade();
    }

    render() {
        return (
            <div>
                <Content>

					<Aguardando aguardando={this.props.aguardando} />

					{this.props.modoTela == 'cadastro' ? (
						<Form onSubmit={this.props.salvar} />
					) : null}
					{this.props.modoTela == 'exclusao' ? (
						<Form excluir onSubmit={this.props.excluir} />
					) : null}
					{this.props.modoTela == 'lista' ? this.lista() : null}
                </Content>
            </div>
        )
    }

	getNovaOrdenacao(atual) {
		return atual == 2 ? 0 : (atual || 0) + 1;
	}

	getOrdenacao(ordenacao) {
		return ordenacao == 1 
			? <i class="fas fa-sort-amount-down-alt" /> 
			: (
				ordenacao == 2 
					? <i class="fas fa-sort-amount-up" /> 
					: null
			);
	}

	filtrar() {
		
		let lista = this.props.lista.filter(item => {
			return !(this.state.pesquisar || '').trim()
				|| (this.state.pesquisar.toUpperCase() || '').split('+').filter(pesquisar => (pesquisar || '').trim()).filter(pesquisar => {		
				if (pesquisar
					&& !(`
					${DateFormat.formatarDataHoraSqlParaTela(item.datahora_inventario)}
					${item.nome_unidade}
					${item.nome_usuario_alteracao}
					${DateFormat.formatarDataHoraSqlParaTela(item.datahora_alteracao)}
					${item.divergencias > 0 ? (
						`${item.divergencias} ${item.divergencias == 1 ? 'divergência' : 'divergências'}`
					) : (
						'Correto'
					)}
				`).toUpperCase().includes(pesquisar)) {
					return false;
				}	
				return true;
			}).length > 0;
		});

		lista = lista.filter(item => {

			if (this.state.filtro.id_unidade && this.state.filtro.id_unidade != item.id_unidade) {
				return false;
			}
			if ((this.state.filtro.status == 'correto' && item.divergencias > 0)
				|| (this.state.filtro.status == 'divergente' && item.divergencias <= 0)) {
				return false;
			}
			
			return true;
		});
		
		if (this.state.ordenacao.datahora_inventario) {
			lista.sort((a, b) => this.state.ordenacao.datahora_inventario == 1 
				? (a.datahora_inventario > b.datahora_inventario ? 1 : -1) 
				: (a.datahora_inventario > b.datahora_inventario ? -1 : 1));
		} else if (this.state.ordenacao.nome_unidade) {
			lista.sort((a, b) => this.state.ordenacao.nome_unidade == 1 
				? (a.nome_unidade > b.nome_unidade ? 1 : -1) 
				: (a.nome_unidade > b.nome_unidade ? -1 : 1));
		} else if (this.state.ordenacao.nome_usuario_alteracao) {
			lista.sort((a, b) => this.state.ordenacao.nome_usuario_alteracao == 1 
				? (a.nome_usuario_alteracao > b.nome_usuario_alteracao ? 1 : -1) 
				: (a.nome_usuario_alteracao > b.nome_usuario_alteracao ? -1 : 1));
		} else if (this.state.ordenacao.datahora_alteracao) {
			lista.sort((a, b) => this.state.ordenacao.datahora_alteracao == 1 
				? (a.datahora_alteracao > b.datahora_alteracao ? 1 : -1) 
				: (a.datahora_alteracao > b.datahora_alteracao ? -1 : 1));
		} else if (this.state.ordenacao.divergencias) {
			lista.sort((a, b) => this.state.ordenacao.divergencias == 1 
				? (parseInt(a.divergencias) > parseInt(b.divergencias) ? 1 : -1) 
				: (parseInt(a.divergencias) > parseInt(b.divergencias) ? -1 : 1));
		}
		
		return lista;

	}

	montarExcel(lista) {

		const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
		const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

		return (
			<ExcelSheet data={lista.map(item => ({
				...item,
				datahora_inventario: DateFormat.formatarDataHoraSqlParaTela(item.datahora_inventario),
				quantidade: parseInt(item.quantidade)
			}))} name='Acessórios - Inventário'>
				{[
					{ componente: <ExcelColumn key={1} label='Data/Hora' value='datahora_inventario' /> },
					{ componente: <ExcelColumn key={2} label='Tipo' value='nome_tipo' /> },
					{ componente: <ExcelColumn key={3} label='Evento' value='nome_evento' /> },
					{ componente: <ExcelColumn key={4} label='Unidade' value='nome_unidade' /> },
					{ componente: <ExcelColumn key={6} label='Qtd.' value='quantidade' type='number' /> }
				].filter(item => {
					return true;
				}).map(item => (
					item.componente
				))}
			</ExcelSheet>
		);
	}

	lista() {

		const ExcelFile = ReactExport.ExcelFile;

		return (
			<ContentCard>
				<ContentCardHeader>
					<Row>
						<InputPesquisa
							cols='12 6 3 1'
							label='Pesquisar'
							minWidth={200}
							onChange={value => this.setState({ ...this.state, pesquisar: value })} />

						<Select
							label='Unidade'
							options={this.props.listaUnidade}
							cols='12 6 3 1'
							placeholder='Selecione'
							minWidth={200}
							value={this.state.filtro.id_unidade}
							onChange={value => {
								this.setState({
									...this.state,
									filtro: {
										...this.state.filtro,
										id_unidade: value
									}
								});
							}} />
						
						<Select
							label='Status'
							options={[{ id: 'ambos', valor: 'Ambos' }, { id: 'correto', valor: 'Correto' }, { id: 'divergente', valor: 'Divergente' }]}
							cols='12 6 3 1'
							placeholder='Selecione'
							minWidth={200}
							value={this.state.filtro.status}
							onChange={value => {
								this.setState({
									...this.state,
									filtro: {
										...this.state.filtro,
										status: value
									}
								});
							}} />

						<LabelAndInputMask
							label='Data Incial' placeholder='Informe a data'
							cols='12 6 2 1'
							mask='99/99/9999'
							minWidth={130}
							maxWidth={130}
							value={this.props.filtro.data_inicial}
							onChange={data => {
								this.props.setFiltro({ ...this.props.filtro, data_inicial: data.target.value });
							}} />

						<LabelAndInputMask
							label='Data Final' placeholder='Informe a data'
							cols='12 6 2 1'
							mask='99/99/9999'
							minWidth={130}
							maxWidth={130}
							value={this.props.filtro.data_final}
							onChange={data => {
								this.props.setFiltro({ ...this.props.filtro, data_final: data.target.value });
							}} />

						<div style={{ marginTop: 8 }}>
							<a class='btn btn-app bg-primary' style={{ marginLeft: 2, minWidth: 75 }}
								onClick={() => {
									this.props.getLista();
								}}>
								<i class='fas fa-sync-alt'></i> Atualizar
							</a>
							{/*<ExcelFile
								filename={'Acessórios - Entrada'}
								element={
									<a class='btn btn-app bg-success' style={{ marginLeft: 2, minWidth: 75 }}>
										<i class='fas fa-file-excel'></i> Exportar
									</a>
								}>
								{this.montarExcel(this.filtrar())}													
							</ExcelFile>*/}

							<a class='btn btn-app bg-primary' style={{ marginLeft: 2, minWidth: 75 }}
								onClick={() => {
									this.props.setModoTela('cadastro');
									this.props.initForm({
										id_unidade: this.props.listaUnidade.length == 1 ? this.props.listaUnidade[0].id : null,
									});
								}}>
								<i class='fas fa-plus'></i> Realizar
							</a>
						</div>
					</Row>
				</ContentCardHeader>
				<ContentCardBody>
				<Table responsive border alturaFixa striped 
						maxHeight={window.innerWidth < 576 ? '100%' : (
							window.innerWidth < 768 ? window.innerHeight - 370 : (
								window.innerWidth < 1558 ? window.innerHeight - 280 : window.innerHeight - 200
							)
						)}>
						<THead>
							<Tr backgroundColor={'#fff'}>
								<Th alignCenter sticky width={100} color={'#1F3066'} small
									onClick={() => this.setState({ ...this.state, ordenacao: { datahora_inventario: this.getNovaOrdenacao(this.state.ordenacao.datahora_inventario) }})}>
									Data/Hora {this.getOrdenacao(this.state.ordenacao.datahora_inventario)}
								</Th>
								<Th alignCenter sticky width={150} color={'#1F3066'} small
								 	onClick={() => this.setState({ ...this.state, ordenacao: { nome_unidade: this.getNovaOrdenacao(this.state.ordenacao.nome_unidade) }})} >
									Unidade {this.getOrdenacao(this.state.ordenacao.nome_unidade)}
								</Th>
								<Th alignCenter sticky width={150} color={'#1F3066'} small
								 	onClick={() => this.setState({ ...this.state, ordenacao: { nome_usuario_alteracao: this.getNovaOrdenacao(this.state.ordenacao.nome_usuario_alteracao) }})} >
									Usuário Alteração {this.getOrdenacao(this.state.ordenacao.nome_usuario_alteracao)}
								</Th>
								<Th alignCenter sticky width={150} color={'#1F3066'} small
								 	onClick={() => this.setState({ ...this.state, ordenacao: { datahora_alteracao: this.getNovaOrdenacao(this.state.ordenacao.datahora_alteracao) }})} >
									Data/Hora Alteração {this.getOrdenacao(this.state.ordenacao.datahora_alteracao)}
								</Th>
								<Th alignCenter sticky width={150} color={'#1F3066'} small
								 	onClick={() => this.setState({ ...this.state, ordenacao: { divergencias: this.getNovaOrdenacao(this.state.ordenacao.divergencias) }})} >
									Status {this.getOrdenacao(this.state.ordenacao.divergencias)}
								</Th>
								<Th width={80} sticky></Th>
							</Tr>
						</THead>
						<TBody>
							{this.filtrar().map(item => (
								<Tr key={item.id} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
									onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
									onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
									<Td alignCenter small>{DateFormat.formatarDataHoraSqlParaTela(item.datahora_inventario)}</Td>
									<Td alignCenter small>{item.nome_unidade}</Td>
									<Td alignCenter small>{item.nome_usuario_alteracao}</Td>
									<Td alignCenter small>{DateFormat.formatarDataHoraSqlParaTela(item.datahora_alteracao)}</Td>
									<Td alignCenter small title='Status'>
										{item.divergencias > 0 ? (
											<span class='badge bg-danger' style={{ fontSize: 12, padding: 8 }}>{item.divergencias} {item.divergencias == 1 ? 'divergência' : 'divergências'}</span>
										) : (
											<span class='badge bg-success' style={{ fontSize: 12, padding: 8 }}>Correto</span>
										)}
									</Td>
									<Td alignCenter small minWidth={80}>
										<ButtonTable
											type={'warning'}
											icon={'fas fa-pencil-alt'}
											visible={this.state.linhaSelecionada == item.id}
											event={() => {
												this.props.setModoTela('cadastro', {
													...item,
													datahora_inventario: DateFormat.formatarDataHoraSqlParaTela(item.datahora_inventario)
												});
												this.props.initForm({
													...item,
													datahora_inventario: DateFormat.formatarDataHoraSqlParaTela(item.datahora_inventario)
												});
											}} />

										<ButtonTable
											type={'danger'}
											icon={'fas fa-trash-alt'}
											visible={this.state.linhaSelecionada == item.id}
											event={() => {
												this.props.setModoTela('exclusao', {
													...item,
													datahora_inventario: DateFormat.formatarDataHoraSqlParaTela(item.datahora_inventario)
												});
												this.props.initForm({
													...item,
													datahora_inventario: DateFormat.formatarDataHoraSqlParaTela(item.datahora_inventario)
												});
											}} />
									</Td>
								</Tr>
							))}
						</TBody>
					</Table>
				</ContentCardBody>
			</ContentCard>
		);
	}


}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	modoTela: state.acessorioInventario.modoTela,
	filtro: state.acessorioInventario.filtro,
	aguardando: state.acessorioInventario.aguardando,
	lista: state.acessorioInventario.lista.filter(item => 
		state.auth.usuarioCarregado && (state.auth.usuarioCarregado.acessorio_unidades || []).includes(item.id_unidade)
	),
	listaUnidade: state.acessorioInventario.listaUnidade.filter(item => 
		state.auth.usuarioCarregado && (state.auth.usuarioCarregado.acessorio_unidades || []).includes(item.id)
	)
});
const mapDispatchToProps = dispatch => bindActionCreators({ 
	setModoTela, initForm, setFiltro, salvar, excluir, getLista, 
	getListaUnidade
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(AcessorioInventario);
