import React, { useEffect, useState } from "react";
import leaflet from "leaflet";
import "leaflet/dist/leaflet.css";
import {
  MapContainer,
  TileLayer,
  Popup,
  Marker,
  useMapEvents,
  Tooltip
} from "react-leaflet";
import "./MapLeaflet.css";

import L from 'leaflet';

const iconTruckEsquerdaPrimary = new L.Icon({
  iconUrl: require('../../assets/map/marker_truck_esquerda_primary.png'),
  iconAnchor: null,
  popupAnchor: null,
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(32, 32)
});
const iconTruckDireitaPrimary = new L.Icon({
  iconUrl: require('../../assets/map/marker_truck_direita_primary.png'),
  iconAnchor: null,
  popupAnchor: null,
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(32, 32)
});

const iconTruckEsquerdaSuccess = new L.Icon({
  iconUrl: require('../../assets/map/marker_truck_esquerda_success.png'),
  iconAnchor: null,
  popupAnchor: null,
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(32, 32)
});

const iconTruckDireitaSuccess = new L.Icon({
  iconUrl: require('../../assets/map/marker_truck_direita_success.png'),
  iconAnchor: null,
  popupAnchor: null,
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(32, 32)
});

const iconTruckEsquerdaWarning = new L.Icon({
  iconUrl: require('../../assets/map/marker_truck_esquerda_warning.png'),
  iconAnchor: null,
  popupAnchor: null,
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(32, 32)
});
const iconTruckDireitaWarning = new L.Icon({
  iconUrl: require('../../assets/map/marker_truck_direita_warning.png'),
  iconAnchor: null,
  popupAnchor: null,
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(32, 32)
});

const iconTruckEsquerdaDanger = new L.Icon({
  iconUrl: require('../../assets/map/marker_truck_esquerda_danger.png'),
  iconAnchor: null,
  popupAnchor: null,
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(32, 32)
});
const iconTruckDireitaDanger = new L.Icon({
  iconUrl: require('../../assets/map/marker_truck_direita_danger.png'),
  iconAnchor: null,
  popupAnchor: null,
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(32, 32)
});

export function MapLeaflet(props) {
  const updateForm = props.onChange;

  //se vier coordenadas nas props atribui, senão atribui as coordenadas do centro do estado
  const [coordenadas, setCoordinates] = useState({
    latitude: props.latitude || -28.412779,
    longitude: props.longitude || -53.9072325,
    zoom: props.latitude && props.longitude ? 12 : 6,
  });

  //ao clicar no mapa altera o valor das coordenadas do state do componente e também no formulário
  const MapEvents = () => {
    useMapEvents({
      click(e) {
        // console.log(e);
        setCoordinates({
          latitude: e.latlng.lat + "",
          longitude: e.latlng.lng + "",
        });
        updateForm({
          latitude: e.latlng.lat + "",
          longitude: e.latlng.lng + "",
        });
      },
    });
    return false;
  };
  useEffect(() => {
    //configura o ícone de marcação
    delete leaflet.Icon.Default.prototype._getIconUrl;
    leaflet.Icon.Default.mergeOptions({
      iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
      iconUrl: require("leaflet/dist/images/marker-icon.png"),
      shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
    });

    //só pega a localização se estiver cadastrando uma integração nova
    if (!props.getCurrentPosition || !props.latitude || !props.longitude)
      return;
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setCoordinates({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
          zoom: 12,
        });
        updateForm({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
      },
      (error) => {
        // console.log(error);
        setCoordinates({
          latitude: -29.895971,
          longitude: -53.0622382,
          zoom: 6,
        });
      }
    );
  }, []);
  
  return (
    <div className="map-container border">
      <MapContainer
        center={[
          props.coordenadas && props.coordenadas[0] ? props.coordenadas[0].latitude : '-28.412779', 
          props.coordenadas && props.coordenadas[0] ? props.coordenadas[0].longitude : '-53.9072325'
        ]}
        zoom={props.zoom}
        scrollWheelZoom={true}
        style={{ height: "100%", width: "100%", zIndex: props.zIndex }}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <MapEvents />
        {(props.coordenadas || []).map(item => (
          <Marker key={item.id} 
            icon={getIcone(item.status, item.direcao)}
            position={[item.latitude, item.longitude]}>
            {item.titulo &&
            <Tooltip style={{ padding: 0, margin: 0 }}>
              <div 
                style={{
                  margin: -8
                }}>
                <div 
                  style={{
                    backgroundColor: getCor(item.status),
                    color: '#ffffff',
                    padding: 5,
                    fontSize: 14,
                    fontWeight: 'bold',
                    textAlign: 'center'
                  }}>
                  {item.titulo}
                </div>
                {item.body &&
                <div 
                  style={{
                    backgroundColor: '#ffffff',
                    color: '#000000',
                    padding: 5,
                    fontSize: 13
                  }}>
                  {item.body}
                </div>}
              </div>
            </Tooltip>}
          </Marker>
        ))}
      </MapContainer>
    </div>
  );
}

function getCor(status) {

    if (status == 'success') {
      return '#28a745';
    } else if (status == 'warning') {
      return '#cca20a';
    } else if (status == 'danger') {
      return '#E00914';
    } else {
      return '#1F3066';
    }

}

function getIcone(status, direcao) {

  if (status == 'success') {
    return direcao == 'S' ? iconTruckEsquerdaSuccess : iconTruckDireitaSuccess;
  } else if (status == 'warning') {
    return direcao == 'S' ? iconTruckEsquerdaWarning : iconTruckDireitaWarning;
  } else if (status == 'danger') {
    return direcao == 'S' ? iconTruckEsquerdaDanger : iconTruckDireitaDanger;
  } else {
    return direcao == 'S' ? iconTruckEsquerdaPrimary : iconTruckDireitaPrimary;
  }
  
}
