import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ContentCardFooter from '../common/template/contentCardFooter';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import LabelAndInputDateTime from '../common/form/labelAndInputDateTime';
import LabelAndCheckbox from '../common/form/labelAndCheckbox';
import Select from '../common/form/select';
import SelectAlteracao from '../common/form/selectAlteracao';
import { setError } from '../common/toast/toast';
import pdfAdd from '../assets/images/icone_add_pdf.png';
import pdfAdicionado from '../assets/images/icone_pdf_adicionado.png';
import imagemAdd from '../assets/images/icone_add_imagem.png';

import {
	setModoTelaComprovante, initFormComprovante
} from './documentoViagemActions';

class DocumentoViagemComprovanteForm extends Component {

	state = {

	}

    componentWillMount() {

		let initSeletorDocumento = () => {
			const fileSelectorDocumento = document.createElement('input');
			fileSelectorDocumento.setAttribute('type', 'file');
			fileSelectorDocumento.addEventListener("change", (data) => {

				let reader = new FileReader();
				let file = data && data.path && data.path[0] && data.path[0].files && data.path[0].files[0] ? data.path[0].files[0] : null;
				if (!file) {
					file = data && data.target && data.target.files && data.target.files[0] ? data.target.files[0] : null;
				}

				if (file) {

					if (!file.type.includes('application/pdf')
						&& !file.type.includes('image/png') && !file.type.includes('image/jpg') && !file.type.includes('image/jpeg')) {
						setError('Selecione apenas (.PDF, .PNG, .JPG e .JPEG)');
						return;
					}

					reader.onloadend = () => {

						let lista = (this.props.formularioValues.arquivo || []).map(item => item);
						lista.push({
							arquivo_base64: reader.result
						});

						this.props.initFormComprovante({
							...this.props.formularioValues,
							arquivo: lista
						});

						initSeletorDocumento();

					}

					reader.readAsDataURL(file);

				}
			});

			this.fileSelectorDocumento = fileSelectorDocumento;
		};

		initSeletorDocumento();

    }

    render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';

        return (
			<ContentCard>
				<Form event={this.props.handleSubmit}>
					<ContentCardBody>

						<Row>
							<Field
								name='id_documento'
								component={Select}
								options={this.props.listaDocumento}
								label='Documento'
								cols='12 12 12 12'
								placeholder='Selecione o documento'
								readOnly={readOnly} />

							<Field
								name='original'
								component={LabelAndCheckbox}
								label='Documento Original Recebido' placeholder='Informe se foi recebido'
								cols='12 12 6 6'
								readOnly={readOnly} />
						</Row>

						<Row>
							<Grid cols='12 12 12 12'>
								<label>Anexo</label>
							</Grid>
							<Grid cols='12 12 12 12'>
							<Row>
								{(this.props.formularioValues.arquivo || []).map(arquivo => (
									<div style={{ display: 'flex', flexDirection: 'column' }}>
										<span class="btn btn-outline-primary fileinput-button dz-clickable">
											<img src={arquivo.arquivo_base64 ? pdfAdicionado : (arquivo.pdf ? pdfAdicionado : pdfAdd)} style={{ height: 30 }} />
										</span>
										<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingRight: 2, marginTop: 4 }}>
											<button
												type='button'
												class='btn btn-flat btn-outline-primary'
												style={{ width: '48%' }}
												disabled={arquivo.arquivo_base64 ?true : false}
												onClick={() => {
													window.open(`https://api-letsarapremiacao.infinitum-ti.com/${arquivo.pdf}`, '_blank');
												}}>
												<i class='fas fa-download'></i>
											</button>

											<button
												type='button'
												class='btn btn-flat btn-outline-danger'
												style={{ width: '48%' }}
												disabled={readOnly}
												onClick={() => {
													this.props.initFormComprovante({
														...this.props.formularioValues,
														arquivo: this.props.formularioValues.arquivo.filter(item => item != arquivo)
													});
												}}>
												<i class='fas fa-trash-alt'></i>
											</button>
										</div>
									</div>
								))}
								{!readOnly ? (
									<span class="btn btn-outline-primary fileinput-button dz-clickable" onClick={() => this.fileSelectorDocumento.click()}>
										<img src={pdfAdd} style={{ height: 30 }} />
									</span>
								) : null}
								</Row>
							</Grid>
						</Row>

					</ContentCardBody>
					<ContentCardFooter>
						<Row alignCenter>
							<Grid cols='6 6 4 3'>
								{this.props.excluir ? (
									<Button
										text='Excluir'
										submit type={'danger'}
										icon={'fa fa-trash'} />
								) : (
								<Button
									text='Salvar'
									submit
									type={'success'}
									icon={'fa fa-check'} />
								)}
							</Grid>
							<Grid cols='6 6 4 3'>
								<Button
									text='Voltar'
									type={'warning'}
									icon={'fa fa-chevron-left'}
									event={() => this.props.setModoTelaComprovante('lista')} />
							</Grid>
						</Row>
					</ContentCardFooter>
				</Form>
			</ContentCard>
        )
    }

}

DocumentoViagemComprovanteForm = reduxForm({form: 'documentoViagemComprovanteForm', destroyOnUnmount: false})(DocumentoViagemComprovanteForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	formularioValues: getFormValues('documentoViagemComprovanteForm')(state),
	formularioViagemValues: getFormValues('documentoViagemForm')(state),
	listaDocumento: state.documentoViagem.listaDocumento
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTelaComprovante, initFormComprovante
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(DocumentoViagemComprovanteForm);
