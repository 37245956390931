import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ContentCardFooter from '../common/template/contentCardFooter';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import LabelAndInputDateTime from '../common/form/labelAndInputDateTime';
import Select from '../common/form/select';
import SelectAlteracao from '../common/form/selectAlteracao';

import {
	setModoTela, initForm, getListaItem, salvarItem, atualizarListaItem
} from './acessorioInventarioActions';

class AcessorioInventarioForm extends Component {

	state = {

	}

    componentWillMount() {
		this.props.getListaItem(this.props.formularioValues.id || 0);
    }

    render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';

        return (
			<>
				<ContentCard>
					<Form event={this.props.handleSubmit}>
						<ContentCardBody>
							<Row>

								<Field
									name='id_unidade'
									component={Select}
									options={this.props.listaUnidade}
									label='Unidade'
									cols='12 12 12 12'
									placeholder='Selecione a unidade'
									readOnly={this.props.formularioValues.id ? 'readonly' : readOnly} />
							</Row>
						</ContentCardBody>
						<ContentCardFooter>
							<Row alignCenter>
								{this.props.excluir &&
								<Grid cols='6 6 4 3'>									
									<Button
										text='Excluir'
										submit type={'danger'}
										icon={'fa fa-trash'} />
								</Grid>}
								{!this.props.formularioValues.id &&
								<Grid cols='6 6 4 3'>
									<Button
										text={this.props.formularioValues.id ? 'Salvar' : 'Iniciar'}
										submit
										type={'success'}
										icon={'fa fa-check'} />
								</Grid>}
								<Grid cols='6 6 4 3'>
									<Button
										text='Voltar'
										type={'warning'}
										icon={'fa fa-chevron-left'}
										event={() => this.props.setModoTela('lista')} />
								</Grid>
							</Row>
						</ContentCardFooter>
					</Form>
				</ContentCard>

				{this.props.formularioValues.id ? this.listaItens(readOnly) : null}
			</>
        )
    }

	listaItens(readOnly) {

		return (
			this.props.listaItem.map(item => (
				<ContentCard key={item.id} 
					style={{
						marginTop: 8,
						backgroundColor: item.alterando ? null : (item.quantidade_sistema != item.quantidade_real ? '#ff9c9c' : null) 
					}}>
					<ContentCardHeader>
						<h6 style={{ padding: 0, margin: 0, fontSize: 18 }}>{item.nome_tipo}</h6>
					</ContentCardHeader>
					<ContentCardBody>
						<Row style={{  }}>
							<LabelAndInputNumber
								label='Qtd. Sistema'
								placeholder='Informe a quantidade'
								cols='4 4 3 2'
								casas={0}
								readOnly={'readOnly'}
								value={item.quantidade_sistema}
								onChange={data => {}} />
							
							<LabelAndInputNumber
								label='Qtd. Real'
								placeholder='Informe a quantidade'
								cols='4 4 3 2'
								casas={0}
								readOnly={readOnly}
								value={item.quantidade_real}
								onChange={data => {
									this.props.atualizarListaItem(this.props.listaItem.map(itemAux => {
										if (itemAux.id == item.id) {
											return {
												...itemAux,
												quantidade_real: data.target.value,
												alterando: true
											};
										} else {
											return itemAux;
										}
									}));
								}} />

							{item.alterando &&							
							<Grid cols='4 4 3 2' style={{ marginTop: 28 }}>
								<Button
									text='Salvar'
									type={'success'}
									icon={'fa fa-chevron-left'}
									event={() => {
										this.props.salvarItem(item, this.props.listaItem);
									}} />
							</Grid>}
						</Row>
					</ContentCardBody>
				</ContentCard>
			))
		)
	}


}

AcessorioInventarioForm = reduxForm({form: 'acessorioInventarioForm', destroyOnUnmount: false})(AcessorioInventarioForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	formularioValues: getFormValues('acessorioInventarioForm')(state),
	registro: state.acessorioInventario.registro,
	listaUnidade: state.acessorioInventario.listaUnidade.filter(item => 
		state.auth.usuarioCarregado && (state.auth.usuarioCarregado.acessorio_unidades || []).includes(item.id)
	),
	listaItem: state.acessorioInventario.listaItem
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, initForm, getListaItem, salvarItem, atualizarListaItem
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(AcessorioInventarioForm);
