import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ContentCardFooter from '../common/template/contentCardFooter';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import LabelAndInputDateTime from '../common/form/labelAndInputDateTime';
import Select from '../common/form/select';
import SelectDefault from '../common/form/selectDefault';
import SelectAlteracao from '../common/form/selectAlteracao';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import DateFormat from '../common/dateFormat/DateFormat';
import FormatUtils from '../common/formatUtils/FormatUtils';
import FormComprovante from './documentoViagemComprovanteForm';
import FormOcorrencia from '../ocorrencia/ocorrenciaForm';
import FormPalete from './viagemPaleteForm';
import { setError } from '../common/toast/toast';
import pdfAdd from '../assets/images/icone_add_pdf.png';
import pdfAdicionado from '../assets/images/icone_pdf_adicionado.png';
import imagemAdd from '../assets/images/icone_add_imagem.png';

import {
	setModoTela, initForm, buscaInformacaoSolicitacao,
	setModoTelaComprovante, initFormComprovante, salvarComprovante, validarComprovanteFinanceiro , excluirComprovante, getListaComprovante, setListaComprovante,
	setModoTelaOcorrencia, getListaOcorrencia, getListaAberta, getListaComprovacao, getListaComprovada,
	setModoTelaPalete, initFormPalete, salvarPalete, finalizarPalete, reabrirPalete
} from './documentoViagemActions';

import {
	salvar as salvarOcorrencia, excluir as excluirOcorrencia, initForm as initFormOcorrencia, 
	getListaTipo as getListaTipoOcorrencia, getListaMotorista as getListaMotoristaOcorrencia,
	getListaGravidade as getListaGravidadeOcorrencia, getListaLocal as getListaLocalOcorrencia, 
	getListaResponsabilidade as getListaResponsabilidadeOcorrencia, getListaTipoCusto as getListaTipoCustoOcorrencia,
	getListaStatus as getListaStatusOcorrencia, getListaAcao as getListaAcaoOcorrencia, getListaUsuario as getListaUsuarioOcorrencia
} from '../ocorrencia/ocorrenciaActions';
import ColorUtils from '../common/colorUtils/ColorUtils';

class DocumentoViagemManualForm extends Component {

	state = {

	}

	componentDidMount() {

	}

    render() {

		let readOnly = null;

        return (
			<div>
				<ContentCard>
					<Form event={this.props.handleSubmit}>

						<ContentCardBody>

							<>
								<Row>

									<Table responsive tableBordered>
										<THead>
											<Tr>
												<Th alignCenter width={80}>Alertas</Th>
												<Th alignCenter width={10}>Sol.</Th>
												<Th alignCenter width={10}>Rom.</Th>
												<Th alignCenter width={100}>Emissão</Th>
												<Th alignCenter width={100}>Descarga</Th>
												<Th alignCenter width={10}>Tipo Doc</Th>
												<Th alignCenter width={10}>Documento</Th>
												<Th alignCenter width={10}>MIC</Th>
												<Th alignCenter width={10}>Shipping</Th>
												<Th alignCenter width={10}>Veículo</Th>
												<Th alignCenter width={200}>Motorista Entrega</Th>
												<Th alignCenter width={10}>Tipo</Th>
											</Tr>
										</THead>
										<TBody>
											<Tr>	
												<Td alignCenter minWidth={80}>
													{this.props.formularioValues.ocorrencia_aberta ? (
														<i class='fas fa-times-circle' style={{ color: '#ed0707', fontSize: 16, paddingTop: 4, paddingLeft: 4 }} 
															title={`Existe ocorrência aberta`}>
														</i>
													) : (this.props.formularioValues.ocorrencia_finalizada ? (
														<i class='fas fa-times-circle' style={{ color: '#a1a1a1', fontSize: 16, paddingTop: 4, paddingLeft: 4 }} 
															title={`Existe ocorrência finalizada`}>
														</i>
													) : null)}
													{this.props.formularioValues.excecao &&
													<i class='fas fa-exclamation-circle' style={{ color: '#c49b04', fontSize: 16, paddingTop: 4, paddingLeft: 4 }}
														title={this.props.formularioValues.excecao ? `Exceção: ${this.props.formularioValues.excecao}` : ''}>
													</i>}
													{this.props.formularioValues.qtd_digital_selecionado > 0 &&
													<i class='fas fa-paperclip' style={{ marginLeft: 4, paddingTop: 4, fontSize: 16 }} title={'Digital anexado'} />}
													{this.props.formularioValues.faturado &&
													<i class='fas fa-dollar-sign' style={{ marginLeft: 4, paddingTop: 4, fontSize: 16 }} title={'Documento faturado'} />}
												</Td>
												<Td alignCenter minWidth={10} small>{this.props.formularioValues.solicitacao}</Td>
												<Td alignCenter minWidth={10}  small>{this.props.formularioValues.romaneio}</Td>
												<Td alignCenter minWidth={100} small>{this.props.formularioValues.datahora_viagem}</Td>
												<Td alignCenter minWidth={100} small>{this.props.formularioValues.datahora_descarga}</Td>
												<Td alignCenter minWidth={10} small>{this.props.formularioValues.tipo_documento}</Td>
												<Td alignCenter minWidth={10} small>{this.props.formularioValues.numero_documento}</Td>
												<Td alignCenter minWidth={10} small>{this.props.formularioValues.mic_documento}</Td>
												<Td alignCenter minWidth={10} small>{this.props.formularioValues.shipping}</Td>
												<Td alignCenter minWidth={10} small>{this.props.formularioValues.cavalo}</Td>
												<Td alignCenter minWidth={200} noWrap small>{this.props.formularioValues.nome_motorista}</Td>
												<Td alignCenter minWidth={10} small>{this.props.formularioValues.tipo_veiculo}</Td>
											</Tr>
										</TBody>
									</Table>
									<Table responsive tableBordered>
										<THead>
											<Tr>
												<Th alignCenter width={200}>Cliente</Th>
												<Th alignCenter width={200}>Recebedor</Th>
												<Th alignCenter width={200}>Local Coleta</Th>
												<Th alignCenter width={200}>Local Entrega</Th>
												<Th alignCenter width={200}>Operação</Th>
											</Tr>
										</THead>
										<TBody>
											<Tr>
												<Td alignCenter minWidth={200} noWrap small>{this.props.formularioValues.nome_cliente}</Td>
												<Td alignCenter minWidth={200} noWrap small>{this.props.formularioValues.nome_recebedor}</Td>
												<Td alignCenter minWidth={200} noWrap small>{this.props.formularioValues.cidade_coleta} - {this.props.formularioValues.estado_coleta} - {this.props.formularioValues.pais_coleta}</Td>
												<Td alignCenter minWidth={200} noWrap small>{this.props.formularioValues.cidade_entrega} - {this.props.formularioValues.estado_entrega} - {this.props.formularioValues.pais_entrega}</Td>
												<Td alignCenter minWidth={200} noWrap small backgroundColor={this.props.formularioValues.cor_perfil} color={ColorUtils.getCorFonte(this.props.formularioValues.cor_perfil)}>{`${this.props.formularioValues.nome_operacao }${this.props.formularioValues.nome_perfil ? ' - ' : ''}${this.props.formularioValues.nome_perfil || ''}`}</Td>
											</Tr>
										</TBody>
									</Table>
								</Row>

								<Row>										
									<Field
										name='datahora_viagem'
										component={LabelAndInputMask}
										label='Data/Hora Viagem' placeholder='Informe a data e hora da viagem'
										cols='12 6 4 3'
										mask='99/99/9999 99:99'
										readOnly={readOnly} />
										
									<Field
										name='datahora_carga'
										component={LabelAndInputMask}
										label='Data/Hora Carga' placeholder='Informe a data e hora da carga'
										cols='12 6 4 3'
										mask='99/99/9999 99:99'
										readOnly={readOnly} />
										
									<Field
										name='datahora_descarga'
										component={LabelAndInputMask}
										label='Data/Hora Descarga' placeholder='Informe a data e hora da descarga'
										cols='12 6 4 3'
										mask='99/99/9999 99:99'
										readOnly={readOnly} />
								</Row>
								
								<Row>
									<Field
										name='tipo_documento'
										component={LabelAndInput}
										label='Tipo Documento' placeholder='Informe o tipo'
										cols='12 3 1 1'
										readOnly={readOnly} />

									<Field
										name='numero_documento'
										component={LabelAndInput}
										label='Número' placeholder='Informe o número'
										cols='12 4 3 3'
										readOnly={readOnly} />

									<Field
										name='mic_documento'
										component={LabelAndInput}
										label='MIC' placeholder='Informe o MIC'
										cols='12 5 3 3'
										readOnly={readOnly} />

									<Field
										name='romaneio'
										component={LabelAndInput}
										label='Romaneio' placeholder='Informe o romaneio'
										cols='12 6 4 2'
										readOnly={readOnly} />
										
								</Row>
									
								<Row>
									<Field
										name='tipo_veiculo'
										component={LabelAndInput}
										label='Tipo Veículo' placeholder='Informe o tipo'
										cols='12 6 2 2'
										readOnly={readOnly} />

									<Field
										name='cavalo'
										component={LabelAndInput}
										label='Cavalo' placeholder='Informe a placa'
										cols='12 6 2 2'
										readOnly={readOnly} />

									<Field
										name='carreta1'
										component={LabelAndInput}
										label='Carreta 1' placeholder='Informe a placa'
										cols='12 6 2 2'
										readOnly={readOnly} />

									<Field
										name='carreta2'
										component={LabelAndInput}
										label='Carreta 2' placeholder='Informe a placa'
										cols='12 6 2 2'
										readOnly={readOnly} />

									<Field
										name='nome_motorista'
										component={LabelAndInput}
										label='Motorista' placeholder='Informe o motorista'
										cols='12 6 4 4'
										readOnly={readOnly} />

								</Row>
							</>
						</ContentCardBody>

						<ContentCardFooter>
							<Row alignCenter>
								<Grid cols='6 6 4 3'>
									<Button
										text='Salvar'
										submit
										type={'success'}
										icon={'fa fa-check'} />
								</Grid>
								<Grid cols='6 6 4 3'>
									<Button
										text='Voltar'
										type={'warning'}
										icon={'fa fa-chevron-left'}
										event={() => {
											this.props.setModoTela('lista');
											this.props.getListaAberta();
											this.props.getListaComprovacao();
											this.props.getListaComprovada();
										}} />
								</Grid>
							</Row>
						</ContentCardFooter>

					</Form>
				</ContentCard>
				
			</div>
        )
    }

}

DocumentoViagemManualForm = reduxForm({form: 'documentoViagemForm', destroyOnUnmount: false})(DocumentoViagemManualForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	formularioValues: getFormValues('documentoViagemForm')(state),
	registro: state.documentoViagem.registro,
	listaComprovante: state.documentoViagem.listaComprovante,
	listaOcorrencia: state.documentoViagem.listaOcorrencia,
	listaOperacao: state.documentoViagem.listaOperacao,
	listaDocumento: state.documentoViagem.listaDocumento
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, initForm, buscaInformacaoSolicitacao, getListaAberta, getListaComprovacao, getListaComprovada,
	setModoTelaComprovante, initFormComprovante, salvarComprovante, validarComprovanteFinanceiro, excluirComprovante, getListaComprovante, setListaComprovante,
	setModoTelaOcorrencia, initFormOcorrencia, salvarOcorrencia, excluirOcorrencia, getListaOcorrencia,
	getListaTipoOcorrencia, getListaMotoristaOcorrencia,
	getListaGravidadeOcorrencia, getListaLocalOcorrencia, 
	getListaResponsabilidadeOcorrencia, getListaTipoCustoOcorrencia,
	getListaStatusOcorrencia, getListaAcaoOcorrencia, getListaUsuarioOcorrencia,
	setModoTelaPalete, initFormPalete, salvarPalete, finalizarPalete, reabrirPalete
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(DocumentoViagemManualForm);
