import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import DateFormat from '../common/dateFormat/DateFormat';
import FormatUtils from '../common/formatUtils/FormatUtils';

export function setModoTela(modo, registro = {}) {
    return {
        type: 'PREMIO_FAIXA_MODO_TELA',
        payload: {
			modo: modo,
			registro: registro
		}
    };
}
export function initForm(registro = {}) {
    return [
        initialize('premioFaixaForm', registro)
    ];
}

export function getLista() {
	return (dispatch, getState) => {
    	axios.get(`${consts.API_URL}/premioFaixa`)
		.then(resp => {
			dispatch({
		        type: 'PREMIO_FAIXA_LISTADO',
		        payload: resp
		    });
		}).catch(e => {
			setErroAPI(e);
		});
	}
}

export function salvar(registro) {
    return (dispatch, getState) => {
		if (!registro.id) {
	        axios.post(`${consts.API_URL}/premioFaixa`, {
				...registro,
				cpf: String(registro.cpf || '').split(".").join("").replace("-", "").replace("/", ""),
				valor_km: FormatUtils.formatarValorBanco(registro.valor_km, 4),
				data_inicio: DateFormat.formatarDataTelaParaSql(registro.data_inicio),
				data_fim: DateFormat.formatarDataTelaParaSql(registro.data_fim),                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                   
				id_usuario_inclusao: getState().auth.usuario.id,
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		} else {
			axios.put(`${consts.API_URL}/premioFaixa`, {
				...registro,
				cpf: String(registro.cpf || '').split(".").join("").replace("-", "").replace("/", ""),
				valor_km: FormatUtils.formatarValorBanco(registro.valor_km, 4),
				data_inicio: DateFormat.formatarDataTelaParaSql(registro.data_inicio),
				data_fim: DateFormat.formatarDataTelaParaSql(registro.data_fim),
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		}
    };
}

export function excluir(registro) {
    return (dispatch, getState) => {
        axios.delete(`${consts.API_URL}/premioFaixa?id=${registro.id}&id_usuario_alteracao=${getState().auth.usuario.id}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista());
			dispatch(setModoTela('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function getListaTipo() {
    const request = axios.get(`${consts.API_URL}/premioTipo/listarSelect`);
    return {
        type: 'PREMIO_FAIXA_TIPO_SELECT_LISTADO',
        payload: request
    };
}

export function getListaGrupo() {
    const request = axios.get(`${consts.API_URL}/grupo/listarSelect`);
    return {
        type: 'PREMIO_FAIXA_GRUPO_SELECT_LISTADO',
        payload: request
    };
}
