import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import Form from './ocorrenciaTipoForm';
import DateFormat from '../common/dateFormat/DateFormat';
import FormatUtils from '../common/formatUtils/FormatUtils';
import ReactExport from 'react-export-excel-fixed-xlsx';

import { setModoTela, initForm, salvar, excluir, getLista, getListaPerfil, getListaCategoria } from './ocorrenciaTipoActions';
import Select from '../common/form/select';

class OcorrenciaTipo extends Component {

	state = {
		linhaSelecionada: null,
		id_vigencia: 1
	}

    componentWillMount() {
        this.props.getLista();
		this.props.getListaPerfil();
		this.props.getListaCategoria();
    }

    render() {
        return (
            <div>
                <Content>
					{this.props.modoTela == 'cadastro' ? (
						<Form onSubmit={this.props.salvar} />
					) : null}
					{this.props.modoTela == 'exclusao' ? (
						<Form excluir onSubmit={this.props.excluir} />
					) : null}
					{this.props.modoTela == 'lista' ? this.lista() : null}
                </Content>
            </div>
        )
    }

	lista() {

		const ExcelFile = ReactExport.ExcelFile;
		const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
		const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

		//Infinitum, Welinson e Paula
		let permiteEditar = this.props.sessao && (this.props.sessao.id == 1 || this.props.sessao.id == 16 || this.props.sessao.id == 17);

		let lista = this.props.lista.filter(item => {

			if (this.state.pesquisar
				&& !(`${item.nome || ''} ${item.nome_perfil || ''} ${(item.perfis || []).map(perfil => this.props.listaPerfil.filter(itemPerfil => itemPerfil.id == perfil).map(itemPerfil => itemPerfil.valor).join('')).join(' | ') || ''}
					${item.nome_usuario_alteracao || ''} ${DateFormat.formatarDataSqlParaTela(item.data_inicio)} ${DateFormat.formatarDataSqlParaTela(item.data_fim)}
				`).toUpperCase().includes(this.state.pesquisar.toUpperCase())) {
				return false;
			}

			if ((this.state.id_vigencia == 1 && !item.vigente) || (this.state.id_vigencia == 2 && item.vigente)) {
				return false;
			}

			return true;
		});

		return (
			<ContentCard>
				<ContentCardHeader>
					<Row>
						<Grid cols='6 6 3 2'>
							<Button
								text='Adicionar'
								type={'success'}
								icon={'fa fa-plus'}
								disabled={!permiteEditar}
								event={() => {
									this.props.setModoTela('cadastro', { permite_finalizar_comprovante: true });
									this.props.initForm({ permite_finalizar_comprovante: true });
								}} />
						</Grid>
						<Grid cols='6 6 3 2'>
							<ExcelFile
								filename={'Tipo de Ocorrência'}
								element={
									<Button
										text='Exportar'
										type={'primary'}
										icon={'fa fa-plus'} />
								}>
								<ExcelSheet data={lista.map(item => ({
									...item,
									perfis: (item.perfis || []).map(perfil => this.props.listaPerfil.filter(itemPerfil => itemPerfil.id == perfil).map(itemPerfil => itemPerfil.valor).join('')).join(' | '),
									percentual_desconto: `${FormatUtils.formatarValorTela(item.percentual_desconto, 0)} %`,
									multiplica_percentual: item.multiplica_percentual ? 'SIM' : 'NÃO',
									nome_usuario_alteracao: item.nome_usuario_alteracao,
									data_inicio: DateFormat.formatarDataSqlParaTela(item.data_inicio),
									data_fim: DateFormat.formatarDataSqlParaTela(item.data_fim),
									nome_categoria: item.nome_categoria || 'Sem Categoria'
								}))} name='Tipo de Ocorrência'>
									<ExcelColumn label='Nome' value='nome' />
									<ExcelColumn label='Perfil Antigo' value='nome_perfil' />
									<ExcelColumn label='Perfis' value='perfis' />
									<ExcelColumn label='Percentual de Desconto' value='percentual_desconto' />
									<ExcelColumn label='Multiplica Percentual' value='multiplica_percentual' />
									<ExcelColumn label='Usuário Alteração' value='nome_usuario_alteracao' />
									<ExcelColumn label='Início Vigência' value='data_inicio' />
									<ExcelColumn label='Fim Vigência' value='data_fim' />
									<ExcelColumn label='Categoria' value='nome_categoria' />
								</ExcelSheet>
							</ExcelFile>
						</Grid>
					</Row>
				</ContentCardHeader>
				<ContentCardBody>
					<Row>
						<Select
							options={[{ id: 1, valor: 'Vigente' }, { id: 2, valor: 'Não Vigente' }, { id: 3, valor: 'Ambos' }]}
							label='Vigência'
							cols='12 12 6 4 '
							placeholder='Selecione'
							value={this.state.id_vigencia}
							onChange={data => {
								this.setState({ ...this.state, id_vigencia: data || 3 });
							}} />
					</Row>

					<input className='form-control'
						 placeholder='Pesquisar'
						 type='text'
						 value={this.state.pesquisar}
						 onChange={e => {
							 this.setState({ ...this.state, pesquisar: e.target.value });
						 }} />

					<Table responsive>
						<THead>
							<Tr>
								<Th>Nome</Th>
								<Th alignCenter>Perfil Antigo</Th>
								<Th alignCenter>Perfis</Th>
								<Th alignCenter>Percentual de Desconto</Th>
								<Th alignCenter>Multiplica Percentual</Th>
								<Th alignCenter>Usuário Alteração</Th>
								<Th alignCenter>Início Vigência</Th>
								<Th alignCenter>Fim Vigência</Th>
								<Th alignCenter title={'Categoria'}>Cat.</Th>
								<Th></Th>
							</Tr>
						</THead>
						<TBody>
							{lista.map(item => (
								<Tr key={item.id} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
									onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
									onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
									backgroundColor={item.vigente ? '#dce6d1' : null} >
									<Td>{item.nome}</Td>
									<Td alignCenter>{item.nome_perfil}</Td>
									<Td alignCenter>{(item.perfis || []).map(perfil => this.props.listaPerfil.filter(itemPerfil => itemPerfil.id == perfil).map(itemPerfil => itemPerfil.valor).join('')).join(' | ')}</Td>
									<Td alignCenter>{FormatUtils.formatarValorTela(item.percentual_desconto, 0)} %</Td>
									<Td alignCenter>{item.multiplica_percentual ? 'SIM' : 'NÃO'}</Td>
									<Td alignCenter>{item.nome_usuario_alteracao}</Td>
									<Td alignCenter>{DateFormat.formatarDataSqlParaTela(item.data_inicio)}</Td>
									<Td alignCenter>{DateFormat.formatarDataSqlParaTela(item.data_fim)}</Td>
									<Td alignCenter title={item.nome_categoria || 'Sem Categoria'}>
										<i class='fas fa-circle' title={item.nome_categoria || 'Sem Categoria'} style={{ fontSize: 18, color: item.cor_categoria || '#bfbfbf' }}></i>
									</Td>
									<Td alignRight minWidth={150}>
										<ButtonTable
											type={'primary'}
											icon={'fas fa-copy'}
											visible={this.state.linhaSelecionada == item.id}
											disabled={!permiteEditar}
											event={() => {
												this.props.salvar({
													...item,
													id: null,
													percentual_desconto: FormatUtils.formatarValorTela(item.percentual_desconto, 2),
													data_inicio: DateFormat.formatarDataSqlParaTela(DateFormat.getDataPosterior(1)),
													data_fim: DateFormat.formatarDataSqlParaTela(DateFormat.getDataPosterior(1))
												});
											}} />

										<ButtonTable
											type={'warning'}
											icon={'fas fa-pencil-alt'}
											visible={this.state.linhaSelecionada == item.id}
											disabled={!permiteEditar}
											event={() => {
												this.props.setModoTela('cadastro', {
													...item,
													percentual_desconto: FormatUtils.formatarValorTela(item.percentual_desconto, 2),
													data_inicio: DateFormat.formatarDataSqlParaTela(item.data_inicio),
													data_fim: DateFormat.formatarDataSqlParaTela(item.data_fim)
												});
												this.props.initForm({
													...item,
													percentual_desconto: FormatUtils.formatarValorTela(item.percentual_desconto, 2),
													data_inicio: DateFormat.formatarDataSqlParaTela(item.data_inicio),
													data_fim: DateFormat.formatarDataSqlParaTela(item.data_fim)
												});
											}} />

										<ButtonTable
											type={'danger'}
											icon={'fas fa-trash-alt'}
											visible={this.state.linhaSelecionada == item.id}
											disabled={!permiteEditar}
											event={() => {
												this.props.setModoTela('exclusao', {
													...item,
													percentual_desconto: FormatUtils.formatarValorTela(item.percentual_desconto, 2),
													data_inicio: DateFormat.formatarDataSqlParaTela(item.data_inicio),
													data_fim: DateFormat.formatarDataSqlParaTela(item.data_fim)
												});
												this.props.initForm({
													...item,
													percentual_desconto: FormatUtils.formatarValorTela(item.percentual_desconto, 2),
													data_inicio: DateFormat.formatarDataSqlParaTela(item.data_inicio),
													data_fim: DateFormat.formatarDataSqlParaTela(item.data_fim)
												});
											}} />
									</Td>
								</Tr>
							))}
						</TBody>
					</Table>
				</ContentCardBody>
			</ContentCard>
		);
	}


}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	modoTela: state.ocorrenciaTipo.modoTela,
	lista: state.ocorrenciaTipo.lista,
	listaPerfil: state.ocorrenciaTipo.listaPerfil
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, initForm, salvar, excluir, getLista, getListaPerfil, getListaCategoria }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(OcorrenciaTipo);
