import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ContentCardFooter from '../common/template/contentCardFooter';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import LabelAndInputDateTime from '../common/form/labelAndInputDateTime';
import Select from '../common/form/select';
import SelectAlteracao from '../common/form/selectAlteracao';

import {
	setModoTelaItem, initFormItem
} from './premiacaoActions';

class PremiacaoMotoristaForm extends Component {

	state = {

	}

    componentWillMount() {

    }

    render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';

        return (
			<ContentCard>
				<Form event={this.props.handleSubmit}>
					<ContentCardBody>

						<Row>
							<Select
								name='id_embalagem'
								options={this.props.listaEmbalagem.filter(embalagem => embalagem.fornecedores.filter(item => item == this.props.formularioViagemValues.id_fornecedor_origem || item == this.props.formularioViagemValues.id_fornecedor_destino).length > 0)}
								label='Embalagem'
								cols='12 12 12 12'
								placeholder='Selecione a embalagem'
								readOnly={readOnly}
								value={this.props.formularioValues.id_embalagem}
								onChange={data => {
									if (data) {
										this.props.initFormItem({
											...this.props.formularioValues,
											id_embalagem: data
										});
									}
								}} />

							<Field
								name='quantidade'
								component={LabelAndInputNumber}
								label='Quantidade' placeholder='Informe a quantidade'
								cols='12 12 6 6'
								casas={0}
								readOnly={readOnly} />

						</Row>
					</ContentCardBody>
					<ContentCardFooter>
						<Row alignCenter>
							<Grid cols='6 6 4 3'>
								{this.props.excluir ? (
									<Button
										text='Excluir'
										submit type={'danger'}
										icon={'fa fa-trash'} />
								) : (
								<Button
									text='Salvar'
									submit
									type={'success'}
									icon={'fa fa-check'} />
								)}
							</Grid>
							<Grid cols='6 6 4 3'>
								<Button
									text='Voltar'
									type={'warning'}
									icon={'fa fa-chevron-left'}
									event={() => this.props.setModoTelaItem('lista')} />
							</Grid>
						</Row>
					</ContentCardFooter>
				</Form>
			</ContentCard>
        )
    }

}

PremiacaoMotoristaForm = reduxForm({form: 'premiacaoMotoristaForm', destroyOnUnmount: false})(PremiacaoMotoristaForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	formularioValues: getFormValues('premiacaoMotoristaForm')(state),
	formularioViagemValues: getFormValues('premiacaoForm')(state),
	listaEmbalagem: state.premiacao.listaEmbalagem
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTelaItem, initFormItem
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(PremiacaoMotoristaForm);
