import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ContentCardFooter from '../common/template/contentCardFooter';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import LabelAndInputDateTime from '../common/form/labelAndInputDateTime';
import Select from '../common/form/select';
import SelectDefault from '../common/form/selectDefault';
import SelectAlteracao from '../common/form/selectAlteracao';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import DateFormat from '../common/dateFormat/DateFormat';
import { setError } from '../common/toast/toast';
import pdfAdd from '../assets/images/icone_add_pdf.png';
import pdfAdicionado from '../assets/images/icone_pdf_adicionado.png';
import imagemAdd from '../assets/images/icone_add_imagem.png';

import {
	setModoTelaPalete as setModoTela, initFormPalete as initForm
} from './documentoViagemActions';

class ViagemPaleteForm extends Component {

	state = {

	}

	componentDidMount() {

		const fileSelectorSolicitacaoAnexo = document.createElement('input');
		fileSelectorSolicitacaoAnexo.setAttribute('type', 'file');
		fileSelectorSolicitacaoAnexo.addEventListener("change", (data) => {

			let reader = new FileReader();
			let file = data && data.path && data.path[0] && data.path[0].files && data.path[0].files[0] ? data.path[0].files[0] : null;
			if (!file) {
				file = data && data.target && data.target.files && data.target.files[0] ? data.target.files[0] : null;
			}

			if (file) {

				if (!file.type.includes('application/pdf')
					&& !file.type.includes('image/png') && !file.type.includes('image/jpg') && !file.type.includes('image/jpeg')) {
					setError('Selecione apenas (.PDF, .PNG, .JPG e .JPEG)');
					return;
				}

				reader.onloadend = () => {

					let lista = (this.props.formularioValues.solicitacao_anexo || []).map(item => item);
					lista.push({
						solicitacao_anexo_base64: reader.result
					});

					this.props.initForm({
						...this.props.formularioValues,
						solicitacao_anexo: lista
					});

				}

				reader.readAsDataURL(file);

			}
		});

		this.fileSelectorSolicitacaoAnexo = fileSelectorSolicitacaoAnexo;

		const fileSelectorCteOrigemAnexo = document.createElement('input');
		fileSelectorCteOrigemAnexo.setAttribute('type', 'file');
		fileSelectorCteOrigemAnexo.addEventListener("change", (data) => {

			let reader = new FileReader();
			let file = data && data.path && data.path[0] && data.path[0].files && data.path[0].files[0] ? data.path[0].files[0] : null;
			if (!file) {
				file = data && data.target && data.target.files && data.target.files[0] ? data.target.files[0] : null;
			}

			if (file) {

				if (!file.type.includes('application/pdf')
					&& !file.type.includes('image/png') && !file.type.includes('image/jpg') && !file.type.includes('image/jpeg')) {
					setError('Selecione apenas (.PDF, .PNG, .JPG e .JPEG)');
					return;
				}

				reader.onloadend = () => {

					let lista = (this.props.formularioValues.cte_origem_anexo || []).map(item => item);
					lista.push({
						cte_origem_anexo_base64: reader.result
					});

					this.props.initForm({
						...this.props.formularioValues,
						cte_origem_anexo: lista
					});

				}

				reader.readAsDataURL(file);

			}
		});

		this.fileSelectorCteOrigemAnexo = fileSelectorCteOrigemAnexo;

		const fileSelectorNotaFiscalAnexo = document.createElement('input');
		fileSelectorNotaFiscalAnexo.setAttribute('type', 'file');
		fileSelectorNotaFiscalAnexo.addEventListener("change", (data) => {

			let reader = new FileReader();
			let file = data && data.path && data.path[0] && data.path[0].files && data.path[0].files[0] ? data.path[0].files[0] : null;
			if (!file) {
				file = data && data.target && data.target.files && data.target.files[0] ? data.target.files[0] : null;
			}

			if (file) {

				if (!file.type.includes('application/pdf')
					&& !file.type.includes('image/png') && !file.type.includes('image/jpg') && !file.type.includes('image/jpeg')) {
					setError('Selecione apenas (.PDF, .PNG, .JPG e .JPEG)');
					return;
				}

				reader.onloadend = () => {

					let lista = (this.props.formularioValues.nota_fiscal_paletes_anexo || []).map(item => item);
					lista.push({
						nota_fiscal_paletes_anexo_base64: reader.result
					});

					this.props.initForm({
						...this.props.formularioValues,
						nota_fiscal_paletes_anexo: lista
					});

				}

				reader.readAsDataURL(file);

			}
		});

		this.fileSelectorNotaFiscalAnexo = fileSelectorNotaFiscalAnexo;

		const fileSelectorNotaFiscalProdutoAnexo = document.createElement('input');
		fileSelectorNotaFiscalProdutoAnexo.setAttribute('type', 'file');
		fileSelectorNotaFiscalProdutoAnexo.addEventListener("change", (data) => {

			let reader = new FileReader();
			let file = data && data.path && data.path[0] && data.path[0].files && data.path[0].files[0] ? data.path[0].files[0] : null;
			if (!file) {
				file = data && data.target && data.target.files && data.target.files[0] ? data.target.files[0] : null;
			}

			if (file) {

				if (!file.type.includes('application/pdf')
					&& !file.type.includes('image/png') && !file.type.includes('image/jpg') && !file.type.includes('image/jpeg')) {
					setError('Selecione apenas (.PDF, .PNG, .JPG e .JPEG)');
					return;
				}

				reader.onloadend = () => {

					let lista = (this.props.formularioValues.nota_fiscal_produtos_anexo || []).map(item => item);
					lista.push({
						nota_fiscal_produtos_anexo_base64: reader.result
					});

					this.props.initForm({
						...this.props.formularioValues,
						nota_fiscal_produtos_anexo: lista
					});

				}

				reader.readAsDataURL(file);

			}
		});

		this.fileSelectorNotaFiscalProdutoAnexo = fileSelectorNotaFiscalProdutoAnexo;

		const fileSelectorValePaleteAnexo = document.createElement('input');
		fileSelectorValePaleteAnexo.setAttribute('type', 'file');
		fileSelectorValePaleteAnexo.addEventListener("change", (data) => {

			let reader = new FileReader();
			let file = data && data.path && data.path[0] && data.path[0].files && data.path[0].files[0] ? data.path[0].files[0] : null;
			if (!file) {
				file = data && data.target && data.target.files && data.target.files[0] ? data.target.files[0] : null;
			}

			if (file) {

				if (!file.type.includes('application/pdf')
					&& !file.type.includes('image/png') && !file.type.includes('image/jpg') && !file.type.includes('image/jpeg')) {
					setError('Selecione apenas (.PDF, .PNG, .JPG e .JPEG)');
					return;
				}

				reader.onloadend = () => {

					let lista = (this.props.formularioValues.vale_palete_anexo || []).map(item => item);
					lista.push({
						vale_palete_anexo_base64: reader.result
					});

					this.props.initForm({
						...this.props.formularioValues,
						vale_palete_anexo: lista
					});

				}

				reader.readAsDataURL(file);

			}
		});

		this.fileSelectorValePaleteAnexo = fileSelectorValePaleteAnexo;

	}

    componentWillMount() {

    }

    render() {

		let readOnly = this.props.excluir || this.props.visualizacao || (this.props.formularioValues && this.props.formularioValues.datahora_finalizacao) ? 'readOnly' : '';

        return (
			<div>
				<ContentCard>
					<ContentCardHeader>
						<label>Paletes</label>
					</ContentCardHeader>
					<Form event={this.props.handleSubmit}>
						<ContentCardBody>
							<Row>
								<Grid cols='12 12 12 12'>
									<label>Solicitação</label>
								</Grid>
								{(this.props.formularioValues.solicitacao_anexo || []).map(arquivo => (
									<div style={{ display: 'flex', flexDirection: 'column' }}>
										<span class="btn btn-outline-primary fileinput-button dz-clickable">
											<img src={arquivo.solicitacao_anexo_base64 ? pdfAdicionado : (arquivo.pdf ? pdfAdicionado : pdfAdd)} style={{ height: 30 }} />
										</span>
										<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingRight: 2, marginTop: 4 }}>
											<button
												type='button'
												class='btn btn-flat btn-outline-primary'
												style={{ width: '48%' }}
												disabled={arquivo.solicitacao_anexo_base64 ? true : false}
												onClick={() => {
													window.open(`https://api-paletes.infinitum-ti.com/${arquivo.pdf}`, '_blank');
												}}>
												<i class='fas fa-download'></i>
											</button>

											<button
												type='button'
												class='btn btn-flat btn-outline-danger'
												style={{ width: '48%' }}
												disabled={readOnly}
												onClick={() => {
													this.props.initForm({
														...this.props.formularioValues,
														solicitacao_anexo: this.props.formularioValues.solicitacao_anexo.filter(item => item != arquivo)
													});
												}}>
												<i class='fas fa-trash-alt'></i>
											</button>
										</div>
									</div>
								))}
								{!readOnly ? (
									<span class="btn btn-outline-primary fileinput-button dz-clickable" onClick={() => this.fileSelectorSolicitacaoAnexo.click()}>
										<img src={pdfAdd} style={{ height: 30 }} />
									</span>
								) : null}
							</Row>

							<br />

							<Row>
								<Grid cols='12 12 12 12'>
									<label>CT-e Origem</label>
								</Grid>
								{(this.props.formularioValues.cte_origem_anexo || []).map(arquivo => (
									<div style={{ display: 'flex', flexDirection: 'column' }}>
										<span class="btn btn-outline-primary fileinput-button dz-clickable">
											<img src={arquivo.cte_origem_anexo_base64 ? pdfAdicionado : (arquivo.pdf ? pdfAdicionado : pdfAdd)} style={{ height: 30 }} />
										</span>
										<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingRight: 2, marginTop: 4 }}>
											<button
												type='button'
												class='btn btn-flat btn-outline-primary'
												style={{ width: '48%' }}
												disabled={arquivo.cte_origem_anexo_base64 ? true : false}
												onClick={() => {
													window.open(`https://api-paletes.infinitum-ti.com/${arquivo.pdf}`, '_blank');
												}}>
												<i class='fas fa-download'></i>
											</button>

											<button
												type='button'
												class='btn btn-flat btn-outline-danger'
												style={{ width: '48%' }}
												disabled={readOnly}
												onClick={() => {
													this.props.initForm({
														...this.props.formularioValues,
														cte_origem_anexo: this.props.formularioValues.cte_origem_anexo.filter(item => item != arquivo)
													});
												}}>
												<i class='fas fa-trash-alt'></i>
											</button>
										</div>
									</div>
								))}
								{!readOnly ? (
									<span class="btn btn-outline-primary fileinput-button dz-clickable" onClick={() => this.fileSelectorCteOrigemAnexo.click()}>
										<img src={pdfAdd} style={{ height: 30 }} />
									</span>
								) : null}
							</Row>

							<br />

							<Row>
								<Grid cols='12 12 12 12'>
									<label>Nota Fiscal de Palete</label>
								</Grid>
								{(this.props.formularioValues.nota_fiscal_paletes_anexo || []).map(arquivo => (
									<div style={{ display: 'flex', flexDirection: 'column' }}>
										<span class="btn btn-outline-primary fileinput-button dz-clickable">
											<img src={arquivo.nota_fiscal_paletes_anexo_base64 ? pdfAdicionado : (arquivo.pdf ? pdfAdicionado : pdfAdd)} style={{ height: 30 }} />
										</span>
										<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingRight: 2, marginTop: 4 }}>
											<button
												type='button'
												class='btn btn-flat btn-outline-primary'
												style={{ width: '48%' }}
												disabled={arquivo.nota_fiscal_paletes_anexo_base64 ? true : false}
												onClick={() => {
													window.open(`https://api-paletes.infinitum-ti.com/${arquivo.pdf}`, '_blank');
												}}>
												<i class='fas fa-download'></i>
											</button>

											<button
												type='button'
												class='btn btn-flat btn-outline-danger'
												style={{ width: '48%' }}
												disabled={readOnly}
												onClick={() => {
													this.props.initForm({
														...this.props.formularioValues,
														nota_fiscal_paletes_anexo: this.props.formularioValues.nota_fiscal_paletes_anexo.filter(item => item != arquivo)
													});
												}}>
												<i class='fas fa-trash-alt'></i>
											</button>
										</div>
									</div>
								))}
								{!readOnly ? (
									<span class="btn btn-outline-primary fileinput-button dz-clickable" onClick={() => this.fileSelectorNotaFiscalAnexo.click()}>
										<img src={pdfAdd} style={{ height: 30 }} />
									</span>
								) : null}
							</Row>

							<br />

							<Row>
								<Grid cols='12 12 12 12'>
									<label>Nota Fiscal de Produto</label>
								</Grid>
								{(this.props.formularioValues.nota_fiscal_produtos_anexo || []).map(arquivo => (
									<div style={{ display: 'flex', flexDirection: 'column' }}>
										<span class="btn btn-outline-primary fileinput-button dz-clickable">
											<img src={arquivo.nota_fiscal_produtos_anexo_base64 ? pdfAdicionado : (arquivo.pdf ? pdfAdicionado : pdfAdd)} style={{ height: 30 }} />
										</span>
										<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingRight: 2, marginTop: 4 }}>
											<button
												type='button'
												class='btn btn-flat btn-outline-primary'
												style={{ width: '48%' }}
												disabled={arquivo.nota_fiscal_produtos_anexo_base64 ? true : false}
												onClick={() => {
													window.open(`https://api-paletes.infinitum-ti.com/${arquivo.pdf}`, '_blank');
												}}>
												<i class='fas fa-download'></i>
											</button>

											<button
												type='button'
												class='btn btn-flat btn-outline-danger'
												style={{ width: '48%' }}
												disabled={readOnly}
												onClick={() => {
													this.props.initForm({
														...this.props.formularioValues,
														nota_fiscal_produtos_anexo: this.props.formularioValues.nota_fiscal_produtos_anexo.filter(item => item != arquivo)
													});
												}}>
												<i class='fas fa-trash-alt'></i>
											</button>
										</div>
									</div>
								))}
								{!readOnly ? (
									<span class="btn btn-outline-primary fileinput-button dz-clickable" onClick={() => this.fileSelectorNotaFiscalProdutoAnexo.click()}>
										<img src={pdfAdd} style={{ height: 30 }} />
									</span>
								) : null}
							</Row>

							<br />

							<Row>
								<Grid cols='12 12 12 12'>
									<label>Vale Palete</label>
								</Grid>
								{(this.props.formularioValues.vale_palete_anexo || []).map(arquivo => (
									<div style={{ display: 'flex', flexDirection: 'column' }}>
										<span class="btn btn-outline-primary fileinput-button dz-clickable">
											<img src={arquivo.vale_palete_anexo_base64 ? pdfAdicionado : (arquivo.pdf ? pdfAdicionado : pdfAdd)} style={{ height: 30 }} />
										</span>
										<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingRight: 2, marginTop: 4 }}>
											<button
												type='button'
												class='btn btn-flat btn-outline-primary'
												style={{ width: '48%' }}
												disabled={arquivo.vale_palete_anexo_base64 ? true : false}
												onClick={() => {
													window.open(`https://api-paletes.infinitum-ti.com/${arquivo.pdf}`, '_blank');
												}}>
												<i class='fas fa-download'></i>
											</button>

											<button
												type='button'
												class='btn btn-flat btn-outline-danger'
												style={{ width: '48%' }}
												disabled={readOnly}
												onClick={() => {
													this.props.initForm({
														...this.props.formularioValues,
														vale_palete_anexo: this.props.formularioValues.vale_palete_anexo.filter(item => item != arquivo)
													});
												}}>
												<i class='fas fa-trash-alt'></i>
											</button>
										</div>
									</div>
								))}
								{!readOnly ? (
									<span class="btn btn-outline-primary fileinput-button dz-clickable" onClick={() => this.fileSelectorValePaleteAnexo.click()}>
										<img src={pdfAdd} style={{ height: 30 }} />
									</span>
								) : null}
							</Row>
						</ContentCardBody>
						<ContentCardFooter>
							<Row alignCenter>
								<Grid cols='6 6 4 3'>
									{!this.props.formularioValues || !this.props.formularioValues.datahora_finalizacao ? (
										<Button
											text='Salvar'
											submit
											type={'success'}
											icon={'fa fa-check'}
											disabled={this.props.visualizacao} />
									) : null}
								</Grid>
								<Grid cols='6 6 4 3'>
									<Button
										text='Voltar'
										type={'warning'}
										icon={'fa fa-chevron-left'}
										event={() => this.props.setModoTela('lista')} />
								</Grid>
							</Row>
						</ContentCardFooter>
					</Form>
				</ContentCard>
				{this.props.formularioValues.id ?
					this.renderEventos(readOnly)
					: null
				}

			</div>
        )
    }

	renderEventos(readOnly) {
		return (

			<ContentCard>
				<ContentCardBody>
					<Table responsive>
						<THead>
							<Tr>
								<Th>Data/Hora</Th>
								<Th>Evento</Th>
								<Th>Observação</Th>
								<Th></Th>
							</Tr>
						</THead>
						<TBody>
							{(this.props.formularioValues.eventos || []).map(item => (
								<Tr key={item.id}>
									<Td>{DateFormat.formatarDataHoraSqlParaTela(item.datahora_evento)}</Td>
									<Td>{item.nome_evento}</Td>
									<Td>{item.observacao}</Td>
								</Tr>
							))}
						</TBody>
					</Table>
				</ContentCardBody>
			</ContentCard>
		);
	}

}

ViagemPaleteForm = reduxForm({form: 'viagemPaleteForm', destroyOnUnmount: false})(ViagemPaleteForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	formularioValues: getFormValues('viagemPaleteForm')(state),
	registro: state.documentoViagem.registro,
	modoTela: state.documentoViagem.modoTelaPalete
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, initForm
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ViagemPaleteForm);
