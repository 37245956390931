import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ContentCardFooter from '../common/template/contentCardFooter';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import LabelAndInputDateTime from '../common/form/labelAndInputDateTime';
import Select from '../common/form/select';
import SelectDefault from '../common/form/selectDefault';
import SelectAlteracao from '../common/form/selectAlteracao';
import pdfAdd from '../assets/images/icone_add_pdf.png';
import pdfAdicionado from '../assets/images/icone_pdf_adicionado.png';
import { setError } from '../common/toast/toast';

import {
	setModoTela, initForm
} from './laudoOcorrenciaActions';
import consts from '../consts';

class LaudoOcorrenciaForm extends Component {

	state = {

	}

	componentDidMount() {

		const fileSelectorArquivo = document.createElement('input');
		fileSelectorArquivo.setAttribute('type', 'file');
		fileSelectorArquivo.addEventListener("change", (data) => {

			let reader = new FileReader();
			let file = data && data.path && data.path[0] && data.path[0].files && data.path[0].files[0] ? data.path[0].files[0] : null;
			if (!file) {
				file = data && data.target && data.target.files && data.target.files[0] ? data.target.files[0] : null;
			}

			if (file) {

				if (!file.type.includes('application/pdf')
					&& !file.type.includes('image/png') && !file.type.includes('image/jpg') && !file.type.includes('image/jpeg')) {
					setError('Selecione apenas (.PDF, .PNG, .JPG e .JPEG)');
					return;
				}

				reader.onloadend = () => {

					let lista = (this.props.formularioValues.anexos || []).map(item => item);
					lista.push({
						arquivo_base64: reader.result
					});

					this.props.initForm({
						...this.props.formularioValues,
						anexos: lista
					});

				}

				reader.readAsDataURL(file);

			}
		});

		this.fileSelectorArquivo = fileSelectorArquivo;
	}

    componentWillMount() {

    }

    render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';

        return (
			<ContentCard>
				<Form event={this.props.handleSubmit}>
					<ContentCardBody>
						<Row>
							
							<Field
								name='id_tipo'
								component={Select}
								options={this.props.listaTipo}
								label='Ocorrência'
								cols='12 12 12 12'
								placeholder='Selecione o tipo'
								readOnly={readOnly} />
								
							<Field
								name='observacao'
								component={LabelAndInput}
								label='Observação' placeholder='Informe a observação'
								cols='12 12 12 12'
								readOnly={readOnly} />

						</Row>
						
						
					</ContentCardBody>
					<ContentCardFooter>
						<Row alignCenter>
							<Grid cols='6 6 4 3'>
								{this.props.excluir ? (
									<Button
										text='Excluir'
										submit type={'danger'}
										icon={'fa fa-trash'} />
								) : (
								<Button
									text='Salvar'
									submit
									type={'success'}
									icon={'fa fa-check'} />
								)}
							</Grid>
							<Grid cols='6 6 4 3'>
								<Button
									text='Voltar'
									type={'warning'}
									icon={'fa fa-chevron-left'}
									event={() => this.props.setModoTela('lista')} />
							</Grid>
						</Row>
					</ContentCardFooter>
				</Form>
			</ContentCard>
        )
    }


}

LaudoOcorrenciaForm = reduxForm({form: 'laudoOcorrenciaForm', destroyOnUnmount: false})(LaudoOcorrenciaForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	formularioValues: getFormValues('laudoOcorrenciaForm')(state),
	registro: state.laudoOcorrencia.registro,
	listaTipo: state.laudoOcorrencia.listaTipo
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, initForm
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(LaudoOcorrenciaForm);
