import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import Form from './despesaForm';
import LabelAndInputMask from '../common/form/labelAndInputMask';

import { 
	setModoTela, initForm, salvar, excluir, getLista, 
	getListaMotorista, getListaTipo, getListaMoeda, setFiltro
} from './despesaActions';
import FormatUtils from '../common/formatUtils/FormatUtils';
import DateFormat from '../common/dateFormat/DateFormat';
import Select from '../common/form/select';

class Despesa extends Component {

	state = {
		linhaSelecionada: null
	}

    componentWillMount() {
        this.props.getLista();
		this.props.getListaMotorista();
		this.props.getListaTipo();
		this.props.getListaMoeda();
    }

    render() {
        return (
            <div>
                <Content>
					{this.props.modoTela == 'cadastro' ? (
						<Form onSubmit={this.props.salvar} />
					) : null}
					{this.props.modoTela == 'exclusao' ? (
						<Form excluir onSubmit={this.props.excluir} />
					) : null}
					{this.props.modoTela == 'lista' ? this.lista() : null}
                </Content>
            </div>
        )
    }

	lista() {

		let acessoComprovantes = this.props.usuarioCarregado && (
			this.props.usuarioCarregado.acesso_comprovante_aberta_visualizar || this.props.usuarioCarregado.acesso_comprovante_aberta_alterar
			|| this.props.usuarioCarregado.acesso_comprovante_encerrada_visualizar || this.props.usuarioCarregado.acesso_comprovante_encerrada_alterar || this.props.usuarioCarregado.acesso_comprovante_encerrada_desfazer
			|| this.props.usuarioCarregado.acesso_comprovante_finalizada_visualizar || this.props.usuarioCarregado.acesso_comprovante_finalizada_alterar || this.props.usuarioCarregado.acesso_comprovante_finalizada_desfazer);

		let verTodas = acessoComprovantes || this.props.sessao.id == 1;
			
		return (
			<ContentCard>
				<ContentCardHeader>
					<Row>
						<Grid cols='6 6 4 2'>
							<Button
								text='Solicitar'
								type={'success'}
								icon={'fa fa-plus'}
								event={() => {
									this.props.setModoTela('cadastro', {});
									this.props.initForm({});
								}} />
						</Grid>
						<Grid cols='6 6 4 2'>
							<Button
								text='Atualizar'
								type={'primary'}
								icon={'fa fa-plus'}
								event={() => {
									this.props.getLista();
									this.props.getListaMotorista();
									this.props.getListaTipo();
									this.props.getListaMoeda();
								}} />
						</Grid>
					</Row>
				</ContentCardHeader>
				<ContentCardBody>
					<Row>
						<LabelAndInputMask
							label='Data/Hora' placeholder='Informe o período inicial'
							cols='12 6 3 2'
							mask='99/99/9999'
							value={this.props.filtro.data_inicial}
							onChange={(data) => {
								this.props.setFiltro({ ...this.props.filtro, data_inicial: data.target.value });
							}} />

						<LabelAndInputMask
							label='Data/Hora' placeholder='Informe o período final'
							cols='12 6 3 2'
							mask='99/99/9999'
							value={this.props.filtro.data_final}
							onChange={(data) => {
								this.props.setFiltro({ ...this.props.filtro, data_final: data.target.value });
							}} />
							
						<Select
							name='id_status'
							options={[{ id: 1, valor: 'Todos' }, { id: 2, valor: 'Solicitado' }, { id: 3, valor: 'Aprovado' }, { id: 4, valor: 'Reprovado' }, { id: 5, valor: 'Reembolsado' }, { id: 6, valor: 'Negado' }]}
							label='Status'
							cols='12 6 3 4'
							placeholder='Selecione o status'
							value={this.state.id_status}
							onChange={(value) => {
								this.props.setFiltro({
									...this.props.filtro,
									id_status: value
								});
							}} />
														
						<Select
							name='id_motorista'
							options={this.props.listaMotorista}
							label='Motorista'
							cols='12 6 3 4'
							placeholder='Selecione o motorista'
							value={this.state.id_motorista}
							onChange={(value) => {
								this.props.setFiltro({
									...this.props.filtro,
									id_motorista: value
								});
							}} />

					</Row>

					<input className='form-control'
						 placeholder='Pesquisar'
						 type='text'
						 value={this.state.pesquisar}
						 onChange={e => {
							 this.setState({ ...this.state, pesquisar: e.target.value });
						 }} />
					<Table responsive>
						<THead>
							<Tr>
								<Th alignCenter>Status</Th>
								<Th alignCenter>Solicitação</Th>
								<Th alignCenter>Motorista</Th>
								<Th alignCenter>Placa</Th>
								<Th alignCenter>Despesa</Th>
								<Th alignCenter>Documento</Th>
								<Th alignCenter>Valor</Th>
								<Th alignCenter>Moeda</Th>
								<Th alignCenter>Token</Th>
								<Th alignCenter>Aprovado/Reprovado</Th>
								<Th alignCenter>Acerto</Th>
								<Th></Th>
							</Tr>
						</THead>
						<TBody>
							{this.props.lista.filter(item => {

								if (this.state.pesquisar
									&& !(`${DateFormat.formatarDataHoraSqlParaTela(item.datahora_solicitacao)} ${item.nome_motorista || ''}
										${item.placa || ''} ${item.nome_tipo || ''} ${item.documento_tipo || ''} ${item.documento || ''} 
										${FormatUtils.formatarValorTela(item.valor, 2)} ${item.nome_moeda || ''}
										${item.datahora_aprovacao ? 'APROVADO' : ''} ${item.datahora_reprovacao ? 'REPROVADO' : ''} 
										${item.nome_acerto || ''}`).toUpperCase().includes(this.state.pesquisar.toUpperCase())) {
									return false;
								}

								//Solicitado
								if (this.props.filtro.id_status == 2 && (item.datahora_aprovacao || item.datahora_reprovacao || item.datahora_acerto)) {
									return false;
								}
								//Aprovado
								if (this.props.filtro.id_status == 3 && (!item.datahora_aprovacao || item.datahora_reprovacao || item.datahora_acerto)) {
									return false;
								}
								//Reprovado
								if (this.props.filtro.id_status == 4 && (item.datahora_aprovacao || !item.datahora_reprovacao || item.datahora_acerto)) {
									return false;
								}
								//Reembolsado
								if (this.props.filtro.id_status == 5 && (!item.datahora_acerto || item.nome_acerto != 'Reembolsada')) {
									return false;
								}
								//Negado
								if (this.props.filtro.id_status == 6 && (!item.datahora_acerto || item.nome_acerto != 'Negada')) {
									return false;
								}

								if (this.props.filtro.id_motorista && item.id_motorista != this.props.filtro.id_motorista) {
									return false;
								}

								if (verTodas) {
									return true;
								}
								if ((item.aprovadores || []).filter(aprovador => aprovador == this.props.sessao.id).length) {
									return true;
								}
								if (item.id_usuario_inclusao == this.props.sessao.id) {
									return true;
								}
								return false;
							}).map(item => (
								<Tr key={item.id} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
									onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
									onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
									<Td alignCenter>{									
										item.datahora_acerto ? item.nome_acerto : (
											item.datahora_aprovacao ? 'Aprovado' : (
												item.datahora_reprovacao ? 'Reprovado' : (
													'Solicitado'
												)
											)
										)
									}</Td>
									<Td alignCenter>{DateFormat.formatarDataHoraSqlParaTela(item.datahora_solicitacao)}</Td>
									<Td alignCenter>{item.nome_motorista}</Td>
									<Td alignCenter>{item.placa}</Td>
									<Td alignCenter>{item.nome_tipo}</Td>
									<Td alignCenter>{item.documento_tipo} {item.documento}</Td>
									<Td alignCenter>{FormatUtils.formatarValorTela(item.valor, 2)}</Td>
									<Td alignCenter>{item.nome_moeda}</Td>
									<Td alignCenter>{item.datahora_aprovacao ? item.token : ''}</Td>
									<Td alignCenter>{
										item.datahora_aprovacao ? 'APROVADO'
										: (
											item.datahora_reprovacao ? 'REPROVADO'
											: (((item.aprovadores || []).filter(aprovador => aprovador == this.props.sessao.id).length) ? (
												<div>
													<ButtonTable
														type={'success'}
														icon={'fas fa-thumbs-up'}
														visible={true}
														event={() => {
															this.props.salvar({
																...item,
																datahora_aprovacao: DateFormat.getDataHoraAtual(),
																id_usuario_aprovacao: this.props.sessao.id,
																valor: FormatUtils.formatarValorTela(item.valor, 2)
															});
														}} />
													
													<ButtonTable
														type={'danger'}
														icon={'fas fa-thumbs-down'}
														visible={true}
														event={() => {
															this.props.salvar({
																...item,
																datahora_reprovacao: DateFormat.getDataHoraAtual(),
																id_usuario_reprovacao: this.props.sessao.id,
																valor: FormatUtils.formatarValorTela(item.valor, 2)
															});
														}} />
												</div>
											 ) : null)
										)
									}</Td>
									<Td alignCenter>{
										item.datahora_aprovacao ? (
											item.datahora_acerto ? item.nome_acerto
											: (acessoComprovantes ? (
												<div>
													<ButtonTable
														type={'success'}
														icon={'fas fa-thumbs-up'}
														visible={true}
														event={() => {
															this.props.salvar({
																...item,
																datahora_acerto: DateFormat.getDataHoraAtual(),
																id_acerto: 1, //Reembolso
																id_usuario_acerto: this.props.sessao.id,
																valor: FormatUtils.formatarValorTela(item.valor, 2)
															});
														}} />
														
													<ButtonTable
														type={'danger'}
														icon={'fas fa-thumbs-down'}
														visible={true}
														event={() => {
															this.props.salvar({
																...item,
																datahora_acerto: DateFormat.getDataHoraAtual(),
																id_acerto: 2, //Negado
																id_usuario_acerto: this.props.sessao.id,
																valor: FormatUtils.formatarValorTela(item.valor, 2)
															});
														}} />
												</div>
											) : null)
										) : null
									}</Td>
									<Td alignRight minWidth={100}>
										{item.datahora_aprovacao &&
										<ButtonTable
											type={'success'}
											icon={'fab fa-whatsapp'}
											visible={true}
											event={() => {
												let mensagem = ''
												+ `Despesa: ${item.nome_tipo}`
												+ `\nMotorista: ${item.nome_motorista}`
												+ `\nSolicitação: ${DateFormat.formatarDataHoraSqlParaTela(item.datahora_solicitacao)}`
												+ `\nValor: ${item.simbolo_moeda} ${FormatUtils.formatarValorTela(item.valor, 2)}`
												+ `\nToken: ${item.token}`
												window.open(`https://api.whatsapp.com/send?phone=+55${item.celular_motorista || '55991735243'}&text=${encodeURI(mensagem)}`, '_blank');
											}} />}
										<ButtonTable
											type={item.datahora_aprovacao || item.datahora_reprovacao ? 'primary' : 'warning'}
											icon={item.datahora_aprovacao || item.datahora_reprovacao ? 'fas fa-eye' : 'fas fa-pencil-alt'}
											visible={this.state.linhaSelecionada == item.id}
											event={() => {
												this.props.setModoTela('cadastro', {
													...item,
													valor: FormatUtils.formatarValorTela(item.valor, 2)
												});
												this.props.initForm({
													...item,
													valor: FormatUtils.formatarValorTela(item.valor, 2)
												});
											}} />

										<ButtonTable
											type={'danger'}
											icon={'fas fa-trash-alt'}
											visible={this.state.linhaSelecionada == item.id}
											event={() => {
												this.props.setModoTela('exclusao', {
													...item,
													valor: FormatUtils.formatarValorTela(item.valor, 2)
												});
												this.props.initForm({
													...item,
													valor: FormatUtils.formatarValorTela(item.valor, 2)
												});
											}} />
									</Td>
								</Tr>
							))}
						</TBody>
					</Table>
				</ContentCardBody>
			</ContentCard>
		);
	}


}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	usuarioCarregado: state.auth.usuarioCarregado,
	modoTela: state.despesa.modoTela,
	lista: state.despesa.lista,
	filtro: state.despesa.filtro,
	listaMotorista: state.despesa.listaMotorista
});
const mapDispatchToProps = dispatch => bindActionCreators({ 
	setModoTela, initForm, salvar, excluir, getLista, 
	getListaMotorista, getListaTipo, getListaMoeda, setFiltro 
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Despesa);
