import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import DateFormat from '../common/dateFormat/DateFormat';
import FormatUtils from '../common/formatUtils/FormatUtils';

export function setModoTela(modo, registro = {}) {
    return {
        type: 'DESPESA_MODO_TELA',
        payload: {
			modo: modo,
			registro: registro
		}
    };
}
export function initForm(registro = {}) {
    return [
        initialize('despesaForm', registro)
    ];
}

export function setFiltro(filtro = {}) {
    return {
        type: 'DESPESA_FILTRO',
        payload: {
			filtro: filtro
		}
    };
}

export function getLista() {
	return (dispatch, getState) => {
    	axios.get(`${consts.API_URL}/despesa?
		data_inicial=${DateFormat.formatarDataTelaParaSql(getState().despesa.filtro.data_inicial) == 'Invalid DateTime' ? null : DateFormat.formatarDataTelaParaSql(getState().despesa.filtro.data_inicial)}
		&data_final=${DateFormat.formatarDataTelaParaSql(getState().despesa.filtro.data_final) == 'Invalid DateTime' ? null : DateFormat.formatarDataTelaParaSql(getState().despesa.filtro.data_final)}
	`).then(resp => {
			dispatch({
		        type: 'DESPESA_LISTADO',
		        payload: resp
		    });
		}).catch(e => {
			setErroAPI(e);
		});
	}
}

export function buscarViagemPeloMotorista(registro, nome_motorista) {
	console.log(nome_motorista);
	return (dispatch, getState) => {
    	axios.get(`${consts.API_URL}/despesa/buscarViagemPeloMotorista?nome_motorista=${nome_motorista}`)
		.then(resp => {
			dispatch(initForm({
				...registro,
				listaDocumentos: resp.data || []
			}));
		}).catch(e => {
			setErroAPI(e);
		});
	}
}

export function getListaMotorista() {
	return (dispatch, getState) => {
    	axios.get(`${consts.API_URL}/motorista/listarSelect`)
		.then(resp => {
			dispatch({
		        type: 'DESPESA_MOTORISTA_SELECT_LISTADO',
		        payload: resp
		    });
		}).catch(e => {
			setErroAPI(e);
		});
	}
}

export function getListaMoeda() {
	return (dispatch, getState) => {
    	axios.get(`${consts.API_URL}/moeda/listarSelect`)
		.then(resp => {
			console.log(resp);
			dispatch({
		        type: 'DESPESA_MOEDA_SELECT_LISTADO',
		        payload: resp
		    });
		}).catch(e => {
			setErroAPI(e);
		});
	}
}

export function getListaTipo() {
	return (dispatch, getState) => {
    	axios.get(`${consts.API_URL}/despesaTipo/listarSelect`)
		.then(resp => {
			dispatch({
		        type: 'DESPESA_TIPO_SELECT_LISTADO',
		        payload: resp
		    });
		}).catch(e => {
			setErroAPI(e);
		});
	}
}

export function salvar(registro) {

    return (dispatch, getState) => {

		const moeda = getState().despesa.listaMoeda.filter(item => item.id == registro.id_moeda)[0];
		const tipo = getState().despesa.listaTipo.filter(item => item.id == registro.id_tipo)[0];
		let valorLimite = 0;
		if (moeda && tipo && tipo.limite > 0) {
			valorLimite = parseFloat(tipo.limite) * parseFloat(moeda.cotacao);
		}

		if (!registro.id) {
	        axios.post(`${consts.API_URL}/despesa`, {
				...registro,
				datahora_solicitacao: DateFormat.getDataHoraAtual(),
				//Se não tem aprovadores ou o aprovador é o próprio usuário de inclusão
				datahora_aprovacao: tipo && (
					!(tipo.aprovadores || []).length 
					|| (tipo.aprovadores || []).filter(aprovador => aprovador == getState().auth.usuario.id).length
				) ? DateFormat.getDataHoraAtual() : null,
				valor: FormatUtils.formatarValorBanco(registro.valor, 2),
				limite: valorLimite,
				id_usuario_inclusao: getState().auth.usuario.id,
				id_usuario_alteracao: getState().auth.usuario.id,
				id_usuario_aprovacao: tipo && (
					!(tipo.aprovadores || []).length 
					|| (tipo.aprovadores || []).filter(aprovador => aprovador == getState().auth.usuario.id).length
				) ? getState().auth.usuario.id : null,
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		} else {
			axios.put(`${consts.API_URL}/despesa`, {
				...registro,
				token: null,
				valor: FormatUtils.formatarValorBanco(registro.valor, 2),
				limite: valorLimite,
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		}
    };
}
/*
datahora_aprovacao TIMESTAMP WITHOUT TIME ZONE,
datahora_reprovacao TIMESTAMP WITHOUT TIME ZONE,
id_acerto INT,
id_usuario_aprovacao INTEGER,
id_usuario_reprovacao INTEGER,
id_usuario_acerto INTEGER,
*/

export function excluir(registro) {
    return (dispatch, getState) => {
        axios.delete(`${consts.API_URL}/despesa?id=${registro.id}&id_usuario_alteracao=${getState().auth.usuario.id}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista());
			dispatch(setModoTela('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}
