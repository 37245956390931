import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import DateFormat from '../common/dateFormat/DateFormat';

export function setModoTela(modo, registro = { id_estoque_local: 2 }) {
    return {
        type: 'ACESSORIO_SALDO_MODO_TELA',
        payload: {
			modo: modo,
			registro: registro
		}
    };
}

export function setAguardando(aguardando) {
    return {
        type: 'ACESSORIO_SALDO_SET_AGUARDANDO',
        payload: aguardando
    };
}

export function setFiltro(filtro) {
    return {
        type: 'ACESSORIO_SALDO_FILTRO',
        payload: {
			filtro: filtro
		}
    };
}

export function getListaTipo() {
	return (dispatch, getState) => {
    	axios.get(`${consts.API_URL}/acessorioTipo/listarSelect`)
		.then(resp => {
			dispatch({
				type: 'ACESSORIO_SALDO_TIPO_SELECT_LISTADO',
		        payload: resp
		    });
		}).catch(e => {
			setErroAPI(e);
		});
	}
}

export function getListaSaldoUnidade(filtro = { id_tipo: null }) {
	return (dispatch, getState) => {
    	axios.get(`${consts.API_URL}/acessorioMovimento/unidadesNovo?id_usuario=${getState().auth.usuario.id}&id_tipo=${filtro.id_tipo}`)
		.then(resp => {
			dispatch({
				type: 'PRINCIPAL_SALDO_UNIDADE_LISTADO',
		        payload: resp
		    });
		}).then(resp => {
			dispatch(setAguardando(false));
		}).catch(e => {
			setErroAPI(e);
			dispatch(setAguardando(false));
		});
	}
}

export function getListaSaldoVeiculo(filtro = { id_tipo: null }) {
	return (dispatch, getState) => {
    	axios.get(`${consts.API_URL}/acessorioMovimento/veiculosNovo?id_usuario=${getState().auth.usuario.id}${filtro.id_tipo ? `&id_tipo=${filtro.id_tipo}` : ''}`)
		.then(resp => {
			dispatch({
				type: 'PRINCIPAL_SALDO_VEICULO_LISTADO',
		        payload: resp
		    });
		}).then(resp => {
			dispatch(setAguardando(false));
		}).catch(e => {
			setErroAPI(e);
			dispatch(setAguardando(false));
		});
	}
}
