const INITIAL_STATE = {
	aguardando: false,
	listaPremiacaoEconomica: [],
	listaPremiacaoExelencia: [],
	listaPremiacaoPenalidades: [],
	listaPremiacaoQuilometragem: []
};

export default function(state = INITIAL_STATE, action) {
    switch (action.type) {

		case 'DASHBOARD_SET_AGUARDANDO':
			return { ...state,
				aguardando: action.payload
			};

		case 'DASHBOARD_PREMIACAO_ECONOMICA_LISTADO':
            return {
				...state,
				listaPremiacaoEconomica: action.payload.data
			};
		case 'DASHBOARD_PREMIACAO_EXCELENCIA_LISTADO':
			return {
				...state,
				listaPremiacaoExelencia: action.payload.data
			};
		case 'DASHBOARD_PREMIACAO_PENALIDADES_LISTADO':
			return {
				...state,
				listaPremiacaoPenalidades: action.payload.data
			};
		case 'DASHBOARD_PREMIACAO_QUILOMETRAGEM_LISTADO':
			return {
				...state,
				listaPremiacaoQuilometragem: action.payload.data
			};

        default:
            return state;
    }
}
