import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ContentCardFooter from '../common/template/contentCardFooter';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import LabelAndInputDateTime from '../common/form/labelAndInputDateTime';
import Select from '../common/form/select';
import SelectAlteracao from '../common/form/selectAlteracao';
import FormatUtils from '../common/formatUtils/FormatUtils';
import FormItem from './premiacaoMotoristaForm';
import { setError } from '../common/toast/toast';
import readXlsxFile from 'read-excel-file/web-worker'
import ReactExport from 'react-export-excel-fixed-xlsx';

import {
	setModoTela, initForm, salvar,
	setModoTelaItem, initFormItem, salvarItem, excluirItem, getListaItem, setListaItem,
	importarMedias, importarQuilometragem, importarOcorrencia, gerarPdf, gerarPdfLista, importarGrupos, reprocessar, reprocessarMotoristas
} from './premiacaoActions';

class PremiacaoForm extends Component {

	state = {

	}

	componentDidMount() {

		const fileSelectorGrupos = document.createElement('input');
		fileSelectorGrupos.setAttribute('type', 'file');
		fileSelectorGrupos.addEventListener("change", async (data) => {

			let reader = new FileReader();
			let file = data && data.path && data.path[0] && data.path[0].files && data.path[0].files[0] ? data.path[0].files[0] : null;
			if (!file) {
				file = data && data.target && data.target.files && data.target.files[0] ? data.target.files[0] : null;
			}

			if (file) {

				if (!file.type.includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
					|| (file.type.includes('application/excel') || file.type.includes('application/vnd.ms-excel'))) {
					setError('Selecione apenas (.XLSX)');
					return;
				}

				let tabela = [];
				await readXlsxFile(file, { sheet: 1 }).then((rows) => {
					tabela = rows;
				});

				this.props.importarGrupos(tabela, this.props.formularioValues.id);

				reader.readAsDataURL(file);

			}
		});

		this.fileSelectorGrupos = fileSelectorGrupos;

		const fileSelectorMedias = document.createElement('input');
		fileSelectorMedias.setAttribute('type', 'file');
		fileSelectorMedias.addEventListener("change", async (data) => {

			let reader = new FileReader();
			let file = data && data.path && data.path[0] && data.path[0].files && data.path[0].files[0] ? data.path[0].files[0] : null;
			if (!file) {
				file = data && data.target && data.target.files && data.target.files[0] ? data.target.files[0] : null;
			}

			if (file) {

				if (!file.type.includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
					|| (file.type.includes('application/excel') || file.type.includes('application/vnd.ms-excel'))) {
					setError('Selecione apenas (.XLSX)');
					return;
				}

				let tabela = [];
				await readXlsxFile(file, { sheet: 1 }).then((rows) => {
					tabela = rows;
				});

				this.props.importarMedias(tabela, this.props.formularioValues.id);

				reader.readAsDataURL(file);

			}
		});

		this.fileSelectorMedias = fileSelectorMedias;

		const fileSelectorQuilometragem = document.createElement('input');
		fileSelectorQuilometragem.setAttribute('type', 'file');
		fileSelectorQuilometragem.addEventListener("change", async (data) => {

			let reader = new FileReader();
			let file = data && data.path && data.path[0] && data.path[0].files && data.path[0].files[0] ? data.path[0].files[0] : null;
			if (!file) {
				file = data && data.target && data.target.files && data.target.files[0] ? data.target.files[0] : null;
			}

			if (file) {

				if (!file.type.includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
					|| (file.type.includes('application/excel') || file.type.includes('application/vnd.ms-excel'))) {
					setError('Selecione apenas (.XLSX)');
					return;
				}

				let tabela = [];
				await readXlsxFile(file, { sheet: 1 }).then((rows) => {
					tabela = rows;
				});

				this.props.importarQuilometragem(tabela, this.props.formularioValues.id);

				reader.readAsDataURL(file);

			}
		});

		this.fileSelectorQuilometragem = fileSelectorQuilometragem;
	}

    componentWillMount() {
		this.props.getListaItem(this.props.formularioValues.id || 0);
    }

    render() {

		let readOnly = this.props.excluir || this.props.formularioValues.finalizada ? 'readOnly' : '';

		const ExcelFile = ReactExport.ExcelFile;
		const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
		const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

        return (
			<>
				<ContentCard>
					<Form event={this.props.handleSubmit}>
						<ContentCardBody>
							<Row>
								<Field
									name='competencia_analise'
									component={LabelAndInputMask}
									label='Competência Análise' placeholder='Informe a competência'
									cols='12 12 12 12'
									mask='99/9999'
									readOnly={this.props.formularioValues.id ? 'readOnly' : readOnly} />

								<Field
									name='competencia_pagamento'
									component={LabelAndInputMask}
									label='Competência Pagamento' placeholder='Informe a competência'
									cols='12 12 12 12'
									mask='99/9999'
									readOnly={this.props.formularioValues.id ? 'readOnly' : readOnly} />
							</Row>
						</ContentCardBody>
						<ContentCardFooter>
							<Row alignCenter>
								<Grid cols='6 6 4 3'>
									{this.props.excluir ? (
										<Button
											text='Excluir'
											submit type={'danger'}
											icon={'fa fa-trash'} />
									) : (
									<Button
										text='Salvar'
										submit
										type={'success'}
										icon={'fa fa-check'}
										disabled={this.props.formularioValues.id ? true : false} />
									)}
								</Grid>
								<Grid cols='6 6 4 3'>
									<Button
										text='Voltar'
										type={'warning'}
										icon={'fa fa-chevron-left'}
										event={() => this.props.setModoTela('lista')} />
								</Grid>
							</Row>
							{this.props.formularioValues.id ? (
								<>
									<Row alignCenter style={{ marginTop: 20 }}>
										<Grid cols='6 6 3 2'>
											<Button
												text={'Novos Motoristas'}
												type={'primary'}
												icon={'fa fa-chevron-left'}
												event={() => this.props.reprocessarMotoristas(this.props.formularioValues)} />
										</Grid>
										<Grid cols='6 6 3 2'>
											<Button
												text='Importar Grupos'
												type={'primary'}
												icon={'fa fa-chevron-left'}
												event={() => this.fileSelectorGrupos.click()}
												disabled={readOnly ? true : false} />
										</Grid>
										<Grid cols='6 6 3 2'>
											<Button
												text='Importar Médias'
												type={'primary'}
												icon={'fa fa-chevron-left'}
												event={() => this.fileSelectorMedias.click()}
												disabled={readOnly ? true : false} />
										</Grid>
										<Grid cols='6 6 3 2'>
											<Button
												text='Importar KM'
												type={'primary'}
												icon={'fa fa-chevron-left'}
												event={() => this.fileSelectorQuilometragem.click()}
												disabled={readOnly ? true : false} />
										</Grid>
										<Grid cols='6 6 3 2'>
											<Button
												text='Importar Ocorrências'
												type={'primary'}
												icon={'fa fa-chevron-left'}
												event={() => this.props.importarOcorrencia(this.props.formularioValues)}
												disabled={readOnly ? true : false} />
										</Grid>
										<Grid cols='6 6 3 2'>
											<Button
												text={'Reprocessar'}
												type={'primary'}
												icon={'fa fa-chevron-left'}
												event={() => this.props.reprocessar(this.props.formularioValues)} />
										</Grid>
									</Row>
									<Row alignCenter style={{ marginTop: 20 }}>
										<Grid cols='6 6 3 2'>
											<Button
												text={'Imprimir Prêmios'}
												type={'primary'}
												icon={'fa fa-chevron-left'}
												event={() => this.props.gerarPdfLista(this.props.formularioValues)} />
										</Grid>
										<Grid cols='6 6 3 2'>
											<ExcelFile
												filename={'Premiação'}
												element={
													<Button
														text='Exportar'
														type={'primary'}
														icon={'fa fa-plus'} />
												}>
												<ExcelSheet data={this.props.listaItem.map(item => ({
													...item,
													km_percorrido: item.km_percorrido || 0,
													valor_maximo_economica: FormatUtils.formatarValorTela(item.valor_maximo_economica, 2),
													atingiu_meta: item.atingiu_meta ? 'SIM' : 'NÃO',
													premio_economica: FormatUtils.formatarValorTela(item.premio_economica, 2),
													valor_maximo_excelencia: FormatUtils.formatarValorTela(item.valor_maximo_excelencia, 2),
													percentual_perda_ocorrencia: FormatUtils.formatarValorTela(item.percentual_perda_ocorrencia, 0),
													premio_excelencia: FormatUtils.formatarValorTela(item.premio_excelencia, 2),
													premio_total: FormatUtils.formatarValorTela(parseFloat(item.premio_economica) + parseFloat(item.premio_excelencia), 2)
												}))} name='Premiação'>
													<ExcelColumn label='Motorista' value='nome_motorista' />
													<ExcelColumn label='Grupo' value='nome_grupo' />
													<ExcelColumn label='KM Percorrida' value='km_percorrido' />
													<ExcelColumn label='Meta Condução Econômica' value='valor_maximo_economica' />
													<ExcelColumn label='Atingiu Condução Econômica' value='atingiu_meta' />
													<ExcelColumn label='Prêmio Condução Econômica' value='premio_economica' />
													<ExcelColumn label='Meta Excelência' value='valor_maximo_excelencia' />
													<ExcelColumn label='Perda Ocorrências' value='percentual_perda_ocorrencia' />
													<ExcelColumn label='Prêmio Excelência' value='premio_excelencia' />
													<ExcelColumn label='Total' value='premio_total' />
												</ExcelSheet>
											</ExcelFile>
										</Grid>
										<Grid cols='6 6 3 2'>
											<Button
												text={this.props.formularioValues.finalizada ? 'Reabrir' : 'Finalizar'}
												type={'success'}
												icon={'fa fa-chevron-left'}
												event={() => this.props.salvar({
													...this.props.formularioValues,
													finalizada: !this.props.formularioValues.finalizada
												})} />
										</Grid>
									</Row>
								</>
							) : null}
						</ContentCardFooter>
					</Form>
				</ContentCard>

				{this.props.formularioValues.id ?
					this.renderItens(readOnly)
				: null}
			</>
        )
    }

	renderItens(readOnly) {

		if (this.props.modoTelaItem == 'cadastro') {
			return (
				<FormItem onSubmit={this.props.salvarItem} />
			);
		} else if (this.props.modoTelaItem == 'exclusao') {
			return (
				<FormItem excluir onSubmit={this.props.excluirItem} />
			);
		} else if (this.props.modoTelaItem == 'lista') {
			return (
				<ContentCard>
					<ContentCardHeader>
						<Row>
							<Grid cols='6 6 4 2'>
								{this.props.formularioValues && this.props.formularioValues.id_status == 1 ? (
									<Button
										text='Adicionar'
										type={'success'}
										icon={'fa fa-plus'}
										disabled={readOnly ? true : false}
										event={() => {
											this.props.setModoTelaItem('cadastro', {
												id_viagem: this.props.formularioValues.id,
												id_transportadora: this.props.formularioValues.id_transportadora
											});
											this.props.initFormItem({
												id_viagem: this.props.formularioValues.id,
												id_transportadora: this.props.formularioValues.id_transportadora
											});
										}} />
								) : null}
							</Grid>
						</Row>
					</ContentCardHeader>
					<ContentCardBody>
						<Table responsive>
							<THead>
								<Tr>
									<Th rowspan='2'>Motorista</Th>
									<Th rowspan='2'>Grupo</Th>
									<Th rowspan='2' alignRight>KM Percorrida</Th>
									<Th colspan='3' alignCenter backgroundColor='#b2dfdb'>Condução Econômica</Th>
									<Th colspan='3' alignCenter backgroundColor='#80d8ff'>Excelência</Th>
									<Th rowspan='2' alignRight backgroundColor='#00e676'>Total</Th>
									<Th></Th>
								</Tr>
								<Tr>
									<Th alignRight backgroundColor='#b2dfdb'>Meta</Th>
									<Th alignCenter backgroundColor='#b2dfdb'>Atingiu</Th>
									<Th alignRight backgroundColor='#b2dfdb'>Prêmio</Th>
									<Th alignRight backgroundColor='#80d8ff'>Meta</Th>
									<Th alignCenter backgroundColor='#80d8ff'>Perda Ocorrências</Th>
									<Th alignRight backgroundColor='#80d8ff'>Prêmio</Th>
									<Th></Th>
								</Tr>
							</THead>
							<TBody>
								{this.props.listaItem.map(item => (
									<Tr key={item.id} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
										onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
										onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
										<Td>{item.nome_motorista}</Td>
										<Td>{item.nome_grupo}</Td>
										<Td alignRight>{item.km_percorrido || 0}</Td>
										<Td alignRight backgroundColor='#b2dfdb'>{FormatUtils.formatarValorTela(item.valor_maximo_economica, 2)}</Td>
										<Td alignCenter backgroundColor='#b2dfdb'>{item.atingiu_meta ? 'SIM' : 'NÃO'}</Td>
										<Td alignRight backgroundColor='#b2dfdb'>{FormatUtils.formatarValorTela(item.premio_economica, 2)}</Td>
										<Td alignRight backgroundColor='#80d8ff'>{FormatUtils.formatarValorTela(item.valor_maximo_excelencia, 2)}</Td>
										<Td alignCenter backgroundColor='#80d8ff'>{FormatUtils.formatarValorTela(item.percentual_perda_ocorrencia, 0)} %</Td>
										<Td alignRight backgroundColor='#80d8ff'>{FormatUtils.formatarValorTela(item.premio_excelencia, 2)}</Td>
										<Td alignRight backgroundColor='#00e676'>{FormatUtils.formatarValorTela(parseFloat(item.premio_economica) + parseFloat(item.premio_excelencia), 2)}</Td>
										<Td alignRight minWidth={100}>
											<ButtonTable
												type={'primary'}
												icon={'fas fa-file-pdf'}
												visible={true}
												event={() => {
													this.props.gerarPdf({
														...this.props.formularioValues,
														premio: {
															...item,
															valor_maximo_economica: FormatUtils.formatarValorTela(item.valor_maximo_economica, 2),
															premio_economica: FormatUtils.formatarValorTela(item.premio_economica, 2),
															valor_maximo_excelencia: FormatUtils.formatarValorTela(item.valor_maximo_excelencia, 2),
															percentual_perda_ocorrencia: FormatUtils.formatarValorTela(item.percentual_perda_ocorrencia, 0),
															premio_excelencia: FormatUtils.formatarValorTela(item.premio_excelencia, 2),
															valor_maximo_total: FormatUtils.formatarValorTela(parseFloat(item.valor_maximo_economica) + parseFloat(item.valor_maximo_excelencia), 2),
															premio_total: FormatUtils.formatarValorTela(parseFloat(item.premio_economica) + parseFloat(item.premio_excelencia), 2),
														}
													});
												}} />

											<ButtonTable
												type={'danger'}
												icon={'fas fa-trash-alt'}
												visible={true}
												disabled={readOnly ? true : false}
												event={() => {
													this.props.setModoTelaItem('exclusao', {
														...item,
														quantidade: String(item.quantidade || '').replace('.', ',')
													});
													this.props.initFormItem({
														...item,
														quantidade: String(item.quantidade || '').replace('.', ',')
													});
												}} />
										</Td>
									</Tr>
								))}
							</TBody>
						</Table>
					</ContentCardBody>
				</ContentCard>
			);
		} else {
			return (
				<div></div>
			);
		}
	}

}

PremiacaoForm = reduxForm({form: 'premiacaoForm', destroyOnUnmount: false})(PremiacaoForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	formularioValues: getFormValues('premiacaoForm')(state),
	registro: state.premiacao.registro,
	listaTipo: state.premiacao.listaTipo,
	listaMotorista: state.premiacao.listaMotorista,
	modoTelaItem: state.premiacao.modoTelaItem,
	listaItem: state.premiacao.listaItem,
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, initForm, salvar,
	setModoTelaItem, initFormItem, salvarItem, excluirItem, getListaItem, setListaItem,
	importarMedias, importarQuilometragem, importarOcorrencia, gerarPdf, gerarPdfLista, importarGrupos, reprocessar, reprocessarMotoristas
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(PremiacaoForm);
