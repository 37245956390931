import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import Form from './operacaoForm';
import Aguardando from '../common/template/aguardando';
import ReactExport from 'react-export-excel-fixed-xlsx';

import { 
	setModoTela, initForm, salvar, excluir, getLista, getListaPerfil,
	setModoTelaItem, getListaItem, setModoTelaExcecao, getListaExcecao 
} from './operacaoActions';
import ColorUtils from '../common/colorUtils/ColorUtils';
import LabelAndCheckbox from '../common/form/labelAndCheckbox';

class Operacao extends Component {

	state = {
		linhaSelecionada: null
	}

    componentWillMount() {
        this.props.getLista();
        this.props.getListaPerfil();
    }

    render() {
        return (
            <div>
                <Content>

					<Aguardando aguardando={this.props.aguardando} />

					{this.props.modoTela == 'cadastro' ? (
						<Form onSubmit={this.props.salvar} />
					) : null}
					{this.props.modoTela == 'exclusao' ? (
						<Form excluir onSubmit={this.props.excluir} />
					) : null}
					{this.props.modoTela == 'lista' ? this.lista() : null}
                </Content>
            </div>
        )
    }

	lista() {

		const ExcelFile = ReactExport.ExcelFile;
		const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
		const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

		let lista  = this.props.lista.filter(item => {

			if (this.state.semPerfil && item.id_perfil) {
				return false;
			}

			if (this.state.pesquisar
				&& !(`${item.codigo} ${item.nome}`).toUpperCase().includes(this.state.pesquisar.toUpperCase())) {
				return false;
			}
			return true;
		});

		return (
			<ContentCard>
				<ContentCardHeader>
					<Row>
						<Grid cols='6 6 4 2'>
							<Button
								text='Adicionar'
								type={'success'}
								icon={'fa fa-plus'}
								event={() => {
									this.props.setModoTela('cadastro', {});
									this.props.initForm({});
									this.props.getListaItem(0);
									this.props.setModoTelaItem('lista');
									this.props.getListaExcecao(0);
									this.props.setModoTelaExcecao('lista');
								}} />
						</Grid>
						<Grid cols='6 6 3 2'>
							<ExcelFile
								filename={'Operações'}
								element={
									<Button
										text='Exportar'
										type={'primary'}
										icon={'fa fa-plus'} />
								}>
								<ExcelSheet data={lista.map(item => ({
									...item,
								}))} name='Operações'>
									<ExcelColumn label='Código' value='codigo' />
									<ExcelColumn label='Nome' value='nome' />
									<ExcelColumn label='Perfil' value='nome_perfil' />
								</ExcelSheet>
							</ExcelFile>
						</Grid>
					</Row>
				</ContentCardHeader>
				<ContentCardBody>

					<Row style={{ paddingTop: 16 }}>
						<LabelAndCheckbox
							label='Sem Perfil Vinculado' placeholder='Selecione'
							cols='12 12 12 12'
							value={this.state.semPerfil}
							onChange={(data) => {
								this.setState({
									...this.state,
									semPerfil: !this.state.semPerfil
								});
							}} />
					</Row>
					
					<input className='form-control'
						 placeholder='Pesquisar'
						 type='text'
						 value={this.state.pesquisar}
						 onChange={e => {
							 this.setState({ ...this.state, pesquisar: e.target.value });
						 }} />

					<Table responsive>
						<THead>
							<Tr>
								<Th>Código</Th>
								<Th>Nome</Th>
								<Th alignCenter>Perfil</Th>
								<Th></Th>
							</Tr>
						</THead>
						<TBody>
							{lista.map(item => (
								<Tr key={item.id} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
									onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
									onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
									<Td>{item.codigo}</Td>
									<Td>{item.nome}</Td>
									<Td alignCenter>
										<div style={{
											backgroundColor: item.cor_perfil,
											color: ColorUtils.getCorFonte(item.cor_perfil),
											padding: 8,
											borderRadius: '5%',
											margin: 6,
										}} >{item.nome_perfil}</div>
									</Td>
									<Td alignRight minWidth={100}>
										<ButtonTable
											type={'warning'}
											icon={'fas fa-pencil-alt'}
											visible={this.state.linhaSelecionada == item.id}
											event={() => {
												this.props.setModoTela('cadastro', {
													...item,
													email1: (item.emails || [])[0],
													email2: (item.emails || [])[1],
													email3: (item.emails || [])[2]
												});
												this.props.initForm({
													...item,
													email1: (item.emails || [])[0],
													email2: (item.emails || [])[1],
													email3: (item.emails || [])[2]
												});
												this.props.getListaItem(item.id);
												this.props.setModoTelaItem('lista');
												this.props.getListaExcecao(item.id);
												this.props.setModoTelaExcecao('lista');
											}} />

										<ButtonTable
											type={'danger'}
											icon={'fas fa-trash-alt'}
											visible={this.state.linhaSelecionada == item.id}
											event={() => {
												this.props.setModoTela('exclusao', {
													...item,
													email1: (item.emails || [])[0],
													email2: (item.emails || [])[1],
													email3: (item.emails || [])[2]
												});
												this.props.initForm({
													...item,
													email1: (item.emails || [])[0],
													email2: (item.emails || [])[1],
													email3: (item.emails || [])[2]
												});
												this.props.getListaItem(item.id);
												this.props.setModoTelaItem('lista');
												this.props.getListaExcecao(item.id);
												this.props.setModoTelaExcecao('lista');
											}} />
									</Td>
								</Tr>
							))}
						</TBody>
					</Table>
				</ContentCardBody>
			</ContentCard>
		);
	}


}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	modoTela: state.operacao.modoTela,
	aguardando: state.operacao.aguardando,
	lista: state.operacao.lista
});
const mapDispatchToProps = dispatch => bindActionCreators({ 
	setModoTela, initForm, salvar, excluir, getLista,  getListaPerfil,
	setModoTelaItem, getListaItem, setModoTelaExcecao, getListaExcecao 
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Operacao);
