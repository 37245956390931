import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import Form from './laudoControleItemForm';

import { setModoTela, initForm, salvar, excluir, getLista } from './laudoControleItemActions';
import FormatUtils from '../common/formatUtils/FormatUtils';
import DateFormat from '../common/dateFormat/DateFormat';
import Select from '../common/form/select';
import consts from '../consts';

class LaudoControleItem extends Component {

	state = {
		linhaSelecionada: null
	}

    componentWillMount() {
        this.props.getLista(this.props.id_controle, this.props.id_tipo);
    }

    render() {
        return (
            <div>
                <Content>
					{this.props.modoTela == 'cadastro' ? (
						<Form onSubmit={this.props.salvar} />
					) : null}
					{this.props.modoTela == 'exclusao' ? (
						<Form excluir onSubmit={this.props.excluir} />
					) : null}
					{this.props.modoTela == 'lista' ? this.lista() : null}
                </Content>
            </div>
        )
    }

	lista() {
		return (
			<ContentCard>
				<Row style={{ marginTop: 8 }}>
					<Grid cols='6 6 4 3'>
						<Button
							text='Novo Vencimento'
							type={'success'}
							icon={'fa fa-plus'}
							event={() => {
								this.props.setModoTela('cadastro', {});
								this.props.initForm({
									id_controle: this.props.id_controle,
									id_tipo: this.props.id_tipo,
									data_realizacao: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAtual()),
									data_vencimento: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAtual()).replace(new Date().getFullYear(), new Date().getFullYear() + 1)
								});
							}} />
					</Grid>
				</Row>
				<ContentCardBody>
					<Table responsive>
						<THead>
							<Tr>
								<Th width={120}alignCenter small>Realização</Th>
								<Th width={120}alignCenter small>Próx. Vencimento</Th>
								<Th width={200}alignCenter small>Observação</Th>
								<Th width={200}alignCenter small>Usuário</Th>
								<Th width={100}alignCenter small>Inclusão</Th>
								<Th width={100}alignCenter small>Alteração</Th>
								<Th width={10}alignCenter small>Anexos</Th>
								<Th width={60}></Th>
							</Tr>
						</THead>
						<TBody>
							{(this.props.lista.filter(item => {
								return true;
							})).map((item, i) => (
								<Tr key={item.id} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`} >
									<Td alignCenter small>{DateFormat.formatarDataSqlParaTela(item.data_realizacao)}</Td>
									<Td alignCenter small backgroundColor={i == 0 && item.vencido ? '#de0025' : null} color={i == 0 && item.vencido ? '#ffffff' : null}>{DateFormat.formatarDataSqlParaTela(item.data_vencimento)}</Td>
									<Td alignCenter small>{item.observacao}</Td>
									<Td alignCenter small>{item.nome_usuario_alteracao}</Td>
									<Td alignCenter small>{DateFormat.formatarDataHoraSqlParaTela(item.datahora_inclusao)}</Td>
									<Td alignCenter small>{DateFormat.formatarDataHoraSqlParaTela(item.datahora_alteracao)}</Td>
									<Td alignCenter small>
										{(item.anexos || []).length > 0 &&
										<ButtonTable
											type={'primary'}
											icon={'fas fa-file-download'}
											visible={true}
											small
											event={() => {
												(item.anexos || []).forEach(arquivo => {
													window.open(`${consts.URL}/${arquivo.arquivo}`, '_blank');
												});
											}} />}
									</Td>
									<Td alignRight minWidth={100}>
										<ButtonTable
											type={'warning'}
											icon={'fas fa-pencil-alt'}
											visible={true}
											small
											event={() => {
												this.props.setModoTela('cadastro', {
													...item,
													data_realizacao: DateFormat.formatarDataSqlParaTela(item.data_realizacao),
													data_vencimento: DateFormat.formatarDataSqlParaTela(item.data_vencimento)
												});
												this.props.initForm({
													...item,
													data_realizacao: DateFormat.formatarDataSqlParaTela(item.data_realizacao),
													data_vencimento: DateFormat.formatarDataSqlParaTela(item.data_vencimento)
												});
											}} />

										<ButtonTable
											type={'danger'}
											icon={'fas fa-trash-alt'}
											visible={true}
											small
											event={() => {
												this.props.setModoTela('exclusao', {
													...item,
													data_realizacao: DateFormat.formatarDataSqlParaTela(item.data_realizacao),
													data_vencimento: DateFormat.formatarDataSqlParaTela(item.data_vencimento)
												});
												this.props.initForm({
													...item,
													data_realizacao: DateFormat.formatarDataSqlParaTela(item.data_realizacao),
													data_vencimento: DateFormat.formatarDataSqlParaTela(item.data_vencimento)
												});
											}} />
									</Td>
								</Tr>
							))}
						</TBody>
					</Table>
				</ContentCardBody>
			</ContentCard>
		);
	}


}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	modoTela: state.laudoControleItem.modoTela,
	lista: state.laudoControleItem.lista
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, initForm, salvar, excluir, getLista }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(LaudoControleItem);
