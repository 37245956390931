import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import MenuItem from './menuItem'
import MenuTree from './menuTree'
import { withRouter } from 'react-router-dom';

import { carregarUsuario, alterarSenha } from '../../auth/authActions';
import LabelAndInput from '../form/labelAndInput';
import Row from '../layout/row';
import Button from '../button/button';
import { Grid } from '@material-ui/core';

class Menu extends Component {

	state = { 
		dadosLogin: {} ,
		cadastroAberto: null,
		integracaoAberto: null,
		laudoAberto: null,
		consultaMotoristaAberto: null,
		comprovacaoAberto: null,
		aprovacaoDespesasAberto: null,
		acessoriosAberto: null,
		valePedagioAberto: null,
		comissaoVariavelAberto: null
	};

	constructor(props) {
		super(props);

		let idInterval = setInterval(() => {
			if (this.props.usuario) {
				this.props.carregarUsuario();
				clearInterval(idInterval);
			}
		}, 2000);
	}

	render() {

		let selecionado = '';
		if (this.props.location && this.props.location.pathname) {
			selecionado = this.props.location.pathname;
		}

		// if (this.props.usuario && this.props.usuario.modoCliente && (
		// 	selecionado == '/usuario' || selecionado == '/cliente' || selecionado == '/transportadora' || selecionado == '/embalagem'
		// 	|| selecionado == '/fornecedor'	|| selecionado == '/fornecedorEmbalagem' || selecionado == '/embalagemEntrada'
		// 	|| selecionado == '/embalagemSaidaMotivo' || selecionado == '/embalagemSaida' || selecionado == '/embalagemViagem'
		// 	|| selecionado == '/embalagemInventario' || selecionado == '/embalagemViagemComprovante' || selecionado == '/inventario')) {
		//
		// 	window.location = '/';
		// }

		if (this.props.usuarioCarregado) {
			if (!this.props.usuarioCarregado.acesso_usuario && (selecionado == '/usuario' || selecionado == '/usuarioCliente' || selecionado == '/usuarioPerfil')) {
				window.location = '/';
			}
			if (!this.props.usuarioCarregado.reports && (selecionado == '/reports')) {
				window.location = '/';
			}
			if (!this.props.usuarioCarregado.acesso_cadastro && (selecionado == '/grupo' || selecionado == '/motorista' || selecionado == '/premioFaixa'
				|| selecionado == '/ocorrenciaTipo')) {
				window.location = '/';
			}
			if (!this.props.usuarioCarregado.acesso_premiacao && (selecionado == '/premiacao')) {
				window.location = '/';
			}
			if (!this.props.usuarioCarregado.acesso_cnh && (selecionado == '/motoristaCnh' || selecionado == '/motoristaCpf')) {
				window.location = '/';
			}
			if (!this.props.usuarioCarregado.acesso_documento && (selecionado == '/operacao')) {
				window.location = '/';
			}
			if (!this.props.usuarioCarregado.acesso_operacao && (selecionado == '/operacao')) {
				window.location = '/';
			}
			if (!this.props.usuarioCarregado.acesso_comprovante_aberta_visualizar && !this.props.usuarioCarregado.acesso_comprovante_aberta_alterar 
				&& (selecionado == '/documentoViagem')) {
				window.location = '/';
			}
			if (!this.props.usuarioCarregado.acesso_comprovante_encerrada_visualizar && !this.props.usuarioCarregado.acesso_comprovante_encerrada_alterar 
				&& !this.props.usuarioCarregado.acesso_comprovante_encerrada_desfazer && (selecionado == '/documentoViagemComprovacao')) {
				window.location = '/';
			}
			if (!this.props.usuarioCarregado.acesso_comprovante_financeiro_visualizar && !this.props.usuarioCarregado.acesso_comprovante_financeiro_alterar 
				&& (selecionado == '/documentoViagemComprovada')) {
				window.location = '/';
			}
			if (!this.props.usuarioCarregado.acesso_comprovante_finalizada_visualizar && !this.props.usuarioCarregado.acesso_comprovante_finalizada_alterar 
				&& !this.props.usuarioCarregado.acesso_comprovante_finalizada_desfazer && (selecionado == '/documentoViagemFinalizada')) {
				window.location = '/';
			}

			if (!this.props.usuarioCarregado.acesso_vale_pedagio && (
				selecionado == '/valePedagio' || selecionado == '/valePedagioMotivo' || selecionado == '/valePedagioPeriodo'
				|| selecionado == '/valePedagioMotorista' || selecionado == '/valePedagioSaldo')) {
				window.location = '/';
			}

			if (!this.props.usuarioCarregado.administrador && (
				selecionado == '/laudoOcorrenciaTipo' || selecionado == '/laudoControle')) {
				window.location = '/';
			}

			if (this.state.cadastroAberto == null) {								
				if (selecionado == '/usuarioPerfil' || selecionado == '/usuario' || selecionado == '/usuarioCliente' || selecionado == '/grupo' 
					|| selecionado == '/motorista' || selecionado == '/premioFaixa' || selecionado == '/ocorrenciaTipo' || selecionado == '/ocorrenciaAcao' 
					|| selecionado == '/ocorrenciaStatus' || selecionado == '/documento' || selecionado == '/operacaoPerfil' || selecionado == '/operacao') {
					this.setState({
						...this.state,
						cadastroAberto: true
					});
				}
			}

			if (this.state.integracaoAberto == null) {
				if (selecionado == '/reportsIntegracaoGM' || selecionado == '/reportsIntegracaoTetraPak' || selecionado == '/braskem') {
					this.setState({
						...this.state,
						integracaoAberto: true
					});
				}
			}

			if (this.state.consultaMotoristaAberto == null) {
				if (selecionado == '/motoristaCnh' || selecionado == '/motoristaCpf') {
					this.setState({
						...this.state,
						consultaMotoristaAberto: true
					});
				}
			}

			if (this.state.comprovacaoAberto == null) {
				if (selecionado == '/documentoViagem' || selecionado == '/documentoViagemComprovacao' 
					|| selecionado == '/documentoViagemComprovada' || selecionado == '/documentoViagemFinalizada' 
					|| selecionado == '/ocorrenciaDocumentoViagem') {
					this.setState({
						...this.state,
						comprovacaoAberto: true
					});
				}
			}

			if (this.state.aprovacaoDespesasAberto == null) {
				if (selecionado == '/despesaTipo' || selecionado == '/operacaoDespesa' || selecionado == '/despesa') {
					this.setState({
						...this.state,
						aprovacaoDespesasAberto: true
					});
				}
			}

			if (this.state.acessoriosAberto == null) {
				if (selecionado == '/usuarioAcessorio' || selecionado == '/unidade' || selecionado == '/veiculo' || selecionado == '/acessorioTipo' || 
					selecionado == '/acessorioEvento' || selecionado == '/acessorioSaldo' || selecionado == '/acessorioHistorico' || 
					selecionado == '/acessorioEntrada' || selecionado == '/acessorioTransferencia' || selecionado == '/acessorioSaida' || 
					selecionado == '/acessorioInventario') {
					this.setState({
						...this.state,
						acessoriosAberto: true
					});
				}
			}

			if (this.state.valePedagioAberto == null) {
				if (selecionado == '/valePedagio' || selecionado == '/valePedagioMotivo' || selecionado == '/valePedagioPeriodo' ||
					selecionado == '/valePedagioMotorista' || selecionado == '/valePedagioSaldo' || selecionado == '/valePedagioSaldoPeriodo' ||
					selecionado == '/valePedagioMotivoPeriodo') {
					this.setState({
						...this.state,
						valePedagioAberto: true
					});
				}
			}

			if (this.state.comissaoVariavelAberto == null) {
				if (selecionado == '/comissaoTipo' || selecionado == '/comissaoOperacao' || selecionado == '/comissaoApuracao') {
					this.setState({
						...this.state,
						comissaoVariavelAberto: true
					});
				}
			}

			if (this.state.laudoAberto == null) {
				if (selecionado == '/laudoOcorrenciaTipo' || selecionado == '/laudoControle') {
					this.setState({
						...this.state,
						laudoAberto: true
					});
				}
			}
		}

		let administrador = this.props.usuarioCarregado && this.props.usuarioCarregado.id_perfil == 1; //Administrador

		let laudos = this.props.usuarioCarregado && (administrador || [6, 11, 41, 219].includes(this.props.usuarioCarregado.id)); //Diego Metztorf Mallmann, Leonardo Muller Maass, Renan Fucilini, Fabieli Schwinn de Lima

		let braskem = this.props.usuarioCarregado && (administrador || [20].includes(this.props.usuarioCarregado.id)); //Andrea Andrade Alves 

		if (this.props.usuarioCarregado && !this.props.usuarioCarregado.acesso_cliente) {
			return (
				<nav className="mt-2">

					{this.props.usuarioCarregado.primeiro_acesso ? this.modalPrimeiroAcesso() : null}

					<ul className="nav nav-pills nav-sidebar flex-column nav-flat nav-compact" data-widget="treeview" role="menu" data-accordion="false">
						<MenuItem path='/' label='Dashboard' icon='fas fa-tachometer-alt' active={selecionado == '/'} />

						{(this.props.usuarioCarregado && (
							this.props.usuarioCarregado.acesso_usuario || this.props.usuarioCarregado.acesso_cadastro || this.props.usuarioCarregado.acesso_usuario
							|| this.props.usuarioCarregado.acesso_ocorrencia || this.props.usuarioCarregado.acesso_premiacao || this.props.usuarioCarregado.acesso_cnh
							|| this.props.usuarioCarregado.acesso_documento || this.props.usuarioCarregado.acesso_operacao 
							|| this.props.usuarioCarregado.acesso_comprovante_aberta_visualizar || this.props.usuarioCarregado.acesso_comprovante_aberta_alterar
							|| this.props.usuarioCarregado.acesso_comprovante_encerrada_visualizar || this.props.usuarioCarregado.acesso_comprovante_encerrada_alterar
							|| this.props.usuarioCarregado.acesso_comprovante_encerrada_desfazer || this.props.usuarioCarregado.acesso_comprovante_financeiro_visualizar
							|| this.props.usuarioCarregado.acesso_comprovante_financeiro_alterar || this.props.usuarioCarregado.acesso_comprovante_finalizada_visualizar
							|| this.props.usuarioCarregado.acesso_comprovante_finalizada_alterar || this.props.usuarioCarregado.acesso_comprovante_finalizada_desfazer
						)) &&
						<MenuTree icon={'fas fa-th-list'} label={'Cadastro'}
							display={this.state.cadastroAberto}
							onClick={() =>
								this.setState({
									...this.state,
									cadastroAberto: !this.state.cadastroAberto,
								})
							} >
							{this.props.usuarioCarregado && this.props.usuarioCarregado.acesso_usuario ? (
								<MenuItem submenu path='usuarioPerfil' label='Perfil de Usuário' icon='fas fa-user-tag' active={selecionado == '/usuarioPerfil'} />
							) :null}
							{this.props.usuarioCarregado && this.props.usuarioCarregado.acesso_usuario ? (
								<MenuItem submenu path='usuario' label='Usuário' icon='fas fa-users' active={selecionado == '/usuario'} />
							) : null}
							{this.props.usuarioCarregado && this.props.usuarioCarregado.acesso_usuario ? (
								<MenuItem submenu path='usuarioCliente' label='Acesso do Cliente' icon='fas fa-user-tie' active={selecionado == '/usuarioCliente'} />
							) : null}
							{this.props.usuarioCarregado && this.props.usuarioCarregado.acesso_cadastro ? (
								<MenuItem submenu path='grupo' label='Grupo' icon='fas fa-th-large' active={selecionado == '/grupo'} />
							) : null}
							{this.props.usuarioCarregado && this.props.usuarioCarregado.acesso_cadastro ? (
								<MenuItem submenu path='motorista' label='Motorista' icon='fas fa-user-plus' active={selecionado == '/motorista'} />
							) : null}
							{this.props.usuarioCarregado && this.props.usuarioCarregado.acesso_cadastro ? (
								<MenuItem submenu path='premioFaixa' label='Faixa de Km' icon='fas fa-list-alt' active={selecionado == '/premioFaixa'} />
							) :null}
							{this.props.usuarioCarregado && this.props.usuarioCarregado.acesso_cadastro ? (
								<MenuItem submenu path='ocorrenciaTipo' label='Tipo de Ocorrência' icon='fas fa-th-list' active={selecionado == '/ocorrenciaTipo'} />
							) : null}
							{this.props.usuarioCarregado && (this.props.usuarioCarregado.acesso_usuario || this.props.usuarioCarregado.acesso_cadastro ||
							this.props.usuarioCarregado.acesso_ocorrencia || this.props.usuarioCarregado.acesso_premiacao ||
							this.props.usuarioCarregado.acesso_cnh || this.props.usuarioCarregado.acesso_documento ||
							this.props.usuarioCarregado.acesso_operacao || this.props.usuarioCarregado.acesso_comprovante_aberta_visualizar || 
							this.props.usuarioCarregado.acesso_comprovante_aberta_alterar || this.props.usuarioCarregado.acesso_comprovante_encerrada_visualizar ||
							this.props.usuarioCarregado.acesso_comprovante_encerrada_alterar || this.props.usuarioCarregado.acesso_comprovante_encerrada_desfazer ||
							this.props.usuarioCarregado.acesso_comprovante_financeiro_visualizar || this.props.usuarioCarregado.acesso_comprovante_financeiro_alterar ||
							this.props.usuarioCarregado.acesso_comprovante_finalizada_visualizar || this.props.usuarioCarregado.acesso_comprovante_finalizada_alterar ||
							this.props.usuarioCarregado.acesso_comprovante_finalizada_desfazer) ? (
								<MenuItem submenu path='ocorrenciaAcao' label='Ação da Ocorrência' icon='fas fa-th-list' active={selecionado == '/ocorrenciaAcao'} />
							) : null}
							{this.props.usuarioCarregado && (this.props.usuarioCarregado.acesso_usuario || this.props.usuarioCarregado.acesso_cadastro ||
							this.props.usuarioCarregado.acesso_ocorrencia || this.props.usuarioCarregado.acesso_premiacao ||
							this.props.usuarioCarregado.acesso_cnh || this.props.usuarioCarregado.acesso_documento ||
							this.props.usuarioCarregado.acesso_operacao || this.props.usuarioCarregado.acesso_comprovante_aberta_visualizar || 
							this.props.usuarioCarregado.acesso_comprovante_aberta_alterar || this.props.usuarioCarregado.acesso_comprovante_encerrada_visualizar ||
							this.props.usuarioCarregado.acesso_comprovante_encerrada_alterar || this.props.usuarioCarregado.acesso_comprovante_encerrada_desfazer ||
							this.props.usuarioCarregado.acesso_comprovante_financeiro_visualizar || this.props.usuarioCarregado.acesso_comprovante_financeiro_alterar ||
							this.props.usuarioCarregado.acesso_comprovante_finalizada_visualizar || this.props.usuarioCarregado.acesso_comprovante_finalizada_alterar ||
							this.props.usuarioCarregado.acesso_comprovante_finalizada_desfazer) ? (
								<MenuItem submenu path='ocorrenciaStatus' label='Status da Ocorrência' icon='fas fa-th-list' active={selecionado == '/ocorrenciaStatus'} />
							) : null}
							{this.props.usuarioCarregado && this.props.usuarioCarregado.acesso_documento ? (
								<MenuItem submenu path='documento' label='Documento' icon='fas fa-sticky-note' active={selecionado == '/documento'} />
							) : null}
							{this.props.usuarioCarregado && this.props.usuarioCarregado.acesso_operacao ? (
								<MenuItem submenu path='operacaoPerfil' label='Perfil de Operação' icon='fas fa-tags' active={selecionado == '/operacaoPerfil'} />
							) : null}
							{this.props.usuarioCarregado && this.props.usuarioCarregado.acesso_operacao ? (
								<MenuItem submenu path='operacao' label='Operação' icon='fas fa-th-large' active={selecionado == '/operacao'} />
							) : null}
						</MenuTree>}

						{this.props.usuarioCarregado && this.props.usuarioCarregado.reports ? (
							<MenuItem path='reports' label='Reports' icon='fas fa-chart-line' active={selecionado == '/reports'} />
						) : null}
						{this.props.usuarioCarregado && this.props.usuarioCarregado.reports ? (
							<MenuItem path='reportsConformidade' label='Não Conformidades' icon='fas fa-exclamation-triangle' active={selecionado == '/reportsConformidade'} />
						) : null}
						{this.props.usuarioCarregado && this.props.usuarioCarregado.track ? (
							<MenuItem path='reportsTrack' label='Track 2.0' icon='fas fa-route' active={selecionado == '/reportsTrack'} />
						) : null}
						{this.props.usuarioCarregado && this.props.usuarioCarregado.reports ? (
							<MenuItem path='reportsHistorico' label='Reports - Histórico' icon='fas fa-history' active={selecionado == '/reportsHistorico'} />
						) : null}
						
						{this.props.usuarioCarregado && this.props.usuarioCarregado.track ? (
							<li class='nav-item'>
								<a class='nav-link' href='https://cliente.letsara.infinitum-ti.com/' target='_blank'>
									<i class='nav-icon fas fa-mobile-alt'></i>
									<p>
										App Cliente
										<span class='badge badge-undefined right'></span>
									</p>
								</a>
							</li>
						) : null}

						{laudos &&
						<MenuTree icon={'fas fa-tasks'} label={'Gestão de Laudos'}
							display={this.state.laudoAberto}
							onClick={() =>
								this.setState({
									...this.state,
									laudoAberto: !this.state.laudoAberto,
								})
							} >
							<MenuItem submenu path='laudoOcorrenciaTipo' label='Modelo de Ocorrência' icon='fas fa-list' active={selecionado == '/laudoOcorrenciaTipo'} />
							<MenuItem submenu path='laudoControle' label='Controle dos Laudos' icon='fas fa-file-invoice' active={selecionado == '/laudoControle'} />
						</MenuTree>}

						{(administrador || braskem) &&
						<MenuTree icon={'fas fa-project-diagram'} label={'Integração'}
							display={this.state.integracaoAberto}
							onClick={() =>
								this.setState({
									...this.state,
									integracaoAberto: !this.state.integracaoAberto,
								})
							} >
							{administrador &&
							<MenuItem submenu path='reportsIntegracaoGM' label='GM - Freight Verify' icon='fas fa-project-diagram' active={selecionado == '/reportsIntegracaoGM'} />}
							{administrador &&
							<MenuItem submenu path='reportsIntegracaoTetraPak' label='Tetra Pak' icon='fas fa-project-diagram' active={selecionado == '/reportsIntegracaoTetraPak'} />}
							<MenuItem submenu path='braskem' label='Braskem' icon='fas fa-project-diagram' active={selecionado == '/braskem'} />
						</MenuTree>}
						
						{this.props.usuarioCarregado && ((this.props.usuarioCarregado.id_perfil != 30 && this.props.usuarioCarregado.id_perfil != 31) /*CCO e REPORTS*/ || this.props.usuarioCarregado.acesso_usuario || this.props.usuarioCarregado.acesso_cadastro ||
						this.props.usuarioCarregado.acesso_ocorrencia || this.props.usuarioCarregado.acesso_premiacao ||
						this.props.usuarioCarregado.acesso_cnh || this.props.usuarioCarregado.acesso_documento ||
						this.props.usuarioCarregado.acesso_operacao || this.props.usuarioCarregado.acesso_comprovante_aberta_visualizar || 
						this.props.usuarioCarregado.acesso_comprovante_aberta_alterar || this.props.usuarioCarregado.acesso_comprovante_encerrada_visualizar ||
						this.props.usuarioCarregado.acesso_comprovante_encerrada_alterar || this.props.usuarioCarregado.acesso_comprovante_encerrada_desfazer ||
						this.props.usuarioCarregado.acesso_comprovante_financeiro_visualizar || this.props.usuarioCarregado.acesso_comprovante_financeiro_alterar ||
						this.props.usuarioCarregado.acesso_comprovante_finalizada_visualizar || this.props.usuarioCarregado.acesso_comprovante_finalizada_alterar ||
						this.props.usuarioCarregado.acesso_comprovante_finalizada_desfazer) ? (
							<MenuItem path='ocorrencia' label='Ocorrência' icon='fas fa-clipboard-list' active={selecionado == '/ocorrencia'} />
						) : null}
						{this.props.usuarioCarregado && this.props.usuarioCarregado.acesso_premiacao ? (
							<MenuItem path='premiacao' label='Premiação' icon='fas fa-trophy' active={selecionado == '/premiacao'} />
						) : null}

						{(this.props.usuarioCarregado && this.props.usuarioCarregado.acesso_cnh) &&
						<MenuTree icon={'fas fa-search'} label={'Consulta Motorista'}
							display={this.state.consultaMotoristaAberto}
							onClick={() =>
								this.setState({
									...this.state,
									consultaMotoristaAberto: !this.state.consultaMotoristaAberto,
								})
							} >
							<MenuItem submenu path='motoristaCnh' label='CNH - Validade' icon='fas fa-id-badge' active={selecionado == '/motoristaCnh'} />
							<MenuItem submenu path='motoristaCpf' label='CPF - Situação' icon='fas fa-id-card' active={selecionado == '/motoristaCpf'} />
						</MenuTree>}

						{this.props.usuarioCarregado && (
							this.props.usuarioCarregado.acesso_comprovante_aberta_visualizar 
							|| this.props.usuarioCarregado.acesso_comprovante_aberta_alterar
							|| this.props.usuarioCarregado.id == 94 //Líder Despachos
							|| this.props.usuarioCarregado.id == 90 //Erik
							|| this.props.usuarioCarregado.id == 20 //Andrea Andrade Alves 
						) ? (
							<MenuItem path='comprovanteEntrega' label='Comprovante de Entrega' icon='fas fa-file-signature' active={selecionado == '/comprovanteEntrega'} />
						) : null}


						{(this.props.usuarioCarregado && (
							this.props.usuarioCarregado.acesso_comprovante_aberta_visualizar || this.props.usuarioCarregado.acesso_comprovante_aberta_alterar							
							|| this.props.usuarioCarregado.acesso_comprovante_encerrada_visualizar || this.props.usuarioCarregado.acesso_comprovante_encerrada_alterar 
							|| this.props.usuarioCarregado.acesso_comprovante_encerrada_desfazer || this.props.usuarioCarregado.acesso_comprovante_financeiro_visualizar
							|| this.props.usuarioCarregado.acesso_comprovante_financeiro_alterar || this.props.usuarioCarregado.acesso_comprovante_finalizada_visualizar
							|| this.props.usuarioCarregado.acesso_comprovante_finalizada_alterar || this.props.usuarioCarregado.acesso_comprovante_finalizada_desfazer
							|| this.props.usuarioCarregado.acesso_usuario || this.props.usuarioCarregado.acesso_cadastro || this.props.usuarioCarregado.acesso_ocorrencia
							|| this.props.usuarioCarregado.acesso_premiacao || this.props.usuarioCarregado.acesso_cnh || this.props.usuarioCarregado.acesso_documento
							|| this.props.usuarioCarregado.acesso_operacao							
							|| this.props.usuarioCarregado.id == 76  // Viviane Rodrigues Moreira
							|| this.props.usuarioCarregado.id == 67  // Agatha Nila Correa Maciel
							|| this.props.usuarioCarregado.id == 68  // Arielli Jaqueline Gross Boeno
							|| this.props.usuarioCarregado.id == 70  // Elisangela Lira
							|| this.props.usuarioCarregado.id == 71  // Ricardo Lazzaretti Neto
							|| this.props.usuarioCarregado.id == 72  // Isadora Konarzewsky Patzer
							|| this.props.usuarioCarregado.id == 73  // Flaviane Rodrigues Oliveira
							|| this.props.usuarioCarregado.id == 74  // Thiago Ribeiro Lopes
							|| this.props.usuarioCarregado.id == 75  // Daniela Ribeiro Autori
							|| this.props.usuarioCarregado.id == 20  // Andrea Andrade Alves
							|| this.props.usuarioCarregado.id == 9   // Fabiana Ribeiro de Almeida-
							|| this.props.usuarioCarregado.id == 60  // EDMILSON DA SILVA SERPA
							|| this.props.usuarioCarregado.id == 10  // Kelvin Lima de Lemos
							|| this.props.usuarioCarregado.id == 77  // Lucas dos Santos Serpa
							|| this.props.usuarioCarregado.id == 78  // Carlos Eduardo Pereira
							|| this.props.usuarioCarregado.id == 79  // Jefferson da Cruz Cardoso
							|| this.props.usuarioCarregado.id == 6   // Diego Metztorf Mallmann
							|| this.props.usuarioCarregado.id == 110 // Ricardo Gabriel
							|| this.props.usuarioCarregado.id == 120 // Bruna Grazieli Barbosa
						)) &&
						<MenuTree icon={'fas fa-file-invoice-dollar'} label={'Documento - Comprovantes'}
							display={this.state.comprovacaoAberto}
							onClick={() =>
								this.setState({
									...this.state,
									comprovacaoAberto: !this.state.comprovacaoAberto,
								})
							} >	
							{this.props.usuarioCarregado && (this.props.usuarioCarregado.acesso_comprovante_aberta_visualizar || this.props.usuarioCarregado.acesso_comprovante_aberta_alterar) ? (
								<MenuItem submenu path='documentoViagem' label='Viagem - Aberta' icon='fas fa-paste' active={selecionado == '/documentoViagem'} />
							) : null}
							{this.props.usuarioCarregado && (this.props.usuarioCarregado.acesso_comprovante_encerrada_visualizar || this.props.usuarioCarregado.acesso_comprovante_encerrada_alterar || this.props.usuarioCarregado.acesso_comprovante_encerrada_desfazer) ? (
								<MenuItem submenu path='documentoViagemComprovacao' label='Viagem - Encerrada' icon='fas fa-clipboard-check' active={selecionado == '/documentoViagemComprovacao'} />
							) : null}
							{this.props.usuarioCarregado && (this.props.usuarioCarregado.acesso_comprovante_financeiro_visualizar || this.props.usuarioCarregado.acesso_comprovante_financeiro_alterar) ? (
								<MenuItem submenu path='documentoViagemComprovada' label='Viagem - Financeiro' icon='fas fa-dollar-sign' active={selecionado == '/documentoViagemComprovada'} />
							) : null}
							{this.props.usuarioCarregado && (this.props.usuarioCarregado.acesso_comprovante_finalizada_visualizar || this.props.usuarioCarregado.acesso_comprovante_finalizada_alterar || this.props.usuarioCarregado.acesso_comprovante_finalizada_desfazer) ? (
								<MenuItem submenu path='documentoViagemFinalizada' label='Viagem - Finalizada' icon='fas fa-check-double' active={selecionado == '/documentoViagemFinalizada'} />
							) : null}
							
							{this.props.usuarioCarregado && (this.props.usuarioCarregado.acesso_usuario || this.props.usuarioCarregado.acesso_cadastro ||
							this.props.usuarioCarregado.acesso_ocorrencia || this.props.usuarioCarregado.acesso_premiacao ||
							this.props.usuarioCarregado.acesso_cnh || this.props.usuarioCarregado.acesso_documento ||
							this.props.usuarioCarregado.acesso_operacao || this.props.usuarioCarregado.acesso_comprovante_aberta_visualizar || 
							this.props.usuarioCarregado.acesso_comprovante_aberta_alterar || this.props.usuarioCarregado.acesso_comprovante_encerrada_visualizar ||
							this.props.usuarioCarregado.acesso_comprovante_encerrada_alterar || this.props.usuarioCarregado.acesso_comprovante_encerrada_desfazer ||
							this.props.usuarioCarregado.acesso_comprovante_financeiro_visualizar || this.props.usuarioCarregado.acesso_comprovante_financeiro_alterar ||
							this.props.usuarioCarregado.acesso_comprovante_finalizada_visualizar || this.props.usuarioCarregado.acesso_comprovante_finalizada_alterar ||
							this.props.usuarioCarregado.acesso_comprovante_finalizada_desfazer

							|| this.props.usuarioCarregado.id == 76  // Viviane Rodrigues Moreira
							|| this.props.usuarioCarregado.id == 67  // Agatha Nila Correa Maciel
							|| this.props.usuarioCarregado.id == 68  // Arielli Jaqueline Gross Boeno
							|| this.props.usuarioCarregado.id == 70  // Elisangela Lira
							|| this.props.usuarioCarregado.id == 71  // Ricardo Lazzaretti Neto
							|| this.props.usuarioCarregado.id == 72  // Isadora Konarzewsky Patzer
							|| this.props.usuarioCarregado.id == 73  // Flaviane Rodrigues Oliveira
							|| this.props.usuarioCarregado.id == 74  // Thiago Ribeiro Lopes
							|| this.props.usuarioCarregado.id == 75  // Daniela Ribeiro Autori
							|| this.props.usuarioCarregado.id == 20  // Andrea Andrade Alves
							|| this.props.usuarioCarregado.id == 9   // Fabiana Ribeiro de Almeida-
							|| this.props.usuarioCarregado.id == 60  // EDMILSON DA SILVA SERPA
							|| this.props.usuarioCarregado.id == 10  // Kelvin Lima de Lemos
							|| this.props.usuarioCarregado.id == 77  // Lucas dos Santos Serpa
							|| this.props.usuarioCarregado.id == 78  // Carlos Eduardo Pereira
							|| this.props.usuarioCarregado.id == 79  // Jefferson da Cruz Cardoso
							|| this.props.usuarioCarregado.id == 6   // Diego Metztorf Mallmann
							|| this.props.usuarioCarregado.id == 110 // Ricardo Gabriel 
							|| this.props.usuarioCarregado.id == 120 // Bruna Grazieli Barbosa
							) ? (
								<MenuItem submenu path='ocorrenciaDocumentoViagem' label='Viagem - Ocorrência' icon='fas fa-clipboard-list' active={selecionado == '/ocorrenciaDocumentoViagem'} />
							) : null}

						</MenuTree>}
						
						{(this.props.usuarioCarregado && (
							this.props.usuarioCarregado.id == 1 || this.props.usuarioCarregado.id == 65 || this.props.usuarioCarregado.id == 30 
							|| this.props.usuarioCarregado.id == 16 || this.props.usuarioCarregado.id == 21 || this.props.usuarioCarregado.id == 63 
							|| this.props.usuarioCarregado.id == 13
						)) &&
						<MenuTree icon={'fas fa-comments-dollar'} label={'Aprovação de Despesas'}
							display={this.state.aprovacaoDespesasAberto}
							onClick={() =>
								this.setState({
									...this.state,
									aprovacaoDespesasAberto: !this.state.aprovacaoDespesasAberto,
								})
							} >
							<MenuItem submenu path='despesaTipo' label='Tipo de Despesa' icon='fas fa-funnel-dollar' active={selecionado == '/despesaTipo'} />
							<MenuItem submenu path='operacaoDespesa' label='Despesa (Repasse Cliente)' icon='fas fa-money-check-alt' active={selecionado == '/operacaoDespesa'} />
							<MenuItem submenu path='despesa' label='Despesa' icon='fas fa-money-check' active={selecionado == '/despesa'} />
						</MenuTree>}
			
						{(this.props.usuarioCarregado && this.props.usuarioCarregado.acessorio_menu) &&
						<MenuTree icon={'fas fa-pallet'} label={'Acessórios'}
							display={this.state.acessoriosAberto}
							onClick={() =>
								this.setState({
									...this.state,
									acessoriosAberto: !this.state.acessoriosAberto,
								})
							} >
							{this.props.usuarioCarregado.acessorio_acesso &&
							<MenuItem submenu path='usuarioAcessorio' label='Configuração de Acesso' icon='fas fa-user-cog' active={selecionado == '/usuarioAcessorio'} />}
							{this.props.usuarioCarregado.acessorio_cadastro &&
							<MenuItem submenu path='unidade' label='Cadastro de Unidade' icon='fas fa-warehouse' active={selecionado == '/unidade'} />}
							{this.props.usuarioCarregado.acessorio_cadastro &&
							<MenuItem submenu path='veiculo' label='Cadastro de Veículo' icon='fas fa-truck-moving' active={selecionado == '/veiculo'} />}
							{this.props.usuarioCarregado.acessorio_cadastro &&
							<MenuItem submenu path='acessorioTipo' label='Cadastro de Acessório' icon='fas fa-pallet' active={selecionado == '/acessorioTipo'} />}
							{this.props.usuarioCarregado.acessorio_cadastro &&
							<MenuItem submenu path='acessorioEvento' label='Cadastro de Evento' icon='fas fa-flag' active={selecionado == '/acessorioEvento'} />}
							{this.props.usuarioCarregado.acessorio_saldo &&
							<MenuItem submenu path='acessorioSaldo' label='Acessório - Saldo' icon='fas fa-list' active={selecionado == '/acessorioSaldo'} />}
							{this.props.usuarioCarregado.acessorio_historico &&
							<MenuItem submenu path='acessorioHistorico' label='Acessório - Histórico' icon='fas fa-list-ol' active={selecionado == '/acessorioHistorico'} />}
							{this.props.usuarioCarregado.acessorio_entrada &&
							<MenuItem submenu path='acessorioEntrada' label='Acessório - Entrada' icon='fas fa-plus-square' active={selecionado == '/acessorioEntrada'} />}
							{(this.props.usuarioCarregado.acessorio_transferencia_unidade || this.props.usuarioCarregado.acessorio_transferencia_veiculo) &&
							<MenuItem submenu path='acessorioTransferencia' label='Acessório - Transferência' icon='fas fa-exchange-alt' active={selecionado == '/acessorioTransferencia'} />}
							{this.props.usuarioCarregado.acessorio_saida &&
							<MenuItem submenu path='acessorioSaida' label='Acessório - Saída' icon='fas fa-minus-square' active={selecionado == '/acessorioSaida'} />}
							{this.props.usuarioCarregado.acessorio_inventario &&
							<MenuItem submenu path='acessorioInventario' label='Acessório - Inventário' icon='fas fa-clipboard-list' active={selecionado == '/acessorioInventario'} />}
						</MenuTree>}

						{(this.props.usuarioCarregado && (
							this.props.usuarioCarregado.acesso_vale_pedagio
						)) &&
						<MenuTree icon={'fas fa-money-check'} label={'Vale Pedágio'}
							display={this.state.valePedagioAberto}
							onClick={() =>
								this.setState({
									...this.state,
									valePedagioAberto: !this.state.valePedagioAberto,
								})
							} >
							<MenuItem submenu path='valePedagio' label='Vale Pedágio' icon='far fa-list-alt' active={selecionado == '/valePedagio'} />
							<MenuItem submenu path='valePedagioMotivo' label='Motivo - Vale Pedágio' icon='fas fa-stream' active={selecionado == '/valePedagioMotivo'} />
							<MenuItem submenu path='valePedagioPeriodo' label='Período - Vale Pedágio' icon='far fa-calendar-alt' active={selecionado == '/valePedagioPeriodo'} />
							<MenuItem submenu path='valePedagioMotorista' label='Conta Corrente - Pedágio' icon='fas fa-money-check' active={selecionado == '/valePedagioMotorista'} />
							<MenuItem submenu path='valePedagioSaldo' label='Saldos - Pedágio' icon='fas fa-money-check-alt' active={selecionado == '/valePedagioSaldo'} />
							<MenuItem submenu path='valePedagioSaldoPeriodo' label='Saldos por Período - Pedágio' icon='fas fa-money-check' active={selecionado == '/valePedagioSaldoPeriodo'} />
							<MenuItem submenu path='valePedagioMotivoPeriodo' label='Motivos por Período - Pedágio' icon='fas fa-list-ol' active={selecionado == '/valePedagioMotivoPeriodo'} />
						</MenuTree>}

						{(this.props.usuarioCarregado && (
							this.props.usuarioCarregado.id == 1 // Infinitum
							|| this.props.usuarioCarregado.id == 30 // Clarissa Brauner
							|| this.props.usuarioCarregado.id == 63 // Guilherme Toldo Stulp
						)) &&
						<MenuTree icon={'fas fa-search-dollar'} label={'Comissão Variável'}
							display={this.state.comissaoVariavelAberto}
							onClick={() =>
								this.setState({
									...this.state,
									comissaoVariavelAberto: !this.state.comissaoVariavelAberto,
								})
							} >
							<MenuItem path='comissaoTipo' label='Comissão - Tipo' icon='fas fa-list' active={selecionado == '/comissaoTipo'} />
							<MenuItem path='comissaoOperacao' label='Comissão - Operação' icon='fas fa-percentage' active={selecionado == '/comissaoOperacao'} />
							<MenuItem path='comissaoApuracao' label='Comissão - Apuração' icon='fas fa-search-dollar' active={selecionado == '/comissaoApuracao'} />
						</MenuTree>}
					</ul>
				</nav>
			);
		
		} else if (this.props.usuarioCarregado && this.props.usuarioCarregado.acesso_cliente) {

			return (
				<nav className="mt-2">

					{this.props.usuarioCarregado.primeiro_acesso ? this.modalPrimeiroAcesso() : null}

					<ul className="nav nav-pills nav-sidebar flex-column nav-flat nav-compact" data-widget="treeview" role="menu" data-accordion="false">
						{this.props.usuarioCarregado && this.props.usuarioCarregado.track ? (
							<MenuItem path='reportsTrack' label='Track 2.0' icon='fas fa-route' active={selecionado == '/'} />
						) : null}
						{this.props.usuarioCarregado && this.props.usuarioCarregado.reports ? (
							<MenuItem path='reports' label='Reports' icon='fas fa-chart-line' active={selecionado == (this.props.usuarioCarregado.track ? '/reports' : '/')} />
						) : null}
					</ul>
				</nav>
			);
		} else {
			return <div />
		}
	}

	modalPrimeiroAcesso() {

        return (
			<div class='modal fade show' id='modal-xl' style={{ display: 'block' }} aria-modal='true'>
				<div class='modal-dialog modal-xl'>
					<div class='modal-content'>
						<div class='modal-header' style={{ backgroundColor: '#1F3066', color: '#fff' }}>
							<h4 class='modal-title'>Primeiro Acesso</h4>
						</div>
						<div class='modal-body' style={{ overflowY: 'scroll' }}>

							<Row alignCenter>
								<LabelAndInput
									name='senha'
									type="password"
									label='Senha *'
									placeholder='Informe a Senha'
									cols='12 12 6 6'
									readOnly={''}
									value={this.state.dadosLogin ? this.state.dadosLogin.senha : ''}
									onChange={data => this.setState({ ...this.state, dadosLogin: { ...this.state.dadosLogin, senha: data.target.value }})}  />
							</Row>
							<Row alignCenter>
								<LabelAndInput
									name='senha_confirmacao'
									type="password"
									label='Confirme sua Senha *'
									placeholder='Informe a Confirmação'
									cols='12 12 6 6'
									readOnly={''}
									value={this.state.dadosLogin ? this.state.dadosLogin.senha_confirmacao : ''}
									onChange={data => this.setState({ ...this.state, dadosLogin: { ...this.state.dadosLogin, senha_confirmacao: data.target.value }})} />
							</Row>
							<Row alignCenter>
								<Grid cols='12 12 6 6'>
									<Button
										text='Salvar nova Senha'
										type={'success'}
										icon={'fa fa-chevron-left'}
										disabled={!this.state.dadosLogin.senha || this.state.dadosLogin.senha != this.state.dadosLogin.senha_confirmacao}
										event={() => this.props.alterarSenha({ ...this.state.dadosLogin, id: this.props.usuarioCarregado.id })} />
								</Grid>
							</Row>

						</div>
					</div>
				</div>
			</div>
        );
    }
}

const mapStateToProps = state => ({
	usuario: state.auth.usuario,
	usuarioCarregado: state.auth.usuarioCarregado
});
const mapDispatchToProps = dispatch => bindActionCreators({ carregarUsuario, alterarSenha }, dispatch);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Menu));
