import React from 'react'

export default props => (
    <tr {...props}
		style={{ 
			backgroundColor: props.backgroundColor,
			height: props.height,
			position: props.sticky && "sticky",
			top: props.top ? props.top : ( props.sticky && (props.top || -1))
		}}>
		{props.children}
	</tr>
)
