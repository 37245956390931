import React, { useState, useRef, useEffect } from 'react';
import Grid from '../layout/grid';
import Select, { components } from 'react-select';
const allOption = { value: '*', label: 'Selecionar todos' };

// Custom Option Component with Checkbox
const CustomOption = (props) => {
  return (
    <components.Option {...props}>
      {props.value != '*' &&
        <input
          type='checkbox'
          checked={props.isSelected}
          onChange={() => null}
          style={{ marginRight: '10px' }} />}
      {props.label}
    </components.Option>
  );
};

const MultiValueContainer = ({ children, selectProps, ...props }) => {
  const itens = props.getValue() || [];

  const handleClick = (event) => {
    event.stopPropagation();
    if (selectProps.selectCompRef && selectProps.selectCompRef.current) {
      selectProps.selectCompRef.current.onMenuOpen();
    }
  }

  return (
    <components.MultiValueContainer {...props} onClick={handleClick}>
      <div onClick={handleClick} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', paddingLeft: 4 }}>
          {itens.length == 0 ? 'Nenhum item selecionado' : (itens.length == 1 ? '1 item selecionado' : `${itens.length} itens selecionados`)}        
      </div>
    </components.MultiValueContainer>
  );
};

const MultiValueContainerSearch = ({ children, ...props }) => {
  return (
    <div style={{ padding: 4 }}>{children[1]}</div>
  );
};

export default props => {

  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const selectRef = useRef(null);
  const selectCompRef = useRef(null);

  const handleClickOutside = (event) => {
    if (selectRef && selectRef.current && !selectRef.current.contains(event.target)) {
      setMenuIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const customStyles = {
		menu: (provided) => ({
			...provided,
			zIndex: 9999
		}),
	};

  return (
    <Grid cols={props.cols}>
      <div className='form-group'>
        {props.label &&
          <label htmlFor={props.name}>{props.label}</label>}
        <div ref={selectRef}>
          <Select
            styles={customStyles}
            ref={selectCompRef}
            selectCompRef={selectCompRef}
            readOnly={props.readOnly}
            components={{ Option: CustomOption, ValueContainer: menuIsOpen ? MultiValueContainerSearch : MultiValueContainer }}
            isMulti
            defaultValue={(props.options || []).map(opt => ({ value: opt.id, label: opt.valor })).filter(opt => (props.value || []).filter(optValue => optValue == opt.value).length)}
            value={(props.options || []).map(opt => ({ value: opt.id, label: opt.valor })).filter(opt => (props.value || []).filter(optValue => optValue == opt.value).length)}
            isClearable={!props.naoLimpar}
            isSearchable={true}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            menuIsOpen={menuIsOpen}
            onMenuOpen={() => setMenuIsOpen(true)}
            onMenuClose={() => setMenuIsOpen(false)}
            onChange={(item) => {
              if (item && item.some(option => option.value === allOption.value)) {
                props.onChange((props.options || []).map(opt => ({ value: opt.id, label: opt.valor })));
              } else {
                props.onChange(item);
              }
            }}
            options={[allOption, ...(props.options || []).map(opt => ({ value: opt.id, label: opt.valor }))]} />
        </div>
      </div>
    </Grid>
  )
}