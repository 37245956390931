import React, { useState, useRef, useEffect } from 'react';
import Grid from '../layout/grid';
import Select, { components } from 'react-select';

export default props =>  {

	const customStyles = {
		menu: (provided) => ({
			...provided,
			zIndex: 9999
		}),
	};

	return (
		<Grid /*ref={selectRef}*/ cols={props.cols} style={{ maxWidth: props.maxWidth, minWidth: props.minWidth, marginLeft: props.marginLeft }}>
			<div className='form-group'>
			{props.label &&
			<label htmlFor={props.name}>{props.label}</label>}
				{props.multiple ? (
					<Select
						styles={customStyles}
						readOnly={props.readOnly}
						isMulti
						defaultValue={props.value}
						isClearable={true}
						isSearchable={true}
						onChange={(item) => props.onChange(item)}
						options={(props.options || []).map(opt => ({ value: opt.id, label: opt.valor }))} />
				) : (props.onChange ? (
					<Select
						styles={customStyles}
						readOnly={props.readOnly}
						defaultValue={props.value ? (props.options || []).map(opt => ({ value: String(opt.id), label: opt.valor })).filter(opt => opt.value == props.value)[0] || null : null}
						isClearable={true}
						isSearchable={true}
						isDisabled={props.readOnly ? true : false}
						onChange={(item) => props.onChange(item ? item.value : null)}
						options={(props.options || []).map(opt => ({ value: opt.id, label: opt.valor }))} />
				) : (
					<Select
						styles={customStyles}
						readOnly={props.readOnly}
						isClearable={true}
						isSearchable={true}
						isDisabled={props.readOnly ? true : false}
						defaultValue={props.input.value ? (props.options || []).map(opt => ({ value: String(opt.id), label: opt.valor })).filter(opt => opt.value == props.input.value)[0] || null : null}
						onChange={(item) => props.input.onChange(item ? item.value : null)}
						onBlur={(value) => props.input.onBlur()}
						options={(props.options || []).map(opt => ({ value: opt.id, label: opt.valor }))} />
				))}
			</div>
		</Grid>
	)
}