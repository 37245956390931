import React, { useState, useRef, useEffect } from 'react';
import Grid from '../layout/grid';
import Select, { components } from 'react-select';

export default props =>  {

	const [modalAberto, setModalAberto] = useState(false);
	const [pesquisar, setPesquisar] = useState('');

	let modal = () => {

		let selecionados = props.value || [];
		
		return (
			<div class='modal fade show' id='modal-default' style={{ display: 'block' }} aria-modal='true'>
				<div class='modal-dialog'>
					<div class='modal-content'>
						<div class='modal-header' style={{ padding: 2 }}>
							<input className='form-control'
								placeholder='Pesquisar'
								type='text'
								value={pesquisar}
								onChange={e => {
									setPesquisar(e.target.value);
								}} />

							<button type='button' class='btn btn-primary' style={{ marginTop: 2 }}
								onClick={() => {
									props.onChange((props.options || []).map(item => item.id));
								}}>
								Todos
							</button>

							<button type='button' class='btn btn-danger' style={{ marginTop: 2 }}
								onClick={() => {
									props.onChange([]);
								}}>
								Limpar
							</button>

						</div>
					
						<div class='modal-body' style={{ overflowY: 'scroll', height: window.innerHeight * 0.75 }}>
							{(props.options || []).filter(item => {
								if (pesquisar
									&& !(`${item.valor}`).toUpperCase().includes(pesquisar.toUpperCase())) {
									return false;
								}
								return true;
							}).map(item => (
								<div class='form-check' key={item.id} 
									style={{ paddingTop: 4, paddingBottom: 2 }}
									onClick={() => {
										let itens = selecionados.map(itemSelecionado => itemSelecionado);
										if (itens.includes(item.id)) {
											itens = itens.filter(itemSelecionado => itemSelecionado != item.id)	;
										} else {
											itens.push(item.id);
										}
										props.onChange(itens);
									}}>
									<input class='form-check-input' type='checkbox' checked={selecionados.includes(item.id)} />
									<label class='form-check-label' style={{ paddingTop: 2 }}>{item.valor}</label>
								</div>
							))}	
						</div>
						<div class='modal-footer justify-content-center' style={{ padding: 2}}>
							<button type='button' class='btn btn-default' style={{ width: '100%' }}
								onClick={() => {
									setModalAberto(false);
								}}>Ok</button>
						</div>
					</div>
				</div>
			</div>
		);
	}

	return (
		<Grid /*ref={selectRef}*/ cols={props.cols} style={{ maxWidth: props.maxWidth, minWidth: props.minWidth, marginLeft: props.marginLeft }}>

			{modalAberto ? modal() : null}

			<div className='form-group'>
				<label htmlFor={props.name}>{props.label}</label>
				<div
					style={{
						border: '1px solid #cccccc',
						borderRadius: 4,
						padding: 8,
						minHeight: 38,
						display: 'flex',
						justifyContent: 'space-between'
					}}
					onClick={() => setModalAberto(true)}>
					<div>{(props.value || []).length 
						? (props.value.length == 1 ? '1 item selecionado' : `${props.value.length} itens selecionados`)
						: 'Selecione'
					}</div>
					<div style={{ display: 'flex', width: 34, alignItems: 'center' }}>
						<div
							style={{
								backgroundColor: '#cccccc',
								width: 1, 
								marginLeft: 4,
								marginRight: 8,
								height: '100%'
							}} />
						
						<svg style={{ color: '#cccccc' }} height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" class="css-tj5bde-Svg"><path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path></svg>
					</div>
				</div>
			</div>
		</Grid>
	)
}