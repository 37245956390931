import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess, setError } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import DateFormat from '../common/dateFormat/DateFormat';
import FormatUtils from '../common/formatUtils/FormatUtils';

export function setModoTela(modo, registro = {}) {
    return {
        type: 'REPORTS_TRACK_MODO_TELA',
        payload: {
			modo: modo,
			registro: registro
		}
    };
}

export function setAguardando(aguardando) {
    return {
        type: 'REPORTS_TRACK_SET_AGUARDANDO',
        payload: aguardando
    };
}

export function setFiltro(filtro = {}) {
    return {
        type: 'REPORTS_TRACK_FILTRO',
        payload: {
			filtro: filtro
		}
    };
}

export function getLista(filtro) {
	return (dispatch, getState) => {
		dispatch(setAguardando(true));

    	axios.get(`${consts.API_URL}/reports/track?padrao=true
			&tipo_periodo=${filtro.tipo_periodo}
			${filtro.data_inicial ? `&data_inicial=${DateFormat.formatarDataTelaParaSql(filtro.data_inicial)}` : ''}
			${filtro.data_final ? `&data_final=${DateFormat.formatarDataTelaParaSql(filtro.data_final)}` : ''}
			${getState().auth.usuarioCarregado && getState().auth.usuarioCarregado.acesso_cliente ? '&acesso_cliente=true' : ''}
		`).then(resp => {
			dispatch({
		        type: 'REPORTS_TRACK_LISTADO',
		        payload: resp
		    });
			dispatch(setAguardando(false));
		}).catch(e => {
			setErroAPI(e);
			dispatch(setAguardando(false));
		});
	}
}

export function getListaOcorrencias(shipping_code_id) {
	return (dispatch, getState) => {
		
		dispatch({
			type: 'REPORTS_TRACK_OCORRENCIA_LISTADO',
			payload: { data: [] }
		});

		dispatch(setAguardando(true));

    	axios.get(`${consts.API_URL}/reports/ocorrencias?shipping_code_id=${shipping_code_id}${getState().auth.usuarioCarregado && getState().auth.usuarioCarregado.acesso_cliente ? '&acesso_cliente=true' : ''}`)
		.then(resp => {
			dispatch({
		        type: 'REPORTS_TRACK_OCORRENCIA_LISTADO',
		        payload: resp
		    });
			dispatch(setAguardando(false));
		}).catch(e => {
			setErroAPI(e);
			dispatch(setAguardando(false));
		});
	}
}

export function getListaFronteiras() {
	return (dispatch, getState) => {
		
		dispatch({
			type: 'REPORTS_TRACK_FRONTEIRA_LISTADO',
			payload: { data: [] }
		});

		dispatch(setAguardando(true));

    	axios.get(`${consts.API_URL}/reports/fronteiras`)
		.then(resp => {
			dispatch({
		        type: 'REPORTS_TRACK_FRONTEIRA_LISTADO',
		        payload: resp
		    });
			dispatch(setAguardando(false));
		}).catch(e => {
			setErroAPI(e);
			dispatch(setAguardando(false));
		});
	}
}

export function getListaNegociadores() {
	return (dispatch, getState) => {
		
		dispatch({
			type: 'REPORTS_TRACK_NEGOCIADOR_LISTADO',
			payload: { data: [] }
		});

		dispatch(setAguardando(true));

    	axios.get(`${consts.API_URL}/reports/negociadores`)
		.then(resp => {
			dispatch({
		        type: 'REPORTS_TRACK_NEGOCIADOR_LISTADO',
		        payload: resp
		    });
			dispatch(setAguardando(false));
		}).catch(e => {
			setErroAPI(e);
			dispatch(setAguardando(false));
		});
	}
}

export function getListaOperacoes() {
	return (dispatch, getState) => {
		
		dispatch({
			type: 'REPORTS_TRACK_OPERACAO_LISTADO',
			payload: { data: [] }
		});

		dispatch(setAguardando(true));

    	axios.get(`${consts.API_URL}/operacao/listarSelect`)
		.then(resp => {
			dispatch({
		        type: 'REPORTS_TRACK_OPERACAO_LISTADO',
		        payload: resp
		    });
			dispatch(setAguardando(false));
		}).catch(e => {
			setErroAPI(e);
			dispatch(setAguardando(false));
		});
	}
}
