import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ContentCardFooter from '../common/template/contentCardFooter';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import LabelAndInputDateTime from '../common/form/labelAndInputDateTime';
import Select from '../common/form/select';
import SelectAlteracao from '../common/form/selectAlteracao';

import {
	setModoTela, initForm
} from './premioFaixaActions';

class PremioFaixaForm extends Component {

	state = {

	}

    componentWillMount() {

    }

    render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';

        return (
			<ContentCard>
				<Form event={this.props.handleSubmit}>
					<ContentCardBody>
						<Row>
							<Field
								name='id_tipo'
								component={Select}
								options={this.props.listaTipo}
								label='Tipo'
								cols='12 12 12 12'
								placeholder='Selecione o tipo'
								readOnly={readOnly} />

							<Field
								name='id_grupo'
								component={Select}
								options={this.props.listaGrupo}
								label='Grupo'
								cols='12 12 12 12'
								placeholder='Selecione o grupo'
								readOnly={readOnly} />

							<Field
								name='km_inicio'
								component={LabelAndInputNumber}
								label='Km Início' placeholder='Informe o Km'
								cols='12 12 6 6'
								casas={0}
								readOnly={readOnly} />

							<Field
								name='km_fim'
								component={LabelAndInputNumber}
								label='Km Fim' placeholder='Informe o Km'
								cols='12 12 6 6'
								casas={0}
								readOnly={readOnly} />

							<Field
								name='valor_km'
								component={LabelAndInputNumber}
								label='Valor por Km' placeholder='Informe o valor'
								cols='12 12 4 3'
								casas={4}
								readOnly={readOnly} />
						</Row>
						<Row>							
							<Field
								name='data_inicio'
								component={LabelAndInputMask}
								label='Início Vigência' placeholder='Informe a data'
								cols='12 6 4 3'
								mask='99/99/9999'
								readOnly={readOnly} />

							<Field
								name='data_fim'
								component={LabelAndInputMask}
								label='Fim Vigência' placeholder='Informe a data'
								cols='12 6 4 3'
								mask='99/99/9999'
								readOnly={readOnly} />
						</Row>
					</ContentCardBody>
					<ContentCardFooter>
						<Row alignCenter>
							<Grid cols='6 6 4 3'>
								{this.props.excluir ? (
									<Button
										text='Excluir'
										submit type={'danger'}
										icon={'fa fa-trash'} />
								) : (
								<Button
									text='Salvar'
									submit
									type={'success'}
									icon={'fa fa-check'} />
								)}
							</Grid>
							<Grid cols='6 6 4 3'>
								<Button
									text='Voltar'
									type={'warning'}
									icon={'fa fa-chevron-left'}
									event={() => this.props.setModoTela('lista')} />
							</Grid>
						</Row>
					</ContentCardFooter>
				</Form>
			</ContentCard>
        )
    }


}

PremioFaixaForm = reduxForm({form: 'premioFaixaForm', destroyOnUnmount: false})(PremioFaixaForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	formularioValues: getFormValues('premioFaixaForm')(state),
	registro: state.premioFaixa.registro,
	listaTipo: state.premioFaixa.listaTipo,
	listaGrupo: state.premioFaixa.listaGrupo
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, initForm
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(PremioFaixaForm);
