import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import Form from './usuarioAcessorioForm';

import { setModoTela, initForm, salvar, excluir, getLista, getListaUnidade, getListaUsuario, getListaTipo } from './usuarioAcessorioActions';
import unidade from '../unidade/unidade';
import InputPesquisa from '../common/form/inputPesquisa';

class UsuarioAcessorio extends Component {

	state = {
		linhaSelecionada: null
	}

    componentWillMount() {
        this.props.getLista();
		this.props.getListaUnidade();
		this.props.getListaUsuario();
		this.props.getListaTipo();
    }

    render() {
        return (
            <div>
                <Content>
					{this.props.modoTela == 'cadastro' ? (
						<Form onSubmit={this.props.salvar} />
					) : null}
					{this.props.modoTela == 'exclusao' ? (
						<Form excluir onSubmit={this.props.excluir} />
					) : null}
					{this.props.modoTela == 'lista' ? this.lista() : null}
                </Content>
            </div>
        )
    }

	lista() {

		let lista = this.props.lista.filter(item => {
			return !(this.state.pesquisar || '').trim()
				|| (this.state.pesquisar.toUpperCase() || '').split('+').filter(pesquisar => (pesquisar || '').trim()).filter(pesquisar => {		
				if (pesquisar
					&& !(`
					${item.nome_usuario}
					${item.nome_unidades}
					${item.nome_tipos}
				`).toUpperCase().includes(pesquisar)) {
					return false;
				}	
				return true;
			}).length > 0;
		});

		return (
			<ContentCard>
				<ContentCardHeader>
					<Row>
						<Grid cols='6 6 4 2'>
							<Button
								text='Adicionar'
								type={'success'}
								icon={'fa fa-plus'}
								event={() => {
									this.props.setModoTela('cadastro', {});
									this.props.initForm({});
								}} />
						</Grid>
					</Row>
				</ContentCardHeader>
				<ContentCardBody>
					<Row>
						<InputPesquisa
							cols='12 12 12 12'
							label='Pesquisar'
							minWidth={200}
							onChange={value => this.setState({ ...this.state, pesquisar: value })} />
					</Row>

					<Table responsive border>
						<THead>
						<Tr>
								<Th rowspan={2} alignCenter width={300}>Usuário</Th>
								<Th rowspan={2} alignCenter width={300}>Unidades</Th>
								<Th rowspan={2} alignCenter width={300}>Acessórios</Th>
								<Th colspan={2} alignCenter width={40}>Configuração</Th>
								<Th colspan={2} alignCenter width={40}>Consulta</Th>
								<Th colspan={5} alignCenter width={100}>Movimentação</Th>
								<Th rowspan={2} alignCenter width={100}></Th>
							</Tr>
							<Tr>
								<Th alignCenter>Acessos</Th>
								<Th alignCenter>Cadastros</Th>
								<Th alignCenter>Saldo</Th>
								<Th alignCenter>Histórico</Th>
								<Th alignCenter>Entrada</Th>
								<Th alignCenter>Trf Unidade</Th>
								<Th alignCenter>Trf Veículo</Th>
								<Th alignCenter>Saída</Th>
								<Th alignCenter>Inventário</Th>
							</Tr>
						</THead>
						<TBody>
							{lista.map(item => (
								<Tr key={item.id} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
									onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
									onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
									<Td alignCenter small>{item.nome_usuario}</Td>
									<Td alignCenter small>{(item.unidades || []).map(unidade => (<span style={{ fontSize: 12, fontWeight: 'normal', padding: 4, margin: 1 }} class='badge badge-secondary'>{unidade.nome}</span>))}</Td>
									<Td alignCenter small>{(item.tipos || []).map(tipo => (<span style={{ fontSize: 12, fontWeight: 'normal', padding: 4, margin: 1 }} class='badge badge-secondary'>{tipo.nome}</span>))}</Td>
									<Td alignCenter small>{item.acesso ? <i class='fas fa-check'></i> : null}</Td>
									<Td alignCenter small>{item.cadastro ? <i class='fas fa-check'></i> : null}</Td>
									<Td alignCenter small>{item.saldo ? <i class='fas fa-check'></i> : null}</Td>
									<Td alignCenter small>{item.historico ? <i class='fas fa-check'></i> : null}</Td>
									<Td alignCenter small>{item.entrada ? <i class='fas fa-check'></i> : null}</Td>
									<Td alignCenter small>{item.transferencia_unidade ? <i class='fas fa-check'></i> : null}</Td>
									<Td alignCenter small>{item.transferencia_veiculo ? <i class='fas fa-check'></i> : null}</Td>
									<Td alignCenter small>{item.saida ? <i class='fas fa-check'></i> : null}</Td>
									<Td alignCenter small>{item.inventario ? <i class='fas fa-check'></i> : null}</Td>
									<Td alignCenter minWidth={100}>
										<ButtonTable
											type={'warning'}
											icon={'fas fa-pencil-alt'}
											visible={this.state.linhaSelecionada == item.id}
											event={() => {
												this.props.setModoTela('cadastro', {
													...item,
													unidades: (item.unidades || []).map(unidade => unidade.id),
													tipos: (item.tipos || []).map(tipo => tipo.id)
												});
												this.props.initForm({
													...item,
													unidades: (item.unidades || []).map(unidade => unidade.id),
													tipos: (item.tipos || []).map(tipo => tipo.id)
												});
											}} />

										<ButtonTable
											type={'danger'}
											icon={'fas fa-trash-alt'}
											visible={this.state.linhaSelecionada == item.id}
											event={() => {
												this.props.setModoTela('exclusao', {
													...item,
													unidades: (item.unidades || []).map(unidade => unidade.id),
													tipos: (item.tipos || []).map(tipo => tipo.id)
												});
												this.props.initForm({
													...item,
													unidades: (item.unidades || []).map(unidade => unidade.id),
													tipos: (item.tipos || []).map(tipo => tipo.id)
												});
											}} />
									</Td>
								</Tr>
							))}
						</TBody>
					</Table>
				</ContentCardBody>
			</ContentCard>
		);
	}


}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	modoTela: state.usuarioAcessorio.modoTela,
	lista: state.usuarioAcessorio.lista
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, initForm, salvar, excluir, getLista, getListaUnidade, getListaUsuario, getListaTipo }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(UsuarioAcessorio);
