import React from 'react'
import Navbar from './navbar'
import imagemLogo from '../../assets/images/logo.png';

export default props => {

    return (
        <nav className="main-header navbar navbar-expand navbar-light navbar-white border-bottom-0">
            <ul className="navbar-nav">
                <li className="nav-item">
                    <a className="nav-link" data-widget="pushmenu" href="#" role="button"><i className="fas fa-bars"></i></a>
                </li>
            </ul>
            {window.innerWidth > 600 &&
            <img src={imagemLogo} style={{ height: 34, marginLeft: 0, marginBottom: 0, marginTop: 2 }}/>}
            <Navbar tela={(window.location.href || '').split('/#')[1]} />
        </nav>
    );
}
/*
<header className='main-header'>
    <a href='/#/' className='logo'>
        <span className='logo-mini'>
            <i className="fa fa-tachometer"></i>
        </span>
        <span className='logo-lg'>
            <b> Ottonelli Terraplanagem</b>
        </span>
    </a>
    <nav className='navbar navbar-static-top'>
        <a href className='sidebar-toggle' data-toggle='offcanvas'></a>
		<Navbar />
    </nav>
</header>
*/
