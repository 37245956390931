import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ContentCardFooter from '../common/template/contentCardFooter';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import LabelAndInputDateTime from '../common/form/labelAndInputDateTime';
import Select from '../common/form/select';
import SelectDefault from '../common/form/selectDefault';
import SelectAlteracao from '../common/form/selectAlteracao';
import pdfAdd from '../assets/images/icone_add_pdf.png';
import pdfAdicionado from '../assets/images/icone_pdf_adicionado.png';
import { setError } from '../common/toast/toast';

import {
	setModoTela, initForm
} from './laudoControleItemActions';
import consts from '../consts';

class LaudoControleItemForm extends Component {

	state = {

	}

	componentDidMount() {

		const fileSelectorArquivo = document.createElement('input');
		fileSelectorArquivo.setAttribute('type', 'file');
		fileSelectorArquivo.addEventListener("change", (data) => {

			let reader = new FileReader();
			let file = data && data.path && data.path[0] && data.path[0].files && data.path[0].files[0] ? data.path[0].files[0] : null;
			if (!file) {
				file = data && data.target && data.target.files && data.target.files[0] ? data.target.files[0] : null;
			}

			if (file) {

				if (!file.type.includes('application/pdf')
					&& !file.type.includes('image/png') && !file.type.includes('image/jpg') && !file.type.includes('image/jpeg')) {
					setError('Selecione apenas (.PDF, .PNG, .JPG e .JPEG)');
					return;
				}

				reader.onloadend = () => {

					let lista = (this.props.formularioValues.anexos || []).map(item => item);
					lista.push({
						arquivo_base64: reader.result
					});

					this.props.initForm({
						...this.props.formularioValues,
						anexos: lista
					});

				}

				reader.readAsDataURL(file);

			}
		});

		this.fileSelectorArquivo = fileSelectorArquivo;
	}

    componentWillMount() {

    }

    render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';

        return (
			<ContentCard>
				<Form event={this.props.handleSubmit}>
					<ContentCardBody>
						<Row>
							
							<LabelAndInputMask
								name='data_realizacao'
								label='Data Realização' placeholder='Informe a data'
								cols='12 6 4 3'
								mask='99/99/9999'
								readOnly={readOnly}
								value={this.props.formularioValues.data_realizacao}
								onChange={data => {

									let dataVencimento = this.props.formularioValues.data_vencimento;
									if (data.target.value && data.target.value.length == 10) {
										dataVencimento = `${data.target.value.split('/')[0]}/${data.target.value.split('/')[1]}/${parseInt(data.target.value.split('/')[2]) + 1}`;
									}

									this.props.initForm({
										...this.props.formularioValues,
										data_realizacao: data.target.value,
										data_vencimento: dataVencimento
									});
								}} />

							<Field
								name='data_vencimento'
								component={LabelAndInputMask}
								label='Data Próximo Vencimento' placeholder='Informe a data'
								cols='12 6 4 3'
								mask='99/99/9999'
								readOnly={readOnly} />

							<Field
								name='observacao'
								component={LabelAndInput}
								label='Observação' placeholder='Informe a observação'
								cols='12 12 12 12'
								readOnly={readOnly} />

						</Row>

						<Row>
							<Grid cols='12 12 12 12'>
								<label>Anexos</label>
							</Grid>
							{(this.props.formularioValues.anexos || []).map(arquivo => (
								<div style={{ display: 'flex', flexDirection: 'column', marginLeft: 8 }}>
									<span class="btn btn-outline-primary fileinput-button dz-clickable">
										<img src={arquivo.arquivo_base64 ? pdfAdicionado : (arquivo.arquivo ? pdfAdicionado : pdfAdd)} style={{ height: 30 }} />
									</span>
									<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingRight: 2, marginTop: 4 }}>
										<button
											type='button'
											class='btn btn-flat btn-outline-primary'
											style={{ width: '48%' }}
											disabled={arquivo.arquivo_base64 ? true : false}
											onClick={() => {
												window.open(`${consts.URL}/${arquivo.arquivo}`, '_blank');
											}}>
											<i class='fas fa-download'></i>
										</button>
										<button
											type='button'
											class='btn btn-flat btn-outline-danger'
											style={{ width: '48%' }}
											onClick={() => {
												this.props.initForm({
													...this.props.formularioValues,
													anexos: this.props.formularioValues.anexos.filter(item => item != arquivo)
												});
											}}>
											<i class='fas fa-trash-alt'></i>
										</button>
									</div>
								</div>
							))}
							{!readOnly ? (
								<span class="btn btn-outline-primary fileinput-button dz-clickable" 
									onClick={() => this.fileSelectorArquivo.click()}
									style={{ marginLeft: 8 }}>
									<img src={pdfAdd} style={{ height: 30 }} />
								</span>
							) : null}
						</Row>

						
						
					</ContentCardBody>
					<ContentCardFooter>
						<Row alignCenter>
							<Grid cols='6 6 4 3'>
								{this.props.excluir ? (
									<Button
										text='Excluir'
										submit type={'danger'}
										icon={'fa fa-trash'} />
								) : (
								<Button
									text='Salvar'
									submit
									type={'success'}
									icon={'fa fa-check'} />
								)}
							</Grid>
							<Grid cols='6 6 4 3'>
								<Button
									text='Voltar'
									type={'warning'}
									icon={'fa fa-chevron-left'}
									event={() => this.props.setModoTela('lista')} />
							</Grid>
						</Row>
					</ContentCardFooter>
				</Form>
			</ContentCard>
        )
    }


}

LaudoControleItemForm = reduxForm({form: 'laudoControleItemForm', destroyOnUnmount: false})(LaudoControleItemForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	formularioValues: getFormValues('laudoControleItemForm')(state),
	registro: state.laudoControleItem.registro,
	listaUsuario: state.laudoControleItem.listaUsuario
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, initForm
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(LaudoControleItemForm);
