import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ContentCardFooter from '../common/template/contentCardFooter';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import LabelAndCheckbox from '../common/form/labelAndCheckbox';
import Select from '../common/form/select';
import SelectDefault from '../common/form/selectDefault';
import SelectAlteracao from '../common/form/selectAlteracao';

import {
	setModoTela, initForm
} from './ocorrenciaTipoActions';

class OcorrenciaTipoForm extends Component {

	state = {
		id_perfil: null
	}

    componentWillMount() {

    }

    render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';

        return (
			<ContentCard>
				<Form event={this.props.handleSubmit}>
					<ContentCardBody>
						<Row>
							<Field
								name='nome'
								component={LabelAndInput}
								label='Nome' placeholder='Informe o nome'
								cols='12 12 12 12'
								readOnly={readOnly} />

							<Field
								name='percentual_desconto'
								component={LabelAndInputNumber}
								label='Percentual de Desconto' placeholder='Informe o percentual'
								cols='12 12 6 6'
								casas={0}
								readOnly={readOnly} />
								
							<Field
								name='multiplica_percentual'
								component={LabelAndCheckbox}
								label='Multiplica o Percentual' placeholder='Selecione se multiplica o percentual'
								cols='12 12 12 12'
								readOnly={readOnly} />

							<SelectDefault 
								options={this.props.listaPerfil}
								label='Perfil'
								cols='12 12 12 12'
								placeholder='Selecione o perfil'
								readOnly={readOnly}
								value={this.state.id_perfil}
								onChange={data => {
									if (data.target.value) {
										let lista = (this.props.formularioValues.perfis || []).filter(item => item != data.target.value).map(perfil => perfil);
										lista.push(parseInt(data.target.value));

										this.props.initForm({
											...this.props.formularioValues,
											perfis: lista
										});
									}
								}} />
						</Row> 
						<Row>
							{(this.props.formularioValues.perfis || []).map((perfil, i) => (
								<div key={perfil} class="col-md-3" style={{ marginBottom: 10 }}>
									<button type="button" 
										class="btn btn-outline-primary btn-block"
										onClick={() => {
											this.props.initForm({
												...this.props.formularioValues,
												perfis: this.props.formularioValues.perfis.filter(item => item != perfil)
											});
											
										}}>
										{this.props.listaPerfil.filter(item => item.id == perfil).map(item => item.valor).join('')} <i class="fa fa-trash"></i>
									</button>
								</div>
							))}
						</Row>

						<Row>
							<Field
								name='id_categoria'
								component={Select}
								options={this.props.listaCategoria}
								label='Categoria' placeholder='Informe a categoria'
								cols='12 12 12 12'
								readOnly={readOnly} />
						</Row>

						<Row> 						
								
							<Field
								name='data_inicio'
								component={LabelAndInputMask}
								label='Início Vigência' placeholder='Informe a data'
								cols='12 6 4 3'
								mask='99/99/9999'
								readOnly={readOnly} />

							<Field
								name='data_fim'
								component={LabelAndInputMask}
								label='Fim Vigência' placeholder='Informe a data'
								cols='12 6 4 3'
								mask='99/99/9999'
								readOnly={readOnly} />

						</Row>
						<Row>
							<Field
								name='documentacao'
								component={LabelAndCheckbox}
								label='Utiliza para Documentação de Viagens' placeholder='Selecione'
								cols='12 6 4 3'
								readOnly={readOnly} />
						</Row>
						{this.props.formularioValues.documentacao &&
						<Row>
							<Field
								name='permite_finalizar_comprovante'
								component={LabelAndCheckbox}
								label='Permite finalizar comprovante com ocorrência aberta' placeholder='Selecione'
								cols='12 6 4 3'
								readOnly={readOnly} />
						</Row>}
					</ContentCardBody>
					<ContentCardFooter>
						<Row alignCenter>
							<Grid cols='6 6 4 3'>
								{this.props.excluir ? (
									<Button
										text='Excluir'
										submit type={'danger'}
										icon={'fa fa-trash'} />
								) : (
								<Button
									text='Salvar'
									submit
									type={'success'}
									icon={'fa fa-check'} />
								)}
							</Grid>
							<Grid cols='6 6 4 3'>
								<Button
									text='Voltar'
									type={'warning'}
									icon={'fa fa-chevron-left'}
									event={() => this.props.setModoTela('lista')} />
							</Grid>
						</Row>
					</ContentCardFooter>
				</Form>
			</ContentCard>
        )
    }


}

OcorrenciaTipoForm = reduxForm({form: 'ocorrenciaTipoForm', destroyOnUnmount: false})(OcorrenciaTipoForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	formularioValues: getFormValues('ocorrenciaTipoForm')(state),
	registro: state.ocorrenciaTipo.registro,
	listaPerfil: state.ocorrenciaTipo.listaPerfil,
	listaCategoria: state.ocorrenciaTipo.listaCategoria
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, initForm
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(OcorrenciaTipoForm);
