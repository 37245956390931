import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess, setError } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import DateFormat from '../common/dateFormat/DateFormat';
import FormatUtils from '../common/formatUtils/FormatUtils';

export function setModoTela(modo, registro = {}) {
    return {
        type: 'REPORTS_MODO_TELA',
        payload: {
			modo: modo,
			registro: registro
		}
    };
}

export function setAguardando(aguardando) {
    return {
        type: 'REPORTS_SET_AGUARDANDO',
        payload: aguardando
    };
}

export function setFiltro(filtro = {}) {
    return {
        type: 'REPORTS_FILTRO',
        payload: {
			filtro: filtro
		}
    };
}

export function getLista() {
	return (dispatch, getState) => {
		dispatch(setAguardando(true));

    	axios.get(`${consts.API_URL}/reports${getState().auth.usuarioCarregado && getState().auth.usuarioCarregado.acesso_cliente ? '?acesso_cliente=true' : ''}`)
		.then(resp => {
			dispatch({
		        type: 'REPORTS_LISTADO',
		        payload: resp
		    });
			dispatch(setAguardando(false));
		}).catch(e => {
			setErroAPI(e);
			dispatch(setAguardando(false));
		});
	}
}

export function getListaSolicitacoes(shipping_code_id) {
	return (dispatch, getState) => {
		
		dispatch({
			type: 'REPORTS_SOLICITACAO_LISTADO',
			payload: { data: [] }
		});

		dispatch(setAguardando(true));

    	axios.get(`${consts.API_URL}/reports?shipping_code_id=${shipping_code_id}`)
		.then(resp => {
			dispatch({
		        type: 'REPORTS_SOLICITACAO_LISTADO',
		        payload: resp
		    });
			dispatch(setAguardando(false));
		}).catch(e => {
			setErroAPI(e);
			dispatch(setAguardando(false));
		});
	}
}

export function setDestinado(tipo, registro) {
	return async (dispatch, getState) => {
		
		let url = null;
		let destinado = null;
		
		if (registro && registro.statusShipping && registro.statusShipping.shipping) {
			
			if (tipo == 'PROGRAMACAO') {
				url = 'destinadoProgramacao';
				destinado = {
					shipping: registro.statusShipping.shipping,
					id_usuario_destinado_programacao: getState().auth.usuario.id,
					datahora_destinado_programacao: registro.statusShipping.datahora_destinado_programacao ? null : DateFormat.getDataHoraAtual()
				};
			} else if (tipo == 'PRIMEIRO_TRANSITO') {
				url = 'destinadoPrimeiroTransito';
				destinado = {
					shipping: registro.statusShipping.shipping,
					id_usuario_destinado_primeiro_transito: getState().auth.usuario.id,
					datahora_destinado_primeiro_transito: registro.statusShipping.datahora_destinado_primeiro_transito ? null : DateFormat.getDataHoraAtual()
				};
			} else if (tipo == 'SEGUNDO_TRANSITO') {
				url = 'destinadoSegundoTransito';
				destinado = {
					shipping: registro.statusShipping.shipping,
					id_usuario_destinado_segundo_transito: getState().auth.usuario.id,
					datahora_destinado_segundo_transito: registro.statusShipping.datahora_destinado_segundo_transito ? null : DateFormat.getDataHoraAtual()
				};
			}

			if (url && destinado) {
				dispatch(setAguardando(true));

				await axios.post(`${consts.API_URL}/reports/${url}`, destinado)
				.then(resp => {
					let lista = getState().reports.lista.map(item => {
						if (item.shipping_code_id == registro.shipping_code_id) {
							return {
								...item,
								statusShipping: {
									...item.statusShipping,
									...destinado
								}
							}
						} else {
							return item;
						}
					});
					dispatch({
						type: 'REPORTS_LISTADO',
						payload: { data: lista }
					});
					dispatch(setAguardando(false));
				}).catch(e => {
					console.log(e);
					setErroAPI(e);
					dispatch(setAguardando(false));
				});
			}
		} else {
			setError('Status ainda não está disponível');
		}
	}
}

export function getListaOcorrencias(shipping_code_id) {
	return (dispatch, getState) => {
		
		dispatch({
			type: 'REPORTS_OCORRENCIA_LISTADO',
			payload: { data: [] }
		});

		dispatch(setAguardando(true));

    	axios.get(`${consts.API_URL}/reports/ocorrencias?shipping_code_id=${shipping_code_id}${getState().auth.usuarioCarregado && getState().auth.usuarioCarregado.acesso_cliente ? '&acesso_cliente=true' : ''}`)
		.then(resp => {
			dispatch({
		        type: 'REPORTS_OCORRENCIA_LISTADO',
		        payload: resp
		    });
			dispatch(setAguardando(false));
		}).catch(e => {
			setErroAPI(e);
			dispatch(setAguardando(false));
		});
	}
}

export function getListaFronteiras() {
	return (dispatch, getState) => {
		
		dispatch({
			type: 'REPORTS_FRONTEIRA_LISTADO',
			payload: { data: [] }
		});

		dispatch(setAguardando(true));

    	axios.get(`${consts.API_URL}/reports/fronteiras`)
		.then(resp => {
			dispatch({
		        type: 'REPORTS_FRONTEIRA_LISTADO',
		        payload: resp
		    });
			dispatch(setAguardando(false));
		}).catch(e => {
			setErroAPI(e);
			dispatch(setAguardando(false));
		});
	}
}

export function getListaNegociadores() {
	return (dispatch, getState) => {
		
		dispatch({
			type: 'REPORTS_NEGOCIADOR_LISTADO',
			payload: { data: [] }
		});

		dispatch(setAguardando(true));

    	axios.get(`${consts.API_URL}/reports/negociadores`)
		.then(resp => {
			dispatch({
		        type: 'REPORTS_NEGOCIADOR_LISTADO',
		        payload: resp
		    });
			dispatch(setAguardando(false));
		}).catch(e => {
			setErroAPI(e);
			dispatch(setAguardando(false));
		});
	}
}

export function getListaOperacoes() {
	return (dispatch, getState) => {
		
		dispatch({
			type: 'REPORTS_OPERACAO_LISTADO',
			payload: { data: [] }
		});

		dispatch(setAguardando(true));

    	axios.get(`${consts.API_URL}/operacao/listarSelect`)
		.then(resp => {
			dispatch({
		        type: 'REPORTS_OPERACAO_LISTADO',
		        payload: resp
		    });
			dispatch(setAguardando(false));
		}).catch(e => {
			setErroAPI(e);
			dispatch(setAguardando(false));
		});
	}
}
