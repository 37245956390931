import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { logout, setCliente, alterarSenha } from '../../auth/authActions';
import imagemLogoReports from '../../assets/images/logo_reports_azul.png';
import imagemLogoReportsHistorico from '../../assets/images/logo_reports_historico_azul.png';
import imagemLogoConformidades from '../../assets/images/logo_conformidades_azul.png';
import imagemLogoTrack from '../../assets/images/logo_track_azul.png';
import consts from '../../consts';
import { event } from 'jquery';
import Row from '../layout/row';
import Grid from '../layout/grid';
import LabelAndInput from '../form/labelAndInput';
import Button from '../button/button';

class Navbar extends Component {
	constructor(props) {
		super(props);
		this.state = { open: false, dadosAlteracaoSenha: {}, alterarSenha: false };
	}
	changeOpen() {
		this.setState({ open: !this.state.open });
	}
	render() {
		const { nome, login } = this.props.usuario;

		let logo = null;
		if (this.props.usuarioCarregado) {
			if (this.props.tela == '/reports' 
				|| (!this.props.usuarioCarregado.acesso_cliente && this.props.tela == '/')
				|| (this.props.usuarioCarregado.acesso_cliente && !this.props.usuarioCarregado.track && this.props.tela == '/')) {
				logo = imagemLogoReports;
			} else if (this.props.tela == '/reportsConformidade') {
				logo = imagemLogoConformidades;
			} else if (this.props.tela == '/reportsHistorico') {
				logo = imagemLogoReportsHistorico;
			} else if (this.props.tela == '/reportsTrack' || (this.props.usuarioCarregado.acesso_cliente && this.props.tela == '/')) {
				logo = imagemLogoTrack;
			}
		}

		return (
			<div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>

				{this.modalAlterarSenha()}

				<div style={{ width: '100%', textAlign: 'center' }}>
					{logo &&
					<img src={logo} style={{ height: 25, marginLeft: 0, marginBottom: 0, marginTop: 2 }}/>}
				</div>
				<ul className="navbar-nav ml-auto" style={{ width: 40 }}>
					<li className="nav-item dropdown">
						<a className="nav-link" data-toggle="dropdown" href="#" onClick={event => event.preventDefault()}> 
							<i className="far fa-user"></i>
						</a>
						<div className="dropdown-menu dropdown-menu-lg dropdown-menu-right" style={{ maxWidth: 400, width: 400 }}>
							<span className="dropdown-item dropdown-header">Perfil</span>
							<div className="dropdown-divider"></div>
							<a href="#" className="dropdown-item" onClick={event => event.preventDefault()}>
								<i className="fas fa-user mr-2"></i> Nome
								<span className="float-right text-muted text-sm">{nome}</span>
							</a>
							<div className="dropdown-divider"></div>
							<a href="#" className="dropdown-item" onClick={event => event.preventDefault()}>
								<i className="fas fa-envelope mr-2"></i> Usuário
								<span className="float-right text-muted text-sm">{login}</span>
							</a>
							<div className="dropdown-divider"></div>
							<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: 4 }}>
								<a href="#" 
									style={{ width: '49%', margin: 0 }}
									onClick={(event) => {
									event.preventDefault();
									this.setState({
										...this.state,
										alterarSenha: true,
										dadosAlteracaoSenha: {}
									});
								}}
									className="btn btn-block btn-success btn-sm">Alterar Senha</a>
								<a href="#" 
									style={{ width: '49%', margin: 0 }}
									onClick={(event) => {
									event.preventDefault();
									this.props.logout();
									window.location = '/';
								}}
									className="btn btn-block btn-danger btn-sm">Sair</a>
							</div>
						</div>
					</li>
				</ul>
			</div>
		);
	}

	modalAlterarSenha() {

		if (!this.state.alterarSenha) {
			return null;
		}

        return (
			<div class='modal fade show' id='modal-xl' style={{ display: 'block' }} aria-modal='true'>
				<div class='modal-dialog modal-xl'>
					<div class='modal-content'>
						<div class='modal-header' style={{ backgroundColor: '#1F3066', color: '#fff' }}>
							<h4 class='modal-title'>Alterar Senha</h4>
							<button type='button' class='close' data-dismiss='modal' aria-label='Close' 
								onClick={() => {
									this.setState({
										...this.state,
										alterarSenha: false,
										dadosAlteracaoSenha: {}
									});
								}}>
								<span aria-hidden='true' style={{ color: '#fff', fontSize: 32 }}>×</span>
							</button>
						</div>
						<div class='modal-body' style={{ overflowY: 'scroll' }}>

							<Row alignCenter>
								<LabelAndInput
									name='senha'
									type="password"
									label='Senha *'
									placeholder='Informe a Senha'
									cols='12 12 6 6'
									readOnly={''}
									value={this.state.dadosAlteracaoSenha ? this.state.dadosAlteracaoSenha.senha : ''}
									onChange={data => this.setState({ ...this.state, dadosAlteracaoSenha: { ...this.state.dadosAlteracaoSenha, senha: data.target.value }})}  />
							</Row>
							<Row alignCenter>
								<LabelAndInput
									name='senha_confirmacao'
									type="password"
									label='Confirme sua Senha *'
									placeholder='Informe a Confirmação'
									cols='12 12 6 6'
									readOnly={''}
									value={this.state.dadosAlteracaoSenha ? this.state.dadosAlteracaoSenha.senha_confirmacao : ''}
									onChange={data => this.setState({ ...this.state, dadosAlteracaoSenha: { ...this.state.dadosAlteracaoSenha, senha_confirmacao: data.target.value }})} />
							</Row>
							<Row alignCenter>
								<Grid cols='12 12 6 6'>
									<Button
										text='Salvar nova Senha'
										type={'success'}
										icon={'fa fa-chevron-left'}
										disabled={!this.state.dadosAlteracaoSenha.senha || this.state.dadosAlteracaoSenha.senha != this.state.dadosAlteracaoSenha.senha_confirmacao}
										event={() => {
											this.props.alterarSenha({ ...this.state.dadosAlteracaoSenha, id: this.props.usuario.id });
											this.setState({
												...this.state,
												alterarSenha: false,
												dadosAlteracaoSenha: {}
											});
										}} />
								</Grid>
							</Row>

						</div>
					</div>
				</div>
			</div>
        );
    }
}

const mapStateToProps = state => ({
	usuario: state.auth.usuario,
	usuarioCarregado: state.auth.usuarioCarregado
});
const mapDispatchToProps = dispatch => bindActionCreators({ logout, setCliente, alterarSenha }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Navbar);

/*
<div className="navbar-custom-menu">
	<ul className="nav navbar-nav">
		<li onMouseLeave={() => this.changeOpen()}
			className={`dropdown user user-menu ${this.state.open ? 'open' :''}`}>
			<a href="javascript:;" onClick={() => this.changeOpen()}
				aria-expanded={this.state.open ? 'true' : 'false'}
				className="dropdown-toggle"
				data-toggle="dropdown">
				<i className="fa fa-user"/>
				<span className="hidden-xs">{nome}</span>
			</a>
			<ul className="dropdown-menu">
				<li className="user-header">
					<p>{nome}<small>{usuario}</small></p>
				</li>
				<li className="user-footer">
					<div className="pull-right">
						<a href="#" onClick={this.props.logout}
							className="btn btn-default btn-flat">Sair</a>
					</div>
				</li>
			</ul>
		</li>
	</ul>
</div>
*/
