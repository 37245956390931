const INITIAL_STATE = {
	registro: {},
	modoTela: 'lista',
	lista: []
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case 'UNIDADE_MODO_TELA':
            return { ...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};

        case 'UNIDADE_LISTADO':
            return {
				...state,
				lista: action.payload.data
			};

        default:
            return state;
    }
}
