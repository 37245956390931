import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { } from './dashboardActions';
import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ValueBox from  '../common/widget/valueBox'
import Row from  '../common/layout/row'
import InfoBox from  '../common/infoBox/infoBox'
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import Button from '../common/button/button';
import ButtonTable from '../common/table/buttonTable';
import DateFormat from '../common/dateFormat/DateFormat';
import LabelAndCheckbox from '../common/form/labelAndCheckbox';
import ReactExport from 'react-export-excel-fixed-xlsx';
import Aguardando from '../common/template/aguardando';
import Select from '../common/form/select';
import FormatUtils from '../common/formatUtils/FormatUtils';

import imagemUser from '../assets/images/user.jpg';

import {
	getListaPremiacaoEconomica, getListaPremiacaoExelencia, getListaPremiacaoPenalidades, getListaPremiacaoQuilometragem
} from './dashboardActions';

class Dashboard extends Component {

	state = {
		pesquisar: ''
	}

	constructor(props) {
		super(props);
    }

    componentDidMount() {
		this.props.getListaPremiacaoEconomica();
		this.props.getListaPremiacaoExelencia();
		this.props.getListaPremiacaoPenalidades();
		this.props.getListaPremiacaoQuilometragem();
    }

    render() {

		let listaMesesExcelencia = [];
		this.props.listaPremiacaoExelencia.forEach(item => {
			if (listaMesesExcelencia.filter(mes => mes == item.competencia_analise).length == 0) {
				listaMesesExcelencia.push(item.competencia_analise);
			}
		});
		let listaCategoriaExcelencia = [];
		this.props.listaPremiacaoExelencia.forEach(item => {
			if (listaCategoriaExcelencia.filter(mes => mes == item.nome_grupo).length == 0) {
				listaCategoriaExcelencia.push(item.nome_grupo);
			}
		});

		if (this.props.usuarioCarregado && this.props.usuarioCarregado.acesso_premiacao) {

			return (
				<Content>
					<ContentCard>
						<ContentCardHeader>
							<h4>Premiação | Condução Econômica</h4>
						</ContentCardHeader>
						<ContentCardBody>
							<Table responsive tableBordered>
								<THead>
									<Tr>
										<Th>Condução Econômica</Th>
										{this.props.listaPremiacaoEconomica.map(item => (
											<Th key={item.competencia_analise} alignCenter backgroundColor={'#0277bd'} color={'#fff'}>{`${String(item.competencia_analise).substring(4, 6)}/${String(item.competencia_analise).substring(0, 4)}`}</Th>
										))}
									</Tr>
								</THead>
								<TBody>
									<Tr>
										<Td backgroundColor={'#0277bd'} color={'#fff'}>Total de Participantes</Td>
										{this.props.listaPremiacaoEconomica.map(item => (
											<Td key={item.competencia_analise} alignCenter backgroundColor={'#29b6f6'} color={'#fff'}>{FormatUtils.formatarValorTelaDecimal(item.quantidade_total, 0)}</Td>
										))}
									</Tr>
									<Tr>
										<Td backgroundColor={'#0277bd'} color={'#fff'}>Atingiram</Td>
										{this.props.listaPremiacaoEconomica.map(item => (
											<Td key={item.competencia_analise} alignCenter backgroundColor={'#039be5'} color={'#fff'}>{FormatUtils.formatarValorTelaDecimal(item.quantidade_atingiu, 0)}</Td>
										))}
									</Tr>
									<Tr>
										<Td backgroundColor={'#43a047'} color={'#fff'}>Perc. que Atingiram</Td>
										{this.props.listaPremiacaoEconomica.map(item => (
											<Td key={item.competencia_analise} alignCenter alignCenter backgroundColor={'#81c784'} color={'#fff'}>{FormatUtils.formatarValorTelaDecimal(item.percentual_atingiu, 2)}</Td>
										))}
									</Tr>
									<Tr>
										<Td backgroundColor={'#0277bd'} color={'#fff'}>Média dos Prêmios</Td>
										{this.props.listaPremiacaoEconomica.map(item => (
											<Td key={item.competencia_analise} alignCenter backgroundColor={'#039be5'} color={'#fff'}>{FormatUtils.formatarValorTelaDecimal(item.media_premio, 2)}</Td>
										))}
									</Tr>
									<Tr>
										<Td backgroundColor={'#0277bd'} color={'#fff'}>Total dos Prêmios</Td>
										{this.props.listaPremiacaoEconomica.map(item => (
											<Td key={item.competencia_analise} alignCenter alignCenter backgroundColor={'#29b6f6'} color={'#fff'}>{FormatUtils.formatarValorTelaDecimal(item.total_premio, 2)}</Td>
										))}
									</Tr>
								</TBody>
							</Table>

							<div style={{ display: 'flex', justifyContent: 'center' }}>
								<img  src={`https://quickchart.io/chart?width=${window.innerWidth * 0.35}&height=220&chart={type:'bar',
											data:{labels:${JSON.stringify(this.props.listaPremiacaoEconomica.map(item => `${String(item.competencia_analise).substring(4, 6)}/${String(item.competencia_analise).substring(0, 4)}`))},
											datasets:[{label:'Perc. que Atingiram',data:${JSON.stringify(this.props.listaPremiacaoEconomica.map(item => item.quantidade_atingiu))}}]},
											options: {legend: {labels: {fontSize: 8,}},scales: {yAxes: [{ticks: {beginAtZero: true,fontSize: 8, },},],
											xAxes: [{ ticks: {fontSize: 8,},},],},},}`}/>
							</div>
						</ContentCardBody>
					</ContentCard>

					<ContentCard>
						<ContentCardHeader>
							<h4>Premiação | Excelência</h4>
						</ContentCardHeader>
						<ContentCardBody>
							<Table responsive tableBordered>
								<THead>
									<Tr>
										<Th colspan={2}>Premiação Econômica</Th>
										{listaMesesExcelencia.map(competencia => (
											<Th key={competencia} colspan={3} alignCenter backgroundColor={'#0277bd'} color={'#fff'}>{`${String(competencia).substring(4, 6)}/${String(competencia).substring(0, 4)}`}</Th>
										))}
									</Tr>
									<Tr>
										<Th>Categoria</Th>
										<Th>Faixa Km</Th>
										{listaMesesExcelencia.map((item, i) => (
											<>
												<Th key={`${item}-${i}`} alignCenter>Qtd</Th>
												<Th key={`${item}-${i+1}`} alignCenter>%</Th>
												<Th key={`${item}-${i+1}`} alignCenter>Total</Th>
											</>
										))}
									</Tr>
								</THead>
								<TBody>
									{listaCategoriaExcelencia.map(categoria => {
										let listaFaixaExcelencia = [];
										this.props.listaPremiacaoExelencia.filter(item => item.nome_grupo == categoria).forEach(item => {
											if (listaFaixaExcelencia.filter(mes => mes == item.faixa).length == 0) {
												listaFaixaExcelencia.push(item.faixa);
											}
										});

										let totalCategoria = [];
										return (
											<>
												{listaFaixaExcelencia.map((faixa, posFaixa) => (
													<Tr key={faixa}>
														{posFaixa == 0 ? <Td rowspan={listaFaixaExcelencia.length + 1} backgroundColor={'#0277bd'} color={'#fff'}>{categoria}</Td> : null}
														<Td backgroundColor={posFaixa % 2 == 0 ? '#039be5' : '#0277bd'} color={'#fff'}>{faixa}</Td>
														{listaMesesExcelencia.map((competencia, posCompetencia) => {

															let itemCompetencia =  this.props.listaPremiacaoExelencia.filter(item => item.nome_grupo == categoria && item.faixa == faixa && item.competencia_analise == competencia)[0];

															if (totalCategoria.filter(valorCategoria => valorCategoria.competencia == competencia).length == 0) {
																totalCategoria.push({
																	competencia: competencia,
																	valor: parseFloat(itemCompetencia ? itemCompetencia.total_excelencia : 0)
																});
															} else {
																totalCategoria = totalCategoria.map(valorCategoria => {
																	if (valorCategoria.competencia == competencia) {
																		return {
																			...valorCategoria,
																			valor: valorCategoria.valor + parseFloat(itemCompetencia ? itemCompetencia.total_excelencia : 0)
																		}
																	} else {
																		return valorCategoria;
																	}
																});
															}

															return (
																<>
																	<Td alignCenter backgroundColor={posFaixa % 2 == 0 ? (posCompetencia % 2 == 0 ? '#29b6f6' : '#4fc3f7') : (posCompetencia % 2 == 0 ? '#039be5' : '#03a9f4')} color={'#fff'}>{FormatUtils.formatarValorTelaDecimal(itemCompetencia ? itemCompetencia.quantidade_total : 0, 0)}</Td>
																	<Td alignCenter backgroundColor={posFaixa % 2 == 0 ? (posCompetencia % 2 == 0 ? '#29b6f6' : '#4fc3f7') : (posCompetencia % 2 == 0 ? '#039be5' : '#03a9f4')} color={'#fff'}>{FormatUtils.formatarValorTelaDecimal(itemCompetencia ? itemCompetencia.percentual_premio : 0, 0)}</Td>
																	<Td alignCenter backgroundColor={posFaixa % 2 == 0 ? (posCompetencia % 2 == 0 ? '#29b6f6' : '#4fc3f7') : (posCompetencia % 2 == 0 ? '#039be5' : '#03a9f4')} color={'#fff'}>{FormatUtils.formatarValorTelaDecimal(itemCompetencia ? itemCompetencia.total_excelencia : 0, 2)}</Td>
																</>
															);
														})}
													</Tr>
												))}

												<Tr>
													<Td></Td>
													{totalCategoria.map(valorCategoria => (
														<Th key={valorCategoria.competencia} colspan={3} alignRight>Total: {FormatUtils.formatarValorTelaDecimal(valorCategoria.valor, 2)}</Th>
													))}
												</Tr>
											</>
										)
									})}
								</TBody>
							</Table>
						</ContentCardBody>
					</ContentCard>

					<ContentCard>
						<ContentCardHeader>
							<h4>Premiação | Penalidades Aplicadas</h4>
						</ContentCardHeader>
						<ContentCardBody>
							<Table responsive tableBordered>
								<THead>
									<Tr>
										<Th>Penalidades</Th>
										{this.props.listaPremiacaoPenalidades.map(item => (
											<Th key={item.nome_ocorrencia} alignCenter>{item.nome_ocorrencia}</Th>
										))}
									</Tr>
								</THead>
								<TBody>
									<Tr>
										<Td backgroundColor={'#0277bd'} color={'#fff'}>Quantidade</Td>
										{this.props.listaPremiacaoPenalidades.map(item => (
											<Td key={item.nome_ocorrencia} alignCenter alignCenter backgroundColor={'#29b6f6'} color={'#fff'}>{FormatUtils.formatarValorTelaDecimal(item.quantidade_ocorrencia, 0)}</Td>
										))}
									</Tr>
									<Tr>
										<Td backgroundColor={'#0277bd'} color={'#fff'}>Desconto R$</Td>
										{this.props.listaPremiacaoPenalidades.map(item => (
											<Td key={item.nome_ocorrencia} alignCenter backgroundColor={'#039be5'} color={'#fff'}>{FormatUtils.formatarValorTelaDecimal(item.valor_desconto, 2)}</Td>
										))}
									</Tr>
								</TBody>
							</Table>

							<div style={{ display: 'flex', justifyContent: 'center' }}>
								<img  src={`https://quickchart.io/chart?width=${window.innerWidth * 0.35}&height=220&chart={type:'bar',
											data:{labels:${JSON.stringify(this.props.listaPremiacaoPenalidades.map(item => item.nome_ocorrencia))},
											datasets:[{label:'Penalidades R$',data:${JSON.stringify(this.props.listaPremiacaoPenalidades.map(item => item.valor_desconto))}}]},
											options: {legend: {labels: {fontSize: 8,}},scales: {yAxes: [{ticks: {beginAtZero: true,fontSize: 8, },},],
											xAxes: [{ ticks: {fontSize: 8,},},],},},}`}/>
							</div>
						</ContentCardBody>
					</ContentCard>

					<ContentCard>
						<ContentCardHeader>
							<h4>Premiação | Km Rodado</h4>
						</ContentCardHeader>
						<ContentCardBody>
							<Table responsive tableBordered>
								<THead>
									<Tr>
										<Th>Categoria</Th>
										{this.props.listaPremiacaoQuilometragem.map(item => (
											<Th key={item.competencia_analise} alignCenter backgroundColor={'#0277bd'} color={'#fff'}>{`${String(item.competencia_analise).substring(4, 6)}/${String(item.competencia_analise).substring(0, 4)}`}</Th>
										))}
									</Tr>
								</THead>
								<TBody>
									<Tr>
										<Td backgroundColor={'#0277bd'} color={'#fff'}>Km Total</Td>
										{this.props.listaPremiacaoQuilometragem.map(item => (
											<Td key={item.competencia_analise} alignCenter backgroundColor={'#29b6f6'} color={'#fff'}>{FormatUtils.formatarValorTelaDecimal(item.km_total, 0)}</Td>
										))}
									</Tr>
									<Tr>
										<Td backgroundColor={'#0277bd'} color={'#fff'}>Km Média</Td>
										{this.props.listaPremiacaoQuilometragem.map(item => (
											<Td key={item.competencia_analise} alignCenter backgroundColor={'#039be5'} color={'#fff'}>{FormatUtils.formatarValorTelaDecimal(item.km_media, 0)}</Td>
										))}
									</Tr>
								</TBody>
							</Table>

							<div style={{ display: 'flex', justifyContent: 'center' }}>
								<img  src={`https://quickchart.io/chart?width=${window.innerWidth * 0.35}&height=150&chart={type:'bar',
											data:{labels:${JSON.stringify(this.props.listaPremiacaoQuilometragem.map(item => `${String(item.competencia_analise).substring(4, 6)}/${String(item.competencia_analise).substring(0, 4)}`))},
											datasets:[{label:'Km Total',data:${JSON.stringify(this.props.listaPremiacaoQuilometragem.map(item => item.km_total))}}]},
											options: {legend: {labels: {fontSize: 8,}},scales: {yAxes: [{ticks: {beginAtZero: true,fontSize: 8, },},],
											xAxes: [{ ticks: {fontSize: 8,},},],},},}`}/>
							</div>

							<div style={{ display: 'flex', justifyContent: 'center' }}>
								<img  src={`https://quickchart.io/chart?width=${window.innerWidth * 0.35}&height=150&chart={type:'bar',
											data:{labels:${JSON.stringify(this.props.listaPremiacaoQuilometragem.map(item => `${String(item.competencia_analise).substring(4, 6)}/${String(item.competencia_analise).substring(0, 4)}`))},
											datasets:[{label:'Km Média',data:${JSON.stringify(this.props.listaPremiacaoQuilometragem.map(item => item.km_media))}}]},
											options: {legend: {labels: {fontSize: 8,}},scales: {yAxes: [{ticks: {beginAtZero: true,fontSize: 8, },},],
											xAxes: [{ ticks: {fontSize: 8,},},],},},}`}/>
							</div>
						</ContentCardBody>
					</ContentCard>
				</Content>
			)
		} else {
			return <Content />
		}
    }
}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	usuarioCarregado: state.auth.usuarioCarregado,
	aguardando: state.dashboard.aguardando,
	listaPremiacaoEconomica: state.dashboard.listaPremiacaoEconomica,
	listaPremiacaoExelencia: state.dashboard.listaPremiacaoExelencia,
	listaPremiacaoPenalidades: state.dashboard.listaPremiacaoPenalidades,
	listaPremiacaoQuilometragem: state.dashboard.listaPremiacaoQuilometragem
});
const mapDispatchToProps = dispatch => bindActionCreators({
	getListaPremiacaoEconomica, getListaPremiacaoExelencia, getListaPremiacaoPenalidades, getListaPremiacaoQuilometragem
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
