import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import DateFormat from '../common/dateFormat/DateFormat';

export function setModoTela(modo, registro = { id_estoque_local: 2 }) {
    return {
        type: 'ACESSORIO_HISTORICO_MODO_TELA',
        payload: {
			modo: modo,
			registro: registro
		}
    };
}

export function setAguardando(aguardando) {
    return {
        type: 'ACESSORIO_HISTORICO_SET_AGUARDANDO',
        payload: aguardando
    };
}

export function setFiltro(filtro) {
    return {
        type: 'ACESSORIO_HISTORICO_FILTRO',
        payload: {
			filtro: filtro
		}
    };
}

export function getLista() {
	return (dispatch, getState) => {

		dispatch(setAguardando(true));
		axios.get(`${consts.API_URL}/acessorioMovimento/historico?
			id_tipo=${getState().acessorioHistorico.filtro.id_tipo || null}
			&id_evento=${getState().acessorioHistorico.filtro.id_evento || null}
			&id_unidade=${getState().acessorioHistorico.filtro.id_unidade || null}
			&id_veiculo=${getState().acessorioHistorico.filtro.id_veiculo || null}
			&data_inicial=${DateFormat.formatarDataTelaParaSql(getState().acessorioHistorico.filtro.data_inicial) == 'Invalid DateTime' ? null : DateFormat.formatarDataTelaParaSql(getState().acessorioHistorico.filtro.data_inicial)}
			&data_final=${DateFormat.formatarDataTelaParaSql(getState().acessorioHistorico.filtro.data_final) == 'Invalid DateTime' ? null : DateFormat.formatarDataTelaParaSql(getState().acessorioHistorico.filtro.data_final)}
		`).then(resp => {			
			dispatch({
				type: 'ACESSORIO_HISTORICO_LISTADO',
		        payload: resp
			});
			dispatch(setAguardando(false));
		}).then(resp => {
			dispatch(setAguardando(false));
		}).catch(e => {
			setErroAPI(e);
			dispatch(setAguardando(false));
		});
	}
}

export function getListaTipo() {
	return (dispatch, getState) => {
    	axios.get(`${consts.API_URL}/acessorioTipo/listarSelect`)
		.then(resp => {
			dispatch({
				type: 'ACESSORIO_HISTORICO_TIPO_SELECT_LISTADO',
		        payload: resp
		    });
		}).catch(e => {
			setErroAPI(e);
		});
	}
}

export function getListaEvento() {
	return (dispatch, getState) => {
    	axios.get(`${consts.API_URL}/acessorioEvento/listarSelect`)
		.then(resp => {
			dispatch({
				type: 'ACESSORIO_HISTORICO_EVENTO_SELECT_LISTADO',
		        payload: resp
		    });
		}).catch(e => {
			setErroAPI(e);
		});
	}
}

export function getListaUnidade() {
    const request = axios.get(`${consts.API_URL}/unidade/listarSelect`);
    return {
        type: 'ACESSORIO_HISTORICO_UNIDADE_SELECT_LISTADO',
        payload: request
    };
}

export function getListaVeiculo() {
	return (dispatch, getState) => {
    	axios.get(`${consts.API_URL}/veiculo/listarSelect`)
		.then(resp => {
			dispatch({
				type: 'ACESSORIO_HISTORICO_VEICULO_SELECT_LISTADO',
		        payload: resp
		    });
		}).catch(e => {
			setErroAPI(e);
		});
	}
}

export function getListaMotorista() {
	return (dispatch, getState) => {
    	axios.get(`${consts.API_URL}/motorista/listarSelect`)
		.then(resp => {
			dispatch({
				type: 'ACESSORIO_HISTORICO_MOTORISTA_SELECT_LISTADO',
		        payload: resp
		    });
		}).catch(e => {
			setErroAPI(e);
		});
	}
}
