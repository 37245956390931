import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import DateFormat from '../common/dateFormat/DateFormat';
import Aguardando from '../common/template/aguardando';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import Select from '../common/form/select';
import InputPesquisa from '../common/form/inputPesquisa';
import ReactExport from 'react-export-excel-fixed-xlsx';

import { 
	setFiltro, getLista, getListaTipo, getListaUnidade, getListaVeiculo, getListaEvento
} from './acessorioHistoricoActions';
import FormatUtils from '../common/formatUtils/FormatUtils';

class AcessorioHistorico extends Component {

	state = {
		pesquisar: '',
		linhaSelecionada: null,
		filtro: {
			id_tipo: null,
			id_evento: null,
			id_unidade: null
		},
		ordenacao: {
			datahora_movimento: 1,
			nome_tipo: 0,
			tipo_movimentacao: 0,
			nome_evento: 0,
			unidade_placa: 0,
			transferencia_origem_destino: 0,
			nome_motorista: 0,
			nome_usuario: 0,
			quantidade_inicial: 0,
			quantidade: 0,
			quantidade_final: 0
		}
	}

    componentWillMount() {
		this.props.getLista();
		this.props.getListaEvento();
		this.props.getListaTipo();
		this.props.getListaUnidade();
		this.props.getListaVeiculo();		
    }

	getNovaOrdenacao(atual) {
		return atual == 2 ? 0 : (atual || 0) + 1;
	}

	getOrdenacao(ordenacao) {
		return ordenacao == 1 
			? <i class="fas fa-sort-amount-down-alt" /> 
			: (
				ordenacao == 2 
					? <i class="fas fa-sort-amount-up" /> 
					: null
			);
	}

	filtrar() {
		
		let lista = this.props.lista.filter(item => {
			return !(this.state.pesquisar || '').trim()
				|| (this.state.pesquisar.toUpperCase() || '').split('+').filter(pesquisar => (pesquisar || '').trim()).filter(pesquisar => {		
				if (pesquisar
					&& !(`
					${DateFormat.formatarDataHoraSqlParaTela(item.datahora_movimento)}
					${item.nome_tipo}
					${item.tipo_movimentacao}
					${item.nome_evento}
					${item.placa_veiculo || item.nome_unidade}
					${item.placa_veiculo_trf || item.nome_unidade_trf}
					${item.nome_motorista}
					${item.nome_usuario}
					${parseInt(item.quantidade_inicial)}
					${parseInt(item.quantidade)}
					${parseInt(item.quantidade_final)}
				`).toUpperCase().includes(pesquisar)) {
					return false;
				}	
				return true;
			}).length > 0;
		});
		
		if (this.state.ordenacao.datahora_movimento) {
			lista.sort((a, b) => this.state.ordenacao.datahora_movimento == 1 
				? (a.datahora_movimento > b.datahora_movimento ? 1 : -1) 
				: (a.datahora_movimento > b.datahora_movimento ? -1 : 1));		
		} else if (this.state.ordenacao.nome_tipo) {
			lista.sort((a, b) => this.state.ordenacao.nome_tipo == 1 
				? (a.nome_tipo > b.nome_tipo ? 1 : -1) 
				: (a.nome_tipo > b.nome_tipo ? -1 : 1));
		} else if (this.state.ordenacao.tipo_movimentacao) {
			lista.sort((a, b) => this.state.ordenacao.tipo_movimentacao == 1 
				? (a.tipo_movimentacao > b.tipo_movimentacao ? 1 : -1) 
				: (a.tipo_movimentacao > b.tipo_movimentacao ? -1 : 1));
		} else if (this.state.ordenacao.nome_evento) {
			lista.sort((a, b) => this.state.ordenacao.nome_evento == 1 
				? (a.nome_evento > b.nome_evento ? 1 : -1) 
				: (a.nome_evento > b.nome_evento ? -1 : 1));		
		} else if (this.state.ordenacao.unidade_placa) {
			lista.sort((a, b) => this.state.ordenacao.unidade_placa == 1 
				? ((a.placa_veiculo || a.nome_unidade) > (b.placa_veiculo || b.nome_unidade) ? 1 : -1) 
				: ((a.placa_veiculo || a.nome_unidade) > (b.placa_veiculo || b.nome_unidade) ? -1 : 1));		
		} else if (this.state.ordenacao.transferencia_origem_destino) {
			lista.sort((a, b) => this.state.ordenacao.transferencia_origem_destino == 1 
				? ((a.placa_veiculo_trf || a.nome_unidade_trf || '') > (b.placa_veiculo_trf || b.nome_unidade_trf || '') ? 1 : -1) 
				: ((a.placa_veiculo_trf || a.nome_unidade_trf || '') > (b.placa_veiculo_trf || b.nome_unidade_trf || '') ? -1 : 1));
		} else if (this.state.ordenacao.nome_motorista) {
			lista.sort((a, b) => this.state.ordenacao.nome_motorista == 1 
				? ((a.nome_motorista || '') > (b.nome_motorista || '') ? 1 : -1) 
				: ((a.nome_motorista || '') > (b.nome_motorista || '') ? -1 : 1));
		} else if (this.state.ordenacao.nome_usuario) {
			lista.sort((a, b) => this.state.ordenacao.nome_usuario == 1 
				? (a.nome_usuario > b.nome_usuario ? 1 : -1) 
				: (a.nome_usuario > b.nome_usuario ? -1 : 1));
		} else if (this.state.ordenacao.quantidade_inicial) {
			lista.sort((a, b) => this.state.ordenacao.quantidade_inicial == 1 
				? (a.quantidade_inicial > b.quantidade_inicial ? 1 : -1) 
				: (a.quantidade_inicial > b.quantidade_inicial ? -1 : 1));
		} else if (this.state.ordenacao.quantidade) {
			lista.sort((a, b) => this.state.ordenacao.quantidade == 1 
				? (a.quantidade > b.quantidade ? 1 : -1) 
				: (a.quantidade > b.quantidade ? -1 : 1));
		} else if (this.state.ordenacao.quantidade_final) {
			lista.sort((a, b) => this.state.ordenacao.quantidade_final == 1 
				? (a.quantidade_final > b.quantidade_final ? 1 : -1) 
				: (a.quantidade_final > b.quantidade_final ? -1 : 1));
		}

		return lista;

	}

	montarExcel(lista) {

		const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
		const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

		return (
			<ExcelSheet data={lista.map(item => ({
				...item,
				datahora_movimento: DateFormat.formatarDataHoraSqlParaTela(item.datahora_movimento),
				unidade_placa: item.placa_veiculo || item.nome_unidade,
				transferencia_origem_destino: item.placa_veiculo_trf || item.nome_unidade_trf,				
				quantidade_inicial: parseInt(item.quantidade_inicial),
				quantidade: parseInt(item.quantidade),
				quantidade_final: parseInt(item.quantidade_final)
			}))} name='Acessórios - Histórico'>
				{[
					{ componente: <ExcelColumn key={1} label='Data/Hora' value='datahora_movimento' />},
					{ componente: <ExcelColumn key={2} label='Acessório' value='nome_tipo' />},
					{ componente: <ExcelColumn key={3} label='Tipo' value='tipo_movimentacao' />},
					{ componente: <ExcelColumn key={4} label='Evento' value='nome_evento' />},
					{ componente: <ExcelColumn key={5} label='Unidade/Placa' value='unidade_placa' />},
					{ componente: <ExcelColumn key={6} label='Trf Origem/Destino' value='transferencia_origem_destino' />},
					{ componente: <ExcelColumn key={7} label='Motorista' value='nome_motorista' />},
					{ componente: <ExcelColumn key={8} label='Alteração' value='nome_usuario' />},
					{ componente: <ExcelColumn key={9} label='Anterior' type='number' value='quantidade_inicial' />},
					{ componente: <ExcelColumn key={10} label='Quantidade' type='number' value='quantidade' />},
					{ componente: <ExcelColumn key={11} label='Atual' type='number' value='quantidade_final' />}
				].filter(item => {
					return true;
				}).map(item => (
					item.componente
				))}
			</ExcelSheet>
		);
	}

    render() {

		const ExcelFile = ReactExport.ExcelFile;
		
		return (
			<Content>

				<Aguardando aguardando={this.props.aguardando} />

				<ContentCard>
					<ContentCardHeader>
						<Row>
							<InputPesquisa
								cols='12 6 3 1'
								label='Pesquisar'
								minWidth={200}
								onChange={value => this.setState({ ...this.state, pesquisar: value })} />

							<Select
								label='Acessório'
								options={this.props.listaTipo}
								cols='12 6 3 1'
								placeholder='Selecione'
								minWidth={200}
								value={this.props.filtro.id_tipo}
								onChange={value => {
									this.props.setFiltro({ ...this.props.filtro, id_tipo: value });
								}} />
							
							<Select
								label='Evento'
								options={this.props.listaEvento}
								cols='12 6 3 1'
								placeholder='Selecione'
								minWidth={200}
								value={this.props.filtro.id_evento}
								onChange={value => {
									this.props.setFiltro({ ...this.props.filtro, id_evento: value });
								}} />

							<Select
								label='Unidade'
								options={this.props.listaUnidade}
								cols='12 6 3 1'
								placeholder='Selecione'
								minWidth={200}
								value={this.props.filtro.id_unidade}
								onChange={value => {
									this.props.setFiltro({ ...this.props.filtro, id_unidade: value });
								}} />

							<Select
								label='Veículo'
								options={this.props.listaVeiculo}
								cols='12 6 3 1'
								placeholder='Selecione'
								minWidth={200}
								value={this.props.filtro.id_veiculo}
								onChange={value => {
									this.props.setFiltro({ ...this.props.filtro, id_veiculo: value });
								}} />

							<LabelAndInputMask
								label='Data Incial' placeholder='Informe a data'
								cols='12 6 2 1'
								mask='99/99/9999'
								minWidth={130}
								maxWidth={130}
								value={this.props.filtro.data_inicial}
								onChange={data => {
									this.props.setFiltro({ ...this.props.filtro, data_inicial: data.target.value });
								}} />

							<LabelAndInputMask
								label='Data Final' placeholder='Informe a data'
								cols='12 6 2 1'
								mask='99/99/9999'
								minWidth={130}
								maxWidth={130}
								value={this.props.filtro.data_final}
								onChange={data => {
									this.props.setFiltro({ ...this.props.filtro, data_final: data.target.value });
								}} />

							<div style={{ marginTop: 8 }}>
								<a class='btn btn-app bg-primary' style={{ marginLeft: 2, minWidth: 75 }}
									onClick={() => {
										this.props.getLista();
									}}>
									<i class='fas fa-sync-alt'></i> Atualizar
								</a>
								<ExcelFile
									filename={'Acessórios - Histórico'}
									element={
										<a class='btn btn-app bg-success' style={{ marginLeft: 2, minWidth: 75 }}>
											<i class='fas fa-file-excel'></i> Exportar
										</a>
									}>
									{this.montarExcel(this.filtrar())}													
								</ExcelFile>
							</div>
						</Row>

					</ContentCardHeader>

					<ContentCardBody>
						<Table responsive border alturaFixa striped 
							maxHeight={window.innerWidth < 576 ? '100%' : (
								window.innerWidth < 768 ? window.innerHeight - 370 : (
									window.innerWidth < 1558 ? window.innerHeight - 280 : window.innerHeight - 200
								)
							)}>
							<THead>
								<Tr backgroundColor={'#fff'}>
									<Th alignCenter sticky width={100} color={'#1F3066'} small
										onClick={() => this.setState({ ...this.state, ordenacao: { datahora_movimento: this.getNovaOrdenacao(this.state.ordenacao.datahora_movimento) }})}>
										Data/Hora {this.getOrdenacao(this.state.ordenacao.datahora_movimento)}
									</Th>
									<Th alignCenter sticky width={80} color={'#1F3066'} small
										onClick={() => this.setState({ ...this.state, ordenacao: { nome_tipo: this.getNovaOrdenacao(this.state.ordenacao.nome_tipo) }})}>
										Acessório {this.getOrdenacao(this.state.ordenacao.nome_tipo)}
									</Th>
									<Th alignCenter sticky width={80} color={'#1F3066'} small
										onClick={() => this.setState({ ...this.state, ordenacao: { tipo_movimentacao: this.getNovaOrdenacao(this.state.ordenacao.tipo_movimentacao) }})}>
										Tipo {this.getOrdenacao(this.state.ordenacao.tipo_movimentacao)}
									</Th>
									<Th alignCenter sticky width={80} color={'#1F3066'} small
										onClick={() => this.setState({ ...this.state, ordenacao: { nome_evento: this.getNovaOrdenacao(this.state.ordenacao.nome_evento) }})}>
										Evento {this.getOrdenacao(this.state.ordenacao.nome_evento)}
									</Th>
									<Th alignCenter sticky width={150} color={'#1F3066'} small
										onClick={() => this.setState({ ...this.state, ordenacao: { unidade_placa: this.getNovaOrdenacao(this.state.ordenacao.unidade_placa) }})}>
										Unidade/Placa {this.getOrdenacao(this.state.ordenacao.unidade_placa)}
									</Th>
									<Th alignCenter sticky width={150} color={'#1F3066'} small
										onClick={() => this.setState({ ...this.state, ordenacao: { transferencia_origem_destino: this.getNovaOrdenacao(this.state.ordenacao.transferencia_origem_destino) }})}>
										Trf Origem/Destino {this.getOrdenacao(this.state.ordenacao.transferencia_origem_destino)}
									</Th>
									<Th alignCenter sticky width={200} color={'#1F3066'} small
										onClick={() => this.setState({ ...this.state, ordenacao: { nome_motorista: this.getNovaOrdenacao(this.state.ordenacao.nome_motorista) }})}>
										Motorista {this.getOrdenacao(this.state.ordenacao.nome_motorista)}
									</Th>
									<Th alignCenter sticky width={150} color={'#1F3066'} small
										onClick={() => this.setState({ ...this.state, ordenacao: { nome_usuario: this.getNovaOrdenacao(this.state.ordenacao.nome_usuario) }})}>
										Alteração {this.getOrdenacao(this.state.ordenacao.nome_usuario)}
									</Th>
									<Th alignCenter sticky width={80} color={'#1F3066'} small
										onClick={() => this.setState({ ...this.state, ordenacao: { quantidade_inicial: this.getNovaOrdenacao(this.state.ordenacao.quantidade_inicial) }})}>
										Anterior {this.getOrdenacao(this.state.ordenacao.quantidade_inicial)}
									</Th>
									<Th alignCenter sticky width={80} color={'#1F3066'} small
										onClick={() => this.setState({ ...this.state, ordenacao: { quantidade: this.getNovaOrdenacao(this.state.ordenacao.quantidade) }})}>
										Quantidade {this.getOrdenacao(this.state.ordenacao.quantidade)}
									</Th>
									<Th alignCenter sticky width={80} color={'#1F3066'} small
										onClick={() => this.setState({ ...this.state, ordenacao: { quantidade_final: this.getNovaOrdenacao(this.state.ordenacao.quantidade_final) }})}>
										Atual {this.getOrdenacao(this.state.ordenacao.quantidade_final)}
									</Th>
								</Tr>
							</THead>
							<TBody>
								{this.filtrar().map(item => (
									<Tr key={item.id} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
										onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
										onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
										<Td alignCenter small>{DateFormat.formatarDataHoraSqlParaTela(item.datahora_movimento)}</Td>
										<Td alignCenter small>{item.nome_tipo}</Td>
										<Td alignCenter small>{item.tipo_movimentacao}</Td>
										<Td alignCenter small>{item.nome_evento}</Td>
										<Td alignCenter small>{item.placa_veiculo || item.nome_unidade}</Td>
										<Td alignCenter small>{item.placa_veiculo_trf || item.nome_unidade_trf}</Td>
										<Td alignCenter small>{item.nome_motorista}</Td>
										<Td alignCenter small>{item.nome_usuario}</Td>
										<Td alignCenter small>{item.quantidade_inicial}</Td>
										<Td alignCenter small>{item.quantidade}</Td>
										<Td alignCenter small>{item.quantidade_final}</Td>
									</Tr> 
								))}
							</TBody>
						</Table>
					</ContentCardBody>
				</ContentCard>
			</Content>
		);
	}

}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	modoTela: state.acessorioHistorico.modoTela,
	filtro: state.acessorioHistorico.filtro,
	aguardando: state.acessorioHistorico.aguardando,
	lista: state.acessorioHistorico.lista.filter(item => 
		state.auth.usuarioCarregado && (
			(!item.id_unidade && !item.id_unidade_trf)
			|| (state.auth.usuarioCarregado.acessorio_unidades || []).includes(item.id_unidade)
			|| (state.auth.usuarioCarregado.acessorio_unidades || []).includes(item.id_unidade_trf)
		)
		&& (state.auth.usuarioCarregado.acessorio_tipos || []).includes(item.id_tipo)
	),
	listaTipo: state.acessorioHistorico.listaTipo.filter(item => 
		state.auth.usuarioCarregado && (state.auth.usuarioCarregado.acessorio_tipos || []).includes(item.id)
	),
	listaEvento: state.acessorioHistorico.listaEvento,
	listaUnidade: state.acessorioHistorico.listaUnidade.filter(item => 
		state.auth.usuarioCarregado && (state.auth.usuarioCarregado.acessorio_unidades || []).includes(item.id)
	),
	listaVeiculo: state.acessorioHistorico.listaVeiculo
});
const mapDispatchToProps = dispatch => bindActionCreators({ 
	setFiltro, getLista, getListaTipo, getListaUnidade, getListaVeiculo, getListaEvento
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(AcessorioHistorico);
