import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import FormEntrada from './valePedagioEntradaForm';
import FormTransferencia from './valePedagioTransferenciaForm';
import FormSaida from './valePedagioSaidaForm';
import DateFormat from '../common/dateFormat/DateFormat';
import FormatUtils from '../common/formatUtils/FormatUtils';
import Aguardando from '../common/template/aguardando';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import Select from '../common/form/select';
import ReactExport from 'react-export-excel-fixed-xlsx';

import { 
	setModoTela, setFiltro, getLista, getListaSaldo,
	initFormEntrada, salvarEntrada, excluirEntrada,
	initFormTransferencia, salvarTransferencia, excluirTransferencia,
	initFormSaida, salvarSaida, excluirSaida,
	getListaMotorista, getListaValePedagio, getListaMotivo, getListaPeriodo, getListaTomador, gerarPdf
} from './valePedagioMotoristaActions';

class ValePedagioMotorista extends Component {

	state = {
		pesquisar: '',
		linhaSelecionada: null
	}

    componentWillMount() {
		this.props.getLista();
		this.props.getListaSaldo();
		this.props.getListaValePedagio();
		this.props.getListaMotorista();
		this.props.getListaMotivo();
		this.props.getListaPeriodo();
		this.props.getListaTomador();
    }

    render() {
        return (
            <div>
                <Content>

					<Aguardando aguardando={this.props.aguardando} />
					
					{this.props.modoTela == 'visualizacaoEntrada' ? (
						<FormEntrada visualizacao onSubmit={this.props.salvarEntrada} />
					) : null}
					{this.props.modoTela == 'cadastroEntrada' ? (
						<FormEntrada onSubmit={this.props.salvarEntrada} />
					) : null}
					{this.props.modoTela == 'exclusaoEntrada' ? (
						<FormEntrada excluir onSubmit={this.props.excluirEntrada} />
					) : null}
					
					{this.props.modoTela == 'visualizacaoTransferencia' ? (
						<FormTransferencia visualizacao onSubmit={this.props.salvarTransferencia} />
					) : null}
					{this.props.modoTela == 'cadastroTransferencia' ? (
						<FormTransferencia onSubmit={this.props.salvarTransferencia} />
					) : null}
					{this.props.modoTela == 'exclusaoTransferencia' ? (
						<FormTransferencia excluir onSubmit={this.props.excluirTransferencia} />
					) : null}
					
					{this.props.modoTela == 'visualizacaoSaida' ? (
						<FormSaida visualizacao onSubmit={this.props.salvarSaida} />
					) : null}
					{this.props.modoTela == 'cadastroSaida' ? (
						<FormSaida onSubmit={this.props.salvarSaida} />
					) : null}
					{this.props.modoTela == 'exclusaoSaida' ? (
						<FormSaida excluir onSubmit={this.props.excluirSaida} />
					) : null}
					{this.props.modoTela == 'lista' ? this.lista() : null}
                </Content>
            </div>
        )
    }

	lista() {

		const ExcelFile = ReactExport.ExcelFile;
		const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
		const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

		let lista = this.props.lista.filter(item => !this.props.filtro.id_tomador || item.id_tomador == this.props.filtro.id_tomador);
		
		return (
			<ContentCard>
				<ContentCardHeader>
					<Row>
						<Select
							options={this.props.listaMotorista}
							label='Motorista'
							cols='12 12 6 4'
							placeholder='Selecione o Motorista'
							value={this.props.filtro.id_motorista}
							onChange={data => {
								this.props.setFiltro({
									...this.props.filtro,
									id_motorista: data
								});
								this.props.getLista();
								this.props.getListaSaldo();
							}} />
						
						<Select
							options={this.props.listaTomador}
							label='Tomador'
							cols='12 12 6 4'
							placeholder='Selecione o Tomador'
							value={this.props.filtro.id_tomador}
							onChange={data => {
								this.props.setFiltro({
									...this.props.filtro,
									id_tomador: data
								});
							}} />

						<LabelAndInputMask
							label='Data Incial' placeholder='Informe a data'
							cols='12 6 3 2'
							mask='99/99/9999'
							value={this.props.filtro.data_inicial}
							onChange={data => {
								this.props.setFiltro({ ...this.props.filtro, data_inicial: data.target.value });
								this.props.getLista();
								this.props.getListaSaldo();
							}} />

						<LabelAndInputMask
							label='Data Final' placeholder='Informe a data'
							cols='12 6 3 2'
							mask='99/99/9999'
							value={this.props.filtro.data_final}
							onChange={data => {
								this.props.setFiltro({ ...this.props.filtro, data_final: data.target.value });
								this.props.getLista();
								this.props.getListaSaldo();
							}} />
					</Row>
					<Row>
						{this.props.filtro.id_motorista ? (
							<Grid cols='6 6 4 2'>
								<Button
									text='Adicionar'
									type={'success'}
									icon={'fa fa-plus'}
									event={() => {
										this.props.setModoTela('cadastroEntrada');
										this.props.initFormEntrada({
											id_motorista: this.props.filtro.id_motorista,
											datahora_entrada: DateFormat.formatarDataHoraSqlParaTela(DateFormat.getDataHoraAtual())
										});
									}} />
							</Grid>
						) : null}
						{this.props.filtro.id_motorista ? (
							<Grid cols='6 6 4 2'>
								<Button
									text='Transferir'
									type={'warning'}
									icon={'fa fa-plus'}
									event={() => {
										this.props.setModoTela('cadastroTransferencia');
										this.props.initFormTransferencia({
											id_motorista_origem: this.props.filtro.id_motorista,
											datahora_transferencia: DateFormat.formatarDataHoraSqlParaTela(DateFormat.getDataHoraAtual())
										});
									}} />
							</Grid>
						) : null}
						{this.props.filtro.id_motorista ? (
							<Grid cols='6 6 4 2'>
								<Button
									text='Saída'
									type={'danger'}
									icon={'fa fa-plus'}
									event={() => {
										this.props.setModoTela('cadastroSaida');
										this.props.initFormSaida({
											id_motorista: this.props.filtro.id_motorista,
											datahora_saida: DateFormat.formatarDataHoraSqlParaTela(DateFormat.getDataHoraAtual())
										});
									}} />
							</Grid>
						) : null}
						{false && this.props.filtro.id_motorista ? (
							<Grid cols='6 6 4 2'>
								<Button
									text='Imprimir'
									type={'primary'}
									icon={'fa fa-plus'}
									event={() => {
										this.props.gerarPdf({
											nome_motorista: this.props.listaMotorista.filter(item => item.id == this.props.filtro.id_motorista)[0].valor,
											id_periodo: this.props.filtro.id_periodo,
											periodo: this.props.listaPeriodo.filter(item => item.id == this.props.filtro.id_periodo)[0].valor,
											itens: this.props.lista.map(item => ({
												datahora_movimento: DateFormat.formatarDataHoraSqlParaTela(item.datahora_movimento),
												nome_vale_pedagio: item.nome_vale_pedagio,
												nome_operacao: item.id_entrada ? 'Entrada' : (
													item.id_saida ? 'Saída' : (
														item.id_transferencia_entrada ? `Transf. recebida` : (
															`Transf. enviada`
														))
												),
												valor: `(${item.id_entrada || item.id_transferencia_entrada ? '+' : '-'}) ${FormatUtils.formatarValorTela(parseFloat(item.valor_entrada) || parseFloat(item.valor_saida), 2)}`,
												saldo: FormatUtils.formatarValorTela(item.valor_final, 2)
											})),
											saldos: this.props.listaSaldo.map(item => ({
												nome_vale_pedagio: item.nome_vale_pedagio,
												saldo: `R$ ${FormatUtils.formatarValorTela(item.saldo, 2)}`
											}))
										});
									}} />
							</Grid>
						) : null}
						<Grid cols='6 6 3 2'>
							<ExcelFile
								filename={'Vale Pedágio - Conta Corrente'}
								element={
									<Button
										text='Exportar'
										type={'primary'}
										icon={'fa fa-plus'} />
								}>
								<ExcelSheet data={lista.map(item => ({
									...item,
									datahora_movimento: DateFormat.formatarDataHoraSqlParaTela(item.datahora_movimento),
									operacao: item.id_entrada ? 'Entrada' : (
										item.id_saida ? 'Saída' : (
											item.id_transferencia_entrada ? `Transf. recebida de ${item.nome_motorista_origem}` : (
												`Transf. enviada para ${item.nome_motorista_destino}`
											)
										)
									),
									documento: (item.entrada || item.transferencia || item.saida || {}).documento || '',
									nome_tomador: item.nome_tomador || '',
									nome_motivo: item.nome_motivo || '',
									valor: `(${item.id_entrada || item.id_transferencia_entrada ? '+' : '-'}) ${FormatUtils.formatarValorTela(parseFloat(item.valor_entrada) || parseFloat(item.valor_saida), 2)}`,
									saldo: FormatUtils.formatarValorTela(item.valor_final, 2)
								}))} name='Vale Pedágio - Conta Corrente'>
									<ExcelColumn label='Data/Hora' value='datahora_movimento' />
									<ExcelColumn label='Motorista' value='nome_motorista' />
									<ExcelColumn label='Tipo' value='nome_vale_pedagio' />
									<ExcelColumn label='Operação' value='operacao' />
									<ExcelColumn label='Documento' value='documento' />
									<ExcelColumn label='Tomador' value='nome_tomador' />
									<ExcelColumn label='Motivo' value='nome_motivo' />
									<ExcelColumn label='Valor' value='valor' />
									<ExcelColumn label='Saldo' value='saldo' />
								</ExcelSheet>
							</ExcelFile>
						</Grid>
					</Row>
				</ContentCardHeader>
				<ContentCardBody>

					<div class='row' style={{ justifyContent:'center'}}>
						{this.props.listaSaldo.map(item => (
							<Grid cols='12 6 4 3'>
								<div class='info-box mb-3 bg-success'>
									<span class='info-box-icon'><i class='fas fa-dollar-sign'></i></span>
									<div class='info-box-content'>
										<span class='info-box-text'>{item.nome_vale_pedagio}</span>
										<span class='info-box-number'>R$ {FormatUtils.formatarValorTela(item.saldo, 2)}</span>
									</div>
								</div>
							</Grid>
						))}
					</div>
					<Table responsive>
						<THead>
							<Tr>
								<Th>Data/Hora</Th>
								<Th alignCenter>Motorista</Th>
								<Th alignCenter>Tipo</Th>
								<Th alignCenter>Operação</Th>
								<Th width={150} alignCenter>Documento</Th>
								<Th width={150} alignCenter>Tomador</Th>
								<Th width={100} alignCenter>Motivo</Th>
								<Th alignRight>Valor</Th>
								<Th alignRight>Saldo</Th>
								<Th width={140}></Th>
							</Tr>
						</THead>
						<TBody>
							{lista.map(item => (
								<Tr key={item.id} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
									onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
									onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
									<Td small>{DateFormat.formatarDataHoraSqlParaTela(item.datahora_movimento)}</Td>
									<Td alignCenter small>{item.nome_motorista}</Td>
									<Td alignCenter small>{item.nome_vale_pedagio}</Td>
									<Td alignCenter small>{
										item.id_entrada ? 'Entrada' : (
											item.id_saida ? 'Saída' : (
												item.id_transferencia_entrada ? `Transf. recebida de ${item.nome_motorista_origem}` : (
													`Transf. enviada para ${item.nome_motorista_destino}`
												))
											)
									}</Td>
									<Td alignCenter small noWrap>{(item.entrada || item.transferencia || item.saida || {}).documento || ''}</Td>
									<Td alignCenter small noWrap>{item.nome_tomador || ''}</Td>
									<Td alignCenter small noWrap>{item.nome_motivo || ''}</Td>
									<Td alignRight small>({item.id_entrada || item.id_transferencia_entrada ? '+' : '-'}) {FormatUtils.formatarValorTela(parseFloat(item.valor_entrada) || parseFloat(item.valor_saida), 2)}</Td>
									<Td alignRight small>{FormatUtils.formatarValorTela(item.valor_final, 2)}</Td>
									<Td alignRight minWidth={100}>

										{item.ocorrencia_aberta ? (
											<i class='fas fa-times-circle' style={{ color: '#ed0707', fontSize: 16, paddingTop: 4, paddingLeft: 4 }} 
												title={`Existe ocorrência aberta`}>
											</i>
										) : (item.ocorrencia_finalizada ? (
											<i class='fas fa-times-circle' style={{ color: '#a1a1a1', fontSize: 16, paddingTop: 4, paddingLeft: 4 }} 
												title={`Existe ocorrência finalizada`}>
											</i>
										) : null)}

										<ButtonTable
											type={'primary'}
											icon={'fas fa-eye'}
											visible={this.state.linhaSelecionada == item.id}
											small
											event={() => {
												
												let registro = null;
												if (item.entrada) {
													registro = {
														...item.entrada,
														datahora_entrada: DateFormat.formatarDataHoraSqlParaTela(item.entrada.datahora_entrada),
														valor: FormatUtils.formatarValorTela(item.entrada.valor, 2)
													};
													this.props.initFormEntrada(registro);
												} else if (item.saida) {
													registro = {
														...item.saida,
														datahora_saida: DateFormat.formatarDataHoraSqlParaTela(item.saida.datahora_saida),
														valor: FormatUtils.formatarValorTela(item.saida.valor, 2)
													};
													this.props.initFormSaida(registro);
												} else if (item.transferencia) {
													registro = {
														...item.transferencia,
														datahora_transferencia: DateFormat.formatarDataHoraSqlParaTela(item.transferencia.datahora_transferencia),
														valor: FormatUtils.formatarValorTela(item.transferencia.valor, 2)
													};
													this.props.initFormTransferencia(registro);
												}
												this.props.setModoTela(item.entrada ? 'visualizacaoEntrada' : (item.saida ? 'visualizacaoSaida' : 'visualizacaoTransferencia'), registro);
												
											}} />

										<ButtonTable
											type={'warning'}
											icon={'fas fa-pencil-alt'}
											visible={this.state.linhaSelecionada == item.id}
											disabled={item.id_transferencia_entrada || item.consolidado || (item.entrada && item.entrada.id_vale_kmm)}
											small
											event={() => {
												
												let registro = null;
												if (item.entrada) {
													registro = {
														...item.entrada,
														datahora_entrada: DateFormat.formatarDataHoraSqlParaTela(item.entrada.datahora_entrada),
														valor: FormatUtils.formatarValorTela(item.entrada.valor, 2)
													};
													this.props.initFormEntrada(registro);
												} else if (item.saida) {
													registro = {
														...item.saida,
														datahora_saida: DateFormat.formatarDataHoraSqlParaTela(item.saida.datahora_saida),
														valor: FormatUtils.formatarValorTela(item.saida.valor, 2)
													};
													this.props.initFormSaida(registro);
												} else if (item.transferencia) {
													registro = {
														...item.transferencia,
														datahora_transferencia: DateFormat.formatarDataHoraSqlParaTela(item.transferencia.datahora_transferencia),
														valor: FormatUtils.formatarValorTela(item.transferencia.valor, 2)
													};
													this.props.initFormTransferencia(registro);
												}
												this.props.setModoTela(item.entrada ? 'cadastroEntrada' : (item.saida ? 'cadastroSaida' : 'cadastroTransferencia'), registro);

											}} />

										<ButtonTable
											type={'danger'}
											icon={'fas fa-trash-alt'}
											visible={this.state.linhaSelecionada == item.id}
											disabled={item.id_transferencia_entrada || item.consolidado || (item.entrada && item.entrada.id_vale_kmm)}
											small
											event={() => {
												
												let registro = null;
												if (item.entrada) {
													registro = {
														...item.entrada,
														datahora_entrada: DateFormat.formatarDataHoraSqlParaTela(item.entrada.datahora_entrada),
														valor: FormatUtils.formatarValorTela(item.entrada.valor, 2)
													};
													this.props.initFormEntrada(registro);
												} else if (item.saida) {
													registro = {
														...item.saida,
														datahora_saida: DateFormat.formatarDataHoraSqlParaTela(item.saida.datahora_saida),
														valor: FormatUtils.formatarValorTela(item.saida.valor, 2)
													};
													this.props.initFormSaida(registro);
												} else if (item.transferencia) {
													registro = {
														...item.transferencia,
														datahora_transferencia: DateFormat.formatarDataHoraSqlParaTela(item.transferencia.datahora_transferencia),
														valor: FormatUtils.formatarValorTela(item.transferencia.valor, 2)
													};
													this.props.initFormTransferencia(registro);
												}
												this.props.setModoTela(item.entrada ? 'exclusaoEntrada' : (item.saida ? 'exclusaoSaida' : 'exclusaoTransferencia'), registro);

											}} />
									</Td>
								</Tr>
							))}
						</TBody>
					</Table>
				</ContentCardBody>
			</ContentCard>
		);
	}


}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	modoTela: state.valePedagioMotorista.modoTela,
	filtro: state.valePedagioMotorista.filtro,
	aguardando: state.valePedagioMotorista.aguardando,
	lista: state.valePedagioMotorista.lista,
	listaSaldo: state.valePedagioMotorista.listaSaldo,
	listaMotorista: state.valePedagioMotorista.listaMotorista,
	listaValePedagio: state.valePedagioMotorista.listaValePedagio,
	listaPeriodo: state.valePedagioMotorista.listaPeriodo,
	listaTomador: state.valePedagioMotorista.listaTomador
});
const mapDispatchToProps = dispatch => bindActionCreators({ 
	setModoTela, setFiltro, getLista, getListaSaldo,
	initFormEntrada, salvarEntrada, excluirEntrada, 
	initFormTransferencia, salvarTransferencia, excluirTransferencia,
	initFormSaida, salvarSaida, excluirSaida,
	getListaMotorista, getListaValePedagio, getListaMotivo, getListaPeriodo, getListaTomador, gerarPdf
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ValePedagioMotorista);
