import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import FormEntrada from './valePedagioEntradaForm';
import FormTransferencia from './valePedagioTransferenciaForm';
import FormSaida from './valePedagioSaidaForm';
import DateFormat from '../common/dateFormat/DateFormat';
import FormatUtils from '../common/formatUtils/FormatUtils';
import Aguardando from '../common/template/aguardando';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import Select from '../common/form/select';
import ReactExport from 'react-export-excel-fixed-xlsx';

import { 
	setModoTela, setFiltro, getLista, getListaSaldo,
	initFormEntrada, salvarEntrada, excluirEntrada,
	initFormTransferencia, salvarTransferencia, excluirTransferencia,
	initFormSaida, salvarSaida, excluirSaida,
	getListaMotorista, getListaValePedagio, getListaMotivo, getListaMotivoPeriodo
} from './valePedagioMotoristaActions';

class ValePedagioSaldo extends Component {

	state = {
		pesquisar: '',
		linhaSelecionada: null,
		modo: 'lista'
	}

    componentWillMount() {
		this.props.getListaMotivoPeriodo();
		this.props.getListaValePedagio();
		this.props.getListaMotorista();
		this.props.getListaMotivo();
    }

    render() {

		const ExcelFile = ReactExport.ExcelFile;
		const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
		const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

		let quantidadeTotal = 0;
		let entradaTotal = 0;
		let saidaTotal = 0;
		let transferenciaTotal = 0;

		let lista = [];
		let listaCompetencias = [];

		(this.props.listaMotivoPeriodo || []).map((item, i) => {

			lista.push(item);

			if (!listaCompetencias.filter(comp => comp.competencia == item.competencia).length) {
				listaCompetencias.push({
					competencia: item.competencia,
					lista: [{ ...item }]
				});
			} else {
				let listaAux = listaCompetencias.filter(comp => comp.competencia == item.competencia)[0].lista.map(itemAux => itemAux);
				listaAux.push(item);
				listaCompetencias = listaCompetencias.map(comp => ({
					...comp,
					lista: comp.competencia == item.competencia ? listaAux : comp.lista
				}));
			}
								
			quantidadeTotal += parseFloat(item.quantidade || 0);
			entradaTotal += parseFloat(item.entrada || 0);
			saidaTotal += parseFloat(item.saida || 0);
			transferenciaTotal += parseFloat(item.transferencia || 0);

			if (!(this.props.listaMotivoPeriodo || [])[i+1] || item.competencia != (this.props.listaMotivoPeriodo || [])[i+1].competencia) {
				lista.push({
					id: 'Total',
					competencia: item.competencia,
					nome: 'Total',
					quantidade: quantidadeTotal,
					entrada: entradaTotal,
					saida: saidaTotal,
					transferencia: transferenciaTotal
				});

				quantidadeTotal = 0;
				entradaTotal = 0;
				saidaTotal = 0;
				transferenciaTotal = 0;
			}
		});

		quantidadeTotal = 0;
		entradaTotal = 0;
		saidaTotal = 0;
		transferenciaTotal = 0;

		let competencia = null;

		return (
			<ContentCard>
				<ContentCardHeader>
					<Row>
						<Grid cols='6 6 3 2'>
							<ExcelFile
								filename={'Motivos por Período - Pedágio'}
								element={
									<Button
										text='Exportar'
										type={'primary'}
										icon={'fa fa-plus'} />
								}>
								<ExcelSheet data={lista.map(item => ({
									...item,
									competencia: item.competencia == '000000' ? 'Total Período' : `${item.competencia.split('/')[1]}/${item.competencia.split('/')[0]}`,
									saldo: FormatUtils.formatarValorTela(item.saldo, 0),
									entrada: FormatUtils.formatarValorTela(item.entrada, 2),
									saida: FormatUtils.formatarValorTela(item.saida, 2),
									transferencia: FormatUtils.formatarValorTela(item.transferencia, 2)
								}))} name='Motivos por Período - Pedágio'>
									<ExcelColumn label='Motivo' value='nome' />
									<ExcelColumn label='Competência' value='competencia' />
									<ExcelColumn label='Quantidade' value='quantidade' />
									<ExcelColumn label='Entrada' value='entrada' />
									<ExcelColumn label='Saída' value='saida' />
									<ExcelColumn label='Transferência' value='transferencia' />
								</ExcelSheet>
							</ExcelFile>
						</Grid>
					</Row>
					<br />
					<Row>
						<LabelAndInputMask
							label='Data Inicial' placeholder='Informe a data inicial'
							cols='12 6 3 2'
							mask='99/99/9999'
							value={this.props.filtro.motivo_periodo_data_inicial}
							onChange={data => {
							this.props.setFiltro({ 
								...this.props.filtro, 
								motivo_periodo_data_inicial: data.target.value 
							});
								this.props.getListaMotivoPeriodo();
							}} />
							
						<LabelAndInputMask
							label='Data Final' placeholder='Informe a data final'
							cols='12 6 3 2'
							mask='99/99/9999'
							value={this.props.filtro.motivo_periodo_data_final}
							onChange={data => {
							this.props.setFiltro({ 
								...this.props.filtro, 
								motivo_periodo_data_final: data.target.value 
							});
								this.props.getListaMotivoPeriodo();
							}} />
							
						<Select
							options={this.props.listaMotivo}
							label='Motivo'
							cols='12 6 3 4'
							placeholder='Selecione o motivo'
							value={this.props.filtro.motivo_periodo_id_motivo}
							onChange={data => {
								this.props.setFiltro({
									...this.props.filtro,
									motivo_periodo_id_motivo: data
								});
								this.props.getListaMotivoPeriodo();
							}} />

					</Row>
				</ContentCardHeader>
				<ContentCardBody>

					<div class="btn-group">
						<button 
							type='button'
							class={`btn btn-${this.state.modo == 'lista' ? 'info' : 'default'}`} style={{ width: 160 }}
							onClick={() => this.setState({ ...this.state, modo: 'lista' })}>
							Lista
						</button>
						<button
							type='button'
							class={`btn btn-${this.state.modo == 'graficoQuantidade' ? 'info' : 'default'}`}
							style={{ width: 160 }}
							onClick={() => {
								this.setState({ ...this.state, modo: 'graficoQuantidade' });
							}}>
							Gráfico - Quantidade
						</button>
						<button
							type='button'
							class={`btn btn-${this.state.modo == 'graficoEntrada' ? 'info' : 'default'}`}
							style={{ width: 160 }}
							onClick={() => {
								this.setState({ ...this.state, modo: 'graficoEntrada' });
							}}>
							Gráfico - Entrada
						</button>
						<button
							type='button'
							class={`btn btn-${this.state.modo == 'graficoSaida' ? 'info' : 'default'}`}
							style={{ width: 160 }}
							onClick={() => {
								this.setState({ ...this.state, modo: 'graficoSaida' });
							}}>
							Gráfico - Saída
						</button>
					</div>

					{this.state.modo == 'lista' ? (
						<Table responsive>
							<THead>
								<Tr>									
									<Th alignCenter>Competência</Th>
									<Th>Motivo</Th>								
									<Th alignRight>Quantidade</Th>
									<Th alignRight>Entrada</Th>
									<Th alignRight>Saída</Th>
									<Th alignRight>Transferência</Th>
									<Th></Th>
								</Tr>
							</THead>
							<TBody>
								{lista.map(item => {
									
									if (item.competencia != '000000' && item.nome != 'Total') {
										quantidadeTotal += parseFloat(item.quantidade || 0);
										entradaTotal += parseFloat(item.entrada || 0);
										saidaTotal += parseFloat(item.saida || 0);
										transferenciaTotal += parseFloat(item.transferencia || 0);
									}

									let rowspan = competencia == null || competencia != item.competencia ? lista.filter(itemAux => itemAux.competencia == item.competencia).length : 0;
									competencia = item.competencia;
									
									return (
										<Tr key={`${item.competencia}-${item.id}`} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
											onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
											onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
											{rowspan > 0 ?
											<Td rowspan={rowspan} alignCenter bold={item.competencia == '000000'}>{item.competencia == '000000' ? 'Total Período' : `${item.competencia.split('/')[1]}/${item.competencia.split('/')[0]}`}</Td>
											: null}
											<Td bold={item.competencia == '000000' || item.nome == 'Total'}>{item.nome}</Td>
											<Td alignRight bold={item.competencia == '000000' || item.nome == 'Total'}>{FormatUtils.formatarValorTela(item.quantidade, 0)}</Td>
											<Td alignRight bold={item.competencia == '000000' || item.nome == 'Total'}>{ FormatUtils.formatarValorTela(item.entrada, 2)}</Td>
											<Td alignRight bold={item.competencia == '000000' || item.nome == 'Total'}>{FormatUtils.formatarValorTela(item.saida, 2)}</Td>
											<Td alignRight bold={item.competencia == '000000' || item.nome == 'Total'}>{FormatUtils.formatarValorTela(item.transferencia, 2)}</Td>
										</Tr>
									);
								})}
								<Tr>
									<Th></Th>
									<Th>Total Geral</Th>
									<Th alignRight>{FormatUtils.formatarValorTela(quantidadeTotal, 0)}</Th>	
									<Th alignRight>{FormatUtils.formatarValorTela(entradaTotal, 2)}</Th>	
									<Th alignRight>{FormatUtils.formatarValorTela(saidaTotal, 2)}</Th>	
									<Th alignRight>{FormatUtils.formatarValorTela(transferenciaTotal, 2)}</Th>	
								</Tr>
							</TBody>
						</Table>
					) : (
						<>
							{listaCompetencias.map(comp => {
															

								let maxY = 100;
								if (this.state.modo == 'graficoQuantidade') {
									maxY = comp.lista[0] ? parseInt(parseInt(comp.lista[0].quantidade) * 1.4) : 100;
								} else if (this.state.modo == 'graficoEntrada') {
									let max = 0;
									for (let i = 0; i < comp.lista.length; i++) {
										max = parseInt(comp.lista[i].entrada) > max ? parseInt(comp.lista[i].entrada) : max;				
									}
									maxY = comp.lista[0] ? parseInt(max * 1.4) : 100;
								} else if (this.state.modo == 'graficoSaida') {
									let max = 0;
									for (let i = 0; i < comp.lista.length; i++) {
										max = parseInt(comp.lista[i].saida) > max ? parseInt(comp.lista[i].saida) : max;				
									}
									maxY = comp.lista[0] ? parseInt(max * 1.4) : 100;
								}

								return (

									<Row key={comp.competencia}>
										<Grid cols='12 12 12 12'>
											<img
												style={{ border: "1px solid #ccc" }}
												src={`https://quickchart.io/chart?width=${
													window.innerWidth * 0.42
												}&height=300&chart={type:'bar',
													data:{
														labels:${JSON.stringify(
															comp.lista.map(
															(item) =>
															//`${String(
																item.nome
															// )}`
														)
														)},
														datasets:[
														{
															data:
															${JSON.stringify(
																comp.lista.map(
																(item) => this.state.modo == 'graficoQuantidade' 
																	? `${parseInt(item.quantidade)}` 
																	: (this.state.modo == 'graficoEntrada' 
																		? `${parseFloat(item.entrada)}`
																		: `${parseFloat(item.saida)}`
																	)
																)
															)},
															backgroundColor: '%2320507e',
														}
														]
													},
													options: {
														title: {
														display: true,
														text: '${comp.competencia == '000000' ? 'Total Período' : `${comp.competencia.split('/')[1]}/${comp.competencia.split('/')[0]}`}',
														fontColor: '%23444',
														position: 'top',
														padding:20
														},
														plugins: {
														datalabels: {
															formatter: ${this.state.modo == 'graficoQuantidade'  ?
																(value) => {
																	return parseInt(value);
																}
																:
																(value) => {
																	return `R$ ${parseFloat(value).toFixed(2).replace('.', ',')}`;
																}
															},
															anchor: 'end',
															align: 'top',
															color: 'black',
															font: {
															size: 8,
															weight: 'bold',
															},
														}
														},
														legend: {
														position: 'bottom', 
														display: false,
														labels: {
															fontSize: 8,
															boxWidth: 10,
														}
														},
														scales: {
														yAxes: [
															{
															ticks: {
																padding: 10,
																min:0,
																max: ${maxY},
																fontSize: 7, 
																fontColor: '%23222',
																callback: ${this.state.modo == 'graficoQuantidade'  ?
																	(value) => {
																		return parseInt(value);
																	}
																	:
																	(value) => {
																		return `R$ ${parseFloat(value).toFixed(2).replace('.', ',')}`;
																	}
																},
															},
															gridLines:{
																drawTicks:false
															}
															},
														],
														xAxes: [
															{ 
															ticks: {
																fontSize: 7,
																fontColor: '%23222',
																weight: 'bold',
															},
															gridLines: {
																display: false,
															}
															},
														],
														},
													},
													}`}
												/>
										</Grid>
									</Row>
								);
							})}
						</>
					)}
				</ContentCardBody>
			</ContentCard>
		);
	}


}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	modoTela: state.valePedagioMotorista.modoTela,
	filtro: state.valePedagioMotorista.filtro,
	aguardando: state.valePedagioMotorista.aguardando,
	listaMotivo: state.valePedagioMotorista.listaMotivo,
	listaMotivoPeriodo: state.valePedagioMotorista.listaMotivoPeriodo,
	listaMotorista: state.valePedagioMotorista.listaMotorista,
	listaPeriodo: state.valePedagioMotorista.listaPeriodo,
	listaValePedagio: state.valePedagioMotorista.listaValePedagio
});
const mapDispatchToProps = dispatch => bindActionCreators({ 
	setModoTela, setFiltro, getLista, getListaSaldo,
	initFormEntrada, salvarEntrada, excluirEntrada, 
	initFormTransferencia, salvarTransferencia, excluirTransferencia,
	initFormSaida, salvarSaida, excluirSaida,
	getListaMotorista, getListaValePedagio, getListaMotivo, getListaMotivoPeriodo
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ValePedagioSaldo);
