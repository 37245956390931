import DateFormat from '../common/dateFormat/DateFormat';

const INITIAL_STATE = {
	aguardando: false,
	registro: {},
	modoTela: 'lista',
	filtroAberta: {
		data_inicial_emissao: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAnterior(10)),
		data_final_emissao: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAtual()),
		data_inicial_carga: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAnterior(10)),
		data_final_carga: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAtual()),
		id_operacao: null,
		id_status_comprovante: 1,
		anexos: 1,
		faturado: 1
	},
	filtroComprovacao: {
		data_inicial_entrega: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAnterior(10)),
		data_final_entrega: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAtual()),
		data_inicial_carga: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAnterior(10)),
		data_final_carga: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAtual()),
		id_operacao: null,
		id_status_comprovante: 1,
		anexos: 1,
		faturado: 1
	},
	filtroComprovada: {
		data_inicial_entrega: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAnterior(10)),
		data_final_entrega: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAtual()),
		data_inicial_carga: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAnterior(10)),
		data_final_carga: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAtual()),
		data_inicial_finalizado: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAnterior(10)),
		data_final_finalizado: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAtual()),
		id_operacao: null,
		id_status_comprovante: 1,
		anexos: 1,
		faturado: 1
	},
	filtroFinalizada: {
		data_inicial_entrega: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAnterior(10)),
		data_final_entrega: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAtual()),
		data_inicial_carga: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAnterior(10)),
		data_final_carga: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAtual()),
		id_operacao: null,
		id_status_comprovante: 1,
		anexos: 1,
		faturado: 1
	},
	listaCliente: [],
	listaAberta: [],
	listaComprovacao: [],
	listaComprovada: [],
	listaFinalizada: [],
	listaComprovante: [],
	listaOperacao: [],
	listaDocumento: [],
	listaStatus: [],
	modoTelaComprovante: 'lista',
	listaComprovante: [],
	modoTelaEvento: 'lista',
	modoTelaOcorrencia: 'lista',
	listaOcorrencia: [],
	modoTelaPalete: 'lista',
	listaPalete: [],
	listaStatusComprovante: [
		{ id: 1, valor: 'Todos' },
		{ id: 2, valor: 'Aguardando Original' },
		{ id: 3, valor: 'Aguardando Digital' },
		{ id: 4, valor: 'Aguardando Original ou Digital' },
		{ id: 5, valor: 'Aguardando Original e Digital' },
		{ id: 6, valor: 'Aguardando Financeiro' },
		{ id: 7, valor: 'Paletes em Aberto' },
		{ id: 8, valor: 'Paletes Finalizados' }
	],
	listaHistorico: [],
	listaPaleteStatus: []
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case 'DOCUMENTO_VIAGEM_MODO_TELA':
            return { ...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};

		case 'DOCUMENTO_VIAGEM_SET_AGUARDANDO':
            return { ...state,
				aguardando: action.payload
			};

		case 'DOCUMENTO_VIAGEM_ABERTA_FILTRO':
            return { ...state,
				filtroAberta: action.payload.filtro
			};

		case 'DOCUMENTO_VIAGEM_COMPROVACAO_FILTRO':
            return { ...state,
				filtroComprovacao: action.payload.filtro
			};

		case 'DOCUMENTO_VIAGEM_COMPROVADA_FILTRO':
            return { ...state,
				filtroComprovada: action.payload.filtro
			};

		case 'DOCUMENTO_VIAGEM_FINALIZADA_FILTRO':
            return { ...state,
				filtroFinalizada: action.payload.filtro
			};

        case 'DOCUMENTO_VIAGEM_LISTADO':
            return {
				...state,
				lista: action.payload.data
			};

		case 'DOCUMENTO_VIAGEM_HISTORICO_LISTADO':
            return {
				...state,
				listaHistorico: action.payload.data
			};

		case 'DOCUMENTO_VIAGEM_CLIENTE_SELECT_LISTADO':
            return {
				...state,
				listaCliente: action.payload.data
			};

        case 'DOCUMENTO_VIAGEM_ABERTA_LISTADO':
            return {
				...state,
				listaAberta: action.payload.data
			};

        case 'DOCUMENTO_VIAGEM_COMPROVACAO_LISTADO':
            return {
				...state,
				listaComprovacao: action.payload.data
			};

        case 'DOCUMENTO_VIAGEM_COMPROVADA_LISTADO':
            return {
				...state,
				listaComprovada: action.payload.data
			};
			  
		case 'DOCUMENTO_VIAGEM_FINALIZADA_LISTADO':
            return {
				...state,
				listaFinalizada: action.payload.data
			};

        case 'DOCUMENTO_VIAGEM_COMPROVANTE_LISTADO':
            return {
				...state,
				listaComprovante: action.payload.data
			};

		case 'DOCUMENTO_VIAGEM_OPERACAO_SELECT_LISTADO':
			return {
				...state,
				listaOperacao: action.payload.data
			};

        case 'DOCUMENTO_VIAGEM_DOCUMENTO_SELECT_LISTADO':
			return {
				...state,
				listaDocumento: action.payload.data
			};

        case 'DOCUMENTO_VIAGEM_STATUS_SELECT_LISTADO':
			return {
				...state,
				listaStatus: action.payload.data
			};

        case 'DOCUMENTO_VIAGEM_STATUS_SELECT_LISTADO':
			return {
				...state,
				listaStatus: action.payload.data
			};

        case 'DOCUMENTO_VIAGEM_COMPROVANTE_MODO_TELA':
			return { ...state,
				modoTelaComprovante: action.payload.modo
			};

        case 'DOCUMENTO_VIAGEM_COMPROVANTE_LISTADO':
			return {
				...state,
				listaComprovante: action.payload.data
			};
		
		case 'DOCUMENTO_VIAGEM_OCORRENCIA_MODO_TELA':
			return { ...state,
				modoTelaOcorrencia: action.payload.modo
			};

        case 'DOCUMENTO_VIAGEM_OCORRENCIA_LISTADO':
			return {
				...state,
				listaOcorrencia: action.payload.data
			};

        case 'DOCUMENTO_VIAGEM_COMPROVANTE_MODO_TELA':
			return { ...state,
				modoTelaComprovante: action.payload.modo
			};

        case 'DOCUMENTO_VIAGEM_COMPROVANTE_LISTADO':
			return {
				...state,
				listaComprovante: action.payload.data
			};

        case 'DOCUMENTO_VIAGEM_COMPROVANTE_MODO_TELA':
			return { ...state,
				modoTelaComprovante: action.payload.modo
			};

        case 'DOCUMENTO_VIAGEM_COMPROVANTE_LISTADO':
			return {
				...state,
				listaComprovante: action.payload.data
			};

        case 'DOCUMENTO_VIAGEM_PALETE_MODO_TELA':
			return { ...state,
				modoTelaPalete: action.payload.modo
			};

        case 'DOCUMENTO_VIAGEM_PALETE_LISTADO':
			return {
				...state,
				listaPalete: action.payload.data
			};

        case 'DOCUMENTO_VIAGEM_PALETE_STATUS_LISTADO':
			return {
				...state,
				listaPaleteStatus: action.payload.data
			};

        default:
            return state;
    }
}
