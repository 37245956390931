import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ContentCardFooter from '../common/template/contentCardFooter';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import LabelAndInputDateTime from '../common/form/labelAndInputDateTime';
import Select from '../common/form/select';
import SelectDefault from '../common/form/selectDefault';
import SelectAlteracao from '../common/form/selectAlteracao';

import {
	setModoTela, initForm
} from './despesaTipoActions';

class DespesaTipoForm extends Component {

	state = {

	}

    componentWillMount() {

    }

    render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';

        return (
			<ContentCard>
				<Form event={this.props.handleSubmit}>
					<ContentCardBody>
						<Row>
							<Field
								name='nome'
								component={LabelAndInput}
								label='Nome' placeholder='Informe o nome'
								cols='12 12 12 12'
								readOnly={readOnly} />

							<Field
								name='limite'
								component={LabelAndInputNumber}
								label='Limite (R$)' placeholder='Informe o limite'
								cols='12 6 4 3'
								casas={2}
								readOnly={readOnly} />

							<SelectDefault 
								options={this.props.listaUsuario}
								label='Usuários Aprovadores'
								cols='12 12 12 12'
								placeholder='Selecione o usuário'
								readOnly={readOnly}
								value={this.state.id_usuario}
								onChange={data => {
									if (data.target.value) {
										let lista = (this.props.formularioValues.aprovadores || []).filter(item => item != data.target.value).map(usuario => usuario);
										lista.push(parseInt(data.target.value));

										this.props.initForm({
											...this.props.formularioValues,
											aprovadores: lista
										});
									}
								}} />
						</Row>
						<Row>
							{(this.props.formularioValues.aprovadores || []).map((usuario, i) => (
								<div key={usuario} class="col-md-3" style={{ marginBottom: 10 }}>
									<button type="button" 
										class="btn btn-outline-primary btn-block"
										onClick={() => {
											this.props.initForm({
												...this.props.formularioValues,
												aprovadores: this.props.formularioValues.aprovadores.filter(item => item != usuario)
											});
											
										}}>
										{this.props.listaUsuario.filter(item => item.id == usuario).map(item => item.valor).join('')} <i class="fa fa-trash"></i>
									</button>
								</div>
							))}
						</Row> 

						<Row> 						
								
							<Field
								name='data_inicio'
								component={LabelAndInputMask}
								label='Início Vigência' placeholder='Informe a data'
								cols='12 6 4 3'
								mask='99/99/9999'
								readOnly={readOnly} />

							<Field
								name='data_fim'
								component={LabelAndInputMask}
								label='Fim Vigência' placeholder='Informe a data'
								cols='12 6 4 3'
								mask='99/99/9999'
								readOnly={readOnly} />

						</Row>
						
					</ContentCardBody>
					<ContentCardFooter>
						<Row alignCenter>
							<Grid cols='6 6 4 3'>
								{this.props.excluir ? (
									<Button
										text='Excluir'
										submit type={'danger'}
										icon={'fa fa-trash'} />
								) : (
								<Button
									text='Salvar'
									submit
									type={'success'}
									icon={'fa fa-check'} />
								)}
							</Grid>
							<Grid cols='6 6 4 3'>
								<Button
									text='Voltar'
									type={'warning'}
									icon={'fa fa-chevron-left'}
									event={() => this.props.setModoTela('lista')} />
							</Grid>
						</Row>
					</ContentCardFooter>
				</Form>
			</ContentCard>
        )
    }


}

DespesaTipoForm = reduxForm({form: 'despesaTipoForm', destroyOnUnmount: false})(DespesaTipoForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	formularioValues: getFormValues('despesaTipoForm')(state),
	registro: state.despesaTipo.registro,
	listaUsuario: state.despesaTipo.listaUsuario
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, initForm
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(DespesaTipoForm);
