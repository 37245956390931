const INITIAL_STATE = {
	aguardando: false,
	registro: {},
	modoTela: 'lista',
	lista: []
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case 'COMISSAO_TIPO_MODO_TELA':
            return { ...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};

		case 'COMISSAO_TIPO_SET_AGUARDANDO':
            return { ...state,
				aguardando: action.payload
			};

        case 'COMISSAO_TIPO_LISTADO':
            return {
				...state,
				lista: action.payload.data
			};


        default:
            return state;
    }
}
