import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import DateFormat from '../common/dateFormat/DateFormat';
import FormatUtils from '../common/formatUtils/FormatUtils';
import ReactExport from 'react-export-excel-fixed-xlsx';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import Aguardando from '../common/template/aguardando';
import { MapLeaflet } from "../common/mapLeaflet/MapLeaflet";
import Cookies from 'js-cookie';

import { 
	setModoTela, setFiltro, getListaFronteiras, getListaNegociadores, getListaOperacoes, getLista
} from './reportsConformidadeActions';
import Select from '../common/form/select';
import SelectNovo from '../common/form/selectNovo';
import SwitchVertical from '../common/form/switchVertical';

class ReportsConformidade extends Component {

	state = {
		linhaSelecionada: null,
		pesquisar: '',
		pesquisarAux: '',
		idShippingExpandido: null,
		resumido: true,
		registroModal: null,
		listaFavoritos: JSON.parse(Cookies.get('shippingsFavoritos') || '[]'),
		listaPeriodo: [
			{ id: 'DATA_PREVISAO_CARGA', valor: 'Previsão Carga' },
			{ id: 'DATA_INICIO_CARGA', valor: 'Chegada Carga' },
			{ id: 'DATA_TERMINO_CARGA', valor: 'Saída Carga' },
			{ id: 'DATA_ENTRADA_ADUANA_ORIGEM', valor: 'Chegada Alfândega Origem' },
			{ id: 'DATA_LIBERACAO_ADUANA_ORIGEM', valor: 'Liberação Alfândega Origem' },
			{ id: 'DATA_PREV_FRONTEIRA', valor: 'Previsão Fronteira' },
			{ id: 'DATA_ENTRADA_FRONTEIRA', valor: 'Chegada Fronteira' },
			{ id: 'DATA_AUTORIZACAO_INGRESSO_FRONTEIRA', valor: 'Autorização Ingresso fronteira' },
			{ id: 'DT_ENTRADA_ADUANA_FRONT_ORG', valor: 'Chegada Alfândega Fronteira Origem' },
			{ id: 'DT_LIBERACAO_ADUANA_FRONT_ORG', valor: 'Liberação Alfândega Fronteira Origem' },
			{ id: 'DT_ENTRADA_ADUANA_FRONT_DEST', valor: 'Chegada Alfândega Fronteira Destino' },
			{ id: 'DT_LIBERACAO_ADUANA_FRONT_DEST', valor: 'Liberação Alfândega Fronteira Destino' },
			{ id: 'DATA_LIBERACAO_FRONTEIRA', valor: 'Liberação Fronteira	' },
			{ id: 'DATA_ENTRADA_ADUANA_DESTINO', valor: 'Chegada Alfândega Destino' },
			{ id: 'DATA_LIBERACAO_ADUANA_DESTINO', valor: 'Liberação Alfândega Destino' },
			{ id: 'DATA_PREVISAO_DESCARGA', valor: 'Previsão Descarga' },
			{ id: 'DATA_INICIO_DESCARGA', valor: 'Chegada Descarga' },
			{ id: 'DATA_TERMINO_DESCARGA', valor: 'Saída Descarga' }
		],
		filtro: {
			tipo_periodo: 'DATA_TERMINO_DESCARGA',
			data_inicial: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAnterior(31)),
			data_final: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAtual()),
			aberto: true,
			finalizado: false,
			semNaoConformidade: false,
			comNaoConformidade: true,
			/*rumos: [],
			farolAtrasos: [],
			fronteiras: [],
			operacoes: [],
			negociadores: [],
			lote: false,
			classificada: false,
			expressa: false,			
			ocorrenciaAberta: false,
			cavaloEngatado: false,
			somenteCarreta: false,
			semConjunto: false,
			emCD: false,
			semDocumento: false,*/
			nacional: this.props.usuarioCarregado ? this.props.usuarioCarregado.nacional : false,
			internacional: this.props.usuarioCarregado ? this.props.usuarioCarregado.internacional : false,
		},
		ordenacao: {
			shipping: 1,
			solicitacao: 0,
			previsao_carga: 0,
			inicio_carga: 0,
			termino_carga: 0,
			entrada_aduana_origem: 0,
			liberacao_aduana_origem: 0,
			previsao_fronteira: 0,
			entrada_fronteira: 0,
			autorizacao_ingresso_fronteira: 0,
			entrada_aduana_front_org: 0,
			liberacao_aduana_front_org: 0,
			entrada_aduana_front_dest: 0,
			liberacao_aduana_front_dest: 0,
			liberacao_fronteira: 0,
			entrada_aduana_destino: 0,
			liberacao_aduana_destino: 0,
			previsao_descarga: 0,
			inicio_descarga: 0,
			termino_descarga: 0,
			documento: 0,
			cavalo: 0,
			carreta: 0,
			cliente: 0,
			rota: 0,
			fronteira: 0,
			indicador_carga: 0,
			indicador_fronteira: 0,
			indicador_descarga: 0,

			/*documentos: 0,
			cavalo: 0,
			carreta: 0,
			lote: 0,
			classificada: 0,
			expressa: 0,
			ocorrencia: 0,
			cliente: 0,
			rota: 0,
			fronteira: 0,
			previsao: 0,
			statusPrevisao: 0,
			posicao: 0,
			statusPosicao: 0,
			destinado: 0*/
		}
	}

    componentWillMount() {
		this.props.getListaFronteiras();
		this.props.getListaNegociadores();
		this.props.getListaOperacoes();
        this.props.getLista(this.state.filtro);

		setInterval(() => {
			this.props.getListaFronteiras();
			this.props.getListaNegociadores();
			this.props.getListaOperacoes();
			this.props.getLista(this.state.filtro);
		}, 120000)
    }

    render() {
        return (
            <div>
                <Content>

					<Aguardando aguardando={this.props.aguardando} />

					{this.lista()}
                </Content>
            </div>
        )
    }

	filtrar() {

		let lista = this.props.lista

			.filter(item => {
				if (this.props.usuarioCarregado && (this.props.usuarioCarregado.placas_cco || []).length > 0) {
					return (this.props.usuarioCarregado.placas_cco || []).filter(placa => placa == item.cavalo).length > 0;
				} else {
					return this.props.usuarioCarregado ? true : false;
				}
			})

			.filter(item => {
				if (this.props.usuarioCarregado && this.props.usuarioCarregado.acesso_cliente) {
					return (this.props.usuarioCarregado.id_negociadores || []).filter(negociador => negociador == item.id_negociador).length > 0;
				} else {
					return true;
				}
			})

			//FILTROS SELECT
			.filter(item => {

				/*if ((this.state.filtro.rumos || []).length && !this.state.filtro.rumos.filter(itemFiltro => itemFiltro == item.rumo).length) {
					return false;
				}
				if ((this.state.filtro.farolAtrasos || []).length 
					&& !this.state.filtro.farolAtrasos.filter(itemFiltro => 
						itemFiltro == 'ADIANTADO' && item.status == 1
						|| itemFiltro == 'ATRASADO' && (item.status == 2 || item.status == 3)
						|| itemFiltro == 'NENHUM' && !item.status
					).length) {
					return false;
				}
				if ((this.state.filtro.fronteiras || []).length && !this.state.filtro.fronteiras.filter(itemFiltro => itemFiltro == item.id_fronteira_ref).length) {
					return false;
				}
				if ((this.state.filtro.operacoes || []).length 
					&& !this.state.filtro.operacoes.filter(itemFiltro => 
						(this.props.listaOperacoes.filter(operacao => operacao.id == itemFiltro)[0] ? this.props.listaOperacoes.filter(operacao => operacao.id == itemFiltro)[0].valor : null)
						 == item.descricao_operacao).length) {
					return false;
				}
				if ((this.state.filtro.negociadores || []).length && !this.state.filtro.negociadores.filter(itemFiltro => itemFiltro == item.id_negociador).length) {
					return false;
				}*/

				return true;
			})

			//FILTROS SWITCH
			.filter(item => {

				if (!this.state.filtro.aberto || !this.state.filtro.finalizado) {
					if (!this.state.filtro.aberto && !this.state.filtro.finalizado) {
						return false;
					} else if (this.state.filtro.aberto && item.finalizado) {
						return false;
					} else if (this.state.filtro.finalizado && !item.finalizado) {
						return false;
					}					
				}

				let possuiErro = 
					item.erro_documento || item.erro_rota
					|| item.erro_previsao_carga || item.erro_chegada_carga || item.erro_saida_carga || item.erro_entrada_aduana_origem || item.erro_saida_aduana_origem 
					|| item.erro_previsao_fronteira || item.erro_chegada_fronteira || item.erro_autorizacao_fronteira || item.erro_entrada_aduana_fronteira_origem 
					|| item.erro_saida_aduana_fronteira_origem || item.erro_entrada_aduana_fronteira_destino || item.erro_saida_aduana_fronteira_destino
					|| item.erro_saida_fronteira || item.erro_entrada_aduana_destino || item.erro_saida_aduana_destino || item.erro_chegada_descarga 
					|| item.erro_previsao_descarga || item.erro_saida_descarga;
					
				if (!this.state.filtro.semNaoConformidade || !this.state.filtro.comNaoConformidade) {
					if (!this.state.filtro.semNaoConformidade && !this.state.filtro.comNaoConformidade) {
						return false;
					} else if (this.state.filtro.semNaoConformidade && possuiErro) {
						return false;
					} else if (this.state.filtro.comNaoConformidade && !possuiErro) {
						return false;
					}
				}
				
				/*
				if (this.state.filtro.lote && !item.lote) {
					return false;
				}
				if (this.state.filtro.classificada && !item.classificada) {
					return false;
				}
				if (this.state.filtro.expressa && !item.expressa) {
					return false;
				}
				if (this.state.filtro.ocorrenciaAberta && !item.ocorrencias_abertas) {
					return false;
				}
				if (this.state.filtro.cavaloEngatado && !item.cavalo) {
					return false;
				}
				if (this.state.filtro.somenteCarreta && !item.carreta) {
					return false;
				}
				if (this.state.filtro.semConjunto && (item.cavalo || item.carreta)) {
					return false;
				}
				if (this.state.filtro.semDocumento && item.qtd_documentos > 0) {
					return false;
				}*/
				if (!this.state.filtro.nacional || !this.state.filtro.internacional) {
					//Se não tem rumo significa que ainda não foram registrados eventos no shipping
					if (!this.state.filtro.nacional && !this.state.filtro.internacional) {
						return item.rumo == 'INDEFINIDO';
					}
					if (this.state.filtro.nacional && item.rumo != 'NAC') {
						return false;
					}
					if (this.state.filtro.internacional && item.rumo == 'NAC') {
						return false;
					}
				}
				
				return true;

			})
			//FILTRO BUSCA TEXTO
			.filter(item => {

				return !(this.state.pesquisar || '').trim()
					|| (this.state.pesquisar.toUpperCase() || '').split('+').filter(pesquisar => (pesquisar || '').trim()).filter(pesquisar => {
					if (pesquisar
						&& !(`
							${item.shipping_code_id || ''}
							${item.solicitacao_carga_id || ''}
							${item.documento || ''}
							${item.cavalo || ''}
							${item.carreta|| ''}
							${item.negociador || ''}
							${item.rota || ''}
							${item.rumo || ''}
							${item.nome_fronteira || ''}
						`).toUpperCase().includes(pesquisar)) {
						return false;
					}

					return true;

				}).length > 0;

			});

			if (this.state.ordenacao.shipping) {
				lista.sort((a, b) => this.state.ordenacao.shipping == 1 
					? (parseInt(a.shipping_code_id) > parseInt(b.shipping_code_id) ? 1 : -1) 
					: (parseInt(a.shipping_code_id) > parseInt(b.shipping_code_id) ? -1 : 1));
			} else if (this.state.ordenacao.solicitacao) {
				lista.sort((a, b) => this.state.ordenacao.solicitacao == 1 
					? (parseInt(a.solicitacao_carga_id) > parseInt(b.solicitacao_carga_id) ? 1 : -1) 
					: (parseInt(a.solicitacao_carga_id) > parseInt(b.solicitacao_carga_id) ? -1 : 1));
			} else if (this.state.ordenacao.previsao_carga) {
				lista.sort((a, b) => this.state.ordenacao.previsao_carga == 1 
					? (new Date(a.data_previsao_carga || null) > new Date(b.data_previsao_carga || null) ? 1 : -1)
					: (new Date(a.data_previsao_carga || null) > new Date(b.data_previsao_carga || null) ? -1 : 1));
			} else if (this.state.ordenacao.inicio_carga) {
				lista.sort((a, b) => this.state.ordenacao.inicio_carga == 1 
					? (new Date(a.data_inicio_carga || null) > new Date(b.data_inicio_carga || null) ? 1 : -1)
					: (new Date(a.data_inicio_carga || null) > new Date(b.data_inicio_carga || null) ? -1 : 1));
			} else if (this.state.ordenacao.termino_carga) {
				lista.sort((a, b) => this.state.ordenacao.termino_carga == 1 
					? (new Date(a.data_termino_carga || null) > new Date(b.data_termino_carga || null) ? 1 : -1)
					: (new Date(a.data_termino_carga || null) > new Date(b.data_termino_carga || null) ? -1 : 1));
			} else if (this.state.ordenacao.entrada_aduana_origem) {
				lista.sort((a, b) => this.state.ordenacao.entrada_aduana_origem == 1 
					? (new Date(a.data_entrada_aduana_origem || null) > new Date(b.data_entrada_aduana_origem || null) ? 1 : -1)
					: (new Date(a.data_entrada_aduana_origem || null) > new Date(b.data_entrada_aduana_origem || null) ? -1 : 1));
			} else if (this.state.ordenacao.liberacao_aduana_origem) {
				lista.sort((a, b) => this.state.ordenacao.liberacao_aduana_origem == 1 
					? (new Date(a.data_liberacao_aduana_origem || null) > new Date(b.data_liberacao_aduana_origem || null) ? 1 : -1)
					: (new Date(a.data_liberacao_aduana_origem || null) > new Date(b.data_liberacao_aduana_origem || null) ? -1 : 1));
			} else if (this.state.ordenacao.previsao_fronteira) {
				lista.sort((a, b) => this.state.ordenacao.previsao_fronteira == 1 
					? (new Date(a.data_prev_fronteira || null) > new Date(b.data_prev_fronteira || null) ? 1 : -1)
					: (new Date(a.data_prev_fronteira || null) > new Date(b.data_prev_fronteira || null) ? -1 : 1));
			} else if (this.state.ordenacao.entrada_fronteira) {
				lista.sort((a, b) => this.state.ordenacao.entrada_fronteira == 1 
					? (new Date(a.data_entrada_fronteira || null) > new Date(b.data_entrada_fronteira || null) ? 1 : -1)
					: (new Date(a.data_entrada_fronteira || null) > new Date(b.data_entrada_fronteira || null) ? -1 : 1));
			} else if (this.state.ordenacao.autorizacao_ingresso_fronteira) {
				lista.sort((a, b) => this.state.ordenacao.autorizacao_ingresso_fronteira == 1 
					? (new Date(a.data_autorizacao_ingresso_fronteira || null) > new Date(b.data_autorizacao_ingresso_fronteira || null) ? 1 : -1)
					: (new Date(a.data_autorizacao_ingresso_fronteira || null) > new Date(b.data_autorizacao_ingresso_fronteira || null) ? -1 : 1));
			} else if (this.state.ordenacao.entrada_aduana_front_org) {
				lista.sort((a, b) => this.state.ordenacao.entrada_aduana_front_org == 1 
					? (new Date(a.dt_entrada_aduana_front_org || null) > new Date(b.dt_entrada_aduana_front_org || null) ? 1 : -1)
					: (new Date(a.dt_entrada_aduana_front_org || null) > new Date(b.dt_entrada_aduana_front_org || null) ? -1 : 1));
			} else if (this.state.ordenacao.liberacao_aduana_front_org) {
				lista.sort((a, b) => this.state.ordenacao.liberacao_aduana_front_org == 1 
					? (new Date(a.dt_liberacao_aduana_front_org || null) > new Date(b.dt_liberacao_aduana_front_org || null) ? 1 : -1)
					: (new Date(a.dt_liberacao_aduana_front_org || null) > new Date(b.dt_liberacao_aduana_front_org || null) ? -1 : 1));
			} else if (this.state.ordenacao.entrada_aduana_front_dest) {
				lista.sort((a, b) => this.state.ordenacao.entrada_aduana_front_dest == 1 
					? (new Date(a.dt_entrada_aduana_front_dest || null) > new Date(b.dt_entrada_aduana_front_dest || null) ? 1 : -1)
					: (new Date(a.dt_entrada_aduana_front_dest || null) > new Date(b.dt_entrada_aduana_front_dest || null) ? -1 : 1));
			} else if (this.state.ordenacao.liberacao_aduana_front_dest) {
				lista.sort((a, b) => this.state.ordenacao.liberacao_aduana_front_dest == 1 
					? (new Date(a.dt_liberacao_aduana_front_dest || null) > new Date(b.dt_liberacao_aduana_front_dest || null) ? 1 : -1)
					: (new Date(a.dt_liberacao_aduana_front_dest || null) > new Date(b.dt_liberacao_aduana_front_dest || null) ? -1 : 1));
			} else if (this.state.ordenacao.liberacao_fronteira) {
				lista.sort((a, b) => this.state.ordenacao.liberacao_fronteira == 1 
					? (new Date(a.data_liberacao_fronteira || null) > new Date(b.data_liberacao_fronteira || null) ? 1 : -1)
					: (new Date(a.data_liberacao_fronteira || null) > new Date(b.data_liberacao_fronteira || null) ? -1 : 1));
			} else if (this.state.ordenacao.entrada_aduana_destino) {
				lista.sort((a, b) => this.state.ordenacao.entrada_aduana_destino == 1 
					? (new Date(a.data_entrada_aduana_destino || null) > new Date(b.data_entrada_aduana_destino || null) ? 1 : -1)
					: (new Date(a.data_entrada_aduana_destino || null) > new Date(b.data_entrada_aduana_destino || null) ? -1 : 1));
			} else if (this.state.ordenacao.liberacao_aduana_destino) {
				lista.sort((a, b) => this.state.ordenacao.liberacao_aduana_destino == 1 
					? (new Date(a.data_liberacao_aduana_destino || null) > new Date(b.data_liberacao_aduana_destino || null) ? 1 : -1)
					: (new Date(a.data_liberacao_aduana_destino || null) > new Date(b.data_liberacao_aduana_destino || null) ? -1 : 1));
			} else if (this.state.ordenacao.previsao_descarga) {
				lista.sort((a, b) => this.state.ordenacao.previsao_descarga == 1 
					? (new Date(a.data_previsao_descarga || null) > new Date(b.data_previsao_descarga || null) ? 1 : -1)
					: (new Date(a.data_previsao_descarga || null) > new Date(b.data_previsao_descarga || null) ? -1 : 1));
			} else if (this.state.ordenacao.inicio_descarga) {
				lista.sort((a, b) => this.state.ordenacao.inicio_descarga == 1 
					? (new Date(a.data_inicio_descarga || null) > new Date(b.data_inicio_descarga || null) ? 1 : -1)
					: (new Date(a.data_inicio_descarga || null) > new Date(b.data_inicio_descarga || null) ? -1 : 1));
			} else if (this.state.ordenacao.termino_descarga) {
				lista.sort((a, b) => this.state.ordenacao.termino_descarga == 1 
					? (new Date(a.data_termino_descarga || null) > new Date(b.data_termino_descarga || null) ? 1 : -1)
					: (new Date(a.data_termino_descarga || null) > new Date(b.data_termino_descarga || null) ? -1 : 1));
			} else if (this.state.ordenacao.cliente) {
				lista.sort((a, b) => this.state.ordenacao.cliente == 1 
					? (a.negociador > b.negociador ? 1 : -1) 
					: (a.negociador > b.negociador ? -1 : 1));
			} else if (this.state.ordenacao.rota) {
				lista.sort((a, b) => this.state.ordenacao.rota == 1 
					? (a.rota > b.rota ? 1 : -1) 
					: (a.rota > b.rota ? -1 : 1));
			} else if (this.state.ordenacao.documento) {
				lista.sort((a, b) => this.state.ordenacao.documento == 1 
					? ((a.documento || '0') > (b.documento || '0') ? 1 : -1) 
					: ((a.documento || '0') > (b.documento || '0') ? -1 : 1));
			} else if (this.state.ordenacao.cavalo) {
				lista.sort((a, b) => this.state.ordenacao.cavalo == 1 
					? ((a.cavalo || '0') > (b.cavalo || '0') ? 1 : -1) 
					: ((a.cavalo || '0') > (b.cavalo || '0') ? -1 : 1));
			} else if (this.state.ordenacao.carreta) {
				lista.sort((a, b) => this.state.ordenacao.carreta == 1 
					? ((a.carreta || '0') > (b.carreta || '0') ? 1 : -1) 
					: ((a.carreta || '0') > (b.carreta || '0') ? -1 : 1));
			} else if (this.state.ordenacao.rumo) {
				lista.sort((a, b) => this.state.ordenacao.rumo == 1 
					? (a.rumo > b.rumo ? 1 : -1) 
					: (a.rumo > b.rumo ? -1 : 1));
			} else if (this.state.ordenacao.fronteira) {
				lista.sort((a, b) => this.state.ordenacao.fronteira == 1 
					? ((a.nome_fronteira, 'a') > (b.nome_fronteira, 'a') ? 1 : -1) 
					: ((a.nome_fronteira, 'a') > (b.nome_fronteira, 'a') ? -1 : 1));
			} else if (this.state.ordenacao.indicador_carga) {
				lista.sort((a, b) => {

					const minutosA = !a.data_previsao_carga ? 0.0001 : (parseInt(a.chegada_carga_dias || 0) * 24 * 60) + (parseInt(a.chegada_carga_horas || 0) * 60) + parseInt(a.chegada_carga_minutos || 0);
					const minutosB = !b.data_previsao_carga ? 0.0001 : (parseInt(b.chegada_carga_dias || 0) * 24 * 60) + (parseInt(b.chegada_carga_horas || 0) * 60) + parseInt(b.chegada_carga_minutos || 0);

					return this.state.ordenacao.indicador_carga == 1 
						? minutosA - minutosB 
						: minutosB - minutosA;
				});
			} else if (this.state.ordenacao.indicador_fronteira) {
				lista.sort((a, b) => {

					const minutosA = !a.data_prev_fronteira ? 0.0001 : (parseInt(a.chegada_fronteira_dias || 0) * 24 * 60) + (parseInt(a.chegada_fronteira_horas || 0) * 60) + parseInt(a.chegada_fronteira_minutos || 0);
					const minutosB = !b.data_prev_fronteira ? 0.0001 : (parseInt(b.chegada_fronteira_dias || 0) * 24 * 60) + (parseInt(b.chegada_fronteira_horas || 0) * 60) + parseInt(b.chegada_fronteira_minutos || 0);

					return this.state.ordenacao.indicador_fronteira == 1 
						? minutosA - minutosB 
						: minutosB - minutosA;
				});
			} else if (this.state.ordenacao.indicador_descarga) {
				lista.sort((a, b) => {

					const minutosA = !a.data_previsao_descarga ? 0.0001 : (parseInt(a.chegada_descarga_dias || 0) * 24 * 60) + (parseInt(a.chegada_descarga_horas || 0) * 60) + parseInt(a.chegada_descarga_minutos || 0);
					const minutosB = !b.data_previsao_descarga ? 0.0001 : (parseInt(b.chegada_descarga_dias || 0) * 24 * 60) + (parseInt(b.chegada_descarga_horas || 0) * 60) + parseInt(b.chegada_descarga_minutos || 0);

					return this.state.ordenacao.indicador_descarga == 1 
						? minutosA - minutosB 
						: minutosB - minutosA;
				});
			}
	
			/*else if (this.state.ordenacao.documentos) {
				lista.sort((a, b) => this.state.ordenacao.documentos == 1 
					? ((a.documentos || []).filter(documento => documento).join(', ') > (b.documentos || []).filter(documento => documento).join(', ') ? 1 : -1) 
					: ((a.documentos || []).filter(documento => documento).join(', ') > (b.documentos || []).filter(documento => documento).join(', ') ? -1 : 1));
			} else if (this.state.ordenacao.cavalo) {
				lista.sort((a, b) => this.state.ordenacao.cavalo == 1 
					? (a.cavalo > b.cavalo ? 1 : -1) 
					: (a.cavalo > b.cavalo ? -1 : 1));
			} else if (this.state.ordenacao.carreta) {
				lista.sort((a, b) => this.state.ordenacao.carreta == 1 
					? (a.carreta > b.carreta ? 1 : -1) 
					: (a.carreta > b.carreta ? -1 : 1));
			} else if (this.state.ordenacao.lote) {
				lista.sort((a, b) => this.state.ordenacao.lote == 1 
					? (a.lotes > b.lotes ? 1 : -1) 
					: (a.lotes > b.lotes ? -1 : 1));
			} else if (this.state.ordenacao.classificada) {
				lista.sort((a, b) => this.state.ordenacao.classificada == 1 
					? (a.classificada > b.classificada ? 1 : -1) 
					: (a.classificada > b.classificada ? -1 : 1));
			} else if (this.state.ordenacao.expressa) {
				lista.sort((a, b) => this.state.ordenacao.expressa == 1 
					? (a.expressa > b.expressa ? 1 : -1) 
					: (a.expressa > b.expressa ? -1 : 1));
			} else if (this.state.ordenacao.ocorrencia) {
				lista.sort((a, b) => this.state.ordenacao.ocorrencia == 1 
					? (a.ocorrencias_abertas > b.ocorrencias_abertas ? 1 : -1) 
					: (a.ocorrencias_abertas > b.ocorrencias_abertas ? -1 : 1));
			} else if (this.state.ordenacao.cliente) {
				lista.sort((a, b) => this.state.ordenacao.cliente == 1 
					? (a.negociador > b.negociador ? 1 : -1) 
					: (a.negociador > b.negociador ? -1 : 1));
			} else if (this.state.ordenacao.rota) {
				lista.sort((a, b) => this.state.ordenacao.rota == 1 
					? (`${a.cidade_origem_shipping} ${a.cidade_origem_shipping ? '-' : ''} ${a.cidade_destino_shipping}` > `${b.cidade_origem_shipping} ${b.cidade_origem_shipping ? '-' : ''} ${b.cidade_destino_shipping}` ? 1 : -1) 
					: (`${a.cidade_origem_shipping} ${a.cidade_origem_shipping ? '-' : ''} ${a.cidade_destino_shipping}` > `${b.cidade_origem_shipping} ${b.cidade_origem_shipping ? '-' : ''} ${b.cidade_destino_shipping}` ? -1 : 1));
			} else if (this.state.ordenacao.fronteira) {
				lista.sort((a, b) => this.state.ordenacao.fronteira == 1 
					? (a.fronteira > b.fronteira ? 1 : -1) 
					: (a.fronteira > b.fronteira ? -1 : 1));
			} else if (this.state.ordenacao.previsao) {
				lista.sort((a, b) => this.state.ordenacao.previsao == 1 
					? (a.datahora_previsao > b.datahora_previsao ? 1 : -1) 
					: (a.datahora_previsao > b.datahora_previsao ? -1 : 1));
			} else if (this.state.ordenacao.statusPrevisao) {
				
				lista.sort((a, b) => {
					let tempoA = a.id_status == 1 || a.id_status == 2 || a.id_status == 4 || a.id_status == 6 ? (
						((parseInt(a.previsao_diferenca_dias || 0) * 24) * 60) 
						+ (parseInt(a.previsao_diferenca_horas || 0) * 60) 
						+ parseInt(a.previsao_diferenca_minutos || 0)
					) : 0;

					let tempoB = b.id_status == 1 || b.id_status == 2 || b.id_status == 4 || b.id_status == 6 ? (
						((parseInt(b.previsao_diferenca_dias || 0) * 24) * 60) 
						+ (parseInt(b.previsao_diferenca_horas || 0) * 60) 
						+ parseInt(b.previsao_diferenca_minutos || 0)
					) : 0;

					if (this.state.ordenacao.statusPrevisao == 1) {
						return (tempoA > tempoB ? 1 : -1);
					} else {
						return (tempoA > tempoB ? -1 : 1);
					}
				});
			} else if (this.state.ordenacao.posicao) {
				lista.sort((a, b) => this.state.ordenacao.posicao == 1 
					? (a.veiculo_referencia > b.veiculo_referencia ? 1 : -1) 
					: (a.veiculo_referencia > b.veiculo_referencia ? -1 : 1));
			} else if (this.state.ordenacao.statusPosicao) {
				lista.sort((a, b) => this.state.ordenacao.statusPosicao == 1 
				? (a.status > b.status ? 1 : -1) 
				: (a.status > b.status ? -1 : 1));
			} else if (this.state.ordenacao.destinado) {

				lista.sort((a, b) => {

					let destinadoA = 1;
					let destinadoB = 1;
					if (a.id_status == 1) {
						destinadoA = a.statusShipping.datahora_destinado_programacao ? 1 : 3;
					} else if (a.id_status == 3) {
						destinadoA = a.statusShipping.datahora_destinado_primeiro_transito ? 1 : 3;
					} else if (a.id_status == 5) {
						destinadoA = a.statusShipping.datahora_destinado_segundo_transito ? 1 : 3;
					}
					if (b.id_status == 1) {
						destinadoB = b.statusShipping.datahora_destinado_programacao ? 1 : 3;
					} else if (b.id_status == 3) {
						destinadoB = b.statusShipping.datahora_destinado_primeiro_transito ? 1 : 3;
					} else if (b.id_status == 5) {
						destinadoB = b.statusShipping.datahora_destinado_segundo_transito ? 1 : 3;
					}
				
					return this.state.ordenacao.destinado == 1 
						? (destinadoA > destinadoB ? 1 : -1) 
						: (destinadoA > destinadoB ? -1 : 1);
				});
			}*/

			return lista;
	}

	getNovaOrdenacao(atual) {
		return atual == 2 ? 0 : (atual || 0) + 1;
	}

	getIconeOrdenacao(tipo) {
		if (tipo == 1) {
			return <i class="fas fa-sort-amount-down-alt" />;
		} else if (tipo == 2) {
			return <i class="fas fa-sort-amount-up" />;
		} else {
			return null;
		}
	}

	lista() {

		const ExcelFile = ReactExport.ExcelFile;
		const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
		const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

		let acessoCliente = this.props.usuarioCarregado && this.props.usuarioCarregado.acesso_cliente;

		let listaFiltrada = this.filtrar();

		let qtdTotal = listaFiltrada.length;

		let qtdNaoConformidades = listaFiltrada.filter(item => 
			item.erro_documento || item.erro_rota
			|| item.erro_previsao_carga || item.erro_chegada_carga || item.erro_saida_carga || item.erro_entrada_aduana_origem || item.erro_saida_aduana_origem 
			|| item.erro_previsao_fronteira || item.erro_chegada_fronteira || item.erro_autorizacao_fronteira || item.erro_entrada_aduana_fronteira_origem 
			|| item.erro_saida_aduana_fronteira_origem || item.erro_entrada_aduana_fronteira_destino || item.erro_saida_aduana_fronteira_destino
			|| item.erro_saida_fronteira || item.erro_entrada_aduana_destino || item.erro_saida_aduana_destino || item.erro_chegada_descarga 
			|| item.erro_previsao_descarga || item.erro_saida_descarga
		).length;

		let percentualNaoConformidade = qtdTotal > 0 ? (qtdNaoConformidades * 100) / qtdTotal : 0;

		let setLinhaSelecionada = (id) => {
			this.setState({
				...this.state,
				linhaSelecionada: id
			});
		}

		return (
			<ContentCard>
				<ContentCardHeader>
					
					<Row alignCenter>					
						
						<Select
							label='Período'
							options={this.state.listaPeriodo}
							cols='12 4 3 2'
							placeholder='Selecione'
							naoLimpar
							value={this.state.filtro.tipo_periodo}
							onChange={value => {
								this.setState({
									...this.state,
									filtro: {
										...this.state.filtro,
										tipo_periodo: value
									}
								});
							}} />

						<LabelAndInputMask
							label='Data Inicial' placeholder='Informe a data'
							cols='12 4 3 3'
							mask='99/99/9999'
							value={this.state.filtro.data_inicial}
							onChange={(data) => {
								this.setState({
									...this.state,
									filtro: {
										...this.state.filtro,
										data_inicial: data.target.value
									}
								});
							}} />

						<LabelAndInputMask
							label='Data Final' placeholder='Informe a data'
							cols='12 4 3 3'
							mask='99/99/9999'
							value={this.state.filtro.data_final}
							onChange={(data) => {
								this.setState({
									...this.state,
									filtro: {
										...this.state.filtro,
										data_final: data.target.value
									}
								});
							}} />
						
						<Grid cols='12 6 2 2' style={{ marginTop: 28 }}>
							<Button
								text='Atualizar'
								type={'primary'}
								icon={'fa fa-plus'}
								event={() => {
									this.props.getLista(this.state.filtro);
								}} />
						</Grid>

						<Grid cols='6 6 2 2' style={{ marginTop: 28 }}>
							{this.state.filtro.internacional ? (
								<ExcelFile
									filename={'Não Conformidades'}
									element={
										<Button
											text='Exportar'
											type={'primary'}
											icon={'fa fa-plus'} />
									}>
									<ExcelSheet data={listaFiltrada.map(item => ({
										...item,
										documento: item.erro_documento ? 'NC' : item.documento,
										rota: item.erro_rota ? 'NC' : item.rota,
										data_previsao_carga: item.erro_previsao_carga ? 'NC' : DateFormat.formatarDataHoraSqlParaTela(item.data_previsao_carga),
										data_inicio_carga: item.erro_chegada_carga ? 'NC' : DateFormat.formatarDataHoraSqlParaTela(item.data_inicio_carga),
										data_termino_carga: item.erro_saida_carga ? 'NC' : DateFormat.formatarDataHoraSqlParaTela(item.data_termino_carga),
										data_entrada_aduana_origem: item.erro_entrada_aduana_origem ? 'NC' : DateFormat.formatarDataHoraSqlParaTela(item.data_entrada_aduana_origem),
										data_liberacao_aduana_origem: item.erro_saida_aduana_origem ? 'NC' : DateFormat.formatarDataHoraSqlParaTela(item.data_liberacao_aduana_origem),
										data_prev_fronteira: item.erro_previsao_fronteira ? 'NC' : DateFormat.formatarDataHoraSqlParaTela(item.data_prev_fronteira),
										data_entrada_fronteira: item.erro_chegada_fronteira ? 'NC' : DateFormat.formatarDataHoraSqlParaTela(item.data_entrada_fronteira),
										data_autorizacao_ingresso_fronteira: item.erro_autorizacao_fronteira ? 'NC' : DateFormat.formatarDataHoraSqlParaTela(item.data_autorizacao_ingresso_fronteira),
										dt_entrada_aduana_front_org: item.erro_entrada_aduana_fronteira_origem ? 'NC' : DateFormat.formatarDataHoraSqlParaTela(item.dt_entrada_aduana_front_org),
										dt_liberacao_aduana_front_org: item.erro_saida_aduana_fronteira_origem ? 'NC' : DateFormat.formatarDataHoraSqlParaTela(item.dt_liberacao_aduana_front_org),
										dt_entrada_aduana_front_dest: item.erro_entrada_aduana_fronteira_destino ? 'NC' : DateFormat.formatarDataHoraSqlParaTela(item.dt_entrada_aduana_front_dest),
										dt_liberacao_aduana_front_dest: item.erro_saida_aduana_fronteira_destino  ? 'NC' : DateFormat.formatarDataHoraSqlParaTela(item.dt_liberacao_aduana_front_dest),
										data_liberacao_fronteira: item.erro_saida_fronteira ? 'NC' : DateFormat.formatarDataHoraSqlParaTela(item.data_liberacao_fronteira),
										data_entrada_aduana_destino: item.erro_entrada_aduana_destino ? 'NC' : DateFormat.formatarDataHoraSqlParaTela(item.data_entrada_aduana_destino),
										data_liberacao_aduana_destino: item.erro_saida_aduana_destino ? 'NC' : DateFormat.formatarDataHoraSqlParaTela(item.data_liberacao_aduana_destino),
										data_previsao_descarga: item.erro_previsao_descarga ? 'NC' : DateFormat.formatarDataHoraSqlParaTela(item.data_previsao_descarga),
										data_inicio_descarga: item.erro_chegada_descarga  ? 'NC' : DateFormat.formatarDataHoraSqlParaTela(item.data_inicio_descarga),
										data_termino_descarga: item.erro_saida_descarga ? 'NC' : DateFormat.formatarDataHoraSqlParaTela(item.data_termino_descarga),

										indicador_carga: !item.data_previsao_carga ? '' : (item.chegada_carga_dias > 0 || item.chegada_carga_horas > 0 || item.chegada_carga_minutos > 0 ? 'Atrasado' : 'Adiantado'),
										indicador_carga_descritivo: (`${item.chegada_carga_dias > 0 ? `${item.chegada_carga_dias}d` : ''} ${item.chegada_carga_horas > 0 ? `${item.chegada_carga_horas}h` : ''} ${item.chegada_carga_minutos > 0 ? `${item.chegada_carga_minutos}min` : ''}`).trim(),

										indicador_fronteira: !item.data_prev_fronteira ? '' : (item.chegada_fronteira_dias > 0 || item.chegada_fronteira_horas > 0 || item.chegada_fronteira_minutos > 0 ? 'Atrasado' : 'Adiantado'),
										indicador_fronteira_descritivo: (`${item.chegada_fronteira_dias > 0 ? `${item.chegada_fronteira_dias}d` : ''} ${item.chegada_fronteira_horas > 0 ? `${item.chegada_fronteira_horas}h` : ''} ${item.chegada_fronteira_minutos > 0 ? `${item.chegada_fronteira_minutos}min` : ''}`).trim(),

										indicador_descarga: !item.data_previsao_descarga ? '' : (item.chegada_descarga_dias > 0 || item.chegada_descarga_horas > 0 || item.chegada_descarga_minutos > 0 ? 'Atrasado' : 'Adiantado'),
										indicador_descarga_descritivo: (`${item.chegada_descarga_dias > 0 ? `${item.chegada_descarga_dias}d` : ''} ${item.chegada_descarga_horas > 0 ? `${item.chegada_descarga_horas}h` : ''} ${item.chegada_descarga_minutos > 0 ? `${item.chegada_descarga_minutos}min` : ''}`).trim()
									}))} name='Não Conformidades'>
										<ExcelColumn bgColor='#f00' label='Ship.' value='shipping_code_id' />
										<ExcelColumn label='Sol.' value='solicitacao_carga_id' />
										<ExcelColumn label='Documento' value='documento' />
										<ExcelColumn label='Cavalo' value='cavalo' />
										<ExcelColumn label='Carreta' value='carreta' />
										<ExcelColumn label='Cliente' value='negociador' />
										<ExcelColumn label='Rota' value='rota' />
										<ExcelColumn label='Rumo' value='rumo' />
										<ExcelColumn label='Fronteira' value='nome_fronteira' />
										<ExcelColumn label='Previsão Carga' value='data_previsao_carga' />
										<ExcelColumn label='Chegada Carga' value='data_inicio_carga' />
										<ExcelColumn label='Saída Carga' value='data_termino_carga' />
										<ExcelColumn label='Indicador JC' value='indicador_carga' />
										<ExcelColumn label='Atraso JC' value='indicador_carga_descritivo' />
										<ExcelColumn label='Chegada Alfândega Origem' value='data_entrada_aduana_origem' />
										<ExcelColumn label='Liberação Alfândega Origem' value='data_liberacao_aduana_origem' />
										<ExcelColumn label='Previsão Fronteira' value='data_prev_fronteira' />
										<ExcelColumn label='Chegada Fronteira' value='data_entrada_fronteira' />
										<ExcelColumn label='Autorização Ingresso fronteira' value='data_autorizacao_ingresso_fronteira' />
										<ExcelColumn label='Chegada Alfândega Fronteira Origem' value='dt_entrada_aduana_front_org' />
										<ExcelColumn label='Liberação Alfândega Fronteira Origem' value='dt_liberacao_aduana_front_org' />
										<ExcelColumn label='Chegada Alfândega Fronteira Destino' value='dt_entrada_aduana_front_dest' />
										<ExcelColumn label='Liberação Alfândega Fronteira Destino' value='dt_liberacao_aduana_front_dest' />
										<ExcelColumn label='Liberação Fronteira' value='data_liberacao_fronteira' />
										<ExcelColumn label='Indicador JF' value='indicador_fronteira' />
										<ExcelColumn label='Atraso JF' value='indicador_fronteira_descritivo' />
										<ExcelColumn label='Chegada Alfândega Destino' value='data_entrada_aduana_destino' />
										<ExcelColumn label='Liberação Alfândega Destino' value='data_liberacao_aduana_destino' />
										<ExcelColumn label='Previsão Descarga' value='data_previsao_descarga' />
										<ExcelColumn label='Chegada Descarga' value='data_inicio_descarga' />
										<ExcelColumn label='Saída Descarga' value='data_termino_descarga' />
										<ExcelColumn label='Indicador JD' value='indicador_descarga' />
										<ExcelColumn label='Atraso JD' value='indicador_descarga_descritivo' />
									</ExcelSheet>
								</ExcelFile>
							) : (
								<ExcelFile
									filename={'Não Conformidades'}
									element={
										<Button
											text='Exportar'
											type={'primary'}
											icon={'fa fa-plus'} />
									}>
									<ExcelSheet data={listaFiltrada.map(item => ({
										...item,
										
										documento: item.erro_documento ? 'NC' : item.documento,
										rota: item.erro_rota ? 'NC' : item.rota,
										data_previsao_carga: item.erro_previsao_carga ? 'NC' : DateFormat.formatarDataHoraSqlParaTela(item.data_previsao_carga),
										data_inicio_carga: item.erro_chegada_carga ? 'NC' : DateFormat.formatarDataHoraSqlParaTela(item.data_inicio_carga),
										data_termino_carga: item.erro_saida_carga ? 'NC' : DateFormat.formatarDataHoraSqlParaTela(item.data_termino_carga),
										data_previsao_descarga: item.erro_previsao_descarga ? 'NC' : DateFormat.formatarDataHoraSqlParaTela(item.data_previsao_descarga),
										data_inicio_descarga: item.erro_chegada_descarga  ? 'NC' : DateFormat.formatarDataHoraSqlParaTela(item.data_inicio_descarga),
										data_termino_descarga: item.erro_saida_descarga ? 'NC' : DateFormat.formatarDataHoraSqlParaTela(item.data_termino_descarga),
										
										indicador_carga: !item.data_previsao_carga ? '' : (item.chegada_carga_dias > 0 || item.chegada_carga_horas > 0 || item.chegada_carga_minutos > 0 ? 'Atrasado' : 'Adiantado'),
										indicador_carga_descritivo: (`${item.chegada_carga_dias > 0 ? `${item.chegada_carga_dias}d` : ''} ${item.chegada_carga_horas > 0 ? `${item.chegada_carga_horas}h` : ''} ${item.chegada_carga_minutos > 0 ? `${item.chegada_carga_minutos}min` : ''}`).trim(),

										indicador_descarga: !item.data_previsao_descarga ? '' : (item.chegada_descarga_dias > 0 || item.chegada_descarga_horas > 0 || item.chegada_descarga_minutos > 0 ? 'Atrasado' : 'Adiantado'),
										indicador_descarga_descritivo: (`${item.chegada_descarga_dias > 0 ? `${item.chegada_descarga_dias}d` : ''} ${item.chegada_descarga_horas > 0 ? `${item.chegada_descarga_horas}h` : ''} ${item.chegada_descarga_minutos > 0 ? `${item.chegada_descarga_minutos}min` : ''}`).trim()
									}))} name='Não Conformidades'>
										<ExcelColumn label='Ship.' value='shipping_code_id' />
										<ExcelColumn label='Sol.' value='solicitacao_carga_id' />
										<ExcelColumn label='Documento' value='documento' />
										<ExcelColumn label='Cavalo' value='cavalo' />
										<ExcelColumn label='Carreta' value='carreta' />
										<ExcelColumn label='Cliente' value='negociador' />
										<ExcelColumn label='Rota' value='rota' />
										<ExcelColumn label='Previsão Carga' value='data_previsao_carga' />
										<ExcelColumn label='Chegada Carga' value='data_inicio_carga' />
										<ExcelColumn label='Saída Carga' value='data_termino_carga' />
										<ExcelColumn label='Indicador JC' value='indicador_carga' />
										<ExcelColumn label='Atraso JC' value='indicador_carga_descritivo' />
										<ExcelColumn label='Previsão Descarga' value='data_previsao_descarga' />
										<ExcelColumn label='Chegada Descarga' value='data_inicio_descarga' />
										<ExcelColumn label='Saída Descarga' value='data_termino_descarga' />
										<ExcelColumn label='Indicador JD' value='indicador_descarga' />
										<ExcelColumn label='Atraso JD' value='indicador_descarga_descritivo' />
									</ExcelSheet>
								</ExcelFile>
							)}
						</Grid>

					</Row>

					{/*
					<Row>
						<Select
							label='Rumo'
							multi
							options={this.props.listaRumos}
							cols='12 6 2 2'
							placeholder='Selecione'
							multiple={true}
							value={this.state.filtro.rumos}
							onChange={value => {
								this.setState({
									...this.state,
									filtro: {
										...this.state.filtro,
										rumos: value.map(item => item.value)
									}
								});
							}} />

						<Select
							label='Farol Atraso'
							multi
							options={this.props.listaFarolAtraso}
							cols='12 6 2 2'
							placeholder='Selecione'
							multiple={true}
							value={this.state.filtro.farolAtrasos}
							onChange={value => {
								this.setState({
									...this.state,
									filtro: {
										...this.state.filtro,
										farolAtrasos: value.map(item => item.value)
									}
								});
							}} />
							
						<Select
							label='Fronteira'
							multi
							options={this.props.listaFronteiras.map(item => ({ id: item.id_fronteira_ref, valor: item.fronteira }))}
							cols='12 6 3 3'
							placeholder='Selecione'
							multiple={true}
							value={this.state.filtro.fronteiras}
							onChange={value => {
								this.setState({
									...this.state,
									filtro: {
										...this.state.filtro,
										fronteiras: value.map(item => item.value)
									}
								});
							}} />
						{!acessoCliente &&
						<Select
							label='Operação'
							multi
							options={this.props.listaOperacoes}
							cols='12 6 2 2'
							placeholder='Selecione'
							multiple={true}
							value={this.state.filtro.operacoes}
							onChange={value => {
								this.setState({
									...this.state,
									filtro: {
										...this.state.filtro,
										operacoes: value.map(item => item.value)
									}
								});
							}} />}

						{!acessoCliente &&
						<Select
							label='Negociador'
							multi
							options={this.props.listaNegociadores.map(item => ({ id: item.id_negociador, valor: item.negociador }))}
							cols='12 6 3 3'
							placeholder='Selecione'
							multiple={true}
							value={this.state.filtro.negociadores}
							onChange={value => {
								this.setState({
									...this.state,
									filtro: {
										...this.state.filtro,
										negociadores: value.map(item => item.value)
									}
								});
							}} />}
					</Row>*/}

					<div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>

						<div style={{ display: 'flex', alignItems: 'center', marginRight: 8 }} title='Aberto'>
							<i class='fas fa-road' style={{ marginRight: 4 }}></i>
							<div class="custom-control custom-switch">
								<input type="checkbox" class='custom-control-input' id='customSwitch1'
									checked={this.state.filtro.aberto}
									onChange={data => this.setState({ ...this.state, filtro: { ...this.state.filtro, aberto: !this.state.filtro.aberto }})} />
								<label class="custom-control-label" for="customSwitch1"></label>
							</div>
						</div>

						<div style={{ display: 'flex', alignItems: 'center', marginRight: 8 }} title='Finalizado'>
							<i class='fas fa-pallet' style={{ marginRight: 4 }}></i>
							<div class="custom-control custom-switch">
								<input type="checkbox" class='custom-control-input' id='customSwitch2'
									checked={this.state.filtro.finalizado}
									onChange={data => this.setState({ ...this.state, filtro: { ...this.state.filtro, finalizado: !this.state.filtro.finalizado }})} />
								<label class="custom-control-label" for="customSwitch2"></label>
							</div>
						</div>

						<div style={{ display: 'flex', alignItems: 'center', marginRight: 8 }} title='Sem Não Conformidade'>
							<i class='fas fa-calendar-check' style={{ marginRight: 4 }}></i>
							<div class="custom-control custom-switch">
								<input type="checkbox" class='custom-control-input' id='customSwitch3'
									checked={this.state.filtro.semNaoConformidade}
									onChange={data => this.setState({ ...this.state, filtro: { ...this.state.filtro, semNaoConformidade: !this.state.filtro.semNaoConformidade }})} />
								<label class="custom-control-label" for="customSwitch3"></label>
							</div>
						</div>

						<div style={{ display: 'flex', alignItems: 'center', marginRight: 8 }} title='Com Não Conformidade'>
							<i class='fas fa-calendar-times' style={{ marginRight: 4 }}></i>
							<div class="custom-control custom-switch">
								<input type="checkbox" class='custom-control-input' id='customSwitch4'
									checked={this.state.filtro.comNaoConformidade}
									onChange={data => this.setState({ ...this.state, filtro: { ...this.state.filtro, comNaoConformidade: !this.state.filtro.comNaoConformidade }})} />
								<label class="custom-control-label" for="customSwitch4"></label>
							</div>
						</div>

						{/*

						<div style={{ display: 'flex', alignItems: 'center', marginRight: 8 }} title='Lote'>
							<i class='fas fa-boxes' style={{ marginRight: 4 }}></i>
							<div class="custom-control custom-switch">
								<input type="checkbox" class='custom-control-input' id='customSwitch1'
									checked={this.state.filtro.lote}
									onChange={data => this.setState({ ...this.state, filtro: { ...this.state.filtro, lote: !this.state.filtro.lote }})} />
								<label class="custom-control-label" for="customSwitch1"></label>
							</div>
						</div>

						<div style={{ display: 'flex', alignItems: 'center', marginRight: 8 }} title='Classificada'>
							<i class='fas fa-exclamation-triangle' style={{ marginRight: 4 }}></i>
							<div class="custom-control custom-switch">
								<input type="checkbox" class='custom-control-input' id='customSwitch2'
									checked={this.state.filtro.classificada}
									onChange={data => this.setState({ ...this.state, filtro: { ...this.state.filtro, classificada: !this.state.filtro.classificada }})} />
								<label class="custom-control-label" for="customSwitch2"></label>
							</div>
						</div>

						<div style={{ display: 'flex', alignItems: 'center', marginRight: 8 }} title='Viagem Expressa'>
							<i class='fas fa-shipping-fast' style={{ marginRight: 4 }}></i>
							<div class="custom-control custom-switch">
								<input type="checkbox" class='custom-control-input' id='customSwitch3'
									checked={this.state.filtro.expressa}
									onChange={data => this.setState({ ...this.state, filtro: { ...this.state.filtro, expressa: !this.state.filtro.expressa }})} />
								<label class="custom-control-label" for="customSwitch3"></label>
							</div>
						</div>

						<div style={{ display: 'flex', alignItems: 'center', marginRight: 8 }} title='Ocorrência Aberta'>
							<i class='fas fa-bell' style={{ marginRight: 4 }}></i>
							<div class="custom-control custom-switch">
								<input type="checkbox" class='custom-control-input' id='customSwitch4'
									checked={this.state.filtro.ocorrenciaAberta}
									onChange={data => this.setState({ ...this.state, filtro: { ...this.state.filtro, ocorrenciaAberta: !this.state.filtro.ocorrenciaAberta }})} />
								<label class="custom-control-label" for="customSwitch4"></label>
							</div>
						</div>

						<div style={{ display: 'flex', alignItems: 'center', marginRight: 8 }} title='Cavalo engatado'>
							<i class='fas fa-link' style={{ marginRight: 4 }}></i>
							<div class="custom-control custom-switch">
								<input type="checkbox" class='custom-control-input' id='customSwitch5'
									checked={this.state.filtro.cavaloEngatado}
									onChange={data => this.setState({ ...this.state, filtro: { ...this.state.filtro, cavaloEngatado: !this.state.filtro.cavaloEngatado }})} />
								<label class="custom-control-label" for="customSwitch5"></label>
							</div>
						</div>
						<div style={{ display: 'flex', alignItems: 'center', marginRight: 8 }} title='Somente Carreta'>
							<i class='fas fa-truck-loading' style={{ marginRight: 4 }}></i>
							<div class="custom-control custom-switch">
								<input type="checkbox" class='custom-control-input' id='customSwitch6'
									checked={this.state.filtro.somenteCarreta}
									onChange={data => this.setState({ ...this.state, filtro: { ...this.state.filtro, somenteCarreta: !this.state.filtro.somenteCarreta }})} />
								<label class="custom-control-label" for="customSwitch6"></label>
							</div>
						</div>
						<div style={{ display: 'flex', alignItems: 'center', marginRight: 8 }} title='Sem conjunto'>
							<i class='fas fa-unlink' style={{ marginRight: 4 }}></i>
							<div class="custom-control custom-switch">
								<input type="checkbox" class='custom-control-input' id='customSwitch7'
									checked={this.state.filtro.semConjunto}
									onChange={data => this.setState({ ...this.state, filtro: { ...this.state.filtro, semConjunto: !this.state.filtro.semConjunto }})} />
								<label class="custom-control-label" for="customSwitch7"></label>
							</div>
						</div>
						<div style={{ display: 'flex', alignItems: 'center', marginRight: 8 }} title='Em CD'>
							<i class='fas fa-warehouse' style={{ marginRight: 4 }}></i>
							<div class="custom-control custom-switch">
								<input type="checkbox" class='custom-control-input' id='customSwitch8'
									checked={this.state.filtro.emCD}
									onChange={data => this.setState({ ...this.state, filtro: { ...this.state.filtro, emCD: !this.state.filtro.emCD }})} />
								<label class="custom-control-label" for="customSwitch8"></label>
							</div>
						</div>
						<div style={{ display: 'flex', alignItems: 'center', marginRight: 8  }} title='Sem Documento'>
							<i class='fas fa-file' style={{ marginRight: 4 }}></i>
							<div class="custom-control custom-switch">
								<input type="checkbox" class='custom-control-input' id='customSwitch9'
									checked={this.state.filtro.semDocumento}
									onChange={data => this.setState({ ...this.state, filtro: { ...this.state.filtro, semDocumento: !this.state.filtro.semDocumento }})} />
								<label class="custom-control-label" for="customSwitch9"></label>
							</div>
						</div>

						*/}

						<div style={{ display: 'flex', alignItems: 'center', marginRight: 8  }} title='Nacionais'>
							<i class='fas fa-home' style={{ marginRight: 4 }}></i>
							<div class="custom-control custom-switch">
								<input type="checkbox" class='custom-control-input' id='customSwitch10'
									checked={this.state.filtro.nacional}
									disabled={!this.props.usuarioCarregado || !this.props.usuarioCarregado.nacional}
									onChange={data => this.setState({ ...this.state, filtro: { ...this.state.filtro, nacional: !this.state.filtro.nacional }})} />
								<label class="custom-control-label" for="customSwitch10"></label>
							</div>
						</div>
						<div style={{ display: 'flex', alignItems: 'center' }} title='Internacionais'>
							<i class='fas fa-globe-americas' style={{ marginRight: 4 }}></i>
							<div class="custom-control custom-switch">
								<input type="checkbox" class='custom-control-input' id='customSwitch11'
									checked={this.state.filtro.internacional}
									disabled={!this.props.usuarioCarregado || !this.props.usuarioCarregado.internacional}
									onChange={data => this.setState({ ...this.state, filtro: { ...this.state.filtro, internacional: !this.state.filtro.internacional }})} />
								<label class="custom-control-label" for="customSwitch11"></label>
							</div>
						</div>
						
					</div>

				</ContentCardHeader>
				<ContentCardBody>

					
					{/*
					FILTRO GERAL
					*/}
					
					<Row>
						<Grid cols='12 6 6 6'>
							<div class='input-group' id='pesquisar'>
								<input 
									type='text'
									class='form-control datetimepicker-input'
									data-target='#pesquisar'
									placeholder='Pesquisar (adicione + para combinar pesquisas)'
									value={this.state.pesquisarAux}
									onChange={e => {
										this.setState({ ...this.state, pesquisarAux: e.target.value });
									}}
									onKeyDown={e => {
										if (e.key === 'Enter') {
											this.setState({ ...this.state, pesquisar: this.state.pesquisarAux });
										}
									}} />
								<div type='button' class='input-group-append' data-target='#pesquisar'>
									<div 
										class='input-group-text'
										onClick={e => {
											this.setState({ ...this.state, pesquisar: this.state.pesquisarAux });
										}}>
										<i class='fas fa-search'></i></div>
								</div>
							</div>
						</Grid>
						<Grid cols='12 6 6 6'>
							<div>
								<div class='progress-group'>
									<b>Não Conformidades</b>
									<span class='float-right'><b style={{ color: '#ba3200' }}>{qtdNaoConformidades}</b>/<b style={{ color: '#000000' }}>{qtdTotal}</b></span>
									<div class='progress progress-sm'>
										<div class={`progress-bar bg-${percentualNaoConformidade <= 5 ? 'success' : (percentualNaoConformidade <= 10 ? 'warning' : 'danger')}`} style={{ width: `${percentualNaoConformidade}%` }}></div>
									</div>
								</div>
							</div>
						</Grid>
					</Row>
					<Table responsive border alturaFixa maxHeight={window.innerWidth < 768 ? '100%' : (window.innerWidth < 1430 ? '70vh' : `${(window.innerHeight - 150) * 0.0926}vh`)}>
						<THead>
							<Tr backgroundColor={'#fff'} height={100}>
								<Th onClick={() => this.setState({ ...this.state, ordenacao: { shipping: this.getNovaOrdenacao(this.state.ordenacao.shipping) }})} 
									sticky width={10} color={'#1F3066'} alignCenter>Ship. {this.getIconeOrdenacao(this.state.ordenacao.shipping)}</Th>
								<Th onClick={() => this.setState({ ...this.state, ordenacao: { solicitacao: this.getNovaOrdenacao(this.state.ordenacao.solicitacao) }})} 
									sticky width={10} color={'#1F3066'} alignCenter>Sol. {this.getIconeOrdenacao(this.state.ordenacao.solicitacao)}</Th>

								<Th onClick={() => this.setState({ ...this.state, ordenacao: { documento: this.getNovaOrdenacao(this.state.ordenacao.documento) }})} 
									sticky width={10} color={'#1F3066'} alignCenter>Documento {this.getIconeOrdenacao(this.state.ordenacao.documento)}</Th>

								<Th onClick={() => this.setState({ ...this.state, ordenacao: { cavalo: this.getNovaOrdenacao(this.state.ordenacao.cavalo) }})} 
									sticky width={10} color={'#1F3066'} alignCenter>Cavalo {this.getIconeOrdenacao(this.state.ordenacao.cavalo)}</Th>

								<Th onClick={() => this.setState({ ...this.state, ordenacao: { carreta: this.getNovaOrdenacao(this.state.ordenacao.carreta) }})} 
									sticky width={10} color={'#1F3066'} alignCenter>Carreta {this.getIconeOrdenacao(this.state.ordenacao.carreta)}</Th>

								<Th onClick={() => this.setState({ ...this.state, ordenacao: { cliente: this.getNovaOrdenacao(this.state.ordenacao.cliente) }})} 
									sticky width={10} color={'#1F3066'} alignCenter>Cliente {this.getIconeOrdenacao(this.state.ordenacao.cliente)}</Th>

								<Th onClick={() => this.setState({ ...this.state, ordenacao: { rota: this.getNovaOrdenacao(this.state.ordenacao.rota) }})} 
									sticky width={10} color={'#1F3066'} alignCenter>Rota {this.getIconeOrdenacao(this.state.ordenacao.rota)}</Th>
									
								{this.state.filtro.internacional &&
								<Th onClick={() => this.setState({ ...this.state, ordenacao: { rumo: this.getNovaOrdenacao(this.state.ordenacao.rumo) }})} 
									sticky width={10} color={'#1F3066'} alignCenter>Rumo {this.getIconeOrdenacao(this.state.ordenacao.rumo)}</Th>}
									
								{this.state.filtro.internacional &&
								<Th onClick={() => this.setState({ ...this.state, ordenacao: { fronteira: this.getNovaOrdenacao(this.state.ordenacao.fronteira) }})} 
									sticky width={250} color={'#1F3066'} alignCenter>Fronteira {this.getIconeOrdenacao(this.state.ordenacao.fronteira)}</Th>}
									
								<Th onClick={() => this.setState({ ...this.state, ordenacao: { previsao_carga: this.getNovaOrdenacao(this.state.ordenacao.previsao_carga) }})} 
									sticky width={100} color={'#1F3066'} alignCenter>Previsão Carga {this.getIconeOrdenacao(this.state.ordenacao.previsao_carga)}</Th>

								<Th onClick={() => this.setState({ ...this.state, ordenacao: { inicio_carga: this.getNovaOrdenacao(this.state.ordenacao.inicio_carga) }})} 
									sticky width={100} color={'#1F3066'} alignCenter>Chegada Carga {this.getIconeOrdenacao(this.state.ordenacao.inicio_carga)}</Th>
								<Th onClick={() => this.setState({ ...this.state, ordenacao: { termino_carga: this.getNovaOrdenacao(this.state.ordenacao.termino_carga) }})} 
									sticky width={100} color={'#1F3066'} alignCenter>Saída Carga {this.getIconeOrdenacao(this.state.ordenacao.termino_carga)}</Th>

								<Th colspan={2} onClick={() => this.setState({ ...this.state, ordenacao: { indicador_carga: this.getNovaOrdenacao(this.state.ordenacao.indicador_carga) }})} 
									sticky width={100} color={'#1F3066'} alignCenter>Indicador JC {this.getIconeOrdenacao(this.state.ordenacao.indicador_carga)}</Th>
								
								{this.state.filtro.internacional &&
								<Th onClick={() => this.setState({ ...this.state, ordenacao: { entrada_aduana_origem: this.getNovaOrdenacao(this.state.ordenacao.entrada_aduana_origem) }})} 
									sticky width={100} color={'#1F3066'} alignCenter>Chegada Alfândega Origem {this.getIconeOrdenacao(this.state.ordenacao.entrada_aduana_origem)}</Th>}
								{this.state.filtro.internacional &&
								<Th onClick={() => this.setState({ ...this.state, ordenacao: { liberacao_aduana_origem: this.getNovaOrdenacao(this.state.ordenacao.liberacao_aduana_origem) }})} 
									sticky width={100} color={'#1F3066'} alignCenter>Liberação Alfândega Origem {this.getIconeOrdenacao(this.state.ordenacao.liberacao_aduana_origem)}</Th>}
								{this.state.filtro.internacional &&
								<Th onClick={() => this.setState({ ...this.state, ordenacao: { previsao_fronteira: this.getNovaOrdenacao(this.state.ordenacao.previsao_fronteira) }})} 
									sticky width={100} color={'#1F3066'} alignCenter>Previsão Fronteira {this.getIconeOrdenacao(this.state.ordenacao.previsao_fronteira)}</Th>}
								{this.state.filtro.internacional &&
								<Th onClick={() => this.setState({ ...this.state, ordenacao: { entrada_fronteira: this.getNovaOrdenacao(this.state.ordenacao.entrada_fronteira) }})} 
									sticky width={100} color={'#1F3066'} alignCenter>Chegada Fronteira {this.getIconeOrdenacao(this.state.ordenacao.entrada_fronteira)}</Th>}
								{this.state.filtro.internacional &&
								<Th onClick={() => this.setState({ ...this.state, ordenacao: { autorizacao_ingresso_fronteira: this.getNovaOrdenacao(this.state.ordenacao.autorizacao_ingresso_fronteira) }})} 
									sticky width={100} color={'#1F3066'} alignCenter>Autorização Ingresso fronteira {this.getIconeOrdenacao(this.state.ordenacao.autorizacao_ingresso_fronteira)}</Th>}
								{this.state.filtro.internacional &&
								<Th onClick={() => this.setState({ ...this.state, ordenacao: { entrada_aduana_front_org: this.getNovaOrdenacao(this.state.ordenacao.entrada_aduana_front_org) }})} 
									sticky width={100} color={'#1F3066'} alignCenter>Chegada Alfândega Fronteira Origem {this.getIconeOrdenacao(this.state.ordenacao.entrada_aduana_front_org)}</Th>}
								{this.state.filtro.internacional &&
								<Th onClick={() => this.setState({ ...this.state, ordenacao: { liberacao_aduana_front_org: this.getNovaOrdenacao(this.state.ordenacao.liberacao_aduana_front_org) }})} 
									sticky width={100} color={'#1F3066'} alignCenter>Liberação Alfândega Fronteira Origem {this.getIconeOrdenacao(this.state.ordenacao.liberacao_aduana_front_org)}</Th>}
								{this.state.filtro.internacional &&
								<Th onClick={() => this.setState({ ...this.state, ordenacao: { entrada_aduana_front_dest: this.getNovaOrdenacao(this.state.ordenacao.entrada_aduana_front_dest) }})} 
									sticky width={100} color={'#1F3066'} alignCenter>Chegada Alfândega Fronteira Destino {this.getIconeOrdenacao(this.state.ordenacao.entrada_aduana_front_dest)}</Th>}
								{this.state.filtro.internacional &&
								<Th onClick={() => this.setState({ ...this.state, ordenacao: { liberacao_aduana_front_dest: this.getNovaOrdenacao(this.state.ordenacao.liberacao_aduana_front_dest) }})} 
									sticky width={100} color={'#1F3066'} alignCenter>Liberação Alfândega Fronteira Destino {this.getIconeOrdenacao(this.state.ordenacao.liberacao_aduana_front_dest)}</Th>}
								{this.state.filtro.internacional &&
								<Th onClick={() => this.setState({ ...this.state, ordenacao: { liberacao_fronteira: this.getNovaOrdenacao(this.state.ordenacao.liberacao_fronteira) }})} 
									sticky width={100} color={'#1F3066'} alignCenter>Liberação Fronteira {this.getIconeOrdenacao(this.state.ordenacao.liberacao_fronteira)}</Th>}
									
								{this.state.filtro.internacional &&
								<Th colspan={2} onClick={() => this.setState({ ...this.state, ordenacao: { indicador_fronteira: this.getNovaOrdenacao(this.state.ordenacao.indicador_fronteira) }})} 
									sticky width={100} color={'#1F3066'} alignCenter>Indicador JF {this.getIconeOrdenacao(this.state.ordenacao.indicador_fronteira)}</Th>}

								{this.state.filtro.internacional &&
								<Th onClick={() => this.setState({ ...this.state, ordenacao: { entrada_aduana_destino: this.getNovaOrdenacao(this.state.ordenacao.entrada_aduana_destino) }})} 
									sticky width={100} color={'#1F3066'} alignCenter>Chegada Alfândega Destino {this.getIconeOrdenacao(this.state.ordenacao.entrada_aduana_destino)}</Th>}
								{this.state.filtro.internacional &&
								<Th onClick={() => this.setState({ ...this.state, ordenacao: { liberacao_aduana_destino: this.getNovaOrdenacao(this.state.ordenacao.liberacao_aduana_destino) }})}
									sticky width={100} color={'#1F3066'} alignCenter>Liberação Alfândega Destino {this.getIconeOrdenacao(this.state.ordenacao.liberacao_aduana_destino)}</Th>}
									
								<Th onClick={() => this.setState({ ...this.state, ordenacao: { previsao_descarga: this.getNovaOrdenacao(this.state.ordenacao.previsao_descarga) }})} 
									sticky width={100} color={'#1F3066'} alignCenter>Previsão Descarga {this.getIconeOrdenacao(this.state.ordenacao.previsao_descarga)}</Th>

								<Th onClick={() => this.setState({ ...this.state, ordenacao: { inicio_descarga: this.getNovaOrdenacao(this.state.ordenacao.inicio_descarga) }})} 
									sticky width={100} color={'#1F3066'} alignCenter>Chegada Descarga {this.getIconeOrdenacao(this.state.ordenacao.inicio_descarga)}</Th>
								<Th onClick={() => this.setState({ ...this.state, ordenacao: { termino_descarga: this.getNovaOrdenacao(this.state.ordenacao.termino_descarga) }})} 
									sticky width={100} color={'#1F3066'} alignCenter>Saída Descarga {this.getIconeOrdenacao(this.state.ordenacao.termino_descarga)}</Th>

								<Th colspan={2} onClick={() => this.setState({ ...this.state, ordenacao: { indicador_descarga: this.getNovaOrdenacao(this.state.ordenacao.indicador_descarga) }})} 
									sticky width={100} color={'#1F3066'} alignCenter>Indicador JD {this.getIconeOrdenacao(this.state.ordenacao.indicador_descarga)}</Th>
							</Tr>
						</THead>
						<TBody>
							{listaFiltrada.map((item, i) => {

								let corLinha = this.state.linhaSelecionada == item.solicitacao_carga_id ? '#bcc7eb' : (i % 2 == 0 ? '#ffffff' : '#f5f5f5');
								
								let colorCargaDescarga = corLinha;
								let colorAduanaOrigem = item.aduana_origem ? corLinha : '#bfc7c9';
								let colorFronteira = item.fronteira ? corLinha : '#bfc7c9';
								let colorAduanaDestino = item.aduana_destino ? corLinha : '#bfc7c9';
			
								return (
									<>
										<Tr key={item.shipping_code_id} 
											backgroundColor={corLinha} >
											<Td onClick={() => setLinhaSelecionada(item.solicitacao_carga_id)} minWidth={10} color={'#000'} alignCenter small>{item.shipping_code_id}</Td>
											<Td onClick={() => setLinhaSelecionada(item.solicitacao_carga_id)} minWidth={10} color={'#000'} alignCenter small>{item.solicitacao_carga_id}</Td>
											<Td onClick={() => setLinhaSelecionada(item.solicitacao_carga_id)} minWidth={100} color={item.erro_documento ? '#ffffff' : '#000000'} backgroundColor={item.erro_documento ? '#de0f00' : null} alignCenter small>{item.documento}</Td>
											<Td onClick={() => setLinhaSelecionada(item.solicitacao_carga_id)} minWidth={10} color={'#000'} alignCenter small>{item.cavalo}</Td>
											<Td onClick={() => setLinhaSelecionada(item.solicitacao_carga_id)} minWidth={10} color={'#000'} alignCenter small>{item.carreta}</Td>
											<Td onClick={() => setLinhaSelecionada(item.solicitacao_carga_id)} minWidth={100} color={'#000'} alignCenter small>{item.negociador}</Td>
											<Td onClick={() => setLinhaSelecionada(item.solicitacao_carga_id)} minWidth={150} color={item.erro_rota ? '#ffffff' : '#000000'} backgroundColor={item.erro_rota ? '#de0f00' : null} alignCenter small>{item.rota}</Td>
											{this.state.filtro.internacional &&
											<Td onClick={() => setLinhaSelecionada(item.solicitacao_carga_id)} minWidth={10} color={'#000'} alignCenter small>{item.rumo}</Td>}
											{this.state.filtro.internacional &&
											<Td onClick={() => setLinhaSelecionada(item.solicitacao_carga_id)} minWidth={250} color={'#000'} alignCenter small>{item.nome_fronteira}</Td>}

											<Td onClick={() => setLinhaSelecionada(item.solicitacao_carga_id)} minWidth={100} alignCenter small 
												color={item.erro_previsao_carga ? '#ffffff' : '#000000'} 
												backgroundColor={item.erro_previsao_carga ? '#de0f00' : colorCargaDescarga}>
												{DateFormat.formatarDataHoraSqlParaTela(item.data_previsao_carga)}
											</Td>
											<Td onClick={() => setLinhaSelecionada(item.solicitacao_carga_id)} minWidth={100} alignCenter small 
												color={item.erro_chegada_carga ? '#ffffff' : '#000000'} 
												backgroundColor={item.erro_chegada_carga ? '#de0f00' : colorCargaDescarga}>
												{DateFormat.formatarDataHoraSqlParaTela(item.data_inicio_carga)}
											</Td>
											<Td onClick={() => setLinhaSelecionada(item.solicitacao_carga_id)} minWidth={100} alignCenter small 
												color={item.erro_saida_carga ? '#ffffff' : '#000000'} 
												backgroundColor={item.erro_saida_carga ? '#de0f00' : colorCargaDescarga}>
												{DateFormat.formatarDataHoraSqlParaTela(item.data_termino_carga)}
											</Td>

											<Td onClick={() => setLinhaSelecionada(item.solicitacao_carga_id)} minWidth={10}
												color={!item.data_previsao_carga ? '#bfbfbf' : (item.chegada_carga_dias > 0 || item.chegada_carga_horas > 0 || item.chegada_carga_minutos > 0 ? '#ff0000' : '#00cf1f') } title={'Status'} alignCenter small>
												<i class='fas fa-circle' style={{ fontSize: 18 }}></i>
											</Td>
											<Td onClick={() => setLinhaSelecionada(item.solicitacao_carga_id)} minWidth={90} alignCenter small>
												{item.chegada_carga_dias > 0 ? `${item.chegada_carga_dias}d` : ''} {item.chegada_carga_horas > 0 ? `${item.chegada_carga_horas}h` : ''} {item.chegada_carga_minutos > 0 ? `${item.chegada_carga_minutos}min` : ''}
											</Td>

											{this.state.filtro.internacional &&
											<Td onClick={() => setLinhaSelecionada(item.solicitacao_carga_id)} minWidth={100} alignCenter small 
												color={item.erro_entrada_aduana_origem ? '#ffffff' : '#000000'} 
												backgroundColor={item.erro_entrada_aduana_origem ? '#de0f00' : colorAduanaOrigem}>
												{DateFormat.formatarDataHoraSqlParaTela(item.data_entrada_aduana_origem)}
											</Td>}
											{this.state.filtro.internacional &&
											<Td onClick={() => setLinhaSelecionada(item.solicitacao_carga_id)} minWidth={100} alignCenter small 
												color={item.erro_saida_aduana_origem ? '#ffffff' : '#000000'} 
												backgroundColor={item.erro_saida_aduana_origem ? '#de0f00' : colorAduanaOrigem}>
												{DateFormat.formatarDataHoraSqlParaTela(item.data_liberacao_aduana_origem)}
											</Td>}
											{this.state.filtro.internacional &&
											<Td onClick={() => setLinhaSelecionada(item.solicitacao_carga_id)} minWidth={100} alignCenter small 
												color={item.erro_previsao_fronteira ? '#ffffff' : '#000000'} 
												backgroundColor={item.erro_previsao_fronteira ? '#de0f00' : colorFronteira}>
												{DateFormat.formatarDataHoraSqlParaTela(item.data_prev_fronteira)}
											</Td>}
											{this.state.filtro.internacional &&
											<Td onClick={() => setLinhaSelecionada(item.solicitacao_carga_id)} minWidth={100} alignCenter small 
												color={item.erro_chegada_fronteira ? '#ffffff' : '#000000'} 
												backgroundColor={item.erro_chegada_fronteira ? '#de0f00' : colorFronteira}>
												{DateFormat.formatarDataHoraSqlParaTela(item.data_entrada_fronteira)}
											</Td>}
											{this.state.filtro.internacional &&
											<Td onClick={() => setLinhaSelecionada(item.solicitacao_carga_id)} minWidth={100} alignCenter small 
												color={item.erro_autorizacao_fronteira ? '#ffffff' : '#000000'} 
												backgroundColor={item.erro_autorizacao_fronteira ? '#de0f00' : colorFronteira}>
												{DateFormat.formatarDataHoraSqlParaTela(item.data_autorizacao_ingresso_fronteira)}
											</Td>}
											{this.state.filtro.internacional &&
											<Td onClick={() => setLinhaSelecionada(item.solicitacao_carga_id)} minWidth={100} alignCenter small 
												color={item.erro_entrada_aduana_fronteira_origem ? '#ffffff' : '#000000'} 
												backgroundColor={item.erro_entrada_aduana_fronteira_origem ? '#de0f00' : colorFronteira}>
												{DateFormat.formatarDataHoraSqlParaTela(item.dt_entrada_aduana_front_org)}
											</Td>}
											{this.state.filtro.internacional &&
											<Td onClick={() => setLinhaSelecionada(item.solicitacao_carga_id)} minWidth={100} alignCenter small 
												color={item.erro_saida_aduana_fronteira_origem ? '#ffffff' : '#000000'} 
												backgroundColor={item.erro_saida_aduana_fronteira_origem ? '#de0f00' : colorFronteira}>
												{DateFormat.formatarDataHoraSqlParaTela(item.dt_liberacao_aduana_front_org)}
											</Td>}
											{this.state.filtro.internacional &&
											<Td onClick={() => setLinhaSelecionada(item.solicitacao_carga_id)} minWidth={100} alignCenter small 
												color={item.erro_entrada_aduana_fronteira_destino ? '#ffffff' : '#000000'} 
												backgroundColor={item.erro_entrada_aduana_fronteira_destino ? '#de0f00' : colorFronteira}>
												{DateFormat.formatarDataHoraSqlParaTela(item.dt_entrada_aduana_front_dest)}
											</Td>}
											{this.state.filtro.internacional &&
											<Td onClick={() => setLinhaSelecionada(item.solicitacao_carga_id)} minWidth={100} alignCenter small 
												color={item.erro_saida_aduana_fronteira_destino ? '#ffffff' : '#000000'} 
												backgroundColor={item.erro_saida_aduana_fronteira_destino ? '#de0f00' : colorFronteira}>
												{DateFormat.formatarDataHoraSqlParaTela(item.dt_liberacao_aduana_front_dest)}
											</Td>}
											{this.state.filtro.internacional &&
											<Td onClick={() => setLinhaSelecionada(item.solicitacao_carga_id)} minWidth={100} alignCenter small 
												color={item.erro_saida_fronteira ? '#ffffff' : '#000000'} 
												backgroundColor={item.erro_saida_fronteira ? '#de0f00' : colorFronteira}>
												{DateFormat.formatarDataHoraSqlParaTela(item.data_liberacao_fronteira)}
											</Td>}

											{this.state.filtro.internacional &&
											<Td onClick={() => setLinhaSelecionada(item.solicitacao_carga_id)} minWidth={10}
												color={!item.data_prev_fronteira ? '#bfbfbf' : (item.chegada_fronteira_dias > 0 || item.chegada_fronteira_horas > 0 || item.chegada_fronteira_minutos > 0 ? '#ff0000' : '#00cf1f') } title={'Status'} alignCenter small>
												<i class='fas fa-circle' style={{ fontSize: 18 }}></i>
											</Td>}
											{this.state.filtro.internacional &&
											<Td onClick={() => setLinhaSelecionada(item.solicitacao_carga_id)} minWidth={90} alignCenter small>
												{item.chegada_fronteira_dias > 0 ? `${item.chegada_fronteira_dias}d` : ''} {item.chegada_fronteira_horas > 0 ? `${item.chegada_fronteira_horas}h` : ''} {item.chegada_fronteira_minutos > 0 ? `${item.chegada_fronteira_minutos}min` : ''}
											</Td>}

											{this.state.filtro.internacional &&
											<Td onClick={() => setLinhaSelecionada(item.solicitacao_carga_id)} minWidth={100} alignCenter small 
												color={item.erro_entrada_aduana_destino ? '#ffffff' : '#000000'}
												backgroundColor={item.erro_entrada_aduana_destino ? '#de0f00' : colorAduanaDestino}>
												{DateFormat.formatarDataHoraSqlParaTela(item.data_entrada_aduana_destino)}
											</Td>}
											{this.state.filtro.internacional &&
											<Td onClick={() => setLinhaSelecionada(item.solicitacao_carga_id)} minWidth={100} alignCenter small 
												color={item.erro_saida_aduana_destino ? '#ffffff' : '#000000'} 
												backgroundColor={item.erro_saida_aduana_destino ? '#de0f00' : colorAduanaDestino}>
												{DateFormat.formatarDataHoraSqlParaTela(item.data_liberacao_aduana_destino)}
											</Td>}
											<Td onClick={() => setLinhaSelecionada(item.solicitacao_carga_id)} minWidth={100} alignCenter small 
												color={item.erro_previsao_descarga ? '#ffffff' : '#000000'}
												backgroundColor={item.erro_previsao_descarga ? '#de0f00' : colorCargaDescarga}>
												{DateFormat.formatarDataHoraSqlParaTela(item.data_previsao_descarga)}
											</Td>
											<Td onClick={() => setLinhaSelecionada(item.solicitacao_carga_id)} minWidth={100} alignCenter small 
												color={item.erro_chegada_descarga ? '#ffffff' : '#000000'}
												backgroundColor={item.erro_chegada_descarga ? '#de0f00' : colorCargaDescarga}>
												{DateFormat.formatarDataHoraSqlParaTela(item.data_inicio_descarga)}
											</Td>
											<Td onClick={() => setLinhaSelecionada(item.solicitacao_carga_id)} minWidth={100} alignCenter small 
												color={item.erro_saida_descarga ? '#ffffff' : '#000000'}
												backgroundColor={item.erro_saida_descarga ? '#de0f00' : colorCargaDescarga}>
												{DateFormat.formatarDataHoraSqlParaTela(item.data_termino_descarga)}
											</Td>
											
											<Td onClick={() => setLinhaSelecionada(item.solicitacao_carga_id)} minWidth={10}
												color={!item.data_previsao_descarga ? '#bfbfbf' : (item.chegada_descarga_dias > 0 || item.chegada_descarga_horas > 0 || item.chegada_descarga_minutos > 0 ? '#ff0000' : '#00cf1f') } title={'Status'} alignCenter small>
												<i class='fas fa-circle' style={{ fontSize: 18 }}></i>
											</Td>
											<Td onClick={() => setLinhaSelecionada(item.solicitacao_carga_id)} minWidth={90} alignCenter small>
												{item.chegada_descarga_dias > 0 ? `${item.chegada_descarga_dias}d` : ''} {item.chegada_descarga_horas > 0 ? `${item.chegada_descarga_horas}h` : ''} {item.chegada_descarga_minutos > 0 ? `${item.chegada_descarga_minutos}min` : ''}
											</Td>
										</Tr>
									</>
								)
							})}
						</TBody>
					</Table>
				</ContentCardBody>
			</ContentCard>
		);
	}

	controleFavoritos(idShippingCode) {
		let lista = JSON.parse(Cookies.get('shippingsFavoritos') || '[]');

		if (this.isFavorito(idShippingCode)) {
			lista = lista.filter(item => item != idShippingCode);
		} else {
			lista.push(idShippingCode);
		}

		lista = lista.filter(item => (this.props.lista || []).filter(shipping => shipping.shipping_code_id == item).length);

		this.setState({ ...this.state, listaFavoritos: lista });

		Cookies.set('shippingsFavoritos', JSON.stringify(lista), { expires: 15 });
	}

	isFavorito(idShippingCode) {
		return (this.state.listaFavoritos || []).filter(shipping => shipping == idShippingCode).length > 0;
	}
	
	modalShipping() {

		const qtdSolicitacoes = this.props.listaSolicitacoes.filter(item => item.shipping_code_id == this.state.registroModal.shipping_code_id).length;
		let mostrarColunaSolicitacao = false;
		this.props.listaPrevisoes.filter(item => item.shipping_code_id == this.state.registroModal.shipping_code_id).map(item => {
			if ((item.solicitacoes || []).filter(sol => sol).length != qtdSolicitacoes) {
				mostrarColunaSolicitacao = true;
			}
		});

		let solicitacao = this.props.listaSolicitacoes.filter(item => item.shipping_code_id == this.state.registroModal.shipping_code_id)[0] || {};

        return (
			<div class='modal fade show' id='modal-xl' style={{ display: 'block' }} aria-modal='true'>
				<div class='modal-dialog modal-xl'>
					<div class='modal-content'>
						<div class='modal-header' style={{ backgroundColor: '#1F3066', color: '#fff' }}>
							<h4 class='modal-title'>Shipping Code: {this.state.registroModal.shipping_code_id} <i class={this.isFavorito(this.state.registroModal.shipping_code_id) ? 'fas fa-star' : 'far fa-star'} title={this.isFavorito(this.state.registroModal.shipping_code_id) ? 'Remover dos favoritos' : 'Adicionar aos favoritos'} onClick={() => this.controleFavoritos(this.state.registroModal.shipping_code_id)} style={{ marginLeft: 8, cursor: 'pointer' }}></i></h4>
							<button type='button' class='close' data-dismiss='modal' aria-label='Close' onClick={() => this.setState({ ...this.state, registroModal: null })}>
								<span aria-hidden='true' style={{ color: '#fff', fontSize: 32 }}>×</span>
							</button>
						</div>
						<div class='modal-body' style={{ height: window.innerHeight * 0.8, overflowY: 'scroll' }}>

							{/*<h6>Previsões</h6>

							<Table responsive>
								<THead>
									<Tr>
										{mostrarColunaSolicitacao &&
										<Th width={100} alignCenter>Solicitações</Th>}
										<Th width={100} alignCenter>Nome</Th>
										<Th width={100} alignCenter>Data Hora</Th>
									</Tr>
								</THead>
								<TBody>
									{this.props.listaPrevisoes.filter(item => item.shipping_code_id == this.state.registroModal.shipping_code_id).map(item => (
										<Tr key={item.solicitacoes}>
											{mostrarColunaSolicitacao &&
											<Td alignCenter small>{(item.solicitacoes || []).filter(sol => sol).join(', ')}</Td>}
											<Td alignCenter small>{item.nome || ''}</Td>
											<Td alignCenter small>{DateFormat.formatarDataHoraSqlParaTela(item.datahora)}</Td>
										</Tr>
									))}
								</TBody>
							</Table>

							<div style={{ width: '100%', height: 2, backgroundColor: '#1F3066', marginTop: 12, marginBottom: 12 }}></div>

							<h6>Eventos</h6>

							<Table responsive>
								<THead>
									<Tr>
										{mostrarColunaSolicitacao &&
										<Th width={100} alignCenter>Solicitações</Th>}
										<Th width={100} alignCenter>Nome</Th>
										<Th width={100} alignCenter>Data Hora</Th>
									</Tr>
								</THead>
								<TBody>
									{this.props.listaEventos.filter(item => item.shipping_code_id == this.state.registroModal.shipping_code_id).map(item => (
										<Tr key={item.solicitacoes}>
											{mostrarColunaSolicitacao &&
											<Td alignCenter small>{(item.solicitacoes || []).filter(sol => sol).join(', ')}</Td>}
											<Td alignCenter small>{item.nome || ''}</Td>
											<Td alignCenter small>{DateFormat.formatarDataHoraSqlParaTela(item.datahora)}</Td>
										</Tr>
									))}
								</TBody>
							</Table>*/}

							<h6>Eventos</h6>

							<Table responsive>
								<THead>
									<Tr>
										<Th width={200} alignLeft>Evento</Th>
										<Th width={300} alignCenter>Referência</Th>
										<Th width={100} alignCenter>Previsão</Th>
										<Th width={100} alignCenter>Realizado</Th>
									</Tr>
								</THead>
								<TBody>
									{this.props.listaPrevisoesEventos.filter(item => item.shipping_code_id == this.state.registroModal.shipping_code_id)
									.filter(item => {
										if (solicitacao.pais_origem_shipping == 'Brasil' && solicitacao.pais_destino_shipping == 'Brasil') {
											return item.nacional;
										} else {
											return true;
										}
									}).map(item => {
										return (
											<Tr key={item.solicitacoes}>
												<Td width={200} alignLeft small>{item.nome || ''}</Td>
												<Td width={300} alignCenter small>{item.referencia || ''}</Td>
												<Td width={100} alignCenter small>{DateFormat.formatarDataHoraSqlParaTela(item.datahora_previsao)}</Td>
												<Td width={100} alignCenter small>{DateFormat.formatarDataHoraSqlParaTela(item.datahora_realizado)}</Td>
											</Tr>
										);
									})}
								</TBody>
							</Table>

							<div style={{ width: '100%', height: 2, backgroundColor: '#1F3066', marginTop: 12, marginBottom: 12 }}></div>
							
							<h6>Ocorrências</h6>

							<Table responsive>
								<THead>
									<Tr>
										<Th alignCenter>Tipo</Th>
										<Th alignCenter>Responsável</Th>
										<Th alignCenter>Início</Th>
										<Th alignCenter>Fim</Th>
										<Th alignCenter>Descrição</Th>
										<Th alignCenter>Usuário</Th>
									</Tr>
								</THead>
								<TBody>
									{this.props.listaOcorrencias.filter(item => item.shipping_code_id == this.state.registroModal.shipping_code_id).map(item => (
										<Tr key={item.id_ocorrencia}>
											<Td alignCenter small>{item.descricao_tipo_ocorrencia || ''}</Td>
											<Td alignCenter small>{item.responsabilidade || ''}</Td>
											<Td alignCenter small>{item.data_inicio || ''}</Td>
											<Td alignCenter small>{item.data_termino || ''}</Td>
											<Td alignCenter small>{item.comentario || ''}</Td>
											<Td alignCenter small>{item.usuario_inclusao || ''}</Td>
										</Tr>
									))}
								</TBody>
							</Table>

							<div style={{ width: '100%', height: 2, backgroundColor: '#1F3066', marginTop: 12, marginBottom: 12 }}></div>

							<h6>Placas</h6>

							<Table responsive>
								<THead>
									<Tr>
										<Th alignCenter>Cavalo</Th>
										<Th alignCenter>Carreta</Th>
										<Th alignCenter>Motorista</Th>
										<Th alignCenter>Local</Th>
										<Th alignCenter>Latitude/Longitude</Th>
										<Th alignCenter>Status</Th>
									</Tr>
								</THead>
								<TBody>
									{this.props.listaSolicitacoes.filter(item => item.shipping_code_id == this.state.registroModal.shipping_code_id).map(item => (
										<Tr key={item.solicitacao_carga_id}>
											<Td alignCenter small>{item.cavalo || ''}</Td>
											<Td alignCenter small>{item.carreta || ''}</Td>
											<Td alignCenter small>{item.motorista || ''}</Td>
											<Td alignCenter small>{item.veiculo_referencia || ''}</Td>
											<Td alignCenter small>
												{(item.veiculo_latitude && item.veiculo_longitude) &&
												<a target='blank' href={`https://www.google.com/maps/place/${item.veiculo_latitude},${item.veiculo_longitude}`}>
													{item.veiculo_latitude}, {item.veiculo_longitude}
												</a>}
											</Td>
											<Td alignCenter small>{item.veiculo_status || ''}</Td>
										</Tr>
									))}
								</TBody>
							</Table>

							<Grid cols="12 12 12 12">
								<div style={{ width: "100%", height: 500 }}>
									<MapLeaflet
										coordenadas={this.props.listaSolicitacoes.filter(item => item.shipping_code_id == this.state.registroModal.shipping_code_id && item.veiculo_latitude && item.veiculo_longitude)
											.map(item => ({
												id: item.solicitacao_carga_id,
												titulo: item.cavalo,
												latitude: item.veiculo_latitude,
												longitude: item.veiculo_longitude,
												direcao: this.state.registroModal.rumo == 'RS' ? 'S' : 'N'
											}))}
										zoom={4}
										latitude={'-28.381875'}
										longitude={'-53.937355'}
										onChange={(coordenadas) => {
										}}
									/>
								</div>
							</Grid>

						</div>
						{/*<div class='modal-footer justify-content-between'>
							<button type='button' class='btn btn-default' data-dismiss='modal' style={{ visibility: 'hidden' }}>Close</button>
							<button type='button' class='btn btn-primary'
								onClick={() => this.setState({ ...this.state, registroModal: null })}>Fechar</button>
						</div>*/}
					</div>
				</div>
			</div>
        );
    }

}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	usuarioCarregado: state.auth.usuarioCarregado,
	modoTela: state.reportsConformidade.modoTela,
	aguardando: state.reportsConformidade.aguardando,
	lista: state.reportsConformidade.lista,
	listaFronteiras: state.reportsConformidade.listaFronteiras,
	listaNegociadores: state.reportsConformidade.listaNegociadores,
	listaRumos: state.reportsConformidade.listaRumos,
	listaFarolAtraso: state.reportsConformidade.listaFarolAtraso,
	listaOcorrencias: state.reportsConformidade.listaOcorrencias,
	listaSolicitacoes: state.reportsConformidade.listaSolicitacoes,
	listaPrevisoes: state.reportsConformidade.listaPrevisoes,
	listaEventos: state.reportsConformidade.listaEventos,
	listaPrevisoesEventos: state.reportsConformidade.listaPrevisoesEventos,
	listaOperacoes: state.reportsConformidade.listaOperacoes,
	filtro: state.reportsConformidade.filtro
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, setFiltro, getListaFronteiras, getListaNegociadores, getListaOperacoes, getLista
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ReportsConformidade);
