import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import DateFormat from '../common/dateFormat/DateFormat';
import FormatUtils from '../common/formatUtils/FormatUtils';
export function setModoTela(modo, registro = {}) {
    return {
        type: 'ACESSORIO_INVENTARIO_MODO_TELA',
        payload: {
			modo: modo,
			registro: registro
		}
    };
}

export function setAguardando(aguardando) {
    return {
        type: 'ACESSORIO_INVENTARIO_SET_AGUARDANDO',
        payload: aguardando
    };
}

export function setFiltro(filtro) {
    return {
        type: 'ACESSORIO_INVENTARIO_FILTRO',
        payload: {
			filtro: filtro
		}
    };
}

export function initForm(registro = {}) {
    return [
        initialize('acessorioInventarioForm', registro)
    ];
}

export function getLista() {
	return (dispatch, getState) => {
		dispatch(setAguardando(true));
    	axios.get(`${consts.API_URL}/acessorioInventario?id_cliente=${getState().auth.usuario.id_cliente || 0}
				&data_inicial=${DateFormat.formatarDataTelaParaSql(getState().acessorioInventario.filtro.data_inicial) == 'Invalid DateTime' ? null : DateFormat.formatarDataTelaParaSql(getState().acessorioInventario.filtro.data_inicial)}
				&data_final=${DateFormat.formatarDataTelaParaSql(getState().acessorioInventario.filtro.data_final) == 'Invalid DateTime' ? null : DateFormat.formatarDataTelaParaSql(getState().acessorioInventario.filtro.data_final)}`)
		.then(resp => {
			dispatch({
		        type: 'ACESSORIO_INVENTARIO_LISTADO',
		        payload: resp
		    });
			dispatch(setAguardando(false));
		}).catch(e => {
			setErroAPI(e);
			dispatch(setAguardando(false));
		});
	}
}

export function salvar(registro) {
		
    return (dispatch, getState) => {
		if (!registro.id) {
	        axios.post(`${consts.API_URL}/acessorioInventario`, {
				...registro,
				id_usuario_inclusao: getState().auth.usuario.id,
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(getListaItem(resp.data.id));
				dispatch(initForm({ ...resp.data }));
			}).catch(e => {
				setErroAPI(e);
			});
		} else {
			axios.put(`${consts.API_URL}/acessorioInventario`, {
				...registro,
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		}
    };
}

export function excluir(registro) {
    return (dispatch, getState) => {
        axios.delete(`${consts.API_URL}/acessorioInventario?id=${registro.id}&id_usuario_alteracao=${getState().auth.usuario.id}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista());
			dispatch(setModoTela('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function getListaUnidade() {
    const request = axios.get(`${consts.API_URL}/unidade/listarSelect`);
    return {
        type: 'ACESSORIO_INVENTARIO_UNIDADE_SELECT_LISTADO',
        payload: request
    };
}

//Itens

export function getListaItem(idInventario) {
	return (dispatch, getState) => {
		axios.get(`${consts.API_URL}/acessorioInventarioItem?id_inventario=${idInventario}`)
		.then(resp => {
			dispatch({
		        type: 'ACESSORIO_INVENTARIO_ITEM_LISTADO',
		        payload: resp
		    });
		}).catch(e => {
			setErroAPI(e);
		});
	}
}

export function atualizarListaItem(lista) {
	return (dispatch, getState) => {
		dispatch({
		    type: 'ACESSORIO_INVENTARIO_ITEM_LISTADO',
		    payload: { data: lista }
		});
	}
}


export function salvarItem(registro, lista) {
		
    return (dispatch, getState) => {
		axios.put(`${consts.API_URL}/acessorioInventarioItem`, {
			...registro,
			quantidade_real: FormatUtils.formatarValorBanco(registro.quantidade_real, 0),
			id_usuario_alteracao: getState().auth.usuario.id
		}).then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			
			dispatch(atualizarListaItem(lista.map(itemAux => {
				if (itemAux.id == registro.id) {
					return {
						...itemAux,
						alterando: false
					};
				} else {
					return itemAux;
				}
			})));
			dispatch(getLista());
			
		}).catch(e => {
			setErroAPI(e);
		});
    };
}