import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import Form from './documentoViagemForm';
import ManualForm from './documentoViagemManualForm';
import DateFormat from '../common/dateFormat/DateFormat';
import LabelAndCheckbox from '../common/form/labelAndCheckbox';
import Aguardando from '../common/template/aguardando';
import Select from '../common/form/select';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import ReactExport from 'react-export-excel-fixed-xlsx';

import {
	setModoTela, setFiltroAberta as setFiltro, initForm, salvar, alterarManual, excluir, getListaAberta as getLista, setEncerrada,
	setModoTelaComprovante, initFormComprovante, salvarComprovante, excluirComprovante, getListaComprovante, getListaHistorico,
	getListaOcorrencia, setModoTelaOcorrencia, getListaPalete, getListaPaleteStatus,
	getListaOperacao, getListaDocumento, getListaStatus, getListaCliente
} from './documentoViagemActions';
import ColorUtils from '../common/colorUtils/ColorUtils';

class DocumentoViagem extends Component {

	state = {
		pesquisar: '',
		pesquisarAux: '',
		linhaSelecionada: null
	}

    componentWillMount() {
        this.props.setModoTela('lista');
        this.props.getLista();
		this.props.getListaOperacao();
		this.props.getListaDocumento();
		this.props.getListaStatus();
		this.props.getListaCliente();
		this.props.getListaPaleteStatus();
    }

    render() {
        return (
            <div>
                <Content>

					<Aguardando aguardando={this.props.aguardando} />

					{this.props.modoTela == 'visualizacao' ? (
						<Form onSubmit={() => {}} visualizacao />
					) : null}
					{this.props.modoTela == 'cadastro' ? (
						<Form onSubmit={this.props.salvar} />
					) : null}
					{this.props.modoTela == 'cadastroManual' ? (
						<ManualForm onSubmit={this.props.alterarManual} />
					) : null}
					{this.props.modoTela == 'exclusao' ? (
						<Form excluir onSubmit={this.props.excluir} />
					) : null}
					{this.props.modoTela == 'lista' ? this.lista() : null}
                </Content>
            </div>
        )
    }

	lista() {

		let alterar = this.props.usuarioCarregado && this.props.usuarioCarregado.acesso_comprovante_aberta_alterar;

		const ExcelFile = ReactExport.ExcelFile;
		const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
		const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

		let lista = (this.props.lista).filter(item => {

			let pesquisar = (this.state.pesquisar || '').trim() 
				? (this.state.pesquisar || '').split(',').map(pesquisa => pesquisa.trim()).filter(pesquisa => pesquisa) 
				: [];

			if (pesquisar.length 
				&& !pesquisar.filter(pesquisa => 
					(`${item.solicitacao || ''} ${item.romaneio || ''} ${DateFormat.formatarDataHoraSqlParaTela(item.datahora_viagem)} ${DateFormat.formatarDataHoraSqlParaTela(item.datahora_descarga)}
					${item.tipo_documento || ''} ${item.numero_documento || ''} ${item.mic_documento || ''} ${item.shipping || ''} ${item.cavalo || ''} ${item.nome_motorista || ''}
					${item.nome_cliente || ''} ${item.nome_recebedor || ''} ${item.cidade_coleta || ''} ${item.estado_coleta || ''} ${item.pais_coleta || ''} ${item.cidade_entrega || ''}
					${item.estado_entrega || ''} ${item.pais_entrega || ''} ${item.nome_operacao || ''} ${item.nome_perfil || ''} ${item.tipo_veiculo || ''}
					`).toUpperCase().includes(pesquisa.toUpperCase())
			).length) {
				return false;
			}
			
			if ((this.props.filtro.anexos == 2 && item.qtd_digital_selecionado <= 0) 
				|| (this.props.filtro.anexos == 3 && item.qtd_digital_selecionado > 0)) {
				return false;
			}

			if ((this.props.filtro.faturado == 2 && !item.faturado) 
				|| (this.props.filtro.faturado == 3 && item.faturado)) {
				return false;
			}

			return true;

		}).filter(item => {

			let retorno = false;
			switch (String(this.props.filtro.id_status_comprovante)) {
				case '1': //Todos
					retorno = true;
					break;
				
				case '2': //Aguardando Original
					retorno = item.aguardando_original;
					break;
					
				case '3': //Aguardando Digital
					retorno = item.aguardando_digital;
					break;
					
				case '4': //Aguardando Original ou Digital
					retorno = item.aguardando_original || item.aguardando_digital;
					break;
					
				case '5': //Aguardando Original e Digital
					retorno = item.aguardando_original && item.aguardando_digital;
					break;
					
				case '6': //Aguardando Financeiro
					retorno = item.aguardando_financeiro;
					break;
					
				case '7': //Paletes em Aberto
					retorno = this.props.listaPaleteStatus.filter(itemPalete => itemPalete.solicitacao == item.solicitacao && itemPalete.aberta).length > 0;
					break;
					
				case '8': //Paletes Finalizados
					retorno = this.props.listaPaleteStatus.filter(itemPalete => itemPalete.solicitacao == item.solicitacao && !itemPalete.aberta).length > 0
					break;
			
				default:
					retorno = true;
					break;
			}

			return retorno;

		});

		return (
			<ContentCard>

				{this.state.documentoViagem ? this.modalData() : null}

				<ContentCardHeader>
					<Row>
						{/*alterar ? (
							<Grid cols='6 6 4 2'>
								<Button
									text='Adicionar'
									type={'success'}
									icon={'fa fa-plus'}
									event={() => {
										this.props.setModoTela('cadastro', {
											datahora_viagem: DateFormat.formatarDataHoraSqlParaTela(DateFormat.getDataHoraAtual())
										});
										this.props.initForm({
											datahora_viagem: DateFormat.formatarDataHoraSqlParaTela(DateFormat.getDataHoraAtual())
										});
										this.props.getListaComprovante(0);
									}} />
							</Grid>
						) : null*/}
						<Grid cols='6 6 3 2'>
							<ExcelFile
								filename={'Viagem Aberta'}
								element={
									<Button
										text='Exportar'
										type={'primary'}
										icon={'fa fa-plus'} />
								}>
								<ExcelSheet data={lista.map(item => ({
									...item,
									datahora_viagem: DateFormat.formatarDataHoraSqlParaTela(item.datahora_viagem),
									datahora_descarga: DateFormat.formatarDataHoraSqlParaTela(item.datahora_descarga),
									local_coleta: `${item.cidade_coleta} - ${item.estado_coleta} - ${item.pais_coleta}`,
									local_entrega: `${item.cidade_entrega} - ${item.estado_entrega} - ${item.pais_entrega}`
								}))} name='Premiação'>
									<ExcelColumn label='Sol.' value='solicitacao' />
									<ExcelColumn label='Rom.' value='romaneio' />
									<ExcelColumn label='Emissão' value='datahora_viagem' />
									<ExcelColumn label='Descarga' value='datahora_descarga' />
									<ExcelColumn label='Tipo Doc' value='tipo_documento' />
									<ExcelColumn label='Documento' value='numero_documento' />
									<ExcelColumn label='MIC' value='mic_documento' />
									<ExcelColumn label='Shipping' value='shipping' />
									<ExcelColumn label='Veículo' value='cavalo' />
									<ExcelColumn label='Motorista Entrega' value='nome_motorista' />
									<ExcelColumn label='Tipo' value='tipo_veiculo' />
									<ExcelColumn label='Cliente' value='nome_cliente' />
									<ExcelColumn label='Recebedor' value='nome_recebedor' />
									<ExcelColumn label='Local Coleta' value='local_coleta' />
									<ExcelColumn label='Local Entrega' value='local_entrega' />
									<ExcelColumn label='Operação' value='nome_operacao' />
									<ExcelColumn label='Perfil' value='nome_perfil' />
								</ExcelSheet>
							</ExcelFile>
						</Grid>
						<Grid cols='6 6 4 2'>
							<Button
								text='Atualizar'
								type={'primary'}
								icon={'fa fa-plus'}
								event={() => {
									this.props.getLista();
									this.props.getListaPaleteStatus();
									this.setState({ ...this.state, pesquisar: this.state.pesquisarAux });
								}} />
						</Grid>
					</Row>
				</ContentCardHeader>
				<ContentCardBody>
					<Row>
						<LabelAndInputMask
							label='Data/Hora Inicial Carga' placeholder='Informe o período inicial emissão'
							cols='12 6 3 2'
							mask='99/99/9999'
							value={this.props.filtro.data_inicial_emissao}
							onChange={(data) => {
								this.props.setFiltro({ ...this.props.filtro, data_inicial_emissao: data.target.value });
							}} />

						<LabelAndInputMask
							label='Data/Hora Final Carga' placeholder='Informe o período final emissão'
							cols='12 6 3 2'
							mask='99/99/9999'
							value={this.props.filtro.data_final_emissao}
							onChange={(data) => {
								this.props.setFiltro({ ...this.props.filtro, data_final_emissao: data.target.value });
							}} />

						<LabelAndInputMask
							label='Data/Hora Inicial Entrega' placeholder='Informe o período inicial carga'
							cols='12 6 3 2'
							mask='99/99/9999'
							value={this.props.filtro.data_inicial_carga}
							onChange={(data) => {
								this.props.setFiltro({ ...this.props.filtro, data_inicial_carga: data.target.value });
							}} />

						<LabelAndInputMask
							label='Data/Hora Final Entrega' placeholder='Informe o período final carga'
							cols='12 6 3 2'
							mask='99/99/9999'
							value={this.props.filtro.data_final_carga}
							onChange={(data) => {
								this.props.setFiltro({ ...this.props.filtro, data_final_carga: data.target.value });
							}} />

					</Row>

					<Row>
						<Select
							options={this.props.listaOperacao}
							label='Operação'
							cols='12 3 3 3 '
							placeholder='Selecione a operação'
							value={this.props.filtro.id_operacao}
							onChange={data => {
								this.props.setFiltro({ ...this.props.filtro, id_operacao: data });
							}} />

						<Select
							options={this.props.listaStatusComprovante}
							label='Status'
							cols='12 3 3 3 '
							placeholder='Selecione o status'
							value={this.props.filtro.id_status_comprovante}
							onChange={data => {
								this.props.setFiltro({ ...this.props.filtro, id_status_comprovante: data });
							}} />
							
						<Select
							options={[{ id: 1, valor: 'Todos' }, { id: 2, valor: 'Apenas com anexo' }, { id: 3, valor: 'Apenas sem anexo' }]}
							label='Anexos'
							cols='12 3 3 3 '
							placeholder='Selecione'
							value={this.props.filtro.anexos}
							onChange={data => {
								this.props.setFiltro({ ...this.props.filtro, anexos: data });
							}} />

						<Select
							options={[{ id: 1, valor: 'Todos' }, { id: 2, valor: 'Apenas faturados' }, { id: 3, valor: 'Apenas não faturados' }]}
							label='Faturado'
							cols='12 3 3 3 '
							placeholder='Selecione'
							value={this.props.filtro.faturado}
							onChange={data => {
								this.props.setFiltro({ ...this.props.filtro, faturado: data });
							}} />
					</Row>

					<input className='form-control'
						 placeholder='Pesquisar (motorista, placa, shipping, CRT, CTE, NFS-e, NF, MIC)'
						 type='text'
						 value={this.state.pesquisarAux}
						 onChange={e => {
							 this.setState({ ...this.state, pesquisarAux: e.target.value });
						 }} />

					<Table responsive tableBordered>
						<THead>
							<Tr>
								<Th alignCenter width={90}></Th>
								<Th alignCenter width={80}></Th>
								<Th alignCenter width={10}>Sol.</Th>
								<Th alignCenter width={10}>Rom.</Th>
								<Th alignCenter width={100}>Emissão</Th>
								<Th alignCenter width={100}>Descarga</Th>
								<Th alignCenter width={10}>Tipo Doc</Th>
								<Th alignCenter width={10}>Documento</Th>
								<Th alignCenter width={10}>MIC</Th>
								<Th alignCenter width={10}>Shipping</Th>
								<Th alignCenter width={10}>Veículo</Th>
								<Th alignCenter width={100}>Motorista Entrega</Th>
								<Th alignCenter width={10}>Tipo</Th>
								<Th alignCenter width={100}>Cliente</Th>
								<Th alignCenter width={100}>Recebedor</Th>
								<Th alignCenter width={100}>Local Coleta</Th>
								<Th alignCenter width={100}>Local Entrega</Th>
								<Th alignCenter width={100}>Operação</Th>
							</Tr>
						</THead>
						<TBody>
							{lista.map((item, i) => (
								<Tr key={i} 									
									className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`} >
									<Td alignCenter minWidth={90}>
										{alterar ? (
											<ButtonTable
												type={'warning'}
												icon={'fas fa-pencil-alt'}
												disabled={!item.ativo}
												visible={true || this.state.linhaSelecionada == item.id}
												title='Editar'
												small
												event={() => {
													this.props.setModoTela('cadastro', {
														...item,
														datahora_viagem: DateFormat.formatarDataHoraSqlParaTela(item.datahora_viagem),
														datahora_finalizacao: DateFormat.formatarDataHoraSqlParaTela(item.datahora_finalizacao),
														datahora_carga: DateFormat.formatarDataHoraSqlParaTela(item.datahora_carga),
														datahora_descarga: DateFormat.formatarDataHoraSqlParaTela(item.datahora_descarga)
													});
													this.props.initForm({
														...item,
														datahora_viagem: DateFormat.formatarDataHoraSqlParaTela(item.datahora_viagem),
														datahora_finalizacao: DateFormat.formatarDataHoraSqlParaTela(item.datahora_finalizacao),
														datahora_carga: DateFormat.formatarDataHoraSqlParaTela(item.datahora_carga),
														datahora_descarga: DateFormat.formatarDataHoraSqlParaTela(item.datahora_descarga)
													});

													this.props.getListaHistorico(item.id);
													this.props.getListaComprovante(item.id);
													this.props.setModoTelaComprovante('lista');
													this.props.getListaOcorrencia(item.id);
													this.props.getListaPalete(item.solicitacao);
													this.props.setModoTelaOcorrencia('lista');
												}} />
										) : (
											<ButtonTable
												type={'primary'}
												icon={'fas fa-eye'}
												visible={true || this.state.linhaSelecionada == item.id}
												title='Visualizar'
												small
												event={() => {
													this.props.setModoTela('visualizacao', {
														...item,
														datahora_viagem: DateFormat.formatarDataHoraSqlParaTela(item.datahora_viagem),
														datahora_finalizacao: DateFormat.formatarDataHoraSqlParaTela(item.datahora_finalizacao),
														datahora_carga: DateFormat.formatarDataHoraSqlParaTela(item.datahora_carga),
														datahora_descarga: DateFormat.formatarDataHoraSqlParaTela(item.datahora_descarga)
													});
													this.props.initForm({
														...item,
														datahora_viagem: DateFormat.formatarDataHoraSqlParaTela(item.datahora_viagem),
														datahora_finalizacao: DateFormat.formatarDataHoraSqlParaTela(item.datahora_finalizacao),
														datahora_carga: DateFormat.formatarDataHoraSqlParaTela(item.datahora_carga),
														datahora_descarga: DateFormat.formatarDataHoraSqlParaTela(item.datahora_descarga)
													});

													this.props.getListaHistorico(item.id);
													this.props.getListaComprovante(item.id);
													this.props.setModoTelaComprovante('lista');
													this.props.getListaOcorrencia(item.id);
													this.props.getListaPalete(item.solicitacao);
													this.props.setModoTelaOcorrencia('lista');
												}} />
										)}
										{alterar ? (
											<ButtonTable
												type={'danger'}
												icon={'fas fa-trash-alt'}
												visible={true || this.state.linhaSelecionada == item.id}
												disabled={item.id_status != 1}
												title='Cancelar'
												small
												event={() => {
													this.props.setModoTela('exclusao', {
														...item,
														datahora_viagem: DateFormat.formatarDataHoraSqlParaTela(item.datahora_viagem),
														datahora_finalizacao: DateFormat.formatarDataHoraSqlParaTela(item.datahora_finalizacao),
														datahora_carga: DateFormat.formatarDataHoraSqlParaTela(item.datahora_carga),
														datahora_descarga: DateFormat.formatarDataHoraSqlParaTela(item.datahora_descarga)
													});
													this.props.initForm({
														...item,
														datahora_viagem: DateFormat.formatarDataHoraSqlParaTela(item.datahora_viagem),
														datahora_finalizacao: DateFormat.formatarDataHoraSqlParaTela(item.datahora_finalizacao),
														datahora_carga: DateFormat.formatarDataHoraSqlParaTela(item.datahora_carga),
														datahora_descarga: DateFormat.formatarDataHoraSqlParaTela(item.datahora_descarga)
													});

													this.props.getListaHistorico(item.id);
													this.props.getListaComprovante(item.id);
													this.props.setModoTelaComprovante('lista');
													this.props.getListaOcorrencia(item.id);
													this.props.getListaPalete(item.solicitacao);
													this.props.setModoTelaOcorrencia('lista');
												}} />
										) : null}
										{alterar ? (
											<ButtonTable
												type={'success'}
												icon={`fas fa-${item.id_status == 1 ? 'check' : 'undo'}`}
												visible={true || this.state.linhaSelecionada == item.id}
												title={item.id_status == 1 ? 'Encerrar viagem' : 'Reabrir viagem'}
												small
												event={() => {
													// this.props.setEncerrada(item, true);
													this.setState({
														...this.state,
														documentoViagem: {
															...item,
															datahora_descarga: DateFormat.formatarDataHoraSqlParaTela(item.datahora_descarga ? item.datahora_descarga : DateFormat.getDataHoraAtual())
														}
													});
												}} />
										) : null}
									</Td>
									<Td alignCenter minWidth={80}>
										{item.ocorrencia_aberta ? (
											<i class='fas fa-times-circle' style={{ color: '#ed0707', fontSize: 16, paddingTop: 4, paddingLeft: 4 }} 
												title={`Existe ocorrência aberta`}>
											</i>
										) : (item.ocorrencia_finalizada ? (
											<i class='fas fa-times-circle' style={{ color: '#a1a1a1', fontSize: 16, paddingTop: 4, paddingLeft: 4 }} 
												title={`Existe ocorrência finalizada`}>
											</i>
										) : null)}
										{item.excecao &&
										<i class='fas fa-exclamation-circle' style={{ color: '#c49b04', fontSize: 16, paddingTop: 4, paddingLeft: 4 }}
											title={item.excecao ? `Exceção: ${item.excecao}` : ''}>
										</i>}
										{item.qtd_digital_selecionado > 0 &&
										<i class='fas fa-paperclip' style={{ marginLeft: 4, paddingTop: 4, fontSize: 16 }} title={'Digital anexado'} />}
										{item.faturado &&
										<i class='fas fa-dollar-sign' style={{ marginLeft: 4, paddingTop: 4, fontSize: 16 }} title={'Documento faturado'} />}
									</Td>
									<Td alignCenter minWidth={10} small>{item.solicitacao}</Td>
									<Td alignCenter minWidth={10}  small>{item.romaneio}</Td>
									<Td alignCenter minWidth={100} small>{DateFormat.formatarDataHoraSqlParaTela(item.datahora_viagem)}</Td>
									<Td alignCenter minWidth={100} small>{DateFormat.formatarDataHoraSqlParaTela(item.datahora_descarga)}</Td>
									<Td alignCenter minWidth={10} small>{item.tipo_documento}</Td>
									<Td alignCenter minWidth={10} small>
										{item.numero_documento}
									</Td>
									<Td alignCenter minWidth={10} small>{item.mic_documento}</Td>
									<Td alignCenter minWidth={10} small>{item.shipping}</Td>
									<Td alignCenter minWidth={10} small>{item.cavalo}</Td>
									<Td alignCenter minWidth={100} noWrap small>{item.nome_motorista}</Td>
									<Td alignCenter minWidth={10} small>{item.tipo_veiculo}</Td>
									<Td alignCenter minWidth={100} noWrap small>{item.nome_cliente}</Td>
									<Td alignCenter minWidth={100} noWrap small>{item.nome_recebedor}</Td>
									<Td alignCenter minWidth={100} noWrap small>{item.cidade_coleta} - {item.estado_coleta} - {item.pais_coleta}</Td>
									<Td alignCenter minWidth={100} noWrap small>{item.cidade_entrega} - {item.estado_entrega} - {item.pais_entrega}</Td>
									<Td alignCenter minWidth={100} noWrap small backgroundColor={item.cor_perfil} color={ColorUtils.getCorFonte(item.cor_perfil)}>{`${item.nome_operacao }${item.nome_perfil ? ' - ' : ''}${item.nome_perfil || ''}`}</Td>
								</Tr>
							))}
						</TBody>
					</Table>
				</ContentCardBody>
			</ContentCard>
		);
	}

	modalData() {

        return (
			<div class='modal fade show' id='modal-md' style={{ display: 'block' }} aria-modal='true'>
				<div class='modal-dialog modal-md'>
					<div class='modal-content'>
						<div class='modal-header'>
							<h6 class='modal-title'>Informe a data e hora da descarga:</h6>
							<button type='button' class='close' data-dismiss='modal' aria-label='Close' onClick={() => this.setState({ ...this.state, documentoViagem: null })}>
								<span aria-hidden='true'>×</span>
							</button>
						</div>
						<div class='modal-body'>

							<LabelAndInputMask
								label='Data/Hora' placeholder='Informe a data e hora'
								cols='12 12 12 12'
								mask='99/99/9999 99:99'
								value={this.state.documentoViagem.datahora_descarga}
								onChange={data => {
									this.setState({
										...this.state,
										documentoViagem: {
											...this.state.documentoViagem,
											datahora_descarga: data.target.value
										}
									});
								}} />
							
						</div>
						<div class='modal-footer justify-content-between'>
							<button type='button' class='btn btn-default' data-dismiss='modal' style={{ visibility: 'hidden' }}>Close</button>
							<button type='button' class='btn btn-primary'
								disabled={!this.state.documentoViagem.datahora_descarga}
								onClick={() => {
									this.props.setEncerrada({
										...this.state.documentoViagem,
										datahora_descarga: DateFormat.formatarDataHoraTelaParaSql(this.state.documentoViagem.datahora_descarga)
									}, true);
									this.setState({ ...this.state, documentoViagem: null });
								}}>Encerrar</button>
						</div>
					</div>
				</div>
			</div>
        );
    }
}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	usuarioCarregado: state.auth.usuarioCarregado,
	aguardando: state.documentoViagem.aguardando,
	modoTela: state.documentoViagem.modoTela,
	filtro: state.documentoViagem.filtroAberta,
	lista: state.documentoViagem.listaAberta,
	listaComprovante: state.documentoViagem.listaComprovante,
	listaOperacao: state.documentoViagem.listaOperacao,
	listaDocumento: state.documentoViagem.listaDocumento,
	listaStatus: state.documentoViagem.listaStatus,
	listaStatusComprovante: state.documentoViagem.listaStatusComprovante,
	listaPaleteStatus: state.documentoViagem.listaPaleteStatus
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, setFiltro, initForm, salvar, alterarManual, excluir, getLista, setEncerrada,
	setModoTelaComprovante, initFormComprovante, salvarComprovante, excluirComprovante, getListaComprovante, getListaHistorico,
	getListaOcorrencia, setModoTelaOcorrencia, getListaPalete, getListaPaleteStatus,
	getListaOperacao, getListaDocumento, getListaStatus, getListaCliente
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(DocumentoViagem);
