import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import Aguardando from '../common/template/aguardando';

import { setModoTela, getLista, getListaLog } from './reportsIntegracaoGMActions';
import DateFormat from '../common/dateFormat/DateFormat';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndTextarea from '../common/form/labelAndTextarea';
import Select from '../common/form/select';
import LabelAndInputMask from '../common/form/labelAndInputMask';

class ReportsIntegracaoGM extends Component {

	state = {
		listaEventos: [
			{ id: 'Shipment Inclusão', valor: 'Shipment Inclusão' },
			{ id: 'Shipment Alteração', valor: 'Shipment Alteração' },
			{ id: 'Evento - XB', valor: 'Evento - XB (Engate)' },
			{ id: 'Evento - XX', valor: 'Evento - XX (Desengate)' },
			{ id: 'Evento - X3', valor: 'Evento - X3 (Chegada na Carga)' },
			{ id: 'Evento - CP', valor: 'Evento - CP (Fim de Carga)' },
			{ id: 'Evento - AF', valor: 'Evento - AF (Saída da Carga)' },
			{ id: 'Evento - X1', valor: 'Evento - X1 (Chegada na Descarga)' },
			{ id: 'Evento - D1', valor: 'Evento - D1 (Fim de Descarga)' },
			{ id: 'Evento - CD', valor: 'Evento - CD (Saída da Descarga)' },
			{ id: 'Evento - LO', valor: 'Evento - LO (Posição)' }
		],
		filtroEvento: [
			'Shipment Inclusão', 'Shipment Alteração', 'Evento - XB', 'Evento - XX', 'Evento - X3', 'Evento - CP', 
			'Evento - AF', 'Evento - X1', 'Evento - D1'
		],
		modalCodigo: null,
		data_inicial: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAnterior(30)),
		data_final: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAtual())
	}

    componentWillMount() {
        this.props.getLista();
    }

    render() {
        return (
            <div>
                <Content>

					<Aguardando aguardando={this.props.aguardando} />

					{this.props.modoTela == 'lista' ? this.lista() : null}
					{this.props.modoTela == 'registro' ? this.registro() : null}
                </Content>
            </div>
        )
    }

	lista() {

		return (
			<ContentCard>
				<ContentCardHeader>
					<Row>
						<LabelAndInputMask
							label='Data/Hora' placeholder='Informe o período inicial'
							cols='12 6 3 2'
							mask='99/99/9999'
							value={this.state.data_inicial}
							onChange={(data) => {
								this.setState({ ...this.state, data_inicial: data.target.value });
							}} />

						<LabelAndInputMask
							label='Data/Hora' placeholder='Informe o período final'
							cols='12 6 3 2'
							mask='99/99/9999'
							value={this.state.data_final}
							onChange={(data) => {
								this.setState({ ...this.state, data_final: data.target.value });
							}} />

						<Grid cols='6 6 4 2' style={{ marginTop: 28 }}>
							<Button
								text='Atualizar'
								type={'primary'}
								icon={'fa fa-plus'}
								event={() => {
									this.props.getLista(this.state.data_inicial, this.state.data_final);
								}} />
						</Grid>
					</Row>
				</ContentCardHeader>
				<ContentCardBody>
					<input className='form-control'
						 placeholder='Pesquisar'
						 type='text'
						 value={this.state.pesquisar}
						 onChange={e => {
							 this.setState({ ...this.state, pesquisar: e.target.value });
						 }} />

					<Table responsive>
						<THead>
							<Tr>
								<Th rowspan={2} alignCenter>Shipping</Th>
								<Th rowspan={2} alignCenter>Código Envio</Th>
								<Th rowspan={2} alignCenter>Shipment ID</Th>
								<Th rowspan={2} alignCenter>Primeiro Envio</Th>
								<Th colspan={3} alignCenter>Último Evento</Th>
								<Th rowspan={2}></Th>
							</Tr>
							<Tr>
								<Th alignCenter>Data Hora</Th>
								<Th alignCenter>Tipo</Th>
								<Th alignCenter>Retorno</Th>
							</Tr>
						</THead>
						<TBody>
							{this.props.lista.filter(item => {

								if (this.state.pesquisar
									&& !(`${item.shipping} ${(item.shipment_id || '').split('|')[2] || ''} ${item.shipment_id}
										${DateFormat.formatarDataHoraSqlParaTela(item.datahora_inclusao)}
										${DateFormat.formatarDataHoraSqlParaTela(item.datahora_ultimo_evento)}
										${item.tipo_ultimo_evento} ${item.sucesso_ultimo_evento ? 'Sucesso' : 'Erro'}
									`).toUpperCase().includes(this.state.pesquisar.toUpperCase())) {
									return false;
								}

								return true;
							}).map(item => (
								<Tr key={item.shipping}>
									<Td alignCenter>{item.shipping}</Td>
									<Td alignCenter>{(item.shipment_id || '').split('|')[2] || ''}</Td>
									<Td alignCenter>{item.shipment_id}</Td>
									<Td alignCenter>{DateFormat.formatarDataHoraSqlParaTela(item.datahora_inclusao)}</Td>
									<Td alignCenter>{DateFormat.formatarDataHoraSqlParaTela(item.datahora_ultimo_evento)}</Td>
									<Td alignCenter>{item.tipo_ultimo_evento}</Td>
									<Td alignCenter>
										{item.sucesso_ultimo_evento ? (
											<span class='badge badge-success' style={{ fontSize: 14, width: 90, padding: 6 }}>Sucesso</span>
										) : (
											<span class='badge badge-danger' style={{ fontSize: 14, width: 90, padding: 6 }}>Erro</span>
										)}
									</Td>
									<Td alignRight minWidth={50}>
										<ButtonTable
											type={'primary'}
											icon={'fas fa-eye'}
											visible={true}
											event={() => {
												this.props.setModoTela('registro', {
													...item
												});
												this.props.getListaLog(item.shipping);
												this.setState({
													...this.state,
													filtroEvento: [
														'Shipment Inclusão', 'Shipment Alteração', 'Evento - XB', 'Evento - XX', 'Evento - X3', 'Evento - CP', 
														'Evento - AF', 'Evento - X1', 'Evento - D1', 'Evento - CD'
													]
												})
											}} />
									</Td>
								</Tr>
							))}
						</TBody>
					</Table>
				</ContentCardBody>
			</ContentCard>
		);
	}

	registro() {

		return (
			<ContentCard>

				{this.modalCodigo()}

				<ContentCardBody>
					<Row>
						<LabelAndInput
							label='Shipping'
							cols='12 6 3 2'
							readOnly={'readOnly'}
							value={this.props.registro.shipping}
							onChange={() => {}} />

						<LabelAndInput
							label='Código Envio'
							cols='12 6 3 2'
							readOnly={'readOnly'}
							value={(this.props.registro.shipment_id || '').split('|')[2] || ''}
							onChange={() => {}} />

						<LabelAndInput
							label='Shipment ID'
							cols='12 6 3 3'
							readOnly={'readOnly'}
							value={this.props.registro.shipment_id}
							onChange={() => {}} />
							
						<LabelAndInput
							label='Cavalo'
							cols='12 6 3 3'
							readOnly={'readOnly'}
							value={this.props.registro.cavalo}
							onChange={() => {}} />

						<Grid cols='6 6 4 2' style={{ marginTop: 28 }}>
							<Button
								text='Voltar'
								type={'success'}
								icon={'fa fa-plus'}
								event={() => {
									this.props.setModoTela('lista', {});
								}} />
						</Grid>
							
						<Select
							options={this.state.listaEventos}
							label='Filtrar Log'
							cols='12 12 12 12'
							placeholder='Selecione'
							multiple
							value={(this.state.filtroEvento || []).map(item => {
								const evento = this.state.listaEventos.filter(itemAux => itemAux.id == item)[0];
								
								return {
									value: item,
									label: evento ? evento.valor : ''
								};
							})}
							onChange={value => {
								this.setState({
									...this.state,
									filtroEvento: (value || []).map(item => item.value)
								});
							}} />
					
					</Row>
					<Table responsive>
						<THead>
							<Tr>
								<Th width={100} alignCenter>Data/Hora</Th>
								<Th width={100} alignCenter>Tipo</Th>
								<Th width={100} alignCenter>Nome</Th>
								<Th width={10} alignCenter>JSON</Th>
								<Th width={10} alignCenter>Retorno</Th>
								<Th width={10} alignCenter>Descrição Retorno</Th>
							</Tr>
						</THead>
						<TBody>
							{this.props.listaLog.filter(item => (this.state.filtroEvento || []).includes(item.tipo)).map(item => (
								<Tr key={item.id}>
									<Td alignCenter>{DateFormat.formatarDataHoraSqlParaTela(item.datahora_inclusao)}</Td>
									<Td alignCenter>{item.tipo}</Td>
									<Td alignCenter>{this.state.listaEventos.filter(evento => evento.id == item.tipo).map(evento => evento.valor).join('')}</Td>
									<Td alignCenter 
										title={''}>
										{item.registro &&
										<ButtonTable
											type={'secondary'}
											icon={'fas fa-code'}
											visible={true}
											event={() => {
												this.setState({ ...this.state, registroJson: item.registro });
											}} />}
									</Td>
									<Td alignCenter>{item.sucesso ? 'Sucesso' : 'Erro'}</Td>
									<Td alignCenter 
										title={''}>
										{item.erro &&
										<ButtonTable
											type={'danger'}
											icon={'fas fa-exclamation-circle'}
											visible={true}
											event={() => {
												this.setState({ ...this.state, registroErro: item.erro });
											}} />}
									</Td>
								</Tr>
							))}
						</TBody>
					</Table>
				</ContentCardBody>
			</ContentCard>
		);
	}

	modalCodigo() {

		if (!this.state.registroJson && !this.state.registroErro) {
			return null;
		}

        return (
			<div class='modal fade show' id='modal-xl' style={{ display: 'block' }} aria-modal='true'>
				<div class='modal-dialog modal-xl'>
					<div class='modal-content'>
						<div class='modal-header' style={{ backgroundColor: this.state.registroJson ? '#1F3066' : '#c70000', color: '#fff' }}>
							<h4 class='modal-title'>{this.state.registroJson ? 'JSON' : 'ERRO'}</h4>
							<button type='button' class='close' data-dismiss='modal' aria-label='Close' onClick={() => this.setState({ ...this.state, registroJson: null, registroErro: null })}>
								<span aria-hidden='true' style={{ color: '#fff', fontSize: 32 }}>×</span>
							</button>
						</div>
						<div class='modal-body' style={{ overflowY: 'scroll', paddingTop: 20 }}>
							
							<p style={{ padding: 20, backgroundColor: '#e9ecef', color: '#000', fontSize: 16 }}>
								{this.state.registroJson || this.state.registroErro}
							</p>
						</div>
					</div>
				</div>
			</div>
        );
    }

}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	modoTela: state.reportsIntegracaoGM.modoTela,
	aguardando: state.reportsIntegracaoGM.aguardando,
	registro: state.reportsIntegracaoGM.registro,
	lista: state.reportsIntegracaoGM.lista,
	listaLog: state.reportsIntegracaoGM.listaLog
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, getLista, getListaLog }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ReportsIntegracaoGM);
