import DateFormat from '../common/dateFormat/DateFormat';

const INITIAL_STATE = {
	registro: {},
	modoTela: 'lista',
	lista: [],
	listaMotorista: [],
	listaTipo: [],
	listaDocumentoTipo: [{ id: 'CT-e', valor: 'CT-e' }, { id: 'CRT', valor: 'CRT' }],
	listaMoeda: [],
	filtro: {
		data_inicial: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAnterior(30)),
		data_final: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAtual()),
		id_status: 1
	}
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case 'DESPESA_MODO_TELA':
            return { ...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};

		case 'DESPESA_FILTRO':
			return { ...state,
				filtro: action.payload.filtro
			};

		case 'DESPESA_MOTORISTA_SELECT_LISTADO':
            return { ...state,
				listaMotorista: action.payload.data
			};

		case 'DESPESA_TIPO_SELECT_LISTADO':
            return { ...state,
				listaTipo: action.payload.data
			};

		case 'DESPESA_MOEDA_SELECT_LISTADO':
            return { ...state,
				listaMoeda: action.payload.data
			};

        case 'DESPESA_LISTADO':
            return {
				...state,
				lista: action.payload.data
			};

        default:
            return state;
    }
}
