import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ContentCardFooter from '../common/template/contentCardFooter';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndCheckbox from '../common/form/labelAndCheckbox';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import LabelAndInputDateTime from '../common/form/labelAndInputDateTime';
import Select from '../common/form/select';
import SelectAlteracao from '../common/form/selectAlteracao';

import {
	setModoTela, initForm
} from './usuarioAcessorioActions';
import unidade from '../unidade/unidade';

class UsuarioAcessorioForm extends Component {

	state = {

	}

    componentWillMount() {

    }

    render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';

        return (
			<ContentCard>
				<Form event={this.props.handleSubmit}>
					<ContentCardBody>
						<Row>
							<Field
								name='id_usuario'
								component={Select}
								options={this.props.listaUsuario.filter(item => !item.acesso_cliente && !this.props.lista.filter(acesso => acesso.id_usuario == item.id).length)}
								label='Usuário' placeholder='Informe o usuário'
								cols='12 12 12 12'
								readOnly={this.props.formularioValues.id ? 'readOnly' : readOnly} />

							<Select
								options={this.props.listaUnidade}
								label='Unidades'
								cols='12 12 12 12'
								placeholder='Selecione as unidades'
								multiple
								readOnly={readOnly}
								value={(this.props.formularioValues.unidades || []).map(item => ({
									value: item,
									label: this.props.listaUnidade.filter(unidade => unidade.id == item).map(unidade => unidade.valor)[0]
								}))}
								onChange={values => {
									this.props.initForm({
										...this.props.formularioValues,
										unidades: (values || []).map(item => parseInt(item.value))
									});
								}} />
								
							<Select
								options={this.props.listaTipo}
								label='Acessórios'
								cols='12 12 12 12'
								placeholder='Selecione os acessórios'
								multiple
								readOnly={readOnly}
								value={(this.props.formularioValues.tipos || []).map(item => ({
									value: item,
									label: this.props.listaTipo.filter(tipo => tipo.id == item).map(tipo => tipo.valor)[0]
								}))}
								onChange={values => {
									this.props.initForm({
										...this.props.formularioValues,
										tipos: (values || []).map(item => parseInt(item.value))
									});
								}} />

							<Field
								name='acesso'
								component={LabelAndCheckbox}
								label='Configurar Acessos' placeholder='Selecione'
								cols='12 6 4 3'
								readOnly={readOnly} />
							
							<Field
								name='cadastro'
								component={LabelAndCheckbox}
								label='Editar Cadastros' placeholder='Selecione'
								cols='12 6 4 3'
								readOnly={readOnly} />
							
							<Field
								name='saldo'
								component={LabelAndCheckbox}
								label='Acessar Saldo' placeholder='Selecione'
								cols='12 6 4 3'
								readOnly={readOnly} />
							
							<Field
								name='historico'
								component={LabelAndCheckbox}
								label='Acessar Histórico' placeholder='Selecione'
								cols='12 6 4 3'
								readOnly={readOnly} />
							
							<Field
								name='entrada'
								component={LabelAndCheckbox}
								label='Realizar Entrada' placeholder='Selecione'
								cols='12 6 4 3'
								readOnly={readOnly} />
							
							<Field
								name='transferencia_unidade'
								component={LabelAndCheckbox}
								label='Realizar Trf. Unidade' placeholder='Selecione'
								cols='12 6 4 3'
								readOnly={readOnly} />

							<Field
								name='transferencia_veiculo'
								component={LabelAndCheckbox}
								label='Realizar Trf. Veículo' placeholder='Selecione'
								cols='12 6 4 3'
								readOnly={readOnly} />
							
							<Field
								name='saida'
								component={LabelAndCheckbox}
								label='Realizar Saída' placeholder='Selecione'
								cols='12 6 4 3'
								readOnly={readOnly} />
							
							<Field
								name='inventario'
								component={LabelAndCheckbox}
								label='Realizar Inventário' placeholder='Selecione'
								cols='12 6 4 3'
								readOnly={readOnly} />
						</Row>
					</ContentCardBody>
					<ContentCardFooter>
						<Row alignCenter>
							<Grid cols='6 6 4 3'>
								{this.props.excluir ? (
									<Button
										text='Excluir'
										submit type={'danger'}
										icon={'fa fa-trash'} />
								) : (
								<Button
									text='Salvar'
									submit
									type={'success'}
									icon={'fa fa-check'} />
								)}
							</Grid>
							<Grid cols='6 6 4 3'>
								<Button
									text='Voltar'
									type={'warning'}
									icon={'fa fa-chevron-left'}
									event={() => this.props.setModoTela('lista')} />
							</Grid>
						</Row>
					</ContentCardFooter>
				</Form>
			</ContentCard>
        )
    }


}

UsuarioAcessorioForm = reduxForm({form: 'usuarioAcessorioForm', destroyOnUnmount: false})(UsuarioAcessorioForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	formularioValues: getFormValues('usuarioAcessorioForm')(state),
	registro: state.usuarioAcessorio.registro,
	listaUnidade: state.usuarioAcessorio.listaUnidade,
	listaUsuario: state.usuarioAcessorio.listaUsuario,
	listaTipo: state.usuarioAcessorio.listaTipo,
	lista: state.usuarioAcessorio.lista
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, initForm
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(UsuarioAcessorioForm);
