import React, { useState } from 'react';
import './switchVertical.css'; // Importe o arquivo CSS para estilização

const SwitchVertical = ({ initialState = false, onChange }) => {
  const [isChecked, setIsChecked] = useState(initialState);

  const toggleSwitch = () => {
    const newState = !isChecked;
    setIsChecked(newState);
    if (onChange) {
      onChange(newState);
    }
  };

  return (
    <div className="switch-vertical" onClick={toggleSwitch}>
      <input type="checkbox" checked={isChecked} readOnly />
      <span className="slider-vertical"></span>
    </div>
  );
};

export default SwitchVertical;