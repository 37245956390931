const INITIAL_STATE = {
	aguardando: false,
	registro: {},
	modoTela: 'lista',
	lista: [],
	modoTelaItem: 'lista',
	registroItem: {},
	listaItem: [],
	listaDocumento: [],
	listaTipo: [{ id: 1, valor: 'Original' }, { id: 2, valor: 'Cópia' }],
	modoTelaExcecao: 'lista',
	registroExcecao: {},
	listaExcecao: [],
	listaPerfil: []
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case 'OPERACAO_MODO_TELA':
            return { ...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};

		case 'OPERACAO_SET_AGUARDANDO':
            return { ...state,
				aguardando: action.payload
			};

		case 'OPERACAO_PERFIL_SELECT_LISTADO':
            return {
				...state,
				listaPerfil: action.payload.data
			};

        case 'OPERACAO_LISTADO':
            return {
				...state,
				lista: action.payload.data
			};

		case 'OPERACAO_ITEM_MODO_TELA':
            return { ...state,
				modoTelaItem: action.payload.modo,
				registroItem: action.payload.registro
			};

        case 'OPERACAO_ITEM_LISTADO':
            return {
				...state,
				listaItem: action.payload.data
			};

        case 'OPERACAO_DOCUMENTO_SELECT_LISTADO':
            return {
				...state,
				listaDocumento: action.payload.data
			};

		case 'OPERACAO_EXCECAO_MODO_TELA':
            return { ...state,
				modoTelaExcecao: action.payload.modo,
				registroExcecao: action.payload.registro
			};

        case 'OPERACAO_EXCECAO_LISTADO':
            return {
				...state,
				listaExcecao: action.payload.data
			};

        default:
            return state;
    }
}
