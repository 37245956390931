import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import DateFormat from '../common/dateFormat/DateFormat';
import FormatUtils from '../common/formatUtils/FormatUtils';

export function setModoTela(modo, registro = {}) {
    return {
        type: 'OCORRENCIA_MODO_TELA',
        payload: {
			modo: modo,
			registro: registro
		}
    };
}

export function initForm(registro = {}) {
    return [
        initialize('ocorrenciaForm', registro)
    ];
}

export function setFiltro(filtro = {}) {
    return {
        type: 'OCORRENCIA_FILTRO',
        payload: {
			filtro: filtro
		}
    };
}

export function getLista() {
	return (dispatch, getState) => {
		
		dispatch({
			type: 'OCORRENCIA_LISTADO',
			payload: { data: [] }
		});

    	axios.get(`${consts.API_URL}/ocorrencia?
			data_inicial=${DateFormat.formatarDataTelaParaSql(getState().ocorrencia.filtro.data_inicial) == 'Invalid DateTime' ? null : DateFormat.formatarDataTelaParaSql(getState().ocorrencia.filtro.data_inicial)}
			&data_final=${DateFormat.formatarDataTelaParaSql(getState().ocorrencia.filtro.data_final) == 'Invalid DateTime' ? null : DateFormat.formatarDataTelaParaSql(getState().ocorrencia.filtro.data_final)}`)
		.then(resp => {
			dispatch({
		        type: 'OCORRENCIA_LISTADO',
		        payload: resp
		    });
		}).catch(e => {
			setErroAPI(e);
		});
	}
}

export function salvar(registro, evento) {
    return async (dispatch, getState) => {
		if (!registro.id) {
			
			let usuarioResponsavel = getState().ocorrencia.listaUsuario.filter(item => item.id == registro.id_usuario_responsavel)[0];
			let usuarioAprovador = getState().ocorrencia.listaUsuario.filter(item => item.id == registro.id_usuario_aprovador)[0];
			let usuarioInclusao = getState().ocorrencia.listaUsuario.filter(item => item.id == registro.id_usuario_inclusao)[0];

			let emails = [];

			if (registro.id_documento_viagem || registro.id_vale_pedagio_entrada
				|| registro.id_vale_pedagio_saida || registro.id_vale_pedagio_transferencia
				|| registro.avulsa) {
				if (usuarioResponsavel && usuarioResponsavel.login) {
					emails.push(usuarioResponsavel.login);
				}
				if (usuarioAprovador && usuarioAprovador.login && !emails.filter(email => email == usuarioAprovador.login).length) {
					emails.push(usuarioAprovador.login);
				}
				if (usuarioInclusao && usuarioInclusao.login && !emails.filter(email => email == usuarioInclusao.login).length) {
					emails.push(usuarioInclusao.login);
				}

				(registro.email_copia || '').split('\n').filter(email => email.trim()).forEach(email => {
					emails.push(email);
				});

			}

			let motoristas = registro.multiplos ? registro.motoristas || [] : [registro.id_motorista];
			if (!motoristas.length) {
				motoristas = [null];
			}

			let erro = false;
			
			for (let i = 0; i < motoristas.length; i++) {
				const itemMotorista = motoristas[i];
				
				if (!erro) {
					let motorista = getState().ocorrencia.listaMotorista.filter(item => item.id == itemMotorista)[0];
					let tipo = getState().ocorrencia.listaTipo.filter(item => item.id == registro.id_tipo)[0];
					let acao = getState().ocorrencia.listaAcao.filter(item => item.id == registro.id_acao)[0];
					let area = getState().ocorrencia.listaResponsabilidade.filter(item => item.id == registro.id_area)[0];
					
					await axios.post(`${consts.API_URL}/ocorrencia`, {
						...registro,
						id_motorista: itemMotorista,
						percentual_desconto: FormatUtils.formatarValorBanco(registro.percentual_desconto, 2),
						datahora_ocorrencia: DateFormat.formatarDataHoraTelaParaSql(registro.datahora_ocorrencia),
						datahora_finalizacao: DateFormat.formatarDataHoraTelaParaSql(registro.datahora_finalizacao),
						datahora_pagamento: DateFormat.formatarDataHoraTelaParaSql(registro.datahora_pagamento),
						custo: FormatUtils.formatarValorBanco(registro.custo, 2),
						valor: FormatUtils.formatarValorBanco(registro.valor, 2),
						valor_autorizado: FormatUtils.formatarValorBanco(registro.valor_autorizado, 2),
						emails: emails || [],
						nome_motorista: motorista ? motorista.valor : '',
						nome_tipo: tipo ? tipo.valor : '',
						nome_usuario_responsavel: usuarioResponsavel ? usuarioResponsavel.valor : '',
						nome_acao: acao ? acao.valor : '',
						nome_area: area ? area.valor : '',
						comentarios: registro.comentario ? registro.comentario : registro.comentarios,
						id_usuario_inclusao: getState().auth.usuario.id,
						id_usuario_alteracao: getState().auth.usuario.id
					}).then(resp => {
						
					}).catch(e => {
						erro = true;
						setErroAPI(e);
					});
				}
			}
			if (!erro) {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('lista'));
				if (evento) {
					evento();
				}
			}
		} else {

			let usuarioResponsavel = getState().ocorrencia.listaUsuario.filter(item => item.id == registro.id_usuario_responsavel)[0];
			let usuarioAprovador = getState().ocorrencia.listaUsuario.filter(item => item.id == registro.id_usuario_aprovador)[0];
			let usuarioInclusao = getState().ocorrencia.listaUsuario.filter(item => item.id == registro.id_usuario_inclusao)[0];

			let emails = [];
			let comentarioEmail = [];
			if (registro.comentario) {

				if (registro.id_documento_viagem || registro.id_vale_pedagio_entrada
					|| registro.id_vale_pedagio_saida || registro.id_vale_pedagio_transferencia
					|| registro.avulsa) {
					if (usuarioResponsavel && usuarioResponsavel.login && usuarioResponsavel.id != getState().auth.usuario.id) {
						emails.push(usuarioResponsavel.login);
					}

					(registro.email_copia || '').split('\n').filter(email => email.trim()).forEach(email => {
						emails.push(email);
					});

					// if (usuarioAprovador && usuarioAprovador.login && !emails.filter(email => email == usuarioAprovador.login).length) {
					// 	emails.push(usuarioAprovador.login);
					// }
					// if (usuarioInclusao && usuarioInclusao.login && !emails.filter(email => email == usuarioInclusao.login).length) {
					// 	emails.push(usuarioInclusao.login);
					// }

					comentarioEmail = getState().ocorrencia.listaHistorico.filter(item => item.coluna == 'comentarios' && item.atual).map(item => ({
						datahora: DateFormat.formatarDataHoraSqlParaTela(item.datahora_historico),
						nome_usuario: item.nome_usuario || '',
						comentario: item.atual || ''
					}));

					comentarioEmail.push({
						datahora: DateFormat.formatarDataHoraSqlParaTela(DateFormat.getDataHoraAtual()),
						nome_usuario: getState().auth.usuario.nome || '',
						comentario: registro.comentario || ''
					});

					comentarioEmail.reverse();
				}				

			}

			let tipo = getState().ocorrencia.listaTipo.filter(item => item.id == registro.id_tipo)[0];
			let acao = getState().ocorrencia.listaAcao.filter(item => item.id == registro.id_acao)[0];
			let area = getState().ocorrencia.listaResponsabilidade.filter(item => item.id == registro.id_area)[0];

			axios.put(`${consts.API_URL}/ocorrencia`, {
				...registro,
				percentual_desconto: FormatUtils.formatarValorBanco(registro.percentual_desconto, 2),
				datahora_ocorrencia: DateFormat.formatarDataHoraTelaParaSql(registro.datahora_ocorrencia),
				datahora_finalizacao: DateFormat.formatarDataHoraTelaParaSql(registro.datahora_finalizacao),
				datahora_pagamento: DateFormat.formatarDataHoraTelaParaSql(registro.datahora_pagamento),
				custo: FormatUtils.formatarValorBanco(registro.custo, 2),
				valor: FormatUtils.formatarValorBanco(registro.valor, 2),
				valor_autorizado: FormatUtils.formatarValorBanco(registro.valor_autorizado, 2),
				comentarios: registro.comentario ? registro.comentario : registro.comentarios,
				id_usuario_alteracao: getState().auth.usuario.id,
				comentarios_email: comentarioEmail,
				emails: emails || [],
				nome_tipo: tipo ? tipo.valor : '',
				nome_usuario_responsavel: usuarioResponsavel ? usuarioResponsavel.valor : '',
				nome_acao: acao ? acao.valor : '',
				nome_area: area ? area.valor : ''
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('lista'));
				if (evento) {
					evento();
				}
			}).catch(e => {
				setErroAPI(e);
			});
		}
    };
}

export function salvarComentarioVisto(registro) {
    return (dispatch, getState) => {
		axios.put(`${consts.API_URL}/ocorrencia/comentarioVisualizado`, {
			...registro,
			id_usuario_comentario_visto: getState().auth.usuario.id
		}).then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista());
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function salvarAbona(registro) {
    return (dispatch, getState) => {
		axios.put(`${consts.API_URL}/ocorrencia/abona`, {
			...registro,
			abona: !registro.abona,
			id_usuario_abono: getState().auth.usuario.id
		}).then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista());
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function excluir(registro, evento) {
    return (dispatch, getState) => {
        axios.delete(`${consts.API_URL}/ocorrencia?id=${registro.id}&id_usuario_alteracao=${getState().auth.usuario.id}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista());
			dispatch(setModoTela('lista'));
			if (evento) {
				evento();
			}
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function getListaHistorico(id_ocorrencia) {
    const request = axios.get(`${consts.API_URL}/ocorrencia/historico?id_ocorrencia=${id_ocorrencia || 0}`);
    return {
        type: 'OCORRENCIA_HISTORICO_LISTADO',
        payload: request
    };
}

export function getListaTipo() {
    const request = axios.get(`${consts.API_URL}/ocorrenciaTipo/listarSelect?todos=true`);
    return {
        type: 'OCORRENCIA_TIPO_SELECT_LISTADO',
        payload: request
    };
}

export function getListaMotorista() {
    const request = axios.get(`${consts.API_URL}/motorista/listarSelect`);
    return {
        type: 'OCORRENCIA_MOTORISTA_SELECT_LISTADO',
        payload: request
    };
}

export function getListaGravidade() {
    const request = axios.get(`${consts.API_URL}/ocorrenciaGravidade/listarSelect`);
    return {
        type: 'OCORRENCIA_GRAVIDADE_SELECT_LISTADO',
        payload: request
    };
}

export function getListaLocal() {
    const request = axios.get(`${consts.API_URL}/ocorrenciaLocal/listarSelect`);
    return {
        type: 'OCORRENCIA_LOCAL_SELECT_LISTADO',
        payload: request
    };
}

export function getListaResponsabilidade() {
    const request = axios.get(`${consts.API_URL}/ocorrenciaResponsabilidade/listarSelect`);
    return {
        type: 'OCORRENCIA_RESPONSABILIDADE_SELECT_LISTADO',
        payload: request
    };
}

export function getListaTipoCusto() {
    const request = axios.get(`${consts.API_URL}/ocorrenciaTipoCusto/listarSelect`);
    return {
        type: 'OCORRENCIA_TIPO_CUSTO_SELECT_LISTADO',
        payload: request
    };
}

export function getListaStatus() {
    const request = axios.get(`${consts.API_URL}/ocorrenciaStatus/listarSelect`);
    return {
        type: 'OCORRENCIA_STATUS_SELECT_LISTADO',
        payload: request
    };
}

export function getListaAcao() {
    const request = axios.get(`${consts.API_URL}/ocorrenciaAcao/listarSelect`);
    return {
        type: 'OCORRENCIA_ACAO_SELECT_LISTADO',
        payload: request
    };
}

export function getListaUsuario() {
    const request = axios.get(`${consts.API_URL}/usuario/listarSelect`);
    return {
        type: 'OCORRENCIA_USUARIO_SELECT_LISTADO',
        payload: request
    };
}

export function gerarBoletim(registro) {
	return (dispatch, getState) => {
		axios.post(`${consts.API_URL}/ocorrencia/gerarBoletim`, {
			...registro
		}).then(resp => {
			window.open(`https://api-letsarapremiacao.infinitum-ti.com/${resp.data}`, '_blank');
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function getListaCategoria() {
    const request = axios.get(`${consts.API_URL}/ocorrenciaCategoria/listarSelect`);
    return {
        type: 'OCORRENCIA_CATEGORIA_SELECT_LISTADO',
        payload: request
    };
}
