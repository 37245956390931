const INITIAL_STATE = {
	registro: {},
	modoTela: 'lista',
	lista: [],
	listaTipo: [],
	listaMotorista: [],
	getListaGrupo: [],
	modoTelaItem: 'lista',
	listaItem: [],
	aguardando: false
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case 'PREMIACAO_MODO_TELA':
            return { ...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};

		case 'PREMIACAO_SET_AGUARDANDO':
            return { ...state,
				aguardando: action.payload
			};

        case 'PREMIACAO_LISTADO':
            return {
				...state,
				lista: action.payload.data
			};

        case 'PREMIACAO_TIPO_SELECT_LISTADO':
			return {
				...state,
				listaTipo: action.payload.data
			};

        case 'PREMIACAO_MOTORISTA_SELECT_LISTADO':
			return {
				...state,
				listaMotorista: action.payload.data
			};

        case 'PREMIACAO_GRUPO_SELECT_LISTADO':
			return {
				...state,
				listaGrupo: action.payload.data
			};

        case 'PREMIACAO_MOTORISTA_MODO_TELA':
			return { ...state,
				modoTelaItem: action.payload.modo
			};

        case 'PREMIACAO_MOTORISTA_LISTADO':
			return {
				...state,
				listaItem: action.payload.data
			};

        default:
            return state;
    }
}
