import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ContentCardFooter from '../common/template/contentCardFooter';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import LabelAndInputDateTime from '../common/form/labelAndInputDateTime';
import Select from '../common/form/select';
import SelectAlteracao from '../common/form/selectAlteracao';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import DateFormat from '../common/dateFormat/DateFormat';
import FormatUtils from '../common/formatUtils/FormatUtils';
import FormOcorrencia from '../ocorrencia/ocorrenciaForm';
import {
	salvar as salvarOcorrencia, excluir as excluirOcorrencia, initForm as initFormOcorrencia, 
	getListaTipo as getListaTipoOcorrencia, getListaMotorista as getListaMotoristaOcorrencia,
	getListaGravidade as getListaGravidadeOcorrencia, getListaLocal as getListaLocalOcorrencia, 
	getListaResponsabilidade as getListaResponsabilidadeOcorrencia, getListaTipoCusto as getListaTipoCustoOcorrencia,
	getListaStatus as getListaStatusOcorrencia, getListaAcao as getListaAcaoOcorrencia, getListaUsuario as getListaUsuarioOcorrencia
} from '../ocorrencia/ocorrenciaActions';

import {
	setModoTela, initFormTransferencia, setModoTelaOcorrencia, getListaOcorrencia, getLista
} from './valePedagioMotoristaActions';

class ValePedagioTransferenciaForm extends Component {

	state = {

	}

    componentWillMount() {
		this.props.setModoTelaOcorrencia('lista');		
		this.props.getListaOcorrencia(this.props.formularioValues.id || 0, 'TRANSFERENCIA');
		this.props.getListaTipoOcorrencia();
		this.props.getListaMotoristaOcorrencia();
		this.props.getListaGravidadeOcorrencia();
		this.props.getListaLocalOcorrencia();
		this.props.getListaResponsabilidadeOcorrencia();
		this.props.getListaTipoCustoOcorrencia();
		this.props.getListaStatusOcorrencia();
		this.props.getListaAcaoOcorrencia();
		this.props.getListaUsuarioOcorrencia();
    }

    render() {

		let readOnly = this.props.excluir || this.props.visualizacao ? 'readOnly' : '';

        return (
			<div>
				
				<ContentCard>
					<Form event={this.props.handleSubmit}>
						<ContentCardBody>
							<Row>

								<Field
									name='datahora_transferencia'
									component={LabelAndInputMask}
									label='Data e Hora' placeholder='Informe a data e hora'
									cols='12 6 3 2'
									mask='99/99/9999 99:99'
									readOnly={this.props.formularioValues.id ? 'readOnly' : readOnly} />

								<Field
									name='id_vale_pedagio'
									component={Select}
									options={this.props.listaValePedagio}
									label='Tipo'
									cols='12 12 12 12'
									placeholder='Selecione o vale'
									readOnly={this.props.formularioValues.id ? 'readOnly' : readOnly} />

								<Field
									name='id_motorista_destino'
									component={Select}
									options={this.props.listaMotorista.filter(item => item.id != this.props.formularioValues.id_motorista)}
									label='Transferir Para'
									cols='12 12 12 12'
									placeholder='Selecione o motorista'
									readOnly={this.props.formularioValues.id ? 'readOnly' : readOnly} />

								<Field
									name='id_motivo'
									component={Select}
									options={this.props.listaMotivo.filter(item => item.transferencia)}
									label='Motivo'
									cols='12 12 12 12'
									placeholder='Selecione o motivo'
									readOnly={readOnly} />
									
								<Field
									name='valor'
									component={LabelAndInputNumber}
									label='Valor' placeholder='Informe a valor'
									cols='12 12 4 4'
									casas={2}
									readOnly={readOnly} />
									
								<Field
									name='id_tomador'
									component={Select}
									options={this.props.listaTomador}
									label='Tomador'
									cols='12 12 12 12'
									placeholder='Selecione o tomador'
									readOnly={readOnly} />
									
								<Field
									name='documento'
									component={LabelAndInput}
									label='Documento' placeholder='Informe o documento'
									cols='12 12 12 12'
									readOnly={readOnly} />

								<Field
									name='observacao'
									component={LabelAndInput}
									label='Observação' placeholder='Informe as observações'
									cols='12 12 12 12'
									readOnly={readOnly} />

							</Row>
						</ContentCardBody>
						<ContentCardFooter>
							<Row alignCenter>
								<Grid cols='6 6 4 3'>
									{this.props.excluir ? (
										<Button
											text='Excluir'
											submit type={'danger'}
											icon={'fa fa-trash'} />
									) : (
									<Button
										text='Salvar'
										submit
										type={'success'}
										icon={'fa fa-check'} />
									)}
								</Grid>
								<Grid cols='6 6 4 3'>
									<Button
										text='Voltar'
										type={'warning'}
										icon={'fa fa-chevron-left'}
										event={() => this.props.setModoTela('lista')} />
								</Grid>
							</Row>
						</ContentCardFooter>
					</Form>
				</ContentCard>

				{this.props.formularioValues.id ?
					this.renderOcorrencias(readOnly)
				: null}

			</div>
        )
    }

	renderOcorrencias(readOnly) {

		if (this.props.modoTelaOcorrencia == 'cadastro') {
			return (
				<FormOcorrencia voltarComprovante={() => this.props.setModoTelaOcorrencia('lista')} onSubmit={(registro) => this.props.salvarOcorrencia(registro, () => {
					this.props.setModoTelaOcorrencia('lista');
					this.props.getListaOcorrencia(this.props.formularioValues.id, 'TRANSFERENCIA');
					this.props.getLista();
				})} />
			);
		} else if (this.props.modoTelaOcorrencia == 'exclusao') {
			return (
				<FormOcorrencia voltarComprovante={() => this.props.setModoTelaOcorrencia('lista')} excluir onSubmit={(registro) => this.props.excluirOcorrencia(registro, () => {
					this.props.setModoTelaOcorrencia('lista');
					this.props.getListaOcorrencia(this.props.formularioValues.id, 'TRANSFERENCIA');
					this.props.getLista();
				})} />
			);
		} else if (this.props.modoTelaOcorrencia == 'lista') {
			return (

				<ContentCard>
					<ContentCardHeader>
						<label>Ocorrências</label>
						<Row>
							<Grid cols='6 6 4 2'>
								<Button
									text='Adicionar Ocorrência'
									type={'success'}
									icon={'fa fa-plus'}
									disabled={false}
									event={() => {
										this.props.setModoTelaOcorrencia('cadastro', {
											id_vale_pedagio_transferencia: this.props.formularioValues.id,
											id_motorista: this.props.formularioValues.id_motorista_origem,
											descricao_documento: this.props.formularioValues.documento,
											datahora_ocorrencia: DateFormat.formatarDataHoraSqlParaTela(DateFormat.getDataHoraAtual())
										});
										this.props.initFormOcorrencia({
											id_vale_pedagio_transferencia: this.props.formularioValues.id,
											id_motorista: this.props.formularioValues.id_motorista_origem,
											descricao_documento: this.props.formularioValues.documento,
											datahora_ocorrencia: DateFormat.formatarDataHoraSqlParaTela(DateFormat.getDataHoraAtual())
										});
									}} />
							</Grid>
						</Row>
					</ContentCardHeader>
					<ContentCardBody>
						<Table responsive>
							<THead>
								<Tr>
									<Th alignCenter>Número</Th>
									<Th alignCenter>Situação</Th>
									<Th alignCenter>Status</Th>
									<Th alignCenter>Tipo</Th>
									<Th alignCenter>Data/Hora</Th>
									<Th alignCenter>Ação Requerida</Th>
									<Th alignCenter>Motorista Vinculado</Th>
									<Th alignCenter>Observação</Th>
									<Th width={5} alignCenter><i class='fas fa-paperclip' style={{ marginLeft: 4, paddingTop: 4, fontSize: 16 }} title={'Digital anexado'} /></Th>
									<Th alignCenter width={100}></Th>
								</Tr>
							</THead>
							<TBody>
								{this.props.listaOcorrencia.map(item => (
									<Tr key={item.id} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
										onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
										onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
										<Td small alignCenter small>{item.id}</Td>
										<Td small alignCenter small>{item.datahora_finalizacao ? 'Finalidada' : 'Em Aberto'}</Td>
										<Td small alignCenter small>{item.nome_status}</Td>
										<Td small alignCenter small>{item.nome_tipo}</Td>
										<Td small alignCenter small>{DateFormat.formatarDataHoraSqlParaTela(item.datahora_ocorrencia)}</Td>
										<Td small alignCenter small>{item.nome_acao}</Td>
										<Td small alignCenter small>{item.nome_motorista}</Td>
										<Td small alignCenter small>{item.observacao}</Td>
										<Td alignCenter minWidth={5} small noWrap>
											{(item.arquivo || []).length > 0 &&
											<i class='fas fa-paperclip' style={{ marginLeft: 4, paddingTop: 4, fontSize: 16 }} title={'Digital anexado'} />}
										</Td>
										<Td alignCenter width={100}>
											<ButtonTable
												type={'warning'}
												icon={'fas fa-pencil-alt'}
												visible={true}
												disabled={readOnly}
												title='Editar'
												small
												event={() => {
													this.props.setModoTelaOcorrencia('cadastro', {
														...item,
														percentual_desconto: FormatUtils.formatarValorTela(item.percentual_desconto, 2),
														datahora_ocorrencia: DateFormat.formatarDataHoraSqlParaTela(item.datahora_ocorrencia),
														datahora_finalizacao: DateFormat.formatarDataHoraSqlParaTela(item.datahora_finalizacao),
														datahora_pagamento: DateFormat.formatarDataHoraSqlParaTela(item.datahora_pagamento),
														custo: FormatUtils.formatarValorTela(item.custo, 2),
														valor: FormatUtils.formatarValorTela(item.valor, 2),
														valor_autorizado: FormatUtils.formatarValorTela(item.valor_autorizado, 2)
													});
													this.props.initFormOcorrencia({
														...item,
														percentual_desconto: FormatUtils.formatarValorTela(item.percentual_desconto, 2),
														datahora_ocorrencia: DateFormat.formatarDataHoraSqlParaTela(item.datahora_ocorrencia),
														datahora_finalizacao: DateFormat.formatarDataHoraSqlParaTela(item.datahora_finalizacao),
														datahora_pagamento: DateFormat.formatarDataHoraSqlParaTela(item.datahora_pagamento),
														custo: FormatUtils.formatarValorTela(item.custo, 2),
														valor: FormatUtils.formatarValorTela(item.valor, 2),
														valor_autorizado: FormatUtils.formatarValorTela(item.valor_autorizado, 2)
													});
												}} />

											<ButtonTable
												type={'danger'}
												icon={'fas fa-trash-alt'}
												visible={true}
												disabled={item.datahora_finalizacao}
												title='Excluir'
												small
												event={() => {
													this.props.setModoTelaOcorrencia('exclusao', {
														...item,
														percentual_desconto: FormatUtils.formatarValorTela(item.percentual_desconto, 2),
														datahora_ocorrencia: DateFormat.formatarDataHoraSqlParaTela(item.datahora_ocorrencia),
														datahora_finalizacao: DateFormat.formatarDataHoraSqlParaTela(item.datahora_finalizacao),
														datahora_pagamento: DateFormat.formatarDataHoraSqlParaTela(item.datahora_pagamento),
														custo: FormatUtils.formatarValorTela(item.custo, 2),
														valor: FormatUtils.formatarValorTela(item.valor, 2),
														valor_autorizado: FormatUtils.formatarValorTela(item.valor_autorizado, 2)
													});
													this.props.initFormOcorrencia({
														...item,
														percentual_desconto: FormatUtils.formatarValorTela(item.percentual_desconto, 2),
														datahora_ocorrencia: DateFormat.formatarDataHoraSqlParaTela(item.datahora_ocorrencia),
														datahora_finalizacao: DateFormat.formatarDataHoraSqlParaTela(item.datahora_finalizacao),
														datahora_pagamento: DateFormat.formatarDataHoraSqlParaTela(item.datahora_pagamento),
														custo: FormatUtils.formatarValorTela(item.custo, 2),
														valor: FormatUtils.formatarValorTela(item.valor, 2),
														valor_autorizado: FormatUtils.formatarValorTela(item.valor_autorizado, 2)
													});
												}} />
										</Td>
									</Tr>
								))}
							</TBody>
						</Table>
					</ContentCardBody>
				</ContentCard>
			);
		} else {
			return (
				<div></div>
			);
		}
	}
}

ValePedagioTransferenciaForm = reduxForm({form: 'valePedagioTransferenciaForm', destroyOnUnmount: false})(ValePedagioTransferenciaForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	formularioValues: getFormValues('valePedagioTransferenciaForm')(state),
	registro: state.valePedagioMotorista.registro,
	listaValePedagio: state.valePedagioMotorista.listaValePedagio,
	listaMotorista: state.valePedagioMotorista.listaMotorista,
	listaMotivo: state.valePedagioMotorista.listaMotivo,
	listaTomador: state.valePedagioMotorista.listaTomador,
	listaOcorrencia: state.valePedagioMotorista.listaOcorrencia,
	modoTelaOcorrencia: state.valePedagioMotorista.modoTelaOcorrencia
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, initFormTransferencia, getLista,
	setModoTelaOcorrencia, initFormOcorrencia, salvarOcorrencia, excluirOcorrencia, getListaOcorrencia,
	getListaTipoOcorrencia, getListaMotoristaOcorrencia,
	getListaGravidadeOcorrencia, getListaLocalOcorrencia, 
	getListaResponsabilidadeOcorrencia, getListaTipoCustoOcorrencia,
	getListaStatusOcorrencia, getListaAcaoOcorrencia, getListaUsuarioOcorrencia
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ValePedagioTransferenciaForm);
