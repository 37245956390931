import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import DateFormat from '../common/dateFormat/DateFormat';

export function setModoTela(modo, registro = {}) {
    return {
        type: 'DOCUMENTO_VIAGEM_MODO_TELA',
        payload: {
			modo: modo,
			registro: registro
		}
    };
}

export function setAguardando(aguardando) {
    return {
        type: 'DOCUMENTO_VIAGEM_SET_AGUARDANDO',
        payload: aguardando
    };
}

export function setFiltroAberta(filtro = {}) {
    return {
        type: 'DOCUMENTO_VIAGEM_ABERTA_FILTRO',
        payload: {
			filtro: filtro
		}
    };
}

export function setFiltroComprovacao(filtro = {}) {
    return {
        type: 'DOCUMENTO_VIAGEM_COMPROVACAO_FILTRO',
        payload: {
			filtro: filtro
		}
    };
}

export function setFiltroComprovada(filtro = {}) {
    return {
        type: 'DOCUMENTO_VIAGEM_COMPROVADA_FILTRO',
        payload: {
			filtro: filtro
		}
    };
}

export function setFiltroFinalizada(filtro = {}) {
    return {
        type: 'DOCUMENTO_VIAGEM_FINALIZADA_FILTRO',
        payload: {
			filtro: filtro
		}
    };
}

export function initForm(registro = {}) {
    return [
        initialize('documentoViagemForm', registro)
    ];
}

export function getListaAberta() {

	return (dispatch, getState) => {
		dispatch(setAguardando(true));
    	axios.get(`${consts.API_URL}/documentoViagem?
			id_status=1
			&data_inicial_emissao=${DateFormat.formatarDataTelaParaSql(getState().documentoViagem.filtroAberta.data_inicial_emissao) == 'Invalid DateTime' ? null : DateFormat.formatarDataTelaParaSql(getState().documentoViagem.filtroAberta.data_inicial_emissao)}
			&data_final_emissao=${DateFormat.formatarDataTelaParaSql(getState().documentoViagem.filtroAberta.data_final_emissao) == 'Invalid DateTime' ? null : DateFormat.formatarDataTelaParaSql(getState().documentoViagem.filtroAberta.data_final_emissao)}
			&data_inicial_carga=${DateFormat.formatarDataTelaParaSql(getState().documentoViagem.filtroAberta.data_inicial_carga) == 'Invalid DateTime' ? null : DateFormat.formatarDataTelaParaSql(getState().documentoViagem.filtroAberta.data_inicial_carga)}
			&data_final_carga=${DateFormat.formatarDataTelaParaSql(getState().documentoViagem.filtroAberta.data_final_carga) == 'Invalid DateTime' ? null : DateFormat.formatarDataTelaParaSql(getState().documentoViagem.filtroAberta.data_final_carga)}
			&id_operacao=${getState().documentoViagem.filtroAberta.id_operacao || null}`)
		.then(resp => {
			dispatch({
		        type: 'DOCUMENTO_VIAGEM_ABERTA_LISTADO',
		        payload: resp
		    });
			dispatch(setAguardando(false));
		}).catch(e => {
			setErroAPI(e);
			dispatch(setAguardando(false));
		});
	}
}

export function getListaComprovacao() {

	return (dispatch, getState) => {
		dispatch(setAguardando(true));
    	axios.get(`${consts.API_URL}/documentoViagem?
			id_status=2
			&data_inicial_carga=${DateFormat.formatarDataTelaParaSql(getState().documentoViagem.filtroComprovacao.data_inicial_carga) == 'Invalid DateTime' ? null : DateFormat.formatarDataTelaParaSql(getState().documentoViagem.filtroComprovacao.data_inicial_carga)}
			&data_final_carga=${DateFormat.formatarDataTelaParaSql(getState().documentoViagem.filtroComprovacao.data_final_carga) == 'Invalid DateTime' ? null : DateFormat.formatarDataTelaParaSql(getState().documentoViagem.filtroComprovacao.data_final_carga)}
			&data_inicial_entrega=${DateFormat.formatarDataTelaParaSql(getState().documentoViagem.filtroComprovacao.data_inicial_entrega) == 'Invalid DateTime' ? null : DateFormat.formatarDataTelaParaSql(getState().documentoViagem.filtroComprovacao.data_inicial_entrega)}
			&data_final_entrega=${DateFormat.formatarDataTelaParaSql(getState().documentoViagem.filtroComprovacao.data_final_entrega) == 'Invalid DateTime' ? null : DateFormat.formatarDataTelaParaSql(getState().documentoViagem.filtroComprovacao.data_final_entrega)}
			&id_operacao=${getState().documentoViagem.filtroComprovacao.id_operacao || null}`)
		.then(resp => {
			dispatch({
		        type: 'DOCUMENTO_VIAGEM_COMPROVACAO_LISTADO',
		        payload: resp
		    });
			dispatch(setAguardando(false));
		}).catch(e => {
			setErroAPI(e);
			dispatch(setAguardando(false));
		});
	}
}

export function getListaComprovada() {
	return (dispatch, getState) => {
		dispatch(setAguardando(true));
    	axios.get(`${consts.API_URL}/documentoViagem?
			id_status=3
			&data_inicial_carga=${DateFormat.formatarDataTelaParaSql(getState().documentoViagem.filtroComprovada.data_inicial_carga) == 'Invalid DateTime' ? null : DateFormat.formatarDataTelaParaSql(getState().documentoViagem.filtroComprovada.data_inicial_carga)}
			&data_final_carga=${DateFormat.formatarDataTelaParaSql(getState().documentoViagem.filtroComprovada.data_final_carga) == 'Invalid DateTime' ? null : DateFormat.formatarDataTelaParaSql(getState().documentoViagem.filtroComprovada.data_final_carga)}
			&data_inicial_entrega=${DateFormat.formatarDataTelaParaSql(getState().documentoViagem.filtroComprovada.data_inicial_entrega) == 'Invalid DateTime' ? null : DateFormat.formatarDataTelaParaSql(getState().documentoViagem.filtroComprovada.data_inicial_entrega)}
			&data_final_entrega=${DateFormat.formatarDataTelaParaSql(getState().documentoViagem.filtroComprovada.data_final_entrega) == 'Invalid DateTime' ? null : DateFormat.formatarDataTelaParaSql(getState().documentoViagem.filtroComprovada.data_final_entrega)}
			&data_inicial_finalizado=${DateFormat.formatarDataTelaParaSql(getState().documentoViagem.filtroComprovada.data_inicial_finalizado) == 'Invalid DateTime' ? null : DateFormat.formatarDataTelaParaSql(getState().documentoViagem.filtroComprovada.data_inicial_finalizado)}
			&data_final_finalizado=${DateFormat.formatarDataTelaParaSql(getState().documentoViagem.filtroComprovada.data_final_finalizado) == 'Invalid DateTime' ? null : DateFormat.formatarDataTelaParaSql(getState().documentoViagem.filtroComprovada.data_final_finalizado)}
			&id_operacao=${getState().documentoViagem.filtroComprovada.id_operacao || null}`)
		.then(resp => {
			dispatch({
		        type: 'DOCUMENTO_VIAGEM_COMPROVADA_LISTADO',
		        payload: resp
		    });
			dispatch(setAguardando(false));
		}).catch(e => {
			setErroAPI(e);
			dispatch(setAguardando(false));
		});
	}
}

export function getListaFinalizada() {
	return (dispatch, getState) => {
		dispatch(setAguardando(true));
    	axios.get(`${consts.API_URL}/documentoViagem?
			id_status=4
			&data_inicial_carga=${DateFormat.formatarDataTelaParaSql(getState().documentoViagem.filtroFinalizada.data_inicial_carga) == 'Invalid DateTime' ? null : DateFormat.formatarDataTelaParaSql(getState().documentoViagem.filtroFinalizada.data_inicial_carga)}
			&data_final_carga=${DateFormat.formatarDataTelaParaSql(getState().documentoViagem.filtroFinalizada.data_final_carga) == 'Invalid DateTime' ? null : DateFormat.formatarDataTelaParaSql(getState().documentoViagem.filtroFinalizada.data_final_carga)}
			&data_inicial_entrega=${DateFormat.formatarDataTelaParaSql(getState().documentoViagem.filtroFinalizada.data_inicial_entrega) == 'Invalid DateTime' ? null : DateFormat.formatarDataTelaParaSql(getState().documentoViagem.filtroFinalizada.data_inicial_entrega)}
			&data_final_entrega=${DateFormat.formatarDataTelaParaSql(getState().documentoViagem.filtroFinalizada.data_final_entrega) == 'Invalid DateTime' ? null : DateFormat.formatarDataTelaParaSql(getState().documentoViagem.filtroFinalizada.data_final_entrega)}
			&id_operacao=${getState().documentoViagem.filtroFinalizada.id_operacao || null}`)
		.then(resp => {
			dispatch({
		        type: 'DOCUMENTO_VIAGEM_FINALIZADA_LISTADO',
		        payload: resp
		    });
			dispatch(setAguardando(false));
		}).catch(e => {
			setErroAPI(e);
			dispatch(setAguardando(false));
		});
	}
}

export function salvar(registro) {
    return (dispatch, getState) => {
		if (!registro.id) {

			registro = {
				...registro,
				id_usuario_inclusao: getState().auth.usuario.id,
				id_usuario_alteracao: getState().auth.usuario.id,
				id_status: 1,
				datahora_viagem: registro.datahora_viagem ? DateFormat.formatarDataHoraTelaParaSql(registro.datahora_viagem) : null,
				datahora_carga: registro.datahora_carga ? DateFormat.formatarDataHoraTelaParaSql(registro.datahora_carga) : null,
				datahora_descarga: registro.datahora_descarga ? DateFormat.formatarDataHoraTelaParaSql(registro.datahora_descarga) : null
			};

	        axios.post(`${consts.API_URL}/documentoViagem`, registro).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getListaAberta());
				dispatch(setModoTela('cadastro', {
					...registro,
					id: resp.data.id,
					datahora_viagem: DateFormat.formatarDataHoraSqlParaTela(registro.datahora_viagem),
					datahora_carga: DateFormat.formatarDataHoraSqlParaTela(registro.datahora_carga),
					datahora_descarga: DateFormat.formatarDataHoraSqlParaTela(registro.datahora_descarga)
				}));
				dispatch(initForm({
					...registro,
					id: resp.data.id,
					datahora_viagem: DateFormat.formatarDataHoraSqlParaTela(registro.datahora_viagem),
					datahora_carga: DateFormat.formatarDataHoraSqlParaTela(registro.datahora_carga),
					datahora_descarga: DateFormat.formatarDataHoraSqlParaTela(registro.datahora_descarga)
				}));
				dispatch(getListaHistorico(resp.data.id));
				dispatch(getListaComprovante(resp.data.id));
			}).catch(e => {
				setErroAPI(e);
			});
		} else {
			axios.put(`${consts.API_URL}/documentoViagem`, {
				...registro,
				id_usuario_alteracao: getState().auth.usuario.id,
				datahora_viagem: DateFormat.formatarDataHoraTelaParaSql(registro.datahora_viagem),
				datahora_carga: DateFormat.formatarDataHoraTelaParaSql(registro.datahora_carga),
				datahora_descarga: DateFormat.formatarDataHoraTelaParaSql(registro.datahora_descarga)
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getListaAberta());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		}
    };
}

export function alterarManual(registro) {
    return (dispatch, getState) => {
		
		axios.post(`${consts.API_URL}/documentoViagem/alterarManual`, {
			...registro,
			datahora_viagem: DateFormat.formatarDataHoraTelaParaSql(registro.datahora_viagem),
			datahora_carga: DateFormat.formatarDataHoraTelaParaSql(registro.datahora_carga),
			datahora_descarga: DateFormat.formatarDataHoraTelaParaSql(registro.datahora_descarga),
			id_usuario_alteracao: getState().auth.usuario.id
		}).then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getListaAberta());
			dispatch(getListaComprovacao());
			dispatch(setModoTela('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function setEncerrada(registro, encerrada = false) {
    return (dispatch, getState) => {

		axios.post(`${consts.API_URL}/documentoViagem/encerrada`, {
			...registro,
			id_usuario_alteracao: getState().auth.usuario.id,
			id_status: encerrada ? 2 : 1
		}).then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getListaAberta());
			dispatch(getListaComprovacao());
			dispatch(getListaComprovada());
			dispatch(getListaFinalizada());
			dispatch(setModoTela('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function setFinanceiro(registro, financeiro = false) {
    return (dispatch, getState) => {

		axios.post(`${consts.API_URL}/documentoViagem/financeiro`, {
			...registro,
			id_usuario_alteracao: getState().auth.usuario.id,
			id_status: financeiro ? 3 : 2,
			datahora_comprovada: financeiro ? registro.datahora_comprovada : null
		}).then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getListaAberta());
			dispatch(getListaComprovacao());
			dispatch(getListaComprovada());
			dispatch(getListaFinalizada());
			dispatch(setModoTela('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function setFinalizada(registro, finalizada = false) {
    return (dispatch, getState) => {

		axios.post(`${consts.API_URL}/documentoViagem/finalizada`, {
			...registro,
			id_usuario_alteracao: getState().auth.usuario.id,
			id_status: finalizada ? 4 : 2,
			datahora_comprovada: finalizada ? (registro.datahora_comprovada ? registro.datahora_comprovada : DateFormat.getDataHoraAtual()) : null,
			datahora_finalizacao: finalizada ? (registro.datahora_finalizacao ? registro.datahora_finalizacao : DateFormat.getDataHoraAtual()) : null
		}).then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getListaAberta());
			dispatch(getListaComprovacao());
			dispatch(getListaComprovada());
			dispatch(getListaFinalizada());
			dispatch(setModoTela('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function excluir(registro) {
    return (dispatch, getState) => {
        axios.delete(`${consts.API_URL}/documentoViagem?id=${registro.id}&id_usuario_alteracao=${getState().auth.usuario.id}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getListaAberta());
			dispatch(setModoTela('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function getListaOperacao() {
	return (dispatch, getState) => {
    	axios.get(`${consts.API_URL}/operacao/listarSelect`)
		.then(resp => {
			dispatch({
				type: 'DOCUMENTO_VIAGEM_OPERACAO_SELECT_LISTADO',
		        payload: resp
		    });
		}).catch(e => {
			setErroAPI(e);
		});
	}
}

export function getListaStatus() {
	return (dispatch, getState) => {
    	axios.get(`${consts.API_URL}/documentoViagemStatus/listarSelect`)
		.then(resp => {
			dispatch({
				type: 'DOCUMENTO_VIAGEM_STATUS_SELECT_LISTADO',
		        payload: resp
		    });
		}).catch(e => {
			setErroAPI(e);
		});
	}
}

export function getListaCliente() {
	return async (dispatch, getState) => {
    	await axios.get(`${consts.API_URL}/cliente/listarSelect`)
		.then(resp => {
			dispatch({
				type: 'DOCUMENTO_VIAGEM_CLIENTE_SELECT_LISTADO',
		        payload: resp
		    });
		}).catch(e => {
			setErroAPI(e);
		});
	}
}

export function getListaDocumento() {
	return (dispatch, getState) => {
    	axios.get(`${consts.API_URL}/documento/listarSelect`)
		.then(resp => {
			dispatch({
				type: 'DOCUMENTO_VIAGEM_DOCUMENTO_SELECT_LISTADO',
		        payload: resp
		    });
		}).catch(e => {
			setErroAPI(e);
		});
	}
}

//Métodos dos Itens
export function setModoTelaComprovante(modo, registro = {}) {
    return {
        type: 'DOCUMENTO_VIAGEM_COMPROVANTE_MODO_TELA',
        payload: {
			modo: modo,
			registro: registro
		}
    };
}

export function getListaHistorico(id_documento_viagem) {

	return (dispatch, getState) => {
    	axios.get(`${consts.API_URL}/documentoViagem/historico?id_documento_viagem=${id_documento_viagem}`)
		.then(resp => {
			dispatch({
		        type: 'DOCUMENTO_VIAGEM_HISTORICO_LISTADO',
		        payload: resp
		    });
		}).catch(e => {
			setErroAPI(e);
		});
	}
}

export function initFormComprovante(registro = {}) {
    return [
        initialize('documentoViagemComprovanteForm', registro)
    ];
}

export function getListaComprovante(id_documento_viagem) {

	return (dispatch, getState) => {
    	axios.get(`${consts.API_URL}/documentoViagemComprovante?id_documento_viagem=${id_documento_viagem}`)
		.then(resp => {
			dispatch({
		        type: 'DOCUMENTO_VIAGEM_COMPROVANTE_LISTADO',
		        payload: resp
		    });
		}).catch(e => {
			setErroAPI(e);
		});
	}
}

export function setListaComprovante(lista) {
	return {
		type: 'DOCUMENTO_VIAGEM_COMPROVANTE_LISTADO',
		payload: { data: lista }
	};
}

export function salvarComprovante(registro) {
    return (dispatch, getState) => {
		if (!registro.id) {
	        axios.post(`${consts.API_URL}/documentoViagemComprovante`, {
				...registro,
				id_usuario_inclusao: getState().auth.usuario.id,
				id_usuario_alteracao: getState().auth.usuario.id,
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getListaHistorico(registro.id_documento_viagem));
				dispatch(getListaComprovante(registro.id_documento_viagem));
				dispatch(setModoTelaComprovante('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		} else {
			axios.put(`${consts.API_URL}/documentoViagemComprovante`, {
				...registro,
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getListaHistorico(registro.id_documento_viagem));
				dispatch(getListaComprovante(registro.id_documento_viagem));
				dispatch(setModoTelaComprovante('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		}
    };
}

export function validarComprovanteFinanceiro(registro) {
    return (dispatch, getState) => {
		axios.post(`${consts.API_URL}/documentoViagemComprovante/validarComprovanteFinanceiro`, {
			...registro,
			id_usuario_alteracao: getState().auth.usuario.id
		}).then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getListaHistorico(registro.id_documento_viagem));
			dispatch(getListaComprovante(registro.id_documento_viagem));
			dispatch(setModoTelaComprovante('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function validarComprovanteFinanceiroNovo(registro) {

    return async (dispatch, getState) => {
		await axios.get(`${consts.API_URL}/documentoViagemComprovante?id_documento_viagem=${registro.id}`)
		.then(async resp => {
			for (let i = 0; i < resp.data.length; i++) {
				let item = resp.data[i];

				if (item.original) {
					await axios.post(`${consts.API_URL}/documentoViagemComprovante/validarComprovanteFinanceiro`, {
						...item,
						digital_validado: true,
						original_validado: true,
						id_usuario_alteracao: getState().auth.usuario.id
					}).then(resp => {
						
					}).catch(e => {
						setErroAPI(e);
					});
				}
				
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getListaAberta());
				dispatch(getListaComprovacao());
				dispatch(getListaComprovada());
				dispatch(getListaFinalizada());
			}
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function excluirComprovante(registro) {
    return dispatch => {
        axios.delete(`${consts.API_URL}/documentoViagemComprovante?id=${registro.id}&id_documento_viagem=${registro.id_documento_viagem}&id_usuario_alteracao=${registro.id_usuario_alteracao}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getListaHistorico(registro.id_viagem));
			dispatch(getListaComprovante(registro.id_viagem));
			dispatch(setModoTelaComprovante('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function buscaInformacaoSolicitacao(registro) {
	return async (dispatch, getState) => {

		if (!registro.solicitacao || !registro.solicitacao.trim()) {
			setErroAPI('Solicitação não informada');
			return;
		}

		await axios.get(`${consts.API_URL}/documentoViagem/buscaInformacaoSolicitacao?solicitacao=${(registro.solicitacao || '').trim()}`)
		.then(async resp => {

			if (resp.data) {

				let cliente = getState().documentoViagem.listaCliente.filter(item => item.cnpj == resp.data.cnpj_cliente_origem)[0];
				let novoCliente = false;
				if (!cliente) {
					novoCliente = true;
					await axios.post(`${consts.API_URL}/cliente`, {
						razao_social: resp.data.nome_cliente_origem,
						nome_fantasia: resp.data.nome_cliente_origem,
						cnpj: resp.data.cnpj_cliente_origem,
						id_usuario_alteracao: getState().auth.usuario.id
					}).then(async resp => {
						cliente = {
							...resp.data,
							razao_social: resp.data.nome_cliente_origem,
							nome_fantasia: resp.data.nome_cliente_origem,
							cnpj: resp.data.cnpj_cliente_origem,
							id_usuario_alteracao: getState().auth.usuario.id
						};
					}).catch(e => {
						console.log(e);
						setErroAPI(e);
					});
				}

				let recebedor = getState().documentoViagem.listaCliente.filter(item => item.cnpj == resp.data.cnpj_cliente_destino)[0];
				if (!recebedor) {
					novoCliente = true;
					await axios.post(`${consts.API_URL}/cliente`, {
						razao_social: resp.data.nome_cliente_destino,
						nome_fantasia: resp.data.nome_cliente_destino,
						cnpj: resp.data.cnpj_cliente_destino,
						id_usuario_alteracao: getState().auth.usuario.id
					}).then(async resp => {
						recebedor = {
							...resp.data,
							razao_social: resp.data.nome_cliente_destino,
							nome_fantasia: resp.data.nome_cliente_destino,
							cnpj: resp.data.cnpj_cliente_destino,
							id_usuario_alteracao: getState().auth.usuario.id
						};
					}).catch(e => {
						console.log(e);
						setErroAPI(e);
					});
				}

				if (novoCliente) {
					dispatch(await getListaCliente());
				}

				dispatch(initForm({
					...registro,
					datahora_carga: DateFormat.formatarDataHoraSqlParaTela(resp.data.datahora_carga),
					datahora_descarga: DateFormat.formatarDataHoraSqlParaTela(resp.data.datahora_descarga),
					tipo_documento: resp.data.tipo,
					mic_documento: resp.data.mic,
					numero_documento: resp.data.numero,
					cavalo: resp.data.cavalo,
					carreta1: (resp.data.carretas || '').split(',')[0],
					carreta2: (resp.data.carretas || '').split(',')[1],
					nome_motorista: resp.data.nome_motorista,
					id_cliente: cliente ? cliente.id : null,
					cidade_coleta: resp.data.cidade_cliente_origem,
					estado_coleta: resp.data.estado_cliente_origem,
					pais_coleta: resp.data.pais_cliente_origem,
					id_recebedor: recebedor ? recebedor.id : null,
					cidade_entrega: resp.data.cidade_cliente_destino,
					estado_entrega: resp.data.estado_cliente_destino,
					pais_entrega: resp.data.pais_cliente_destino
				}));
			}
		}).catch(e => {
			console.log(e);
			// setErroAPI(e);
		});
	}
}

//Métodos das Ocorrências
export function setModoTelaOcorrencia(modo, registro = {}) {
    return {
        type: 'DOCUMENTO_VIAGEM_OCORRENCIA_MODO_TELA',
        payload: {
			modo: modo,
			registro: registro
		}
    };
}

export function getListaOcorrencia(id_documento_viagem) {

	return (dispatch, getState) => {
    	axios.get(`${consts.API_URL}/ocorrencia?id_documento_viagem=${id_documento_viagem}`)
		.then(resp => {
			dispatch({
		        type: 'DOCUMENTO_VIAGEM_OCORRENCIA_LISTADO',
		        payload: resp
		    });
		}).catch(e => {
			setErroAPI(e);
		});
	}
}

//PALETES

export function setModoTelaPalete(modo, registro = {}) {
    return {
        type: 'DOCUMENTO_VIAGEM_PALETE_MODO_TELA',
        payload: {
			modo: modo,
			registro: registro
		}
    };
}

export function initFormPalete(registro = {}) {
    return [
        initialize('viagemPaleteForm', registro)
    ];
}

export function getListaPalete(solicitacao) {

	return (dispatch, getState) => {
    	axios.get(`${consts.API_URL}/documentoViagemPalete?solicitacao=${solicitacao}`)
		.then(resp => {
			dispatch({
		        type: 'DOCUMENTO_VIAGEM_PALETE_LISTADO',
		        payload: resp
		    });
		}).catch(e => {
			setErroAPI(e);
		});
	}
}

export function salvarPalete(registro) {
    return (dispatch, getState) => {
		axios.put(`https://api-paletes.infinitum-ti.com/oapi/viagem/comprovante`, {
			...registro
		}).then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getListaPalete(registro.solicitacao));
			dispatch(getListaPaleteStatus());
			dispatch(setModoTela('cadastro'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function finalizarPalete(registro) {
    return (dispatch, getState) => {

		axios.post(`${consts.API_URL}/documentoViagemPalete/finalizar`, {
			...registro
		}).then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getListaPalete(registro.solicitacao));
			dispatch(getListaPaleteStatus());
			dispatch(setModoTela('cadastro'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function reabrirPalete(registro) {
    return (dispatch, getState) => {

		axios.post(`${consts.API_URL}/documentoViagemPalete/reabrir`, {
			...registro
		}).then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getListaPalete(registro.solicitacao));
			dispatch(getListaPaleteStatus());
			dispatch(setModoTela('cadastro'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function getListaPaleteStatus() {

	return (dispatch, getState) => {
    	axios.get(`${consts.API_URL}/documentoViagemPalete/listarStatus`)
		.then(resp => {			
			dispatch({
		        type: 'DOCUMENTO_VIAGEM_PALETE_STATUS_LISTADO',
		        payload: resp
		    });
		}).catch(e => {
			setErroAPI(e);
		});
	}
}