const INITIAL_STATE = {
	registro: null,
	modoTela: 'lista',
	listaCnpj: [
		{ id: '92337732000190', valor: 'Ijuí - RS' },
		{ id: '92337732000270', valor: 'Embu das Artes - SP' },
		{ id: '92337732000785', valor: 'Canoinhas - SC' },
		{ id: '92337732001242', valor: 'Campina Grande do Sul - PR' },
	],
	lista: [],
	listaLog: []
};


export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case 'BRASKEM_MODO_TELA':
            return { ...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};

        case 'BRASKEM_LISTADO':
            return {
				...state,
				registro: action.payload.data
			};
		
		case 'BRASKEM_LOG_LISTADO':
			return {
				...state,
				listaLog: action.payload.data
			};

        default:
            return state;
    }
}
