import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import DateFormat from '../common/dateFormat/DateFormat';

export function setModoTela(modo, registro = { id_estoque_local: 2 }) {
    return {
        type: 'LAUDO_CONTROLE_MODO_TELA',
        payload: {
			modo: modo,
			registro: registro
		}
    };
}

export function setAguardando(aguardando) {
    return {
        type: 'LAUDO_CONTROLE_SET_AGUARDANDO',
        payload: aguardando
    };
}

export function setFiltro(filtro) {
    return {
        type: 'LAUDO_CONTROLE_FILTRO',
        payload: {
			filtro: filtro
		}
    };
}

export function getLista() {
	return (dispatch, getState) => {

		dispatch(setAguardando(true));
		axios.get(`${consts.API_URL}/laudoControle`).then(resp => {
			dispatch({
				type: 'LAUDO_CONTROLE_LISTADO',
		        payload: resp
			});
			dispatch(setAguardando(false));
		}).then(resp => {
			dispatch(setAguardando(false));
		}).catch(e => {
			setErroAPI(e);
			dispatch(setAguardando(false));
		});
	}
}

export function getListaTipo() {
	return (dispatch, getState) => {
    	axios.get(`${consts.API_URL}/acessorioTipo/listarSelect`)
		.then(resp => {
			dispatch({
				type: 'LAUDO_CONTROLE_TIPO_SELECT_LISTADO',
		        payload: resp
		    });
		}).catch(e => {
			setErroAPI(e);
		});
	}
}

export function getListaEvento() {
	return (dispatch, getState) => {
    	axios.get(`${consts.API_URL}/acessorioEvento/listarSelect`)
		.then(resp => {
			dispatch({
				type: 'LAUDO_CONTROLE_EVENTO_SELECT_LISTADO',
		        payload: resp
		    });
		}).catch(e => {
			setErroAPI(e);
		});
	}
}

export function getListaUnidade() {
    const request = axios.get(`${consts.API_URL}/unidade/listarSelect`);
    return {
        type: 'LAUDO_CONTROLE_UNIDADE_SELECT_LISTADO',
        payload: request
    };
}

export function getListaVeiculo() {
	return (dispatch, getState) => {
    	axios.get(`${consts.API_URL}/veiculo/listarSelect`)
		.then(resp => {
			dispatch({
				type: 'LAUDO_CONTROLE_VEICULO_SELECT_LISTADO',
		        payload: resp
		    });
		}).catch(e => {
			setErroAPI(e);
		});
	}
}

export function getListaMotorista() {
	return (dispatch, getState) => {
    	axios.get(`${consts.API_URL}/motorista/listarSelect`)
		.then(resp => {
			dispatch({
				type: 'LAUDO_CONTROLE_MOTORISTA_SELECT_LISTADO',
		        payload: resp
		    });
		}).catch(e => {
			setErroAPI(e);
		});
	}
}

export function baixarAnexos(lista) {
	return (dispatch, getState) => {
		dispatch(setAguardando(true));

		let listaExportar = [];
		lista.forEach(item => {
			listaExportar.push(item.id);
			if (item.modo == 'cavalo' && item.laudoControleCarreta) {
				listaExportar.push(item.laudoControleCarreta.id);
			}
		});
    	axios.post(`${consts.API_URL}/laudoControle/baixarAnexos`, {
			lista: listaExportar
		}).then(resp => {
			dispatch(setAguardando(false));
			window.open(`${consts.URL}/${resp.data}`, '_blank');
		}).catch(e => {
			dispatch(setAguardando(false));
			setErroAPI(e);
		});
	}
}
