import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ContentCardFooter from '../common/template/contentCardFooter';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import LabelAndInputDateTime from '../common/form/labelAndInputDateTime';
import Select from '../common/form/select';
import SelectAlteracao from '../common/form/selectAlteracao';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import OperacaoDocumentoForm from './operacaoDocumentoForm';
import OperacaoExcecaoForm from './operacaoExcecaoForm';
import { setError } from '../common/toast/toast';
import imagemAdd from '../assets/images/icone_add_imagem.png';
import pdfAdd from '../assets/images/icone_add_pdf.png';
import pdfAdicionado from '../assets/images/icone_pdf_adicionado.png';
import readXlsxFile from 'read-excel-file/web-worker'

import {
	setModoTela, initForm,
	salvarItem, excluirItem, setModoTelaItem, initFormItem, getListaItem, getListaDocumento,
	salvarExcecao, excluirExcecao, setModoTelaExcecao, initFormExcecao, getListaExcecao, importarExcecao
} from './operacaoActions';

class OperacaoForm extends Component {

	state = {

	}

	componentDidMount() {

		this.props.getListaDocumento();

		const fileSelectorArquivo = document.createElement('input');
		fileSelectorArquivo.setAttribute('type', 'file');
		fileSelectorArquivo.addEventListener("change", (data) => {

			let reader = new FileReader();
			let file = data && data.path && data.path[0] && data.path[0].files && data.path[0].files[0] ? data.path[0].files[0] : null;
			if (!file) {
				file = data && data.target && data.target.files && data.target.files[0] ? data.target.files[0] : null;
			}

			if (file) {

				if (!file.type.includes('application/pdf')
					&& !file.type.includes('image/png') && !file.type.includes('image/jpg') && !file.type.includes('image/jpeg')) {
					setError('Selecione apenas (.PDF, .PNG, .JPG e .JPEG)');
					return;
				}

				reader.onloadend = () => {

					let lista = (this.props.formularioValues.arquivo || []).map(item => item);
					lista.push({
						arquivo_base64: reader.result
					});

					this.props.initForm({
						...this.props.formularioValues,
						arquivo: lista
					});

				}

				reader.readAsDataURL(file);

			}
		});

		this.fileSelectorArquivo = fileSelectorArquivo;

		let initSeletorImportacao = () => {
	
			const fileSelectorImportacao = document.createElement('input');
			fileSelectorImportacao.setAttribute('type', 'file');
			fileSelectorImportacao.addEventListener("change", async (data) => {
		
				let reader = new FileReader();
				let file = data && data.path && data.path[0] && data.path[0].files && data.path[0].files[0] ? data.path[0].files[0] : null;
				if (!file) {
					file = data && data.target && data.target.files && data.target.files[0] ? data.target.files[0] : null;
				}
		
				if (file) {
		
					if (!file.type.includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
						|| (file.type.includes('application/excel') || file.type.includes('application/vnd.ms-excel'))) {
						setError('Selecione apenas (.XLSX)');
						return;
					}
		
					let tabela = [];
					await readXlsxFile(file, { sheet: 1 }).then((rows) => {
						tabela = rows;
					});
		
					this.props.importarExcecao(tabela, this.props.formularioValues.id);
		
					reader.readAsDataURL(file);

					initSeletorImportacao();
		
				}
			});
		
			this.fileSelectorImportacao = fileSelectorImportacao;
		};

		initSeletorImportacao();
    }

    componentWillMount() {

    }

    render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';

        return (
			<div>
				<ContentCard>
					<Form event={this.props.handleSubmit}>
						<ContentCardBody>
							<Row>
								<Field
									name='codigo'
									component={LabelAndInput}
									label='Código' placeholder='Informe o código'
									cols='12 12 12 12'
									readOnly={readOnly} />

								<Field
									name='nome'
									component={LabelAndInput}
									label='Nome' placeholder='Informe o nome'
									cols='12 12 12 12'
									readOnly={readOnly} />
									
								<Field
									name='id_perfil'
									component={Select}
									label='Perfil' placeholder='Informe o perfil'
									cols='12 12 12 12'
									readOnly={readOnly}
									options={this.props.listaPerfil} />
									
								<Field
									name='observacao'
									component={LabelAndInput}
									label='Observação' placeholder='Informe a observação'
									cols='12 12 12 12'
									readOnly={readOnly} />

								<Field
									name='email1'
									component={LabelAndInput}
									label='E-mail 1' placeholder='Informe o e-mail'
									cols='12 4 3 3'
									readOnly={readOnly} />
									
								<Field
									name='email2'
									component={LabelAndInput}
									label='E-mail 2' placeholder='Informe o e-mail'
									cols='12 4 3 3'
									readOnly={readOnly} />

								<Field
									name='email3'
									component={LabelAndInput}
									label='E-mail 3' placeholder='Informe o e-mail'
									cols='12 4 3 3'
									readOnly={readOnly} />


								
							</Row>

							<Row>
							<Grid cols='12 12 12 12'>
								<label>Arquivo</label>
							</Grid>
							{(this.props.formularioValues.arquivo || []).map(arquivo => (
								<div style={{ display: 'flex', flexDirection: 'column' }}>
									<span class="btn btn-outline-primary fileinput-button dz-clickable">
										<img src={arquivo.arquivo_base64 ? pdfAdicionado : (arquivo.arquivo ? pdfAdicionado : pdfAdd)} style={{ height: 30 }} />
									</span>
									<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingRight: 2, marginTop: 4 }}>
										<button
											type='button'
											class='btn btn-flat btn-outline-primary'
											style={{ width: '48%' }}
											disabled={arquivo.arquivo_base64 ?true : false}
											onClick={() => {
												window.open(`https://api-letsarapremiacao.infinitum-ti.com/${arquivo.arquivo}`, '_blank');
											}}>
											<i class='fas fa-download'></i>
										</button>

										<button
											type='button'
											class='btn btn-flat btn-outline-danger'
											style={{ width: '48%' }}
											disabled={readOnly}
											onClick={() => {
												this.props.initForm({
													...this.props.formularioValues,
													arquivo: this.props.formularioValues.arquivo.filter(item => item != arquivo)
												});
											}}>
											<i class='fas fa-trash-alt'></i>
										</button>
									</div>
								</div>
							))}
							{!readOnly ? (
								<span class="btn btn-outline-primary fileinput-button dz-clickable" onClick={() => this.fileSelectorArquivo.click()}>
									<img src={pdfAdd} style={{ height: 30 }} />
								</span>
							) : null}
						</Row>

						</ContentCardBody>
						<ContentCardFooter>
							<Row alignCenter>
								<Grid cols='6 6 4 3'>
									{this.props.excluir ? (
										<Button
											text='Excluir'
											submit type={'danger'}
											icon={'fa fa-trash'} />
									) : (
									<Button
										text='Salvar'
										submit
										type={'success'}
										icon={'fa fa-check'} />
									)}
								</Grid>
								<Grid cols='6 6 4 3'>
									<Button
										text='Voltar'
										type={'warning'}
										icon={'fa fa-chevron-left'}
										event={() => this.props.setModoTela('lista')} />
								</Grid>
							</Row>
						</ContentCardFooter>
					</Form>
				</ContentCard>

				{this.props.modoTelaItem == 'cadastro' ? (
					<OperacaoDocumentoForm onSubmit={this.props.salvarItem} />
				) : null}
				{this.props.modoTelaItem == 'exclusao' ? (
					<OperacaoDocumentoForm excluir onSubmit={this.props.excluirItem} />
				) : null}
				{this.props.modoTelaItem == 'lista' && this.props.formularioValues.id ? this.listaItens() : null}


				{this.props.modoTelaExcecao == 'cadastro' ? (
					<OperacaoExcecaoForm onSubmit={this.props.salvarExcecao} />
				) : null}
				{this.props.modoTelaExcecao == 'exclusao' ? (
					<OperacaoExcecaoForm excluir onSubmit={this.props.excluirExcecao} />
				) : null}
				{this.props.modoTelaExcecao == 'lista' && this.props.formularioValues.id ? this.listaExcecoes() : null}

			</div>
        )
    }

	listaItens() {

		return (
			<ContentCard>
				<ContentCardHeader>
					<Row>
						<Grid cols='6 6 4 2'>
							<Button
								text='Adicionar'
								type={'success'}
								icon={'fa fa-plus'}
								event={() => {
									this.props.setModoTelaItem('cadastro', { id_operacao: this.props.formularioValues.id });
									this.props.initFormItem({ id_operacao: this.props.formularioValues.id });
								}} />
						</Grid>
					</Row>
				</ContentCardHeader>
				<ContentCardBody>
					<Table responsive>
						<THead>
							<Tr>
								<Th>Documento</Th>
								<Th alignCenter>Cliente</Th>
								<Th alignCenter>Letsara</Th>
								<Th alignCenter>Digital</Th>
								<Th></Th>
							</Tr>
						</THead>
						<TBody>
							{this.props.listaItem.map(item => (
								<Tr key={item.id} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
									onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
									onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
									<Td>{item.nome_documento}</Td>
									<Td alignCenter>{item.nome_cliente_tipo || '-'}</Td>
									<Td alignCenter>{item.nome_letsara_tipo || '-'}</Td>
									<Td alignCenter>{item.digital ? 'SIM' : '-'}</Td>
									<Td alignRight minWidth={100}>
										<ButtonTable
											type={'warning'}
											icon={'fas fa-pencil-alt'}
											visible={this.state.linhaSelecionada == item.id}
											event={() => {
												this.props.setModoTelaItem('cadastro', {
													...item
												});
												this.props.initFormItem({
													...item
												});
											}} />

										<ButtonTable
											type={'danger'}
											icon={'fas fa-trash-alt'}
											visible={this.state.linhaSelecionada == item.id}
											event={() => {
												this.props.setModoTelaItem('exclusao', {
													...item
												});
												this.props.initFormItem({
													...item
												});
											}} />
									</Td>
								</Tr>
							))}
						</TBody>
					</Table>
				</ContentCardBody>
			</ContentCard>
		);
	}
	
	listaExcecoes() {

		return (
			<ContentCard>
				<ContentCardHeader>
					<Row>
						<Grid cols='6 6 4 2'>
							<Button
								text='Adicionar'
								type={'success'}
								icon={'fa fa-plus'}
								event={() => {
									this.props.setModoTelaExcecao('cadastro', { id_operacao: this.props.formularioValues.id });
									this.props.initFormExcecao({ id_operacao: this.props.formularioValues.id });
								}} />
						</Grid>
						<Grid cols='6 6 4 2'>
							<Button
								text='Importar'
								type={'primary'}
								icon={'fa fa-plus'}
								event={() => {
									this.fileSelectorImportacao.click();
								}} />
						</Grid>
					</Row>
				</ContentCardHeader>
				<ContentCardBody>
					<Table responsive>
						<THead>
							<Tr>
								<Th>CNPJ do Destinatário</Th>
								<Th>Nome do Destinatário</Th>
								<Th>Exceção</Th>
								<Th></Th>
							</Tr>
						</THead>
						<TBody>
							{this.props.listaExcecao.map(item => (
								<Tr key={item.id} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
									onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
									onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
									<Td>{item.cnpj_destinatario}</Td>
									<Td>{item.razao_social}</Td>
									<Td>{item.excecao}</Td>
									<Td alignRight minWidth={100}>
										<ButtonTable
											type={'warning'}
											icon={'fas fa-pencil-alt'}
											visible={this.state.linhaSelecionada == item.id}
											event={() => {
												this.props.setModoTelaExcecao('cadastro', {
													...item
												});
												this.props.initFormExcecao({
													...item
												});
											}} />

										<ButtonTable
											type={'danger'}
											icon={'fas fa-trash-alt'}
											visible={this.state.linhaSelecionada == item.id}
											event={() => {
												this.props.setModoTelaExcecao('exclusao', {
													...item
												});
												this.props.initFormExcecao({
													...item
												});
											}} />
									</Td>
								</Tr>
							))}
						</TBody>
					</Table>
				</ContentCardBody>
			</ContentCard>
		);
    }

}

OperacaoForm = reduxForm({form: 'operacaoForm', destroyOnUnmount: false})(OperacaoForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	formularioValues: getFormValues('operacaoForm')(state),
	modoTelaItem: state.operacao.modoTelaItem,
	registro: state.operacao.registro,
	listaPerfil: state.operacao.listaPerfil,
	listaItem: state.operacao.listaItem,
	modoTelaExcecao: state.operacao.modoTelaExcecao,
	listaExcecao: state.operacao.listaExcecao
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, initForm,
	salvarItem, excluirItem, setModoTelaItem, initFormItem, getListaItem, getListaDocumento,
	salvarExcecao, excluirExcecao, setModoTelaExcecao, initFormExcecao, getListaExcecao, importarExcecao
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(OperacaoForm);
